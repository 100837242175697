<template>
    <div v-if="type.toLowerCase() ==='active'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #007247;"></span>
        <span class="statusvalue" style="color: black; ">Active</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='paid'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #007247;"></span>
        <span class="statusvalue" style="color: black; ">Paid</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='inactive'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #ff5050;"></span>
        <span class="statusvalue" style="color: black; ">Inactive</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='pending'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #f8a523;"></span>
        <span class="statusvalue" style="color: black; ">Pending</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='draft'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #2879b7;"></span>
        <span class="statusvalue" style="color: black; ">Draft</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='in study'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #2879b7;"></span>
        <span class="statusvalue" style="color: black; ">In study</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='validated'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #5F9EA0;"></span>
        <span class="statusvalue" style="color: black; ">Validated</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='cancelled'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #ff5050;"></span>
        <span class="statusvalue" style="color: black; ">Cancelled</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='rejected'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #ff5050;"></span>
        <span class="statusvalue" style="color: black; ">Rejected</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='partial'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #2879b7;"></span>
        <span class="statusvalue" style="color: black; ">Partial</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='inprogress'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #2879b7;"></span>
        <span class="statusvalue" style="color: black; ">Inprogress</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='held'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #f8a523;"></span>
        <span class="statusvalue" style="color: black; ">Held</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='acknowledged'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #02b55c;"></span>
        <span class="statusvalue" style="color: black; ">Acknowledged</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='completed'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #54bd95;"></span>
        <span class="statusvalue" style="color: black; ">Completed</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='failed'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #d0c9d6;"></span>
        <span class="statusvalue" style="color: black; ">Failed</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='initialized'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #2879b7;"></span>
        <span class="statusvalue" style="color: black; ">Initialized</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='approved'" class="status-wrapper">
      <div class="status">
        <span class="point" style="background: #007247;"></span>
        <span class="statusvalue" style="color: black; ">Approved</span>
      </div>
    </div>
    <div v-if="type.toLowerCase() ==='custom'" class="status-wrapper">
      <div class="status">
        <span class="point" :style="{ backgroundColor: pointColor }"></span>
        <span class="statusvalue" :style="{ color: statuscolor }">{{ statusValue }}</span>
      </div>
    </div>
    <div v-if="type.toLowerCase()==='select-status'">

      <hlxselect
      v-model:value="valuepre"
      :pre-value="valuepre"
      :slot-options="true" 
      :required="required"
      :options="statusOption1"
      :placeholder-value="placeholderValue"
      :disabled="readonly"
      :clearable="clearable"
      :single-border="singleBorder"
      :label="'name'"
      :prop-value="'value'"
      :label-animation="labelAnimation"
      :inline-search="true"
      @selected-value="emitStatus"
    > 
      <template #displayValue="value">
        <span style="padding-left: 0px; display: flex; align-items: center">
          <span
            :style="{
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              backgroundColor: value.displayLabel.color,
              marginLeft: '-2px',
              marginRight: '8px'
            }"
          ></span>
          {{ value.displayLabel[label] }}</span
        >
      </template>
      <template #options="option">
        <span style="padding-left: 8px; display: flex; align-items: center;
    font-size: 14px;
    font-family: 'Opensans';">
          <span
            :style="{
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              backgroundColor: option.optionsData.color,
              marginLeft: '4px',
              marginRight: '8px',
            }"
          ></span
          >{{ option.optionsData[label] }}</span
        >
      </template>
    </hlxselect>
    
    </div>
  </template>
  
  <script>
  import hlxselect from "./SelectComponent.vue";
  export default {
    name:"HlxStatus",
    components:{
      hlxselect
    },
    props: {
      label: {
      type: String,
      default: "name",
    },
      prevalue: {
      type: String,
      default: "",
    },
      singleBorder: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
      required: {
      type: Boolean,
      default: false,
    },
      labelAnimation: {
      type: Boolean,
      default: true,
    },
      statusOption:{
        type: Array,
        required: true,
        default:()=>([])
      },
      type:{
        type: String,
        required: true,
        default:"label-status"
      },
      statusValue: {
        type: String,
        // required: true,
        default:"Active "
      },
      placeholderValue: {
        type: String,
        required: false,
        default:"Status"
      },
      pointColor: {
        type: String,
        default: '#007247'
      },
      statuscolor: {
        type: String,
        default: '#000000'
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    emits: ['emit-select'],
    
    data(){
      return{
        valuepre:this.prevalue,
        statusOption1:this.statusOption
      }
    },
    watch:{
      prevalue: {
      handler(val) {
        this.valuepre = val;

        // console.log("yasar",this.val);
      },
      immediate: true,
      deep: true,
    },
    statusOption: {
      handler(val) {
        this.statusOption1 = val;
        this.valuepre = this.prevalue;
      },
      immediate: true,
      deep: true,
    }
    }, 
    methods:{
      getStaticStyle() {
    return {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: '400'
    };
  },
  emitStatus(val){
    this.$emit('emit-select', val);
        console.log("qqqqqqqqqqqqqq",val);
      
      }
    }
    ,
    
  };
  </script>
<style >

</style>  