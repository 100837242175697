<template>
  <div ref="slider" class="custom-slider minmax">
    <div class="minmax-indicator"></div>
    <input
      id="min"
      ref="inputMin"
      type="range"
      name="min"
      :min="min"
      :max="max"
      :value="minValue"
      :step="step"
      @input="onInput"
      @change="returnval"
    />
    <input
      id="max"
      ref="inputMax"
      type="range"
      name="max"
      :min="min"
      :max="max"
      :value="maxValue"
      :step="step"
      @input="onInput"
      @change="returnval"

    />
  </div>
  <div class="minmax-inputs">
   <span style="display: flex">{{icon + ' '}}{{sliderMinValue}}</span> 
    <span style="display: flex">{{icon + ' '}}{{sliderMaxValue}} </span>
    <input  v-model="sliderMinValue" style="display:none" type="number" :step="step" />
    <input v-model="sliderMaxValue" style="display:none" type="number" :step="step" />
  </div>
</template>
<script>
export default {
  name: "HlxMinMaxSlider",
  props: {
    icon:{
    type:String,
    default:''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    minValue: {
      type: Number,
      default: 50
    },
    maxValue: {
      type: Number,
      default: 80
    }
  },
  emits: ["update:minValue", "update:maxValue","slidervalue"],
  data() {
    return {
      slider: null,
      inputMin: null,
      inputMax: null,
      sliderMinValue: null,
      sliderMaxValue: null
    };
  },
  computed: {
    sliderDifference() {
      return Math.abs(this.sliderMaxValue - this.sliderMinValue);
    }
  },
  watch: {
    minValue: {
      handler(value) {
        this.sliderMinValue = value;
      },
      immediate: true
    },
    maxValue: {
      handler(value) {
        this.sliderMaxValue = value;
      },
      immediate: true
    },
    sliderMaxValue: {
      handler(value) {
        // console.log("🚀 ~ file: MinMaxRangeSliderComponent.vue:75 ~ handler ~ data:", data)
        if (value) {
          // emit slidet values when updated
          this.$emit("update:minValue", this.sliderMinValue);
          this.$emit("update:maxValue", this.sliderMaxValue);

          // calculate values in percentages
          const leftPercent = this.getPercent(
            this.sliderMinValue,
            this.min,
            this.max
          );
          const rightPercent =
            100 - this.getPercent(this.sliderMaxValue, this.min, this.max);

          // set the CSS variables
          if (
            leftPercent != null &&
            leftPercent != undefined &&
            !Number.isNaN(leftPercent) &&
            rightPercent != null &&
            rightPercent != undefined &&
            !Number.isNaN(rightPercent)
          ) {
            this.setCSSProps(leftPercent, rightPercent);
          }
        }
      }
    },
    sliderMinValue: {
      handler(value) {
        // console.log("🚀 ~ file: MinMaxRangeSliderComponent.vue:75 ~ handler ~ data:", data)
        if (value) {
          // emit slidet values when updated
          this.$emit("update:minValue", this.sliderMinValue);
          this.$emit("update:maxValue", this.sliderMaxValue);

          // calculate values in percentages
          const leftPercent = this.getPercent(
            this.sliderMinValue,
            this.min,
            this.max
          );
          console.log(leftPercent,'popaaaaa');
          const rightPercent =
            100 - this.getPercent(this.sliderMaxValue, this.min, this.max);

          // set the CSS variables
          // console.log(
          //   "🚀 ~ file: MinMaxRangeSliderComponent.vue:119 ~ handler ~ leftPercent&&rightPercent:",
          //   leftPercent && rightPercent
          // );
          if (
            leftPercent != null &&
            leftPercent != undefined &&
            !Number.isNaN(leftPercent) &&
            rightPercent != null &&
            rightPercent != undefined &&
            !Number.isNaN(rightPercent)
          ) {
            this.setCSSProps(leftPercent, rightPercent);
          }
        }
      }
    }
  },
  mounted() {
    this.sliderMinValue = this.minValue
    this.sliderMaxValue = this.maxValue
    if(this.minValue == undefined)
    {
      this.sliderMinValue = this.min
    }
    if(this.maxValue == undefined)
    {
      this.sliderMaxValue = this.max
    }
    // calculate values in percentages
    const leftPercent = this.getPercent(
      this.sliderMinValue,
      this.min,
      this.max
    );
    const rightPercent =
      100 - this.getPercent(this.sliderMaxValue, this.min, this.max);

    // set the CSS variables
    if (
      leftPercent != null &&
      leftPercent != undefined &&
      !Number.isNaN(leftPercent) &&
      rightPercent != null &&
      rightPercent != undefined &&
      !Number.isNaN(rightPercent)
    ) {
      this.setCSSProps(leftPercent, rightPercent);
    }
  },
  methods: {
    returnval()
    {
      this.$emit('slidervalue',this.sliderMinValue,this.sliderMaxValue)
      //       if (target.name === "min") {
      //   target.value > this.sliderMaxValue && target.value <= this.max
      //     ? (target.value = this.sliderMaxValue)
      //     : target.value > this.sliderMaxValue && target.value > this.max
      //     ? (this.sliderMaxValue = parseFloat(target.value))
      //     : (this.sliderMinValue = parseFloat(target.value));
      // }

      // if (target.name === "max") {
      //   target.value < this.sliderMinValue
      //     ? (target.value = this.sliderMinValue)
      //     : (this.sliderMaxValue = parseFloat(target.value));
      // } 
    },
    onInput({ target }) {
      if (target.name === "min") {
        target.value > this.sliderMaxValue && target.value <= this.max
          ? (target.value = this.sliderMaxValue)
          : target.value > this.sliderMaxValue && target.value > this.max
          ? (this.sliderMaxValue = parseFloat(target.value))
          : (this.sliderMinValue = parseFloat(target.value));
          console.log(target.value);
      }

      if (target.name === "max") {
        target.value < this.sliderMinValue
          ? (target.value = this.sliderMinValue)
          : (this.sliderMaxValue = parseFloat(target.value));
      }
    },
    // onchange({})
    getPercent(value, min, max) {
      console.log(value,min,max);
      return ((value - min) / (max - min)) * 100;
    },
    setCSSProps(left, right) {
      // console.log(
      //   "🚀 ~ file: MinMaxRangeSliderComponent.vue:164 ~ setCSSProps ~ this.$refs?.slider:",
      //   this.$refs.slider
      // );
      this.$refs?.slider?.style.setProperty("--progressLeft", `${left}%`);
      this.$refs?.slider?.style.setProperty("--progressRight", `${right}%`);
    }
  }
};
</script>
<style scoped>
/* color palette from <https://github.com/vuejs/theme> */
:root {
  --vt-c-white: #ffffff;
  --vt-c-white-soft: #f8f8f8;
  --vt-c-white-mute: #f2f2f2;

  --vt-c-black: #181818;
  --vt-c-black-soft: #222222;
  --vt-c-black-mute: #282828;

  --vt-c-indigo: #2c3e50;

  --vt-c-divider-light-1: rgba(60, 60, 60, 0.29);
  --vt-c-divider-light-2: rgba(60, 60, 60, 0.12);
  --vt-c-divider-dark-1: rgba(84, 84, 84, 0.65);
  --vt-c-divider-dark-2: rgba(84, 84, 84, 0.48);

  --vt-c-text-light-1: var(--vt-c-indigo);
  --vt-c-text-light-2: rgba(60, 60, 60, 0.66);
  --vt-c-text-dark-1: var(--vt-c-white);
  --vt-c-text-dark-2: rgba(235, 235, 235, 0.64);
}

/* semantic color variables for this project */
:root {
  --color-background: var(--vt-c-white);
  --color-background-soft: var(--vt-c-white-soft);
  --color-background-mute: var(--vt-c-white-mute);

  --color-border: var(--vt-c-divider-light-2);
  --color-border-hover: var(--vt-c-divider-light-1);

  --color-heading: var(--vt-c-text-light-1);
  --color-text: var(--vt-c-text-light-1);

  --section-gap: 160px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: var(--vt-c-black);
    --color-background-soft: var(--vt-c-black-soft);
    --color-background-mute: var(--vt-c-black-mute);

    --color-border: var(--vt-c-divider-dark-2);
    --color-border-hover: var(--vt-c-divider-dark-1);

    --color-heading: var(--vt-c-text-dark-1);
    --color-text: var(--vt-c-text-dark-2);
  }
}

a,
.green {
  text-decoration: none;
  color: #00bd7e;
  transition: 0.4s;
}

@media (hover: hover) {
  a:hover {
    background-color: hsla(160, 100%, 37%, 0.2);
  }
}

.slider-cont {
  width: 300px;
}

.custom-slider {
  --trackHeight: 0.35rem;
  --thumbRadius: 1rem;
}

/* style the input element with type "range" */
.custom-slider input[type="range"] {
  position: relative;
  appearance: none;
  background: none;
  border-radius: 999px;
  z-index: 0;
  height: 100%;
  pointer-events: none;
}

/* ::before element to replace the slider track */
.custom-slider input[type="range"]::before {
  content: "";
  display: block;
  position: absolute;
  width: var(--ProgressPercent, 100%);
  height: 100%;
  background: red;
  border-radius: 999px;
}

/* `::-webkit-slider-runnable-track` targets the track (background) of a range slider in chrome and safari browsers. */
.custom-slider input[type="range"]::-webkit-slider-runnable-track {
  appearance: none;
  background: yellow;
  height: var(--trackHeight);
  border-radius: 999px;
}

.custom-slider input[type="range"]::-webkit-slider-thumb {
  position: relative;
  width: var(--thumbRadius);
  height: var(--thumbRadius);
  margin-top: calc((var(--trackHeight) - var(--thumbRadius)) / 2);
  background: var(--hlx-color-primary);
  border: 1px solid white;
  border-radius: 999px;
  pointer-events: all;
  appearance: none;
  z-index: 1;
  -webkit-appearance: none;
  top: -2px;

  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
  background: var(--hlx-color-primary);
  cursor: pointer;
  -webkit-transition: background 0.11s ease-in-out;
  transition: background 0.11s ease-in-out;
}

/* `::-moz-range-track` targets the track (background) of a range slider in Mozilla Firefox. */
.custom-slider.default input[type="range"]::-moz-range-track {
  appearance: none;
  background: pink;
  height: var(--trackHeight);
  border-radius: 999px;
}

.custom-slider input[type="range"]::-moz-range-thumb {
  position: relative;
  box-sizing: border-box;
  width: var(--thumbRadius);
  height: var(--thumbRadius);
  margin-top: calc((var(--trackHeight) - var(--thumbRadius)) / 2);
  background: orange;
  border: 1px solid lemonchiffon;
  border-radius: 999px;
  pointer-events: all;
  appearance: none;
  z-index: 1;
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -2px;
  border: 1px solid white;
  background: var(--hlx-color-primary);
  cursor: pointer;
  -webkit-transition: background 0.11s ease-in-out;
  transition: background 0.11s ease-in-out;
}

.custom-slider.minmax {
  position: relative;
  height: var(--trackHeight);
  background: #f0eeec;
  border-radius: 999px;
  margin: 0.5rem 0;
  --progressLeft: 0%;
  --progressRight: 0%;
}

.custom-slider .minmax-indicator {
  position: absolute;
  height: 100%;
  pointer-events: none;
  left: var(--thumbRadius);
  right: var(--thumbRadius);
}

.custom-slider .minmax-indicator::before {
  content: "";
  position: absolute;
  background: var(--hlx-color-primary);
  height: 100%;
  left: var(--progressLeft);
  right: var(--progressRight);
}

.custom-slider.minmax input[type="range"] {
  position: absolute;
  width: calc(100% - var(--thumbRadius));
}

.custom-slider.minmax input[type="range"][name="max"] {
  left: var(--thumbRadius);
}

.custom-slider.minmax input[type="range"]::-webkit-slider-runnable-track {
  background: none;
}

.custom-slider.minmax input[type="range"]::before {
  display: none;
}

.minmax-inputs {
  display: flex;
  justify-content: space-between;
}

.minmax-inputs input {
  width: 50px;
}
</style>
