<template>
  <!-- <div class="outer-container"> -->
  <div class="search-container" >
    <!-- Style 1 -->
    <form v-if="searchStyle == 'style1' || style1" class="search-1" @submit.prevent="onSubmit">
      <!-- <div class="search-1" > -->
      <input
        id="input-1"
        v-model="searchkey1"
        type="search"
        class="input-1"
        name="input-1"
        autocomplete="off"
        :placeholder=labelValue
        :aria-required="false"
        @input="debounceSearch"
        @focus="focusValue"
        @blur="blurValue"
      />
      <label class="icon-1" for="input-1" aria-label="search"
        >
        <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          right: 10px;
          color: #d8d8d8;
          cursor: pointer;
        "
        @click="searchkey1 = ''"
      >
      </i>
        <i v-else class="icon-search-regular"></i>
      </label>
    </form>
    <!-- </div> -->

    <!-- Style 2 -->
    <!-- <div class="search-2"> --> 
    <form v-if="searchStyle == 'style2'" class="search-2" @submit.prevent="onSubmit">
      <input
        id="input-2"
        v-model="searchkey1"
        type="search"
        autocomplete="off"
        :placeholder=labelValue
        :aria-required="false"
        @input="debounceSearch"
        @focus="focusValue"
        @blur="blurValue"
      />
      <div style="width:5%">
      <label class="icon-2" for="input-2" aria-label="search"
        ><i class="icon-search-regular"></i
      ></label>
    </div>
    </form>
    <!-- </div> -->

    <!-- Style 3 -->
    <!-- <div class="search-3" > -->
    <div v-if="searchStyle == 'style3'" class="search-3" @submit.prevent="onSubmit">
      <input
        id="input-3"
        v-model="searchkey1"
        type="text"
        autocomplete="off"
        :placeholder=labelValue
        @input="debounceSearch"
        @focus="focusValue"
        @blur="blurValue"
      />
      <!-- <div class="mycircle"></div>
      <div class="icon-3"><i class="icon-search-regular"></i></div> -->
      <!-- <img src="../assets/img/search3.svg" alt=""> -->
      <img class="search-3img" />
    </div>
    <!-- </div> -->
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "HlxSearch",
  props: {
    labelValue: {
        type: String,
        default: "Search",
      },
    searchStyle: {
      type: String,
      default: "style1",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: () => ""
    },
  },
  watch:{
    modelValue:{
      handler(val){
        this.searchkey1 = val
      },
      deep: true,
      immediate: true
    },
    searchkey1:{
      handler(val){       
        if(val != "" && this.clearable){
          this.showClear = true
        }else{
          this.showClear = false
        }
      },
      deep: true,
      immediate: true
    }
  },
  emits: ["searchKey", "blurValue", "focusValue", "update:modelValue"],
  data() {
    return {
      searchkey1: "",
      debounce: null,
      style1: false,
      showClear: false
    };
  },
  mounted() {
    if (this.searchStyle == undefined) {
      this.style1 = true;
    } else {
      this.style1 = false;
    }
    this.searchkey1 = this.modelValue
    // console.log(this.modelValue)
  },
  methods: {
    onSubmit() {
    // Handle form submission logic here
    // For example, you can call your search method
    this.debounceSearch();
  },
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$emit("searchKey", this.searchkey1);
        this.$emit("update:modelValue", this.searchkey1);
      }, 300);
    },
    blurValue(){
      this.$emit("blurValue", this.searchkey1);
      this.$emit("update:modelValue", this.searchkey1);
    },
    focusValue(){
      this.$emit("focusValue", this.searchkey1);
      this.$emit("update:modelValue", this.searchkey1);
    },
  },
};
</script>
