import components from './components/index'
import './assets/styles/index.scss'
import './assets/icons/icon-style.css'
const plugin = {
  install (Vue) {
    for (const prop in components) {
      if (components.hasOwnProperty(prop)) {
        const component = components[prop]
        Vue.component(component.name, component)
      }
    }
  }
}

export default plugin
export * from './components/index.js'
