<template>
  <div class="grid">
    <div
      v-for="(code, index) in characterCodes"
      :key="index"
      class="cell"
      v-html="code"
      @click="emitCharacter(code)"
    ></div>
  </div>
</template>

<script>
export default {
  props: {

    characterCodes: {
      type: Array,
      default: () => {
        return [
'&#9733;', // ★ (Star)
'&#9734;', // ☆ (Empty Star)
'&#10003;', // ✓ (Checkmark)
'&#10005;', // ✗ (Cross)
'&#10004;', // ✔ (Bold Checkmark)
'&#10006;', // ✖ (Bold Cross)
'&#9745;', // ☑ (Checkbox)
'&#9786;', // ☺ (Smiley Face)
'&#9785;', // ☹ (Sad Face)
'&#9825;', // ♡ (Empty Heart)
'&#9830;', // ♦ (Diamond)
'&#9827;', // ♣ (Club)
'&#9824;', // ♠ (Spade)
'&#9829;', // ♥ (Heart)
'&#9831;', // ♤ (Empty Spade)
'&#9834;', // ♪ (Music Note)
'&#9835;', // ♫ (Double Music Note)
'&#9889;', // ⚡ (Lightning)
'&#9728;', // ☀ (Sun)
'&#9729;', // ☁ (Cloud)
'&#9730;', // ☂ (Umbrella)
'&#9731;', // ☃ (Snowman)
'&#9739;', // ☯ (Yin-Yang)
'&#9760;', // ☠ (Skull and Crossbones)
'&#9774;', // ☮ (Peace Symbol)
'&#9775;', // ☯ (Yin-Yang Variant)
'&#9788;', // ☼ (Sunshine)
'&#9820;', // ♜ (Chess Rook)
'&#9822;', // ♞ (Chess Knight)
'&#9832;', // ♲ (Recycling Symbol)
'&#9855;', // ⚛ (Atom)
'&#9884;', // ⚖ (Balance Scale)
'&#9874;', // ⚔ (Crossed Swords)
]

      },
    },
  },
  methods: {
    emitCharacter(code) {
      this.$emit('character-clicked', code);
    },
  },
};
</script>


