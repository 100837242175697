<template>
  <div     v-if="single_select === true && clearable === true && disabled == false"
 style="display:flex;flex-direction: column;width:100%">
  <button
    :id="'hlx-select-container'+unique"
    :class="selectoptioncontainer == true ? 'hlx-select-container change-color': 'hlx-select-container'"
    :style="[error == true ? 'border-color:#ff5050 !important' : '',singleBorder == true ? 'border-bottom:1px solid #d8d8d8 !important;border-left:none;border-right:none;border-top:none;border-radius:0px': '']"
    @click="OptionContainer"
    @mouseover="clearIcon"
    @mouseout="arrowIcon"
    @blur="customError1"
    @keydown.prevent="OptionKeyContainer"
  >
    <p
      v-show="dropdownname === true && required == false" 
      :id="'title' + unique"
      class="title-name"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ title }}
    </p>
    <div
      v-if="placeholder === true"
      class="hlx-select-input-placeholder"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }}
      <span v-show="required == true" class="asterik">{{ "*" }}</span>
    </div>
    <div
      v-show="dropdownvalue === true"
      class="hlx-select-input"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :class="icon_value"></i>{{ option_value }}
    </div>
    <p
      v-show="dropdownname === true && required == true"
      :id="'asterik-title' + unique"
      class="title-name"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ title }}<span class="asterik">{{ "*" }}</span>
    </p>
    <div
      v-if="clearable === true"
      class="hlx-select-icon"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :id="'arrowid' + unique" :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"></i>
      <i
        :id="'crossid' + unique"
        class="icon-times-circle-regular"
        @click.stop="removevalue"
      ></i>
    </div>
    <div
      v-if="clearable === false"
      class="hlx-select-icon"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :id="'arrowid' + unique"  :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"></i>
      <!-- <i class="icon-times-circle-regular" :id="'crossid'+this.unique" @click="removevalue"></i> -->
    </div>
<hlx-overlay v-show="selectoptioncontainer === true" :show ="selectoptioncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :height="containerheight" :conditionvalue="250" :conditiondown="45" :conditionup="10">
  <template #overlay>
    <div
       :id="'hlx-select-whole-option-container'+unique" class="hlx-select-whole-option-container"       :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%'
      ]">
    <div
      class="hlx-select-option-container"
    >
      <button
        v-for="(i, index) in optionsD"
        :id="'value'+index+unique"
        :key="index"
        class="hlx-select-option-value-container"
        :class="optionsD[index].checked === true ? 'select-value' : ''"
        @click="SelectOption(optionsD[index][propValue])"
        @keydown.prevent ="focusInValue(index,$event)"
      >
        <i id="icon-value" :class="optionsD[index].icon"></i>
        <p class="hlx-select-option-value">{{ optionsD[index][label] }}</p>
  </button>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
        <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click="footerclick"></i>
          <p class="hlx-footer-content" @click="footerclick">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
</hlx-overlay>
</button>
<span v-if="displayError == true" style="height:24px;font-size:12px;color:#ff5050" class="customerror">{{ customError }}</span>
</div>

<div v-if="single_select === true && clearable === true && disabled == true" style="display:flex;flex-direction: column;width:100%">
  <div 
    :id="'hlx-select-container'+unique"
    :class="selectoptioncontainer == true ? 'hlx-select-container change-color': 'hlx-select-container'"
    :style="disabled == true ? 'border-color:#d8d8d8 !important' : ''"
    @blur="customError1"
  >
    <p
      v-show="dropdownname === true && required == false"
      :id="'title' + unique"
      class="title-name-disabled"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ title }}
    </p>
    <div
      v-if="placeholder === true"
      class="hlx-select-input-placeholder"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }}
      <span v-show="required == true" class="asterik">{{ "*" }}</span>
    </div>
    <div
      v-show="dropdownvalue === true"
      class="hlx-select-input"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :class="icon_value"></i>{{ option_value }}
    </div>
    <p
      v-show="dropdownname === true && required == true"
      :id="'asterik-title' + unique"
      class="title-name-disabled"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ title }}<span class="asterik">{{ "*" }}</span>
    </p>
    <!-- <p class="customerror">{{ customError }}</p> -->
    <div
      v-if="clearable === true"
      class="hlx-select-icon"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :id="'arrowid' + unique" :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"></i>
      <i
        :id="'crossid' + unique"
        class="icon-times-circle-regular"
        @click.stop="removevalue"
      ></i>
    </div>
    <div
      v-if="clearable === false"
      class="hlx-select-icon"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :id="'arrowid' + unique" :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"></i>
      <!-- <i class="icon-times-circle-regular" :id="'crossid'+this.unique" @click="removevalue"></i> -->
    </div>
<hlx-overlay v-show="selectoptioncontainer === true" :show ="selectoptioncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :height="containerheight" :conditionvalue="250" :conditiondown="45" :conditionup="10">
  <template #overlay>
    <div :id="'hlx-select-whole-option-container'+unique" class="hlx-select-whole-option-container"       :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      class="hlx-select-option-container"
    >
      <div
        v-for="(i, index) in optionsD"
        :id="optionsD[index].id"
        :key="index"
        class="hlx-select-option-value-container"
        :class="optionsD[index].checked === true ? 'select-value' : ''"
        @click="SelectOption(optionsD[index][propValue])"
      >
        <i id="icon-value" :class="optionsD[index].icon"></i>
        <p class="hlx-select-option-value">{{ optionsD[index][label] }}</p>
      </div>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
        <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click="footerclick"></i>
          <p class="hlx-footer-content" @click="footerclick">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
</hlx-overlay>
  </div>
  <span v-if="displayError == true" style="height:24px;font-size:12px;color:#ff5050" class="customerror">{{ customError }}</span>
</div>

  <div v-if="single_select == true && clearable === false && disabled !== true"
  style="display:flex;flex-direction: column;width:100%">
  <button
        :id="'hlx-select-container'+unique"
    :class="selectoptioncontainer == true ? 'hlx-select-container change-color': 'hlx-select-container'"
    :style="[error == true ? 'border-color:#ff5050 !important' : '',singleBorder == true ? 'border-bottom:1px solid #d8d8d8 !important;border-left:none;border-right:none;border-top:none;border-radius:0px': '']"
    @click="OptionContainer"
    @keypress="OptionKeyContainer"
  >
    <p
      v-show="dropdownname === true && required == false"
      :id="'title' + unique"
      class="title-name"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ title }}
    </p>
    <div
      v-if="placeholder === true"
      class="hlx-select-input-placeholder"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }} <span v-show="required == true" class="asterik">{{ "*" }}</span>
    </div>
    <div
      v-show="dropdownvalue === true"
      class="hlx-select-input"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :class="icon_value"></i>{{ option_value }}
    </div>
    <p
      v-show="dropdownname === true && required == true"
      :id="'title' + unique"
      class="title-name"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ title }}<span class="asterik">{{ "*" }}</span>
    </p>
    <div v-if="clearable === true" class="hlx-select-icon">
      <i :id="'arrowid' + unique" :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"></i>
      <i
        :id="'crossid' + unique"
        class="icon-times-circle-regular"
        @click="removevalue"
      ></i>
    </div>
    <div
      v-if="clearable === false"
      class="hlx-select-icon"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :id="'arrowid' + unique" :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"></i>
    </div>
    <hlx-overlay v-show="selectoptioncontainer === true" :show ="selectoptioncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :height="containerheight" :conditionvalue="250" :conditiondown="45" :conditionup="10">
  <template #overlay>
    <div :id="'hlx-select-whole-option-container'+unique" class="hlx-select-whole-option-container"       :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      class="hlx-select-option-container"
    >
      <button
        v-for="(i, index) in optionsD"
        :id="'value'+index+unique"
        :key="index"
        class="hlx-select-option-value-container"
        :class="optionsD[index].checked === true ? 'select-value' : ''"
        @click="SelectOption(optionsD[index][propValue])"
        @keydown.prevent ="focusInValue(index,$event)"
      >
        <i id="icon-value" :class="optionsD[index].icon"></i>
        <p class="hlx-select-option-value">{{ optionsD[index][label] }}</p>
  </button>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
        <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click="footerclick"></i>
          <p class="hlx-footer-content" @click="footerclick">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
    </hlx-overlay>

  </button>
  <span v-if="displayError == true" style="height:24px;font-size:12px;color:#ff5050" class="customerror">{{ customError }}</span>
  </div>
<div v-if="single_select == true && clearable === false && disabled === true" style="display:flex;flex-direction: column;width:100%">
  <div
        :id="'hlx-select-container'+unique"
    :class="selectoptioncontainer == true ? 'hlx-select-container change-color': 'hlx-select-container'"
    :style="disabled == true ? 'border-color:#d8d8d8 !important' : ''"

  >
    <p
      v-show="dropdownname === true && required == false"
      :id="'title' + unique"
      class="title-name-disabled"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ title }}
    </p>
    <div
      v-if="placeholder === true"
      class="hlx-select-input-placeholder"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }} <span v-show="required == true" class="asterik">{{ "*" }}</span>
    </div>
    <div
      v-show="dropdownvalue === true"
      class="hlx-select-input"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :class="icon_value"></i>{{ option_value }}
    </div>
    <p
      v-show="dropdownname === true && required == true"
      :id="'astreik-title' + unique"
      class="title-name-disabled"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ title }}<span class="asterik">{{ "*" }}</span>
    </p>
    <!-- <p class="customerror">{{ customError }}</p> -->
    <div v-if="clearable === true" class="hlx-select-icon">
      <i :id="'arrowid' + unique" :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"></i>
      <i
        :id="'crossid' + unique"
        class="icon-times-circle-regular"
        @click="removevalue"
      ></i>
    </div>
    <div
      v-if="clearable === false"
      class="hlx-select-icon"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :id="'arrowid' + unique" :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"></i>
    </div>
<hlx-overlay v-show="selectoptioncontainer === true" :show ="selectoptioncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :height="containerheight" :conditionvalue="250" :conditiondown="45" :conditionup="10">
  <template #overlay>
    <div :id="'hlx-select-whole-option-container'+unique" class="hlx-select-whole-option-container"       :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      class="hlx-select-option-container"
    >
      <div
        v-for="(i, index) in optionsD"
        :id="optionsD[index].id"
        :key="index"
        class="hlx-select-option-value-container"
        :class="optionsD[index].checked === true ? 'select-value' : ''"
        @click="SelectOption(optionsD[index][propValue])"
      >
        <i id="icon-value" :class="optionsD[index].icon"></i>
        <p class="hlx-select-option-value">{{ optionsD[index][label] }}</p>
      </div>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
        <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click="footerclick"></i>
          <p class="hlx-footer-content" @click="footerclick">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
</hlx-overlay>
  </div>
  <span v-if="displayError == true" style="height:24px;font-size:12px;color:#ff5050" class="customerror">{{ customError }}</span>
</div>


  <!----------------------------------------------- Icon container --------------------------------------------------------------->
  <div
    v-if="icon === true"
        :id="'hlx-select-container'+unique"
    :class="selectoptioncontainer == true ? 'hlx-select-container change-color': 'hlx-select-container'"
        :style="error == true ? 'border-color:#ff5050 !important' : ''"
    @click="OptionContainer"

  >
    <p
      v-show="dropdownname === true && required == false"
      :id="'title1' + unique"
      class="title-name"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ title }}
    </p>
    <div
      v-if="placeholder === true"
      class="hlx-select-input-placeholder"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }} <span v-show="required == true" class="asterik">{{ "*" }}</span>
    </div>
    <div
      v-if="dropdownvalue === true"
      class="hlx-select-input"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <span v-if="flagIcon == true">
        <CountryFlag :iso="icon_country_flag" />
      </span>
      <span v-if="flagValue == true">
        {{ option_value }}
      </span>
    </div>
    <div
      class="hlx-select-icon"
      :style="{ background: backgroundColor, color: fontColor }"
    >
      <i :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"></i>
      <p
        v-show="dropdownname === true && required == true"
        :id="'title' + unique"
        class="title-name"
        :style="{ background: labelBackgroundColor, color: labelFontColor }"
      >
        {{ title }}<span class="asterik">{{ "*" }}</span>
      </p>
      <span v-if="error == true" class="customerror">{{ customError }}</span>
    </div>

    <hlx-overlay v-show="selectoptioncontainer === true" :show ="selectoptioncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :height="containerheight" :conditionvalue="250" :conditiondown="45" :conditionup="10">
  <template #overlay>
    <div :id="'hlx-select-whole-option-container'+unique"
          class="hlx-select-whole-option-container"       :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      class="hlx-select-option-container"
    >
      <div
        v-for="(i, index) in optionsD"
        :id="options[index].id"
        :key="index"
        class="hlx-select-option-value-container"
        :class="optionsD[index].checked === true ? 'select-value' : ''"
        @click="SelectOption(optionsD[index][propValue])"
      >
        <CountryFlag :iso="i.flag" />
        <p class="hlx-select-option-value">{{ i[label] }}</p>
      </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
        <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click="footerclick"></i>
          <p class="hlx-footer-content" @click="footerclick">{{footerContent}}</p>
        </span>
    </div>
    </div>
    </div>
  </template>
    </hlx-overlay>
  </div>

  <!-----------------------------------------------------------------Multiple select--------------------------------------------------------------------->
  <div
    v-if="multiSelect === true"
    :id="'multiselect' + unique"
    class="hlx-multi-select-container"
    :style="error == true ? 'border-color:#ff5050 !important' : ''"
    @click="MultiselectOptioncontainer"
  >
    <p
      v-if="multiselecttitle == true && required == false"
      class="title-name"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ title }}
    </p>
    <div
      v-if="multiselectplaceholder == true"
      class="hlx-multi-select-input-placeholder"
      :style="{ background: backgroundColor, color: placeholderColor }"
      @click="color"
    >
      {{ placeholderValue }}
    </div>
    <div
      v-show="multiselectdropdownvalue == true"
      :id="'select' + unique"
      class="hlx-multi-select-input"
      :style="{ background: backgroundColor, color: fontColor }"
      @click="color"
    >
      <span v-if="all === true" class="hlx-multi-select-flag-container">{{
        "All"
      }}</span>
      <span
        v-for="(j, index) in computeMultiSelect"
        v-else
        :key="j"
        class="hlx-multi-select-flag-container"
      >
        <div v-if="index < tagvalue" class="hlx-multi-select-border">
          <div class="hlx-multi-select-value">{{ j }}</div>
          <i
            class="icon-times-regular"
            @click.stop="removemultiselectvalue(j)"
          ></i>
          <p
            v-if="multiselecttitle == true && required === true"
            class="title-name"
            :style="{
              background: labelBackgroundColor,
              color: labelFontColor,
            }"
          >
            {{ title }}<span class="asterik">{{ "*" }}</span>
          </p>
          <p class="customerror">{{ customError }}</p>
        </div>
      </span>
    </div>
    <div
      v-if="all !== true"
      class="hlx-multi-select-count"
      :style="{ background: backgroundColor, color: fontColor }"
      @click="color"
    >
      <div v-show="count_select_value > 0" class="hlx-multi-select-count-check">
        {{ "+" + count_select_value }}
      </div>
    </div>
    <div
      class="hlx-multi-select-icon"
      :style="{ background: backgroundColor, color: fontColor }"
      @click="color"
    >
      <i v-if="isMultiSelect != true" class="icon-angle-down-regular"></i>
      <i v-else class="icon-angle-down-regular add"></i>
    </div>
    <div
      v-show="isMultiSelect == true"
      class="hlx-multi-select-option-container"
      :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
        'top:' + top + 'px',
        'left:' + left + 'px',
      ]"
    >
      <Search
        v-show="searchable === true"
        class="sea"
        :search_style="'style1'"
        @searchkey="search"
      ></Search>
      <div class="multi-check">
        <inputItems
          v-model:value="checkedData"
          class="check"
          :options="computeDropdownData"
          :show-select-all="selectall"
          :type="'checkbox'"
        ></inputItems>
      </div>
      <div v-if="filterSelect === true" class="applyfilterselect">
        <button class="clear-button" @click="emitclearvalue">clear</button>
        <button v-if="disablebutton === true" class="disablebutton" disabled>
          Apply
        </button>
        <button v-else @click="emitfiltervalue">Apply</button>
      </div>
    </div>
  </div>

  <!---------------------------------------------------------------------Texteditor-------------------------------------------------->
  <button
    v-if="textEditor === true"
    :id="'hlx-select-container'+unique"
    :class="selectoptioncontainer == true ? 'hlx-select-container change-color': 'hlx-select-container'"
    @click="OptionContainer"
  >
    <p v-show="dropdownname === true" :id="'title' + unique" class="title-name">
      {{ title }}
    </p>
    <button v-if="placeholder === true" class="hlx-select-input-placeholder">
      {{ placeholderValue }}
    </button>
    <button v-show="dropdownvalue === true" class="hlx-select-input">
      <i :class="icon_value"></i>{{ option_value }}
    </button>
    <button v-if="clearable === true" class="hlx-select-icon">
      <i :id="'arrowid' + unique" class="icon-angle-down-regular"></i>
      <i
        :id="'crossid' + unique"
        class="icon-times-circle-regular"
        @click="removevalue"
      ></i>
    </button>
    <button v-if="clearable === false" class="hlx-select-icon">
      <i :id="'arrowid' + unique" class="icon-angle-down-regular"></i>
      <!-- <i class="icon-times-circle-regular" :id="'crossid'+this.unique" @click="removevalue"></i> -->
    </button>
<hlx-overlay v-show="selectoptioncontainer === true" :show ="selectoptioncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :height="containerheight" :conditionvalue="250" :conditiondown="45" :conditionup="10">
  <template #overlay>
    <div
   :id="'hlx-select-whole-option-container'+unique" class="hlx-select-whole-option-container"       :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      class="hlx-select-option-container"
    >
      <div
        v-for="(i, index) in optionsD"
        :id="'value'+index+unique"
        :key="index"
        class="hlx-select-option-value-container"
        :class="optionsD[index].checked === true ? 'select-value' : ''"
        @click="SelectOption(optionsD[index][propValue])"
      >
        <i id="icon-value" :class="optionsD[index].icon"></i>
        <p class="hlx-select-option-value">{{ optionsD[index][label] }}</p>
      </div>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
        <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click="footerclick"></i>
          <p class="hlx-footer-content" @click="footerclick">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
</hlx-overlay>
  </button>

  <!---------------------------------------------------------------------------Inline search single select ---------------------------->
  <div v-if="inlineSearch === true && disabled === false && slotOptions == false && customSearch == false"
  style="display:flex;flex-direction: column;width:100%">
  <div
    :id="'custom-dropdown' + unique"
    class="custom-dropdown"
    :style="[error == true ? 'border-color:#ff5050 !important' : '',singleBorder == true ? 'border-bottom:1px solid #d8d8d8 !important;border-left:none;border-right:none;border-top:none;border-radius:0px': '',border==false ? 'border:none' : '']"
    @click="Customoptioncontainer"
    @mouseover="displayCloseIcon"
    @mouseout="displayarrowIcon"
  >
    <input
      v-if="showsearchinput == true "
      :id="'custom-input' + unique"
      v-model="searchValue"
      type="text"
      class="custom-dropdown-input"
      :placeholder="selectedValue != '' ? selectedValue : placeholderValue"
      :style="{ background: backgroundColor, color: fontColor }"
      @input="check"
      @keypress="senddata"
    />
    <span v-if="showsearchinput == false"  class="tagimagecontainer">
       <p v-show="selectedValue != ''">{{ selectedValue }}</p>
      <hlx-label v-show="searchImageTag == true && selectedValue != '' && inlinesearchtagvalue[tagValue] != ''" class="primary sm selectvalue"> {{ inlinesearchtagvalue[tagValue] }} </hlx-label>
      <div v-show="selectedValue == ''" style="display:flex"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }}
      <span v-show="required == true" class="asterik">{{ "*" }}</span>
    </div>
    </span>

    <i
      :id="'icon-angle-down-regular'+unique" class="icon-angle-down-regular"
      :style="{ background: backgroundColor, color: fontColor }"
    ></i>
    <div
      v-if="labelAnimation == true  && required === false && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}
    </div>
    <div
      v-if="labelAnimation == true  && required === true && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}<span class="asterik">{{ " *" }}</span>
    </div>
    <!-- <span v-if="error == true" class="customerror">{{ customError }}</span> -->

        <hlx-overlay v-show="customdropdowncontainer === true" :search="searchValue" :show ="customdropdowncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :conditionvalue="250" :conditiondown="45" :conditionup="10" @close-overlay="inlineclosecontainer">
  <template #overlay>
    <div
 class="custom-dropdown-whole-container" :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      :id="'custom-option-data'+unique"         class="customdropdown-option-container"
    >
      <div v-if="dropdowndata.length === 0" class="no-data-option">
        <p>No data</p>
      </div>
      <div
        v-for="(i, index) in dropdowndata"
        v-else
        :key="index"
        class="option"
        :style="searchImageTag == false ? 'height:30px' : 'height:50px!important'"
        :class="i.checked === true ? 'select-value' : ''"
        @click.stop="customSelectoptionvalue(i[propValue], i[label])"
      >
        <span v-if="searchImageTag == false" class="single-option" style="width:100%"><p class="option-value" :style="i[style]">{{ i[label] }}</p></span>
        <i v-if="searchImageTag == false" :class="i.icon" style="margin-right: 10px; font-size: 18px"></i>
        <span v-if="searchImageTag == true" class="option-image-tag-container">
           <span class="option-image-tag-container-image-fit">
            <img v-if="i[imageLink] != undefined" :src="i[imageLink]" class="image-fit">
            <span v-else class="firstandsecondcolor">{{ getFirstAndSecondLetters(i[label]) }}</span>
           </span>
           <span class="option-image-tag-container-image-fit-title-subtitle">
            <span class="option-image-tag-container-image-fit-title-subtitle-section">
            <p class="option-image-tag-container-image-fit-title-subtitle-section-title-name"> {{ i[label] }}</p>
            <hlx-label v-if="i[tagValue] != ''" class="primary sm"> {{ i[tagValue] }} </hlx-label>
          </span>
          <p class="option-image-tag-container-image-fit-title-subtitle-subtitle-name">{{ i[subTitle] }}</p>
           </span>
           <!-- <span class="option-image-tag-container-image-fit-label-value">
           </span> -->
        </span>
      </div>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
         <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click.stop="footerclick1"></i>
          <p class="hlx-footer-content" @click.stop="footerclick1">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
        </hlx-overlay>
  </div>
  <span v-if="displayError == true" style="height:24px;font-size:12px;color:#ff5050" class="customerror">{{ customError }}</span>
  </div>


  <div v-if="inlineSearch === true && disabled === true && slotOptions == false && customSearch == false"
  style="display:flex;flex-direction: column;width:100%">
  <div
    :id="'custom-dropdown' + unique"
    class="custom-dropdown"
    :style="[error == true ? 'border-color:#ff5050 !important' : '',singleBorder == true ? 'border-bottom:1px solid #d8d8d8 !important;border-left:none;border-right:none;border-top:none;border-radius:0px': '',border==false ? 'border:none' : '']"
  >
    <input
      v-if="showsearchinput == true "
      :id="'custom-input' + unique"
      v-model="searchValue"
      type="text"
      class="custom-dropdown-input"
      :placeholder="selectedValue != '' ? selectedValue : placeholderValue "
      :style="{ background: backgroundColor, color: fontColor }"
      @input="check"
      @keypress="senddata"
    />
    <span v-if="showsearchinput == false"  class="tagimagecontainer">
       <p v-show="selectedValue != ''">{{ selectedValue }}</p>
      <hlx-label v-show="searchImageTag == true && selectedValue != '' && inlinesearchtagvalue[tagValue] != ''" class="primary sm selectvalue"> {{ inlinesearchtagvalue[tagValue] }} </hlx-label>
      <div v-show="selectedValue == ''" style="display:flex"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }}
      <span v-show="required == true" class="asterik">{{ " *" }}</span>
    </div>
    </span>

    <i
      :id="'icon-angle-down-regular'+unique" class="icon-angle-down-regular"
      :style="{ background: backgroundColor, color: fontColor }"
    ></i>
    <div
      v-if="labelAnimation == true  && required === false && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}
    </div>
    <div
      v-if="labelAnimation == true  && required === true && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}<span class="asterik">{{ " *" }}</span>
    </div>
    <!-- <span v-if="error == true" class="customerror">{{ customError }}</span> -->

        <hlx-overlay v-show="customdropdowncontainer === true" :search="searchValue" :show ="customdropdowncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :conditionvalue="250" :conditiondown="45" :conditionup="10" @close-overlay="inlineclosecontainer">
  <template #overlay>
    <div
 class="custom-dropdown-whole-container" :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      :id="'custom-option-data'+unique"         class="customdropdown-option-container"
    >
      <div v-if="dropdowndata.length === 0" class="no-data-option">
        <p>No data</p>
      </div>
      <div
        v-for="(i, index) in dropdowndata"
        v-else
        :key="index"
        class="option"
        :style="searchImageTag == false ? 'height:30px' : 'height:50px!important'"
        :class="i.checked === true ? 'select-value' : ''"
        @click.stop="customSelectoptionvalue(i[propValue], i[label])"
      >
        <span v-if="searchImageTag == false" class="single-option" style="width:100%"><p class="option-value" :style="i[style]">{{ i[label] }}</p></span>
        <i v-if="searchImageTag == false" :class="i.icon" style="margin-right: 10px; font-size: 18px"></i>
        <span v-if="searchImageTag == true" class="option-image-tag-container">
           <span class="option-image-tag-container-image-fit">
            <img v-if="i[imageLink] != undefined" :src="i[imageLink]" class="image-fit">
            <span v-else class="firstandsecondcolor">{{ getFirstAndSecondLetters(i[label]) }}</span>
           </span>
           <span class="option-image-tag-container-image-fit-title-subtitle">
            <span class="option-image-tag-container-image-fit-title-subtitle-section">
            <p class="option-image-tag-container-image-fit-title-subtitle-section-title-name"> {{ i[label] }}</p>
            <hlx-label v-if="i[tagValue] != ''" class="primary sm"> {{ i[tagValue] }} </hlx-label>
          </span>
          <p class="option-image-tag-container-image-fit-title-subtitle-subtitle-name">{{ i[subTitle] }}</p>
           </span>
           <!-- <span class="option-image-tag-container-image-fit-label-value">
           </span> -->
        </span>
      </div>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
         <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click.stop="footerclick1"></i>
          <p class="hlx-footer-content" @click.stop="footerclick1">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
        </hlx-overlay>
  </div>
  <span v-if="displayError == true" style="height:24px;font-size:12px;color:#ff5050" class="customerror">{{ customError }}</span>
  </div>




 <!--Custom search-->
<div v-if="inlineSearch === true && disabled === false && slotOptions == false && customSearch == true"
  style="display:flex;flex-direction: column;width:100%">
  <div
    :id="'custom-dropdown' + unique"
    class="custom-dropdown"
    :style="[error == true ? 'border-color:#ff5050 !important' : '',singleBorder == true ? 'border-bottom:1px solid #d8d8d8 !important;border-left:none;border-right:none;border-top:none;border-radius:0px': '',border==false ? 'border:none' : '']"
    @click="Customoptioncontainer"
    @mouseover="displayCloseIcon"
    @mouseout="displayarrowIcon"
  >
    <input
      v-if="showsearchinput == true "
      :id="'custom-input' + unique"
      v-model="searchValue"
      type="text"
      class="custom-dropdown-input"
      :placeholder="selectedValue != '' ? selectedValue : placeholderValue "
      :style="{ background: backgroundColor, color: fontColor }"
      @input="check"
      @keypress="senddata"
    />
    <span v-if="showsearchinput == false"  class="tagimagecontainer">
       <p v-show="selectedValue != ''">{{ selectedValue }}</p>
      <hlx-label v-show="searchImageTag == true && selectedValue != '' && inlinesearchtagvalue[tagValue] != ''" class="primary sm selectvalue"> {{ inlinesearchtagvalue[tagValue] }} </hlx-label>
      <div v-show="selectedValue == ''" style="display:flex"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }}
      <span v-show="required == true" class="asterik">{{ " *" }}</span>
    </div>
    </span>

    <i
      :id="'icon-angle-down-regular'+unique" class="icon-angle-down-regular"
      :style="{ background: backgroundColor, color: fontColor }"
    ></i>
    <div
      v-if="labelAnimation == true  && required === false && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}
    </div>
    <div
      v-if="labelAnimation == true  && required === true && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}<span class="asterik">{{ " *" }}</span>
    </div>
    <!-- <span v-if="error == true" class="customerror">{{ customError }}</span> -->

        <hlx-overlay v-show="customdropdowncontainer === true" :search="searchValue" :show ="customdropdowncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :conditionvalue="250" :conditiondown="45" :conditionup="10" @close-overlay="inlineclosecontainer">
  <template #overlay>
    <div
 class="custom-dropdown-whole-container" :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      :id="'custom-option-data'+unique"         class="customdropdown-option-container"
    >
      <div v-if="dropdowndata.length === 0" class="no-data-option">
        <p>No data</p>
      </div>
      <div
        v-for="(i, index) in customoption"
        v-else
        :key="index"
        class="option"
        :style="searchImageTag == false ? 'height:30px' : 'height:50px!important'"
        :class="i.checked === true ? 'select-value' : ''"
        @click.stop="customSelectoptionvalue(i[propValue], i[label])"
      >
        <span v-if="searchImageTag == false" class="single-option" style="width:100%"><p class="option-value" :style="i[style]">{{ i[label] }}</p></span>
        <i v-if="searchImageTag == false" :class="i.icon" style="margin-right: 10px; font-size: 18px"></i>
        <span v-if="searchImageTag == true" class="option-image-tag-container">
           <span class="option-image-tag-container-image-fit">
            <img v-if="i[imageLink] != undefined" :src="i[imageLink]" class="image-fit">
            <span v-else class="firstandsecondcolor">{{ getFirstAndSecondLetters(i[label]) }}</span>
           </span>
           <span class="option-image-tag-container-image-fit-title-subtitle">
            <span class="option-image-tag-container-image-fit-title-subtitle-section">
            <p class="option-image-tag-container-image-fit-title-subtitle-section-title-name"> {{ i[label] }}</p>
            <hlx-label v-if="i[tagValue] != ''" class="primary sm"> {{ i[tagValue] }} </hlx-label>
          </span>
          <p class="option-image-tag-container-image-fit-title-subtitle-subtitle-name">{{ i[subTitle] }}</p>
           </span>
           <!-- <span class="option-image-tag-container-image-fit-label-value">
           </span> -->
        </span>
      </div>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
         <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click.stop="footerclick1"></i>
          <p class="hlx-footer-content" @click.stop="footerclick1">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
        </hlx-overlay>
  </div>
  <span v-if="displayError == true" style="height:24px;font-size:12px;color:#ff5050" class="customerror">{{ customError }}</span>
  </div> 

  <div v-if="inlineSearch === true && disabled === true && slotOptions == false && customSearch == true"
  style="display:flex;flex-direction: column;width:100%">
  <div
    :id="'custom-dropdown' + unique"
    class="custom-dropdown"
    :style="[error == true ? 'border-color:#ff5050 !important' : '',singleBorder == true ? 'border-bottom:1px solid #d8d8d8 !important;border-left:none;border-right:none;border-top:none;border-radius:0px': '',border==false ? 'border:none' : '']"
  >
    <input
      v-if="showsearchinput == true "
      :id="'custom-input' + unique"
      v-model="searchValue"
      type="text"
      class="custom-dropdown-input"
      :placeholder="selectedValue != '' ? selectedValue : placeholderValue "
      :style="{ background: backgroundColor, color: fontColor }"
      @input="check"
      @keypress="senddata"
    />
    <span v-if="showsearchinput == false"  class="tagimagecontainer">
       <p v-show="selectedValue != ''">{{ selectedValue }}</p>
      <hlx-label v-show="searchImageTag == true && selectedValue != '' && inlinesearchtagvalue[tagValue] != ''" class="primary sm selectvalue"> {{ inlinesearchtagvalue[tagValue] }} </hlx-label>
      <div v-show="selectedValue == ''" style="display:flex"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }}
      <span v-show="required == true" class="asterik">{{ " *" }}</span>
    </div>
    </span>

    <i
      :id="'icon-angle-down-regular'+unique" class="icon-angle-down-regular"
      :style="{ background: backgroundColor, color: fontColor }"
    ></i>
    <div
      v-if="labelAnimation == true  && required === false && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}
    </div>
    <div
      v-if="labelAnimation == true  && required === true && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}<span class="asterik">{{ " *" }}</span>
    </div>
    <!-- <span v-if="error == true" class="customerror">{{ customError }}</span> -->

        <hlx-overlay v-show="customdropdowncontainer === true" :search="searchValue" :show ="customdropdowncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :conditionvalue="250" :conditiondown="45" :conditionup="10" @close-overlay="inlineclosecontainer">
  <template #overlay>
    <div
 class="custom-dropdown-whole-container" :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      :id="'custom-option-data'+unique"         class="customdropdown-option-container"
    >
      <div v-if="dropdowndata.length === 0" class="no-data-option">
        <p>No data</p>
      </div>
      <div
        v-for="(i, index) in customoption"
        v-else
        :key="index"
        class="option"
        :style="searchImageTag == false ? 'height:30px' : 'height:50px!important'"
        :class="i.checked === true ? 'select-value' : ''"
        @click.stop="customSelectoptionvalue(i[propValue], i[label])"
      >
        <span v-if="searchImageTag == false" class="single-option" style="width:100%"><p class="option-value" :style="i[style]">{{ i[label] }}</p></span>
        <i v-if="searchImageTag == false" :class="i.icon" style="margin-right: 10px; font-size: 18px"></i>
        <span v-if="searchImageTag == true" class="option-image-tag-container">
           <span class="option-image-tag-container-image-fit">
            <img v-if="i[imageLink] != undefined" :src="i[imageLink]" class="image-fit">
            <span v-else class="firstandsecondcolor">{{ getFirstAndSecondLetters(i[label]) }}</span>
           </span>
           <span class="option-image-tag-container-image-fit-title-subtitle">
            <span class="option-image-tag-container-image-fit-title-subtitle-section">
            <p class="option-image-tag-container-image-fit-title-subtitle-section-title-name"> {{ i[label] }}</p>
            <hlx-label v-if="i[tagValue] != ''" class="primary sm"> {{ i[tagValue] }} </hlx-label>
          </span>
          <p class="option-image-tag-container-image-fit-title-subtitle-subtitle-name">{{ i[subTitle] }}</p>
           </span>
           <!-- <span class="option-image-tag-container-image-fit-label-value">
           </span> -->
        </span>
      </div>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
         <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click.stop="footerclick1"></i>
          <p class="hlx-footer-content" @click.stop="footerclick1">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
        </hlx-overlay>
  </div>
  <span v-if="displayError == true" style="height:24px;font-size:12px;color:#ff5050" class="customerror">{{ customError }}</span>
  </div>


<!-- Slot Options-->
<div v-if="inlineSearch === true && disabled === false && slotOptions == true && customSearch == false"
  style="display:flex;flex-direction: column;width:100%">
  <div
    :id="'custom-dropdown' + unique"
    class="custom-dropdown"
    :style="[error == true ? 'border-color:#ff5050 !important' : '',singleBorder == true ? 'border-bottom:1px solid #d8d8d8 !important;border-left:none;border-right:none;border-top:none;border-radius:0px': '',border==false ? 'border:none' : '']"
    @click="Customoptioncontainer"
    @mouseover="displayCloseIcon"
    @mouseout="displayarrowIcon"
  >
    <input
      v-if="showsearchinput == true "
      :id="'custom-input' + unique"
      v-model="searchValue"
      type="text"
      class="custom-dropdown-input"
      :placeholder="selectedValue != '' ? selectedValue : placeholderValue "
      :style="{ background: backgroundColor, color: fontColor }"
      @input="check"
      @keypress="senddata"
    />
    <span v-show="showsearchinput == false"  class="tagimagecontainer">
      <span v-show="selectedValue != ''">
      <slot name="displayValue" :displayLabel="inlinesearchtagvalue">
        
      </slot>
    </span>
      <div v-show="selectedValue == ''" style="display:flex"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }}
      <span v-show="required == true" class="asterik">{{ " *" }}</span>
    </div>
    </span>
    <i
      :id="'icon-angle-down-regular'+unique" class="icon-angle-down-regular"
      :style="{ background: backgroundColor, color: fontColor }"
    ></i>
    <div
      v-if="labelAnimation == true  && required === false && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}
    </div>
    <div
      v-if="labelAnimation == true  && required === true && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}<span class="asterik">{{ " *" }}</span>
    </div>
    <!-- <span v-if="error == true" class="customerror">{{ customError }}</span> -->

        <hlx-overlay v-show="customdropdowncontainer === true" :search="searchValue" :show ="customdropdowncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :conditionvalue="250" :conditiondown="45" :conditionup="10" @close-overlay="inlineclosecontainer">
  <template #overlay>
    <div
 class="custom-dropdown-whole-container" :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      :id="'custom-option-data'+unique"         class="customdropdown-option-container"
    >
      <div v-if="dropdowndata.length === 0" class="no-data-option">
        <p>No data</p>
      </div>
      <div
        v-for="(i, index) in dropdowndata"
        v-else
        :key="index"
        class="option"
        :style="searchImageTag == false ? '' : 'height:50px!important'"
        :class="i.checked === true ? 'select-value' : ''"
        @click.stop="customSelectoptionvalue(i[propValue], i[label])"
      >
      <slot name="options" :optionsData="i">

      </slot>
      </div>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
         <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click.stop="footerclick1"></i>
          <p class="hlx-footer-content" @click.stop="footerclick1">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
        </hlx-overlay>
  </div>
  <span v-if="displayError == true" style="height:24px;font-size:12px;color:#ff5050" class="customerror">{{ customError }}</span>
</div>


<div v-if="inlineSearch === true && disabled === true && slotOptions == true && customSearch == false"
  style="display:flex;flex-direction: column;width:100%">
  <div
    :id="'custom-dropdown' + unique"
    class="custom-dropdown"
    :style="[error == true ? 'border-color:#ff5050 !important' : '',singleBorder == true ? 'border-bottom:1px solid #d8d8d8 !important;border-left:none;border-right:none;border-top:none;border-radius:0px': '',border==false ? 'border:none' : '']"
  >
    <input
      v-if="showsearchinput == true "
      :id="'custom-input' + unique"
      v-model="searchValue"
      type="text"
      class="custom-dropdown-input"
      :placeholder="selectedValue != '' ? selectedValue : placeholderValue "
      :style="{ background: backgroundColor, color: fontColor }"
      @input="check"
      @keypress="senddata"
    />
    <span v-show="showsearchinput == false"  class="tagimagecontainer">
      <span v-show="selectedValue != ''">
      <slot name="displayValue" :displayLabel="inlinesearchtagvalue">
        
      </slot>
    </span>
      <div v-show="selectedValue == ''" style="display:flex"
      :style="{ background: backgroundColor, color: placeholderColor }"
    >
      {{ placeholderValue }}
      <span v-show="required == true" class="asterik">{{ " *" }}</span>
    </div>
    </span>
    <i
      :id="'icon-angle-down-regular'+unique" class="icon-angle-down-regular"
      :style="{ background: backgroundColor, color: fontColor }"
    ></i>
    <div
      v-if="labelAnimation == true  && required === false && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}
    </div>
    <div
      v-if="labelAnimation == true  && required === true && selectedValue != ''"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}<span class="asterik">{{ " *" }}</span>
    </div>
    <!-- <span v-if="error == true" class="customerror">{{ customError }}</span> -->

        <hlx-overlay v-show="customdropdowncontainer === true" :search="searchValue" :show ="customdropdowncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :conditionvalue="250" :conditiondown="45" :conditionup="10" @close-overlay="inlineclosecontainer">
  <template #overlay>
    <div
 class="custom-dropdown-whole-container" :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%',
      ]">
    <div
      :id="'custom-option-data'+unique"         class="customdropdown-option-container"
    >
      <div v-if="dropdowndata.length === 0" class="no-data-option">
        <p>No data</p>
      </div>
      <div
        v-for="(i, index) in dropdowndata"
        v-else
        :key="index"
        class="option"
        :style="searchImageTag == false ? 'height:30px' : 'height:50px!important'"
        :class="i.checked === true ? 'select-value' : ''"
        @click.stop="customSelectoptionvalue(i[propValue], i[label])"
      >
      <slot name="options" :optionsData="i">

      </slot>
      </div>
    </div>
    <div v-show="footer == true" :class="'hlx-fixed-footer'+unique" style="height:33px;border-top:1px solid #d8d8d8;display:flex;justify-content:center;align-items:center" >
        <span  class="hlx-fixed-footer-container" style="display:flex;align-items:center;font-size:14px;padding-left:15px" >
         <i :class="footerIcon" class="hlx-footer-icon" style="margin-right:5px;font-size:16px" @click.stop="footerclick1"></i>
          <p class="hlx-footer-content" @click.stop="footerclick1">{{footerContent}}</p>
        </span>
    </div>
    </div>
  </template>
        </hlx-overlay>
  </div>
  <span v-if="displayError == true" style="height:24px;font-size:12px;color:#ff5050" class="customerror">{{ customError }}</span>
</div>






  

</template>

<script>
import inputItems from "./InputElementsComponent.vue";
import Search from "./SearchComponent.vue";
import CountryFlag from "vue3-country-flag-icon";
import hlxOverlay from '../components/OverlayComponent.vue'
import hlxLabel from "../components/LabelComponent.vue";
import "vue3-country-flag-icon/dist/CountryFlag.css";

export default {
  name: "HlxSelect",
  components: {
    inputItems,
    Search,
    CountryFlag,
    hlxLabel,
    hlxOverlay
    // hlxinput,
    // hlxtag
    // HlxInput
    // Hlxbutton
  },
  inheritAttrs: false,
  // props: ['options', 'multiSelect', 'title', 'multiSelectOptions', 'search', 'placeholderValue', 'icon','preValue'],
  props: {
    defaultEmit:{
      type:Boolean,
       default:false
    },
    displayError:{
      type:Boolean,
       default:false
    },
    singleBorder:{
      type:Boolean,
       default:false
    },
    border:{
      type:Boolean,
       default:true
    },
    customSearch:{
      type:Boolean,
       default:false
    },
    swatch:{
      type:Boolean,
       default:false
    },
    imageLink:{
      type: String,
      default: "link",
    },
    subTitle:{
      type: String,
      default: "subtitle",
    },
    tagValue:{
      type: String,
      default: "",
    },
    slotOptions:{
      type:Boolean,
       default:false
    },
    searchImageTag:{
      type:Boolean,
       default:false
    },
    error:{
       type:Boolean,
       default:false
    },
    footerContent:{
       type:String,
       default:''
    },
    footerIcon:{
       type:String,
       default:''
    },
        footer:{
      type: Boolean,
      default: false,
    },
    scrollClassname:{
       type:String,
       default:''
    },
    flagIcon:{
      type: Boolean,
      default: false,
    },
    flagValue: {
      type: Boolean,
      default: false,
    },
    widthContainer: {
      type: Number,
      default: 0,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "name",
    },
    propValue: {
      type: String,
      default: "value",
    },
    style:{
      type:String,
      default:"style"
    },
    customError: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiSelectInlineSearch: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelValue: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "#ffffff",
    },
    placeholderColor: {
      type: String,
      default: "#a6a6a6",
    },
    labelFontColor: {
      type: String,
      default: "#a6a6a6",
    },
    fontColor: {
      type: String,
      default: "#565553",
    },
    labelBackgroundColor: {
      type: String,
      default: "#ffffff",
    },
    customSelect: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    multiSelectOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    preValue: {
      type: String,
      default: "",
    },
    multiSelectPreValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    placeholderValue: {
      type: String,
      default: "Select",
    },
    multiSelect: {
      type: Boolean,
    },
    filterSelect: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    labelAnimation: {
      type: Boolean,
      default: false,
    },
    textEditor: {
      type: Boolean,
      default: false,
    },
    inlineSearch: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "update:native",
    "footer-click",
    "custom-change",
    "update:value",
    "selected-value",
    "update:name",
    "search-value",
    "clearable-value",
    "inline-value",
    "inline-reference-value",
    'custom-error-emit-value',
    'dropdownopen',
    "dropdownclose",
    'enter',
    'keyPress',
    'inline-select-value'
  ],
  data() {
    return {
      duplicateValue:'',
      duplicatePreVal:'',
      tabIndexValue:0,
      selectedValue:'',
      searchValue:'',
      keydownHandled: false,
      currentIndex:0,
      inlinesearchtagvalue:{},
      showsearchinput:false,
      containerwidth:0,
      containertop:0,
      containerleft:0,
      containerheight:0,
      inlinevalueselect: false,
      multi_select_inline_tag_array: [],
      multi_select_inline_tag_value: 0,
      inputvalue: "",
      multi_select_inline_count_show: false,
      multi_select_inline_count_value: 0,
      multiselecttagvalue: 0,
      showselectedvalue: [],
      returndata: [],
      multiselectinlineoptions: [],
      multiselectarray: [],
      multiselectplaceholder: false,
      multiselectdropdownvalue: false,
      single_select: false,
      count: false,
      isMultiSelect: false,
      search_by: "",
      multi_select_value: [],
      count_select_value: [],
      multi_select_count_value: [],
      listValue: [],
      checkedData: [],
      value: "",
      selectall: true,
      dropdownname: false,
      placeholder: true,
      dropdownvalue: false,
      selected_icon: "",
      selectoptioncontainer: false,
      iconselect: false,
      icon_value: "",
      icon_country_flag: "undefined",
      obj: {},
      selectedvalue: [],
      title: "",
      tagvalue: 11,
      multiselecttitle: false,
      valueid: "",
      all: false,
      unique: Math.floor(Math.random() * 1000 + 1),
      disablebutton: false,
      option_value: "",
      optionsD: [],
      custom_select: false,
      custom_dropdown_option: false,
      customoption: [],
      customdropdowncontainer: false,
      selectvalue: "",
      placeholdervalue: "",
      selectcount: 0,
      //  label:false,
      //  input:true,
      //  labelinput:false,
      animation: false,
      count22: 0,
      top:45,
      left:1
    };
  },
  computed: {
    computeMultiSelect() {
      this.computeDropdownData;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.count_select_value = this.multi_select_value.length - this.tagvalue;
      return this.multi_select_value;
    },
    computeDropdownData() {
      const data = [...this.multiselectarray];
      if (this.search_by && this.search_by != "") {
        return data.filter((item) =>
          item[this.label]
            .toLowerCase()
            .toString()
            .includes(this.search_by.toLowerCase())
        );
      } else {
        return this.multiselectarray;
      }
    },
    dropdowndata() {
      const data = [...this.customoption];
      let inlinsearchvalue = []
      let data1 = []
      if(this.swatch!=true){
      if(this.customSearch != true)
      {
      if (this.searchValue != "" && this.searchValue) {
        if(this.searchImageTag != true)
        {
        data1 = data.filter((e)=>{
          if(e[this.label] .toString()
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()))
            {
               return e
            }
        })
        this.switchdropdown(data1)
      }
      else{
        data1 = data.filter((e)=>{
          if(e[this.label] .toString()
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) || e[this.subTitle] .toString()
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()))
            {
               return e
            }
        })
        this.switchdropdown(data1)
      }
        // console.log(data1);
        inlinsearchvalue = data1
        // return data1
      } else {
        this.switchdropdown(data)
        inlinsearchvalue = data
        // return this.customoption;
      }
    }
    else{
      inlinsearchvalue = data
    }
      }else{
        inlinsearchvalue = data
      }
    return inlinsearchvalue
    },
    multiselectsearch() {
      const data = [...this.multiselectinlineoptions];
      // console.log(this.selectvalue);
      if (this.inputvalue != "" && this.inputvalue) {
        return data.filter((item) =>
          item[this.label]
            .toString()
            .toLowerCase()
            .includes(this.inputvalue.toLowerCase())
        );
      } else {
        return this.multiselectinlineoptions;
      }
    },
  },
  watch: {
    // containertop:{
    //   handler(val)
    //   {
    //     val

    //   },
    //   immediate:true
    // },
        label:{
       handler(val)
       {
        val
       },
       immediate:true,
       deep:true
    },
    propValue:{
             handler(val)
       {
        val
       },
       immediate:true,
       deep:true
    },
    error:{
      handler(val)
      {
          val
      },
      immediate:true,
      deep:true
    },
    returndata: {
      handler(val) {
        if (val != "") {
          if (this.multiSelectInlineSearch == true) {
            this.showselectedvalue = this.returndata.filter((e) => {
              if (e.checked == true) {
                return e;
              }
            });
            // console.log(this.showselectedvalue);
            const box1 = document.querySelector(
              "#custom-multi-select" + this.unique
            );
            const observer = new ResizeObserver((e) => {
              // console.log(e[0].contentRect.width < 920);
              if (
                e[0].contentRect.width > 1000 ||
                e[0].contentRect.width < 920
              ) {
                this.multiselecttagvalue = 8;
                if (this.multiselecttagvalue > this.showselectedvalue.length) {
                  // console.log('sj');
                  this.multi_select_inline_count_show = false;
                  const tagwidth = 100;
                  document.getElementById(
                    "custom-multi-select-tag-value" + this.unique
                  ).style.width = tagwidth + "px";
                  const inputwidth =
                    e[0].contentRect.width -
                    100 * this.showselectedvalue.length -
                    30;
                  document.getElementById(
                    "custom-multi-select-input" + this.unique
                  ).style.width = inputwidth + "px";
                  this.multi_select_inline_tag_array = this.showselectedvalue;
                  if (
                    this.showselectedvalue.length >
                    this.multi_select_inline_tag_value
                  ) {
                    this.multi_select_inline_tag_value =
                      this.showselectedvalue.length;
                  }
                  // console.log(this.multi_select_inline_tag_value);
                } else {
                  // this.multi_select_inline_tag_value = this.showselectedvalue.length
                  this.multi_select_inline_count_show = true;
                  this.multi_select_inline_count_value =
                    this.showselectedvalue.length -
                    this.multiselecttagvalue +
                    1;
                  if (this.showselectedvalue.length == 8) {
                    const positionvalue =
                      this.showselectedvalue.length * 100 - 30;
                    // console.log(positionvalue)
                    document.getElementById(
                      "multi-select-inline-count-value" + this.unique
                    ).style.left = positionvalue + "px";
                    const inputwidth =
                      e[0].contentRect.width -
                      100 * this.multiselecttagvalue -
                      34;
                    document.getElementById(
                      "custom-multi-select-input" + this.unique
                    ).style.width = inputwidth + "px";
                    document.getElementById(
                      "custom-multi-select-input" + this.unique
                    ).style.marginLeft = "24px";
                    //  count++
                  }
                  // console.log('thambi',this.multi_select_inline_count_value);
                }
              }
            });
            observer.observe(box1);
          }
        }
      },
      immediate: true,
      deep: true,
    },
    multiSelectPreValue: {
      handler(val) {
        if (val != []) {
          val.forEach((e) => {
            this.multiselectarray.forEach((ele) => {
              // console.log(ele.value,e);
              if (ele[this.propValue] === e) {
                ele.checked = true;
              }
            });
          });
          let multiselectpre_valuecount = 0;
          this.multiselectarray.forEach((e) => {
            if (e.checked === true) {
              multiselectpre_valuecount++;
            }
          });
          if (multiselectpre_valuecount === this.multiselectarray.length) {
            this.all = true;
          } else {
            this.all = false;
          }
        }
        // let count = 0
        // console.log(count);
        // count++
        // document.getElementById('multiselect'+ this.unique).click()
        // document.querySelector('.whole-checkbox').children[0].children[0].nextElementSibling.classList.remove('select-all-theme')
      },
      immediate: true,
      deep: true,
    },
    modelValue: {
      handler(val) {
        if(val != '')
        {
          console.log(val,'modelvalue',this.customoption);
          // this.$emit("keyPress", val); 
        }
      },
      immediate: true,
      deep:true
    },
    inlinesearchtagvalue:{
      handler(val) {
        val
        this.$emit('inline-select-value',val)
          //  console.log(val,'kkkslkss');
      },
      immediate:true,
      deep:true
    },
    customoption: {
      handler(val) {
        if (val) {
          // if (this.selectvalue != "") {
          //   val.forEach((e) => {
          //     if (e[this.propValue] === this.selectvalue) {
          //       // console.log(val,'vale',e);
          //       // console.log(this.selectvalue);
          //       this.inlinesearchtagvalue = e
          //       if(this.slotOptions == true)
          //       {
          //         if(this.showsearchinput == true)
          //         this.showsearchinput = false
          //       }
          //       e.checked = true;
          //       this.selectvalue = e[this.label];
          //       // this.placeholdervalue = this.placeholdervalue;
          //       this.selectcount = 1;
          //       if (this.labelAnimation === true) {
          //         this.animation = true;
          //       } else {
          //         this.animation = false;
          //       }
          //     } else {
          //       e.checked = false;
          //     }
          //   });
          //   // console.log(val,'check');
          // }else{
          //   console.log('aasjd');
          // }
        }
      },
      immediate: true,
      deep: true,
    },
    options: {
      handler(val) {
        if (val) {
          if (this.inlineSearch === true) {
            this.customoption = JSON.parse(JSON.stringify([...val]));
            if (this.preValue != "") {
              this.customoption.forEach((e) => {
                if (e[this.propValue] === this.preValue) {
                  this.selectedValue = e[this.label]
                  this.inlinesearchtagvalue = e
                  // this.$emit("selected-value", e);
                  e.checked = true;
                } else {
                  e.checked = false;
                }
              });
            }
          } else if (this.single_select == true) {
            this.optionsD = JSON.parse(JSON.stringify([...val]));
            if (this.preValue != "") {
              this.optionsD.forEach((e) => {
                if (e[this.propValue] === this.preValue) {
                  // console.log(this.inlinesearchtagvalue,'la');
                  e.checked = true;
                  this.duplicatePreVal = e[this.label]
                  // this.$emit("selected-value", e);
                } else {
                  e.checked = false;
                }
              });
            }
            // if()
          } else if (this.multiSelectInlineSearch == true) {
            this.multiselectinlineoptions = JSON.parse(
              JSON.stringify([...val])
            );
          }
        }
      },
      immediate: true,
      deep: true,
    },
    optionsD: {
      handler(val) {
        // console.log(val, 'waaa')
        if (val) {
          if (this.single_select === true) {
            val.forEach((e) => {
              // if(e.id === undefined)
              // {
              //   e.id = 'singleselect' + this.unique + index
              // }
              if (e.checked === true) {
                this.placeholder = false;
                this.dropdownvalue = true;
                this.option_value = e[this.label];
              }
            });
          }
          if (this.textEditor === true) {
            if (val != undefined) {
              val.forEach((e, index) => {
                if (e.id === undefined) {
                  e.id = "textEditor" + this.unique + index;
                }
                if (e.checked === true) {
                  this.placeholder = false;
                  this.dropdownvalue = true;
                  this.option_value = e[this.label];
                }
              });
            }
          } else if (this.icon === true) {
            // console.log(val,'op');
            val.forEach((e) => {
              if (e.checked === true) {
                this.placeholder = false;
                this.dropdownvalue = true;
                this.option_value = e[this.label];
                // this.icon_country_flag = e.icon
                // console.log(this.icon_country_flag,'new');
              }
            });
          }
        }
        // console.log(e,'in watch');
      },
      immediate: true,
      deep: true,
      flush: "post",
    },
    duplicatePreVal:{
        handler(val){
          if(val != '' && val != this.duplicateValue)
           {
              this.duplicateValue = val
              this.duplicateCount = 0
                this.optionsD.forEach((e)=>{
                  if(e.checked == true){
                    if(this.defaultEmit == true)
                    {
                    this.$emit("selected-value", e);
                    }
                  } 
                })
              }
            },
            immediate: true,
            deep: true,
        },
    icon_value: {
      handler() {
        // console.log(val);
      },
    },
    checkedData: {
      handler(val) {
        if (this.filterSelect === true) {
          // console.log('hi',val);
          if (val != []) {
            // console.log(val,'op');
            //  let count= 0
            let count1 = 0;
            val.forEach((e) => {
              if (e.checked === true) {
                //  count++
              }
              if (e.checked === false) {
                count1++;
              }
            });
            //  if(val.length === count)
            //  {
            //   //  this.multiselectdropdownvalue = false
            //     this.all = true
            //  }
            //  else{
            //    this.all = false
            //    // console.log(document.querySelector('.hlx-multi-select-input'),'lol');
            //  }
            if (val.length === count1) {
              //  this.multiselectdropdownvalue = false
              this.disablebutton = true;
            } else {
              this.disablebutton = false;
              // console.log(document.querySelector('.hlx-multi-select-input'),'lol');
            }
            this.$emit("update:value", this.checkedData);
          }
        } else {
          // console.log('hello');
          if (this.multiSelect == true) {
            if (val != []) {
              // console.log(val,'op');
              let count = 0;
              val.forEach((e) => {
                if (e.checked === true) {
                  count++;
                }
              });
              if (val.length === count) {
                //  this.multiselectdropdownvalue = false
                this.all = true;
              } else {
                this.all = false;
                // console.log(document.querySelector('.hlx-multi-select-input'),'lol');
              }
              this.$emit("custom-change", val);
              this.$emit("update:value", this.checkedData);
            }
          }
        }
      },
      deep: true,
      flush: "post",
    },
    multiselectarray: {
      handler(val) {
        if (this.filterSelect === true) {
          // console.log('hi');
          if (val !== undefined) {
            // val.forEach((e,index)=>{
            //    e.id = 'multiselect' + this.unique + index
            // })
            //  console.log(val,'loljhnddhnddjkdkjsdskjd');/
            if (val != []) {
              //  let count = 0
              let count1 = 0;
              val.forEach((e) => {
                // if(e.checked === true)
                // {
                //   count++
                // }
                if (e.checked === false) {
                  count1++;
                }
              });
              //  if(val.length === count)
              //  {
              //     //  this.multiselectdropdownvalue = false
              //       this.all = true
              //  }
              //  else{
              //    this.all = false
              //  }
              if (val.length === count1) {
                //  this.multiselectdropdownvalue = false
                this.disablebutton = true;
              } else {
                this.disablebutton = false;
              }
            }
          }
        } else {
          // console.log('hello');
          if (val !== undefined) {
            //  console.log(val,'loljhnddhnddjkdkjsdskjd');/
            if (val != []) {
              let count = 0;
              val.forEach((e) => {
                if (e.checked === true) {
                  count++;
                }
              });
              if (val.length === count) {
                //  this.multiselectdropdownvalue = false
                this.all = true;
              } else {
                this.all = false;
              }
            }
          }
        }

        // this.$emit('update:value', this.checkedData)
      },
      immediate: true,
      deep: true,
      flush: "post",
    },
    tagvalue: {
      handler() {
        // console.log(val,'tagvalue');
      },
      immediate: true,
    },
    multiSelectOptions: {
      handler(val) {
        if (val) {
          if (val && val.length > 0) {
            this.multiselectarray = JSON.parse(JSON.stringify([...val]));
            if (this.multiSelectPreValue != []) {
              this.multiSelectPreValue.forEach((e) => {
                this.multiselectarray.forEach((ele) => {
                  if (ele[this.propValue] === e) {
                    ele.checked = true;
                  }
                });
              });
              let multiselectpre_valuecount = 0;
              this.multiselectarray.forEach((e) => {
                if (e.checked === true) {
                  multiselectpre_valuecount++;
                }
              });
              if (multiselectpre_valuecount === this.multiselectarray.length) {
                this.all = true;
              } else {
                this.all = false;
              }
            }
            if (document.getElementById("multiselect" + this.unique) != null) {
              document.getElementById("multiselect" + this.unique).click();
              document
                .querySelector(".whole-checkbox")
                .children[0].children[0].nextElementSibling.classList.remove(
                  "select-all-theme"
                );
            }
          }
        }
      },
      immediate: true,
      deep: true,
      flush: "post",
    },
    selectvalue: {
      handler() {
        // this.$emit("keyPress", this.selectvalue);
        //  console.log(val);
      },
      immediate: true,
    },
    searchValue: {
      handler(val) {
        // this.$emit("keyPress", this.selectvalue);
         console.log(val);
      },
      immediate: true,
    },
    preValue:{
      handler(val)
      {
        if(this.inlineSearch != true){
          if(val != '')
          {
            this.dropdownname = true
            this.optionsD.forEach((e)=>{
            // console.log('test')
            if(e[this.propValue] === val)
            {
              // console.log('akakak',this.inlinesearchtagvalue);
               e.checked = true
               this.duplicatePreVal = e[this.label]
              //  this.$emit("selected-value", e);
            }
            else{
              e.checked = false
            }
          })

          let checkedCount = 0
    if(this.preValue != ""){
      // console.log(this.preValue,'ltot');
       this.optionsD.forEach((e)=>{
        if(e.checked == true)
        {
          checkedCount += 1
        }
       })
       if(checkedCount == 0){
         this.dropdownname = false
       }
    }
                  // console.log(val,'prevalue',this.optionsD);

        }
      }
      else{
        if(val != '')
        {
          this.customoption.forEach((e)=>{
            if(e[this.propValue] === val)
            {
              // console.log('akakak',this.inlinesearchtagvalue);
               e.checked = true
              //  this.$emit("selected-value", e);
               this.selectedValue = e[this.label]
               this.inlinesearchtagvalue = e
            }
            else{
              e.checked = false
            }
          })
        }
      }
      },
      deep: true,
      immediate: true,
    },
    placeholderValue:{
      handler(val)
      {
        console.log(val,'sss');
         if(this.inlineSearch == true && this.disabled == false)
         {
          this.placeholdervalue = val
         }
         else{
          this.title = val
         }
      },
      deep:true,
      immediate:true
    },
  },
//   updated(){ 
// if(this.customdropdowncontainer == true)
// {
//             this.$nextTick(()=>{
//           this.containerleft = document.querySelector('#custom-dropdown'+this.unique).getBoundingClientRect().left
//           this.containertop = document.querySelector('#custom-dropdown'+this.unique).getBoundingClientRect().top
//           console.log(this.containertop,'top');
//           this.containerwidth = document.querySelector('#custom-dropdown'+this.unique).getBoundingClientRect().width
//           })
// }
//     },
  mounted() {
  if(this.inlineSearch == true)
  {
    // if(this.searchImageTag == true){
            this.showsearchinput = false
          // }else{
          //   this.showsearchinput = true
          // }
  }

    if (this.options.length !== 0) {
      this.options.forEach((e) => {
        if (e.checked === undefined) {
          e.checked = false;
          e.disabled = false;
        }
      });
    }
    document.addEventListener("click",(e)=>{
      // console.log(e.target.className,'laola',e.target.id,'icon-angle-down-regular'+this.unique);
      // console.log(e.target.className == 'icon-times-circle-regular' && e.target.id == 'icon-angle-down-regular'+this.unique,'trueorfalse');
      if( e.target.id == 'icon-angle-down-regular'+this.unique)
      {
        if(this.clearable == true && this.inlineSearch == true && this.selectedValue != '')
        {
          console.log('hellooo')
          document.getElementById('icon-angle-down-regular'+this.unique).classList.remove('icon-times-circle-regular') 
          document.getElementById('icon-angle-down-regular'+this.unique).classList.add('icon-angle-down-regular')
          document.querySelector('#custom-dropdown'+this.unique).classList.remove("selecteddropdown")
          // console.log(e.target,'ll,');
          // console.log('jfdk');
          this.showsearchinput = false
          this.customdropdowncontainer = false
          this.selectedValue = ''
          this.inlinesearchtagvalue = {}
          this.placeholdervalue = this.placeholderValue
          this.animation = true
          this.selectcount = 0
          this.customoption.forEach((e)=>{
            e.checked = false
          })   
          this.$emit("update:value", this.selectedValue);  


        }
      }
    })
    document.addEventListener("click", (e) => {
      // console.log(event.target,'multis');
      if (this.multiSelect === true) {
        if (this.isMultiSelect == true) {
          const elem = document
            .getElementById("multiselect" + this.unique)
            .contains(e.target);
          if (elem === false) {
            this.isMultiSelect = false;
            this.$emit("custom-error-emit-value", this.option_value);
            document
              .getElementById("multiselect" + this.unique)
              .classList.remove("add-style");
            // console.log(elem);
          }
        }
      }
    });

    // document.addEventListener("click", (e) => {
    //   if (this.inlineSearch === true) {
    //     if (this.customdropdowncontainer === true) {
    //       const ele = document
    //         .getElementById("custom-dropdown" + this.unique)
    //         .contains(e.target);
    //       if (ele === false) {
    //         this.customdropdowncontainer = false;
    //         if (this.inlinevalueselect === false) {
    //           this.$emit("custom-error-emit-value", this.selectvalue);
    //         } else {
    //           this.$emit("custom-error-emit-value", this.placeholderValue);
    //         }

    //         // console.log(document.getElementById('custom-dropdown'+this.unique).children[1]);
    //         document
    //           .getElementById("custom-dropdown" + this.unique)
    //           .children[1].classList.add("icon-angle-down-regular");
    //         document
    //           .getElementById("custom-dropdown" + this.unique)
    //           .children[1].classList.remove("icon-angle-up-regular");
    //         document
    //           .getElementById("custom-dropdown" + this.unique)
    //           .classList.remove("selecteddropdown");
    //         if (this.selectcount > 0) {
    //           this.selectvalue = this.placeholdervalue;
    //           this.placeholdervalue = "";
    //           this.animation = true;
    //         }
    //       }
    //     }
    //   }
    // });
    document.addEventListener("click", (e) => {
      if(this.single_select === true)
      {
      if (
        e.target.className != "hlx-select-container change-color" &&
        e.target.className != "title-name" &&
        e.target.className != "hlx-select-input" &&
        e.target.className != "hlx-select-icon" &&
        e.target.className != "icon-angle-down-regular" &&
        e.target.className != "hlx-select-option-container" &&
        e.target.className != "hlx-select-option-value-container" &&
        e.target.className != "hlx-select-option-value" &&
        e.target.className != "icon-container" &&
        e.target.tagName != "I" &&
        e.target.className != "hlx-select-input-placeholder"&&
        e.target.className != "hlx-select-whole-option-container"&&
        e.target.className != "hlx-fixed-footer"+this.unique &&
        e.target.className != "hlx-fixed-footer-container"&&
        e.target.className != "hlx-footer-icon" &&
        e.target.className != "hlx-footer-content"
      ) {
        // console.log(e.target);
        if (this.selectoptioncontainer === true) {
          this.selectoptioncontainer = false;
          this.keydownHandled = false
          console.log('222')
          // console.log(this.option_value)
          this.$emit("custom-error-emit-value", this.option_value);

          document.querySelectorAll(".hlx-select-container").forEach((eve) => {
            eve.classList.remove("change-color");
          });

          document.querySelectorAll(".title-name").forEach((e) => {
            e.classList.remove("dropdownname-color");
          });

          document
            .querySelectorAll(".icon-angle-down-regular")
            .forEach((ev) => {
              ev.classList.remove("add");
            });
        }
      }
      }
    });
    if (this.labelAnimation === false) {
      this.title = "";
    } else {
      this.title = this.placeholderValue;
    }
    if (this.multiSelectInlineSearch == true) {
      this.single_select = false;
    } else if (this.multiSelect === true) {
      this.single_select = false;
      this.multiselectplaceholder = true;
      this.$emit("update:value", this.multiselectarray);
      const value = this.multiselectarray.filter((data) => {
        if (data.checked == true) {
          return true;
        }
      });
      // console.log(value.length, 'kkk')
      document.querySelectorAll(".hlx-multi-select-container").forEach((e) => {
        if (value.length > 0) {
          this.multiselecttitle = true;
          this.multiselectplaceholder = false;
          this.multiselectdropdownvalue = true;
          e.click();
        }
      });

      const box = document.querySelector("#select" + this.unique);
      const observer = new ResizeObserver((e) => {
        if (e[0].contentRect.width > 882 && e[0].contentRect.width < 920) {
          this.tagvalue = 11;
        } else if (
          e[0].contentRect.width > 806 &&
          e[0].contentRect.width < 881
        ) {
          this.tagvalue = 10;
        } else if (
          e[0].contentRect.width > 731 &&
          e[0].contentRect.width < 805
        ) {
          this.tagvalue = 9;
        } else if (
          e[0].contentRect.width > 658 &&
          e[0].contentRect.width < 730
        ) {
          this.tagvalue = 8;
        } else if (
          e[0].contentRect.width > 584 &&
          e[0].contentRect.width < 657
        ) {
          this.tagvalue = 7;
        } else if (
          e[0].contentRect.width > 507 &&
          e[0].contentRect.width < 583
        ) {
          this.tagvalue = 6;
        } else if (
          e[0].contentRect.width > 434 &&
          e[0].contentRect.width < 506
        ) {
          this.tagvalue = 5;
        } else if (
          e[0].contentRect.width > 358 &&
          e[0].contentRect.width < 433
        ) {
          this.tagvalue = 4;
        } else if (
          e[0].contentRect.width > 283 &&
          e[0].contentRect.width < 357
        ) {
          this.tagvalue = 3;
        } else if (
          e[0].contentRect.width > 213 &&
          e[0].contentRect.width < 282
        ) {
          this.tagvalue = 2;
        } else if (e[0].contentRect.width > 0 && e[0].contentRect.width < 212) {
          this.tagvalue = 1;
        }
      });
      observer.observe(box);
      // this.all = true
      if (this.multiSelectOptions && this.multiSelectOptions.length > 0) {
        this.multiselectarray = JSON.parse(
          JSON.stringify([...this.multiSelectOptions])
        );
      }
      if (this.multiSelectPreValue != []) {
        this.multiSelectPreValue.forEach((e) => {
          this.multiselectarray.forEach((ele) => {
            // console.log(ele.value,e);
            if (ele[this.propValue] === e) {
              ele.checked = true;
            }
            //  else{
            //     ele.checked = false
            //  }
          });
        });
        let multiselectpre_valuecount = 0;
        this.multiselectarray.forEach((e) => {
          if (e.checked === true) {
            multiselectpre_valuecount++;
          }
        });
        if (multiselectpre_valuecount === this.multiselectarray.length) {
          this.all = true;
        } else {
          this.all = false;
        }
        //  if(this.multiselectpre_valuecount > 0)
        //  {
        //   // console.log('jij');
        // this.multiselecttitle = true
        // this.multiselectplaceholder = false
        // this.multiselectdropdownvalue = true
        //  }
        //  console.log(this.multiselectarray,'olkjh',this.multiSelectPreValue);
      }
      document.getElementById("multiselect" + this.unique).click();
      document
        .querySelector(".whole-checkbox")
        .children[0].children[0].nextElementSibling.classList.remove(
          "select-all-theme"
        );
    } else if (this.icon == true) {
      this.single_select = false;

      this.options.forEach((e, index) => {
        if (e.id === undefined) {
          e.id = "singleselect" + this.unique + index;
        }
      });
    } else if (this.textEditor === true) {
      this.single_select = false;
      if (this.options && this.options.length > 0) {
        this.optionsD = JSON.parse(JSON.stringify([...this.options]));
      }
      this.optionsD.forEach((e, index) => {
        if (e.id === undefined) {
          e.id = "textEditor" + this.unique + index;
        }
        if (e.checked === true) {
          this.option_value = e[this.label];
        }
        // console.log('op');
      });
    } else if (this.customSelect === true) {
      this.custom_select = true;
    } else if (this.inlineSearch === true) {
      this.single_select = false;
      if (this.selectvalue == "") {
        this.placeholdervalue = this.placeholderValue;
      }
      else{
        this.selectcount = 1
      }
    } else {
      this.single_select = true;
      let count = this.options.length;
      const logic = "";
      this.options.forEach((e) => {
        if (e.checked === true) {
          this.$emit("update:value", e[this.propValue]);
          this.$emit("selected-value", e);
          count--;
        }
      });
      if (count === this.options.length) {
        if (this.preValue != "") {
          this.$emit("update:value", logic);
        }
      }
    }
    this.selectall = true;

    if (this.options && this.options.length > 0) {
      if (this.inlineSearch !== true) {
        this.optionsD = JSON.parse(JSON.stringify([...this.options]));
      }
    }

    if (this.preValue !== "") {
      this.pre_value = this.preValue;
      this.optionsD.forEach((e) => {
        if (e.checked === undefined) {
          if (this.pre_value === e[this.propValue]) {
            this.inlinesearchtagvalue = e
            // console.log('aaaaaaa',this.inlinesearchtagvalue);
            e.checked = true;
          } else {
            e.checked = false;
          }
        } else {
          if (this.pre_value === e[this.propValue]) {
            e.checked = true;
            this.placeholder = false;
            this.dropdownname = true;
            this.dropdownvalue = true;
            this.$emit("update:value", e[this.propValue]);
            this.$emit("custom-change", this.optionsD);
          } else {
            e.checked = false;
          }
        }
      });
    }

    let checkedCount = 0
    if(this.preValue != ""){
      // console.log(this.preValue,'ltot');
       this.optionsD.forEach((e)=>{
        if(e.checked == true)
        {
          checkedCount += 1
        }
       })
       if(checkedCount == 0){
         this.dropdownname = false
       }
    }


          this.$nextTick(()=>{
        if(document.querySelector('#hlx-select-container'+this.unique) != null)
        {
          if(this.footer != true)
          {
            var element = document.querySelector('#hlx-select-container'+this.unique);
            var position = element.getBoundingClientRect();
            var y = position.top;
            var innerheight = window.innerHeight - y
            if(innerheight < 250)
            {
            if(this.optionsD.length == 1)
            {
               this.top = -45
              this.left = -2
            }
            else if(this.optionsD.length == 2)
            {
               this.top = -78
              this.left = -2
            }
            else if(this.optionsD.length == 3)
            {
               this.top = -106
              this.left = -2
            }
            else if(this.optionsD.length == 4)
            {
               this.top = -137
              this.left = -2
            }
            else if(this.optionsD.length == 5)
            {
               this.top = -169
              this.left = -2
            }
            else if(this.optionsD.length == 6)
            {
               this.top = -197
              this.left = -2
            }
            else{
              this.top = -213
              this.left = -2
            }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
          else if(this.footer == true)
          {
            var element1 = document.querySelector('#hlx-select-container'+this.unique);
            var position1 = element1.getBoundingClientRect();
            var y1 = position1.top;
            var innerheight1 = window.innerHeight - y1
            if(innerheight1 < 250)
            {
            if(this.optionsD.length == 1)
            {
               this.top = -74
              this.left = -2
            }
            else if(this.optionsD.length == 2)
            {
               this.top = -102
              this.left = -2
            }
            else if(this.optionsD.length == 3)
            {
               this.top = -133
              this.left = -2
            }
            else if(this.optionsD.length == 4)
            {
               this.top = -165
              this.left = -2
            }
            else if(this.optionsD.length == 5)
            {
               this.top = -195
              this.left = -2
            }
            else if(this.optionsD.length == 6)
            {
               this.top = -224
              this.left = -2
            }
            else{
              this.top = -242
              this.left = -2
            }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
        }
      })
            this.$nextTick(()=>{
        if(document.querySelector('#custom-dropdown' + this.unique))
        {

          if(this.footer != true)
          {
            var element = document.querySelector('#custom-dropdown' + this.unique);
            var position = element.getBoundingClientRect();
            // var x = position.left;
            var y = position.top;
            // console.log(x,y,'pop',window.1 - y);
            var innerheight = window.innerHeight - y
            if(innerheight < 250)
            {
              // console.log();
                    if(this.dropdowndata.length == 0)
                      {
                        this.top = -45
                        this.left = -2
                      }
                  else if(this.dropdowndata.length == 1)
                      {
                        this.top = -45
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 2)
                      {
                        this.top = -71
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 3)
                      {
                        this.top = -101
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 4)
                      {
                        this.top = -131
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 5)
                      {
                        this.top = -161
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 6)
                      {
                        this.top = -173
                        this.left = -2
                      }
                      else {
                        this.top = -173
                        this.left = -2
                      }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
          else if(this.footer == true)
          {
            var element1 = document.querySelector('#custom-dropdown' + this.unique);
            var position1 = element1.getBoundingClientRect();
            // var x = position.left;
            var y1 = position1.top;
            // console.log(x,y,'pop',window.1 - y);
            var innerheight1 = window.innerHeight - y1
            if(innerheight1 < 250)
            {
              // console.log(this.dropdowndata.length,'data');
                    if(this.dropdowndata.length == 0)
                      {
                        this.top = -76
                        this.left = -2
                      }
                  else if(this.dropdowndata.length == 1)
                      {
                        this.top = -76
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 2)
                      {
                        this.top = -106
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 3)
                      {
                        this.top = -137
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 4)
                      {
                        this.top = -169
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 5)
                      {
                        this.top = -197
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 6)
                      {
                        this.top = -213
                        this.left = -2
                      }
                      else{
                        this.top = -213
                        this.left = -2
                      }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
        }
      })
       this.$nextTick(()=>{
        if(document.querySelector('#multiselect' + this.unique))
        {
          var element = document.querySelector('#multiselect' + this.unique);
          var position = element.getBoundingClientRect();
          // var x = position.left;
          var y = position.top;
          // console.log(x,y,'pop',window.innerHeight - y);
          var innerheight = window.innerHeight - y
          if(innerheight < 250)
          {
                  if(this.options.length == 1)
                    {
                      this.top = -45
                      this.left = 0
                    }
                    else if(this.options.length == 2)
                    {
                      this.top = -78
                      this.left = 0
                    }
                    else if(this.options.length == 3)
                    {
                      this.top = -106
                      this.left = 0
                    }
                    else if(this.options.length == 4)
                    {
                      this.top = -137
                      this.left = 0
                    }
                    else if(this.options.length == 5)
                    {
                      this.top = -169
                      this.left = 0
                    }
                    else if(this.options.length == 6)
                    {
                      this.top = -197
                      this.left = 0
                    }
                    else{
                      this.top = -230
                      this.left = 0
                    }
          }
          else{
            this.top = 45
            this.left = -1
          }
        }
      })
      this.$nextTick(()=>{
        if(this.scrollClassname != ''){
          if(document.querySelector('.'+this.scrollClassname))
          {
  
          document.querySelector('.'+this.scrollClassname).addEventListener("scroll", (event) => {
            event
            if(this.inlineSearch == true)
            {
              // console.log(this.scrollClassname,'data');
              // console.log('abc');
              this.$nextTick(()=>{
                 if(document.querySelector('#custom-dropdown' + this.unique)) 
              {
          if(this.footer != true)
          {
            var element = document.querySelector('#custom-dropdown' + this.unique);
            var position = element.getBoundingClientRect();
            // var x = position.left;
            var y = position.top;
            // console.log(x,y,'pop',window.1 - y);
            var innerheight = window.innerHeight - y
            if(innerheight < 250)
            {
              // console.log(this.dropdowndata.length);
                    if(this.dropdowndata.length == 0)
                      {
                        this.top = -45
                        this.left = -2
                      }
                  else if(this.dropdowndata.length == 1)
                      {
                        this.top = -45
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 2)
                      {
                        this.top = -71
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 3)
                      {
                        this.top = -101
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 4)
                      {
                        this.top = -131
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 5)
                      {
                        this.top = -161
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 6)
                      {
                        this.top = -173
                        this.left = -2
                      }
                      else {
                        this.top = -173
                        this.left = -2
                      }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
          else if(this.footer == true)
          {
            var element1 = document.querySelector('#custom-dropdown' + this.unique);
            var position1 = element1.getBoundingClientRect();
            // var x = position.left;
            var y1 = position1.top;
            // console.log(x,y,'pop',window.1 - y);
            var innerheight1 = window.innerHeight - y1
            if(innerheight1 < 250)
            {
              // console.log(this.dropdowndata.length,'data');
                    if(this.dropdowndata.length == 0)
                      {
                        this.top = -76
                        this.left = -2
                      }
                  else if(this.dropdowndata.length == 1)
                      {
                        this.top = -76
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 2)
                      {
                        this.top = -106
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 3)
                      {
                        this.top = -137
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 4)
                      {
                        this.top = -169
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 5)
                      {
                        this.top = -197
                        this.left = -2
                      }
                      else if(this.dropdowndata.length == 6)
                      {
                        this.top = -213
                        this.left = -2
                      }
                      else{
                        this.top = -213
                        this.left = -2
                      }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
              }
            })
              // console.log('aaaa');
              // console.log(event);
            }
            else if(this.multiSelect == true)
            {
                        this.$nextTick(()=>{
                 if(document.querySelector('#multiselect' + this.unique)) 
              {
                var element = document.querySelector('#multiselect' + this.unique);
                var position = element.getBoundingClientRect();
                // var x = position.left;
                var y = position.top;
                // console.log('bbbb');
                // console.log(x,y,'pop',window.innerHeight - y);
                var innerheight = window.innerHeight - y
                if(innerheight < 250)
                {
                  if(this.options.length == 1)
                    {
                      this.top = -45
                      this.left = 0
                    }
                    else if(this.options.length == 2)
                    {
                      this.top = -78
                      this.left = 0
                    }
                    else if(this.options.length == 3)
                    {
                      this.top = -106
                      this.left = 0
                    }
                    else if(this.options.length == 4)
                    {
                      this.top = -137
                      this.left = 0
                    }
                    else if(this.options.length == 5)
                    {
                      this.top = -169
                      this.left = 0
                    }
                    else if(this.options.length == 6)
                    {
                      this.top = -197
                      this.left = 0
                    }
                    else{
                      this.top = -230
                      this.left = 0
                    }
                }
                else{
                  this.top = 45
                  this.left = -1
                }
              }
            })
              // console.log('aaaa');
              // console.log(event);
            }
            else{
              this.$nextTick(()=>{
             if(document.querySelector('#hlx-select-container'+this.unique)) 
             {
          if(this.footer != true)
          {
            var element = document.querySelector('#hlx-select-container'+this.unique);
            var position = element.getBoundingClientRect();
            var y = position.top;
            var innerheight = window.innerHeight - y
            if(innerheight < 250)
            {
            if(this.optionsD.length == 1)
            {
               this.top = -45
              this.left = -2
            }
            else if(this.optionsD.length == 2)
            {
               this.top = -78
              this.left = -2
            }
            else if(this.optionsD.length == 3)
            {
               this.top = -106
              this.left = -2
            }
            else if(this.optionsD.length == 4)
            {
               this.top = -137
              this.left = -2
            }
            else if(this.optionsD.length == 5)
            {
               this.top = -169
              this.left = -2
            }
            else if(this.optionsD.length == 6)
            {
               this.top = -197
              this.left = -2
            }
            else{
              this.top = -213
              this.left = -2
            }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
          else if(this.footer == true)
          {
            var element1 = document.querySelector('#hlx-select-container'+this.unique);
            var position1 = element1.getBoundingClientRect();
            var y1 = position1.top;
            var innerheight1 = window.innerHeight - y1
            if(innerheight1 < 250)
            {
            if(this.optionsD.length == 1)
            {
               this.top = -74
              this.left = -2
            }
            else if(this.optionsD.length == 2)
            {
               this.top = -102
              this.left = -2
            }
            else if(this.optionsD.length == 3)
            {
               this.top = -133
              this.left = -2
            }
            else if(this.optionsD.length == 4)
            {
               this.top = -165
              this.left = -2
            }
            else if(this.optionsD.length == 5)
            {
               this.top = -195
              this.left = -2
            }
            else if(this.optionsD.length == 6)
            {
               this.top = -224
              this.left = -2
            }
            else{
              this.top = -242
              this.left = -2
            }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
             }
           }) 
            }
          })
        }
        }
      })
    
    
  },
  methods: {
    focusInValue(index,event)
    {
      console.log(index,event.key,event.shiftKey)
      this.currentIndex = index

      if(event.key == 'ArrowUp' && index > 0){
        if(this.optionsD.length>this.tabIndexValue){
                this.tabIndexValue -= 1
        }
        document.getElementById('value'+index+this.unique).previousElementSibling.focus()
      }else if(event.key == 'ArrowDown' && index < this.optionsD.length-1){
        if(this.optionsD.length>this.tabIndexValue){
                this.tabIndexValue += 1
        }
        document.getElementById('value'+index+this.unique).nextElementSibling.focus()
      }else if(event.key == 'Tab' && index < this.optionsD.length-1){
        document.getElementById('value'+index+this.unique).nextElementSibling.focus()
      }
      else if(event.key == 'Enter'){
        // console.log('ssssssssss',this.selectoptioncontainer);
        this.SelectOption(this.optionsD[index][this.propValue])
        this.keydownHandled = false
        document.getElementById('hlx-select-container'+this.unique).focus()
      }
      else if(event.key == 'Escape'){
        document.getElementById('hlx-select-container'+this.unique).focus()
        this.selectoptioncontainer = false
        this.keydownHandled = false
      }else if(event.shiftKey == true && event.key === 'Tab' && index > 0){
        console.log(document.getElementById('value'+index).previousElementSibling,'chesk');
        document.getElementById('value'+index+this.unique).previousElementSibling.focus()
      }
    },
    removeValue(event)
    {
         if(event.target.className == 'icon-times-circle-regular')
         {
                    this.customoption.forEach((e)=>{
                     e.checked = false
            })
            let val = ''
            this.$emit("selected-value", val);
            this.$emit("update:value", val);
            this.$emit("update:name", val);

          }
    },
    getFirstAndSecondLetters(input) {
  // Split the input string into an array of words
  const words = input.split(' ');

  // Get the first letter of the first word
  const firstLetter = words[0] ? words[0][0] : '';

  // Get the first letter of the second word
  const secondLetter = words[1] ? words[1][0] : '';

  // Return an object containing the first and second letters
  return firstLetter.toUpperCase() + secondLetter.toUpperCase() 
},   
 inlineclosecontainer()
    {
      this.$emit("dropdownclose","dropdownclose" );
      // var value = JSON.parse(JSON.stringify(this.inlinesearchtagvalue))
      // console.log('before',this.inlinesearchtagvalue);
      // this.inlinesearchtagvalue = {}
      // console.log('after',this.inlinesearchtagvalue);
      // this.inlinesearchtagvalue = value

      if(this.customdropdowncontainer == true)
      {
        // console.log('daa');
        // this.showsearchinput = false
        // if(this.searchImageTag == true){
        //     this.showsearchinput = false
        //   }else{
        //     this.showsearchinput = true
        //   }

          // if(this.slotOptions == true)
          // {
          //   if(this.selectcount != 0)
          //   {
          //   if(this.showsearchinput == true)
          //   {
          //     this.showsearchinput = false
          //   }
          // }
          // else{
          //   this.selectvalue=""
          //   this.animation = false
          // }
          //   console.log(this.selectcount,'count',this.placeholdervalue,this.selectvalue);
          //   // if(this.selectvalue)
          //   // if (this.selectcount > 0) {
          //   //   this.selectvalue = this.placeholdervalue;
          //   //   this.placeholdervalue = "";
          //   //   this.animation = true;
          //   // }
          //   // else{

          //   // }
          // }
        // this.customdropdowncontainer = false
        // this.showsearchinput = false
        this.searchValue = ''
                    if (this.inlinevalueselect === false) {
              this.$emit("custom-error-emit-value", this.selectvalue);
            } else {
              this.$emit("custom-error-emit-value", this.placeholderValue);
            }
            document.getElementById('icon-angle-down-regular' + this.unique).classList.remove("icon-angle-up-regular");
            document.getElementById('icon-angle-down-regular' + this.unique).classList.add("icon-angle-down-regular");
            document.querySelector('#custom-dropdown'+this.unique).classList.remove("selecteddropdown")

            // console.log(document.getElementById('custom-dropdown'+this.unique).children[1]);
            // document
            //   .getElementById("custom-dropdown" + this.unique)
            //   .children[2].classList.add("icon-angle-down-regular");
            // document
            //   .getElementById("custom-dropdown" + this.unique)
            //   .children[2].classList.remove("icon-angle-up-regular");
            // document
            //   .getElementById("custom-dropdown" + this.unique)
            //   .classList.remove("selecteddropdown");
          //     if(this.slotOptions != true)
          //     {
          //   if (this.selectcount > 0) {
          //     this.selectvalue = this.placeholdervalue;
          //     this.placeholdervalue = "";
          //     this.animation = true;
          //   }
          // }
      }
    },
    // inlineSlotclosecontainer()
    // {
    //   this.$emit("dropdownclose","dropdownclose" );
    //   console.log(event.target.className,'className')
    //   if(event.target.className == 'outer-overlay')
    //   {
    //     this.customdropdowncontainer = false
    //   }
    //   // if(this.customdropdowncontainer == true)
    //   // {
    //     // console.log('daa');
    //     // this.showsearchinput = false
    //     if(this.searchImageTag == true){
    //         this.showsearchinput = false
    //       }else{
    //         this.showsearchinput = true
    //       }

    //       if(this.slotOptions == true)
    //       {
    //         if(this.selectcount != 0)
    //         {
    //         if(this.showsearchinput == true)
    //         {
    //           this.showsearchinput = false
    //         }
    //       }
    //       else{
    //         this.selectvalue=""
    //         this.animation = false
    //       }
    //         console.log(this.selectcount,'count',this.placeholdervalue,this.selectvalue);
    //         // if(this.selectvalue)
    //         // if (this.selectcount > 0) {
    //         //   this.selectvalue = this.placeholdervalue;
    //         //   this.placeholdervalue = "";
    //         //   this.animation = true;
    //         // }
    //         // else{

    //         // }
    //       }
    //     // this.customdropdowncontainer = false
    //     // this.showsearchinput = false
    //                 if (this.inlinevalueselect === false) {
    //           this.$emit("custom-error-emit-value", this.selectvalue);
    //         } else {
    //           this.$emit("custom-error-emit-value", this.placeholderValue);
    //         }

    //         // console.log(document.getElementById('custom-dropdown'+this.unique).children[1]);
    //         // document
    //         //   .getElementById("custom-dropdown" + this.unique)
    //         //   .children[2].classList.add("icon-angle-down-regular");
    //         // document
    //         //   .getElementById("custom-dropdown" + this.unique)
    //         //   .children[2].classList.remove("icon-angle-up-regular");
    //         // document
    //         //   .getElementById("custom-dropdown" + this.unique)
    //         //   .classList.remove("selecteddropdown");
    //           document.getElementById('icon-angle-down-regular'+this.unique).classList.add("icon-angle-down-regular")
    //           document.getElementById('icon-angle-down-regular'+this.unique).classList.remove("icon-angle-up-regular")
    //           document.querySelector('.custom-dropdown' + this.unique).classList.remove("selecteddropdown")




    //           if(this.slotOptions != true)
    //           {
    //         if (this.selectcount > 0) {
    //           this.selectvalue = this.placeholdervalue;
    //           this.placeholdervalue = "";
    //           this.animation = true;
    //         }
    //       }
    //   // }
    // },
    footerclick()
    {
                this.selectoptioncontainer = false;
          this.$emit("custom-error-emit-value", this.option_value);
          this.$emit("footer-click");

          document.querySelectorAll(".hlx-select-container").forEach((eve) => {
            eve.classList.remove("change-color");
          });

          document.querySelectorAll(".title-name").forEach((e) => {
            e.classList.remove("dropdownname-color");
          });

          document
            .querySelectorAll(".icon-angle-down-regular")
            .forEach((ev) => {
              ev.classList.remove("add");
            });

    },
    footerclick1()
    {
      this.$nextTick(()=>{
          if(document.getElementById('icon-angle-down-regular' + this.unique))
          {
            document.getElementById('icon-angle-down-regular' + this.unique).classList.remove("icon-angle-up-regular");
            document.getElementById('icon-angle-down-regular' + this.unique).classList.add("icon-angle-down-regular");
            }
            if(document.querySelector('#custom-dropdown'+this.unique))
            {
              document.querySelector('#custom-dropdown'+this.unique).classList.remove("selecteddropdown")
              }
              })
            this.showsearchinput = false
            this.customdropdowncontainer = false; 
            this.$emit("footer-click");
    },
    switchdropdown(data)
    {
      // console.log(data);
                  this.$nextTick(()=>{
        if(document.querySelector('#custom-dropdown' + this.unique))
        {
          if(this.footer != true)
          {
            var element = document.querySelector('#custom-dropdown' + this.unique);
            var position = element.getBoundingClientRect();
            // var x = position.left;
            var y = position.top;
            // console.log(x,y,'pop',window.1 - y);
            var innerheight = window.innerHeight - y
            if(innerheight < 250)
            {
              // console.log();
                    if(data.length == 0)
                      {
                        this.top = -45
                        this.left = -2
                      }
                  else if(data.length == 1)
                      {
                        this.top = -45
                        this.left = -2
                      }
                      else if(data.length == 2)
                      {
                        this.top = -71
                        this.left = -2
                      }
                      else if(data.length == 3)
                      {
                        this.top = -101
                        this.left = -2
                      }
                      else if(data.length == 4)
                      {
                        this.top = -131
                        this.left = -2
                      }
                      else if(data.length == 5)
                      {
                        this.top = -161
                        this.left = -2
                      }
                      else if(data.length == 6)
                      {
                        this.top = -173
                        this.left = -2
                      }
                      else {
                        this.top = -173
                        this.left = -2
                      }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
          else if(this.footer == true)
          {
            var element1 = document.querySelector('#custom-dropdown' + this.unique);
            var position1 = element1.getBoundingClientRect();
            // var x = position.left;
            var y1 = position1.top;
            // console.log(x,y,'pop',window.1 - y);
            var innerheight1 = window.innerHeight - y1
            if(innerheight1 < 250)
            {
              // console.log(data.length,'data');
                    if(data.length == 0)
                      {
                        this.top = -76
                        this.left = -2
                      }
                  else if(data.length == 1)
                      {
                        this.top = -76
                        this.left = -2
                      }
                      else if(data.length == 2)
                      {
                        this.top = -106
                        this.left = -2
                      }
                      else if(data.length == 3)
                      {
                        this.top = -137
                        this.left = -2
                      }
                      else if(data.length == 4)
                      {
                        this.top = -169
                        this.left = -2
                      }
                      else if(data.length == 5)
                      {
                        this.top = -197
                        this.left = -2
                      }
                      else if(data.length == 6)
                      {
                        this.top = -213
                        this.left = -2
                      }
                      else{
                        this.top = -213
                        this.left = -2
                      }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
        }
      })
    },
    check(event) {
      // console.log(event.target.value);
      this.$emit("keyPress", event.target.value); 
      this.$emit("update:native", this.inlinesearchtagvalue['prop-value']);
      this.$emit("update:value", event.target.value);
    },
    inlineOpen(){
      if(document.getElementById('custom-dropdown' + this.unique)){
            // True or false for tag Component
              // if(this.searchImageTag == true){
                this.showsearchinput = true
              // }else{
              //   this.showsearchinput = true
              // }
             this.customdropdowncontainer = true; //Dropdown open or close variable
          // position of the option container
            this.$nextTick(()=>{
          this.containerleft = document.querySelector('#custom-dropdown'+this.unique).getBoundingClientRect().left
          this.containertop = document.querySelector('#custom-dropdown'+this.unique).getBoundingClientRect().top
          this.containerwidth = document.querySelector('#custom-dropdown'+this.unique).getBoundingClientRect().width
          document.querySelector("#custom-input" + this.unique).focus();

          })
            document.getElementById('icon-angle-down-regular' + this.unique).classList.add("icon-angle-up-regular");
            document.getElementById('icon-angle-down-regular' + this.unique).classList.remove("icon-angle-down-regular");
            document.querySelector('#custom-dropdown'+this.unique).classList.add("selecteddropdown")

          // }
          }
        this.$emit("dropdownopen","dropdownopen" );
    },
    inlineClose(){
      this.$emit("dropdownclose","dropdownclose" );
      this.$nextTick(()=>{
        if(document.getElementById('icon-angle-down-regular' + this.unique))
        {
          document.getElementById('icon-angle-down-regular' + this.unique).classList.remove("icon-angle-up-regular");
          document.getElementById('icon-angle-down-regular' + this.unique).classList.add("icon-angle-down-regular");
          }
          if(document.querySelector('#custom-dropdown'+this.unique))
          {
            document.querySelector('#custom-dropdown'+this.unique).classList.remove("selecteddropdown")
            }
            })
          this.showsearchinput = false
            this.customdropdowncontainer = false; //Dropdown open or close variable
            
    },
    Customoptioncontainer(event) {
      if(event.target.className != 'icon-times-circle-regular'){
      event
      var value = 0
      if(this.customdropdowncontainer == true){
        value = 2
        }else{
          value = 1
          }
        console.log('customoptioncontainer',value,this.customdropdowncontainer)
      switch(value){
        case 1:
          this.inlineOpen();
          break;
        case 2:
          this.inlineClose();
          break;
      }
    }
    },
    CustomSlotoptioncontainer(event) {

      if(event.target.className == 'outer-overlay')
      {
        console.log(document.querySelector('.outer-overlay').contains(event.target),'checkkkk');
        this.$emit("dropdownclose","dropdownclose" );
        var value = this.inlinesearchtagvalue
        this.inlinesearchtagvalue = value
        document.querySelector("#custom-input" + this.unique).blur();
        if (event.target.className === "icon-angle-up-regular") {
          if(this.searchImageTag == true){
            this.showsearchinput = false
          }else{
            this.showsearchinput = true
          }          this.customdropdowncontainer = false;
          event.target.classList.add("icon-angle-down-regular");
          event.target.classList.remove("icon-angle-up-regular");
          // console.log(event.target.parentElement,'pppppppppp');
          event.target.parentElement.classList.remove("selecteddropdown");
          if (this.selectcount > 0) {
            this.selectvalue = this.placeholdervalue;
            this.placeholdervalue = "";
          }
        } else if (event.target.className === "custom-dropdown") {
          if(this.searchImageTag == true){
            this.showsearchinput = false
          }else{
            this.showsearchinput = true
          }          this.customdropdowncontainer = false;
          // this.input = false
          // this.labelinput = true
          event.target.children[1].classList.add("icon-angle-down-regular");
          event.target.children[1].classList.remove("icon-angle-up-regular");
          event.target.classList.remove("selecteddropdown");
          if (this.selectcount > 0) {
            this.selectvalue = this.placeholdervalue;
            this.placeholdervalue = "";
          }
        } else if (event.target.className === "custom-dropdown-input") {
          // console.log('aa',event.target);
          if(this.searchImageTag == true){
            this.showsearchinput = false
          }else{
            this.showsearchinput = true
          }          this.customdropdowncontainer = false;
          event.target.nextElementSibling.classList.add(
            "icon-angle-down-regular"
          );
          event.target.nextElementSibling.classList.remove(
            "icon-angle-up-regular"
          );
          event.target.parentElement.classList.remove("selecteddropdown");
          if (this.selectcount > 0) {
            this.selectvalue = this.placeholdervalue;
            this.placeholdervalue = "";
          }
                   
        }
        this.$emit("custom-error-emit-value", this.selectvalue)
      }else if(document.querySelector('.outer-overlay').contains(event.target) == false){
        if(document.getElementById('custom-dropdown' + this.unique)){
          const data =document.getElementById('custom-dropdown' + this.unique).contains(event.target)
          if(data === true){
            console.log("sucesccc");
          if(this.searchImageTag == true){
            this.showsearchinput = true
          }else{
            this.showsearchinput = true
          }
          this.customdropdowncontainer = true;
                    this.$nextTick(()=>{
          this.containerleft = document.querySelector('#custom-dropdown'+this.unique).getBoundingClientRect().left
          this.containertop = document.querySelector('#custom-dropdown'+this.unique).getBoundingClientRect().top
          // console.log(this.containertop,'top');
          this.containerwidth = document.querySelector('#custom-dropdown'+this.unique).getBoundingClientRect().width
          // document.querySelector("#custom-input" + this.unique).focus();

          })
          // event.target.classList.add("icon-angle-up-regular");
          // event.target.classList.remove("icon-angle-down-regular");
          document.querySelector('#custom-input' + this.unique).classList.add("selecteddropdown")
          document.getElementById('icon-angle-down-regular'+this.unique).classList.add("icon-angle-up-regular")
          document.getElementById('icon-angle-down-regular'+this.unique).classList.remove("icon-angle-down-regular")
          // event.target.parentElement.classList.add("selecteddropdown");
          // console.log(this.selectcount,'pop');
          if (this.selectcount > 0) {
            this.placeholdervalue = this.selectvalue;
            this.selectvalue = "";
          }

          if(this.modelValue != ''){
            console.log('succcccccccc');
                     this.placeholdervalue = ''
                   }

        }
          }
        this.$emit("dropdownopen","dropdownopen" );
      }
    },
    customSelectoptionvalue(data, data1) {
      // this.showsearchinput = false
      this.customoption.forEach((e) => {
        if (data === e[this.propValue]) {
          // console.log(data,e.value);
          e.checked = true;
          this.inlinesearchtagvalue = e
          this.selectedValue = e[this.label];
          this.$emit("update:value", this.selectedValue);
          this.$emit("update:native", this.inlinesearchtagvalue[this.propValue]);
          this.$emit("selected-value", e);
          // console.log(this.selectvalue,"asdasdasd")
        } else {
          e.checked = false;
        }
      });
      document.getElementById('icon-angle-down-regular' + this.unique).classList.remove("icon-angle-up-regular");
      document.getElementById('icon-angle-down-regular' + this.unique).classList.add("icon-angle-down-regular");
      document.querySelector('#custom-dropdown'+this.unique).classList.remove("selecteddropdown")
            // if(this.searchImageTag == true){
      this.searchValue = ''
      this.showsearchinput = false
      this.customdropdowncontainer = false;
      this.$emit("custom-change", this.customoption);
      this.inlinevalueselect = true;
      this.$emit("custom-error-emit-value", this.selectvalue);
      this.$emit("inline-reference-value", data1);
      this.$emit("inline-value", data);
      // let elem = document
      //       .getElementById("custom-option-data" + this.unique)
      //       .contains(event.target);
      //       if(elem === true)
      //       {
      //         // console.log(document.getElementById('custom-dropdown' + this.unique).children[1].className);
      //         document.getElementById('custom-dropdown' + this.unique).children[2].classList.add('icon-angle-down-regular')
      //         document.getElementById('custom-dropdown' + this.unique).children[2].classList.remove('icon-angle-up-regular')
      //         document.getElementById('custom-dropdown' + this.unique).classList.remove('selecteddropdown')
      //       }

    },
    customSlotSelectoptionvalue(data, data1) {
      // this.selectvalue = data1
      // console.log(this.selectvalue,data1);
              this.customdropdowncontainer = false;

      console.log(event.target,'loll');
      this.showsearchinput = false
      this.customoption.forEach((e) => {
        if (data === e[this.propValue]) {
          // console.log(data,e.value);
          e.checked = true;
          this.inlinesearchtagvalue = e
          this.selectvalue = e[this.label];
          this.$emit("update:value", this.selectedValue);
          this.$emit("selected-value", e);
          // console.log(this.selectvalue,"asdasdasd")
        } else {
          e.checked = false;
        }
      });
      this.selectcount++;
      this.placeholdervalue = "";
      console.log(this.customdropdowncontainer,'lodsk');
      console.log(document.querySelector('.outer-overlay').contains(event.target),'checkkkk',event.target,document.querySelector('.outer-overlay'));

      // if(this.customdropdowncontainer == true)
      // {
        this.customdropdowncontainer = false;
      // }
      if (this.labelAnimation === true) {
        this.animation = true;
      }
      this.$emit("custom-change", this.customoption);
      this.inlinevalueselect = true;
      this.$emit("custom-error-emit-value", this.selectvalue);
      // console.log(data1,data,'okok');
      this.$emit("inline-reference-value", data1);
      this.$emit("inline-value", data);
      let elem = document
            .getElementById("custom-option-data" + this.unique)
            .contains(event.target);
            if(elem === true)
            {
              // console.log(document.getElementById('custom-dropdown' + this.unique).children[1].className);
              document.querySelector('#custom-input' + this.unique).classList.remove("selecteddropdown")
              document.getElementById('icon-angle-down-regular'+this.unique).classList.add("icon-angle-down-regular")
          document.getElementById('icon-angle-down-regular'+this.unique).classList.remove("icon-angle-up-regular")
            }

    },
    senddata(event) {
      if (event.key === "Enter") {
        console.log('aa',event.target);
        this.customdropdowncontainer = false;
        event.target.nextElementSibling.nextElementSibling.classList.add(
          "icon-angle-down-regular"
        );
        event.target.nextElementSibling.nextElementSibling.classList.remove(
          "icon-angle-up-regular"
        );
        document.getElementById('custom-dropdown'+this.unique).classList.remove('selecteddropdown')
        this.customoption.forEach((e) => {
          e.checked = false;
        });
        const obj = {};
        obj[this.label] = this.selectedValue;
        obj[this.propValue] = this.selectedValue;
        obj.checked = true;
        obj.icon = "icon-database-regular";
        // this.customoption.push(obj);
        this.$emit("enter",obj)
        document.querySelector("#custom-input" + this.unique).blur();
        this.$emit("search-value", this.selectedValue);
      }

    },
    removevalue() {
      // console.log(this.selectoptioncontainer,'pos');
      // this.selectoptioncontainer
      this.dropdownvalue = false;
      this.placeholder = true;
      this.option_value = "";
      this.dropdownname = false;
      this.optionsD.forEach((e) => {
        if (e.checked == true) {
          e.checked = false;
        }
      });
      this.$emit("selected-value", this.option_value);
      this.$emit("update:value", this.option_value);
      this.$emit("update:name", this.option_value);
      this.$emit("custom-change", this.optionsD);
      this.$emit("clearable-value", this.optionsD);
      if (this.selectoptioncontainer == false) {
        this.$emit("custom-error-emit-value", this.option_value);
      }

      // console.log(this.options,'poj');
    },
    SelectOption(value) {
      // console.log(event.target);
      this.selectedvalue = [];
      this.pre_value = "";
      this.placeholder = false;
      this.dropdownvalue = true;
      this.dropdownname = true;
      this.selectoptioncontainer = false;
      this.keydownHandled = false
      if (document.getElementById("title" + this.unique)) {
        document
          .getElementById("title" + this.unique)
          .classList.remove("dropdownname-color");
      }
      if (document.getElementById("title1" + this.unique)) {
        document
          .getElementById("title1" + this.unique)
          .classList.remove("dropdownname-color");
      }
        // console.log(event.target);
        // console.log(event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement);
      if (
        event.target.parentElement.parentElement.parentElement.parentElement.className ===
        "hlx-select-container change-color"
      ) {

        // event.target.parentElement.parentElement.parentElement.parentElement.classList.remove(
        //   "change-color"
        // );
        // event.target.parentElement.parentElement.parentElement.previousElementSibling.children[0].classList.remove(
        //   "add"
        // );
      }
      if (
        event.target.parentElement.parentElement.parentElement.className ===
        "hlx-select-container change-color"
      ) {
        //                     console.log(event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement);

        // event.target.parentElement.parentElement.parentElement.classList.remove(
        //   "change-color"
        // );
        // event.target.parentElement.parentElement.previousElementSibling.children[0].classList.remove(
        //   "add"
        // );
      }
      if (this.single_select == true || this.textEditor == true) {
        this.optionsD.map((e) => {
          if (e[this.propValue] === value) {
            e.checked = true;
            this.option_value = e[this.label];
            this.icon_value = e.icon;
          } else {
            e.checked = false;
          }
        });
      }
      if (this.icon === true) {
        this.optionsD.map((e) => {
          if (e[this.propValue] === value) {
            e.checked = true;
            this.option_value = e[this.label];
            this.icon_country_flag = e.flag;
          } else {
            e.checked = false;
          }
        });
      }
      let b = "";
      let c = "";
      this.optionsD.forEach((e) => {
        if (e.checked === true) {
          b = e[this.propValue];
          c = e[this.label];
          this.$emit("selected-value", e);
        }
      });
      this.$emit("custom-error-emit-value", this.option_value);
      this.$emit("update:value", b);
      this.$emit("update:name", c);
      this.$emit("custom-change", this.optionsD);
    },
    closeselect() {
      document.querySelector(".hlx-select-option-container").style.display = "";
    },
    OptionContainer(event) {
      console.log('ddd',event.key)
      if(this.keydownHandled == false){
      if (this.selectoptioncontainer === false) {
        if (event.target.className === "hlx-select-container") {
          if (
            event.target.lastElementChild.previousElementSibling.children[0]
              .className === "icon-angle-down-regular"
          ) {
            // event.target.lastElementChild.previousElementSibling.children[0].classList.add(
            //   "add"
            // );
          }
          this.selectoptioncontainer = true;
          // this.$nextTick(()=>{
          //   document.getElementById('value'+0+this.unique).focus()
          // })
          if (this.selectoptioncontainer == true && this.option_value != "") {
            if (document.getElementById("title1" + this.unique)) {
              document
                .getElementById("title1" + this.unique)
                .classList.add("dropdownname-color");
            }
            if (document.getElementById("title" + this.unique)) {
              document
                .getElementById("title" + this.unique)
                .classList.add("dropdownname-color");
            }
          }
          // event.target.classList.add("change-color");
          this.containerheight = document.querySelector('#hlx-select-whole-option-container'+this.unique).getBoundingClientRect().height
          this.containerleft = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left
          this.containertop = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top
          this.containerwidth = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width,'width');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top,'top');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left,'left');
          // console.log(document.querySelector('.hlx-select-whole-option-container').offsetHeight,'height');
        } else if (
          event.target.parentElement.className === "hlx-select-container"
        ) {
          this.selectoptioncontainer = true;
          // this.$nextTick(()=>{
          //   document.getElementById('value'+0+this.unique).focus()
          // })
          this.containerheight = document.querySelector('#hlx-select-whole-option-container'+this.unique).getBoundingClientRect().height
          this.containerleft = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left
          this.containertop = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top
          this.containerwidth = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width,'width');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top,'top');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left,'left');
          if (this.selectoptioncontainer == true && this.option_value != "") {
            if (document.getElementById("title1" + this.unique)) {
              document
                .getElementById("title1" + this.unique)
                .classList.add("dropdownname-color");
            }
            if (document.getElementById("title" + this.unique)) {
              document
                .getElementById("title" + this.unique)
                .classList.add("dropdownname-color");
            }
          }
          // event.target.parentElement.classList.add("change-color");
          if (
            event.target.parentElement.lastElementChild.previousElementSibling
              .children[0].className === "icon-angle-down-regular"
          ) {
            // event.target.parentElement.lastElementChild.previousElementSibling.children[0].classList.add(
            //   "add"
            // );
          }
        } else if (
          event.target.parentElement.parentElement.className ===
          "hlx-select-container"
        ) {
          this.selectoptioncontainer = true;
          // this.$nextTick(()=>{
          //   document.getElementById('value'+0+this.unique).focus()
          // })
          this.containerheight = document.querySelector('#hlx-select-whole-option-container'+this.unique).getBoundingClientRect().height
          this.containerleft = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left
          this.containertop = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top
          this.containerwidth = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width,'width');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top,'top');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left,'left');
          // event.target.parentElement.parentElement.classList.add(
          //   "change-color"
          // );
          // if (event.target.className === "icon-angle-down-regular") {
          //   event.target.classList.add("add");
          // }
          if (this.selectoptioncontainer == true && this.option_value != "") {
            if (document.getElementById("title1" + this.unique)) {
              document
                .getElementById("title1" + this.unique)
                .classList.add("dropdownname-color");
            }
            if (document.getElementById("title" + this.unique)) {
              document
                .getElementById("title" + this.unique)
                .classList.add("dropdownname-color");
            }
          }
        }
      } else if (this.selectoptioncontainer === true) {
        if(event.target.className !== 'hlx-fixed-footer'+this.unique)
        {
          // console.log(event.target.className,'ppp','hlx-fixed-footer'+this.unique);
        if (this.option_value === "") {
          this.placeholder = true;
        }
        // console.log(event.target);
        this.selectoptioncontainer = false;
        this.keydownHandled = false
        if (document.getElementById("title1" + this.unique)) {
          document
            .getElementById("title1" + this.unique)
            .classList.remove("dropdownname-color");
        }
        if (document.getElementById("title" + this.unique)) {
          document
            .getElementById("title" + this.unique)
            .classList.remove("dropdownname-color");
        }
        if (event.target.className === "hlx-select-container change-color") {
          // event.target.classList.remove("change-color");
          // console.log(event.target, 'lol')
          // event.target.lastElementChild.previousElementSibling.children[0].classList.remove(
          //   "add"
          // );
        } else if (
          event.target.parentElement.className ===
          "hlx-select-container change-color"
        ) {
          // event.target.parentElement.classList.remove("change-color");
          // event.target.parentElement.lastElementChild.previousElementSibling.children[0].classList.remove(
          //   "add"
          // );
        } else if (
          event.target.parentElement.parentElement.className ===
          "hlx-select-container change-color"
        ) {
          // event.target.parentElement.parentElement.classList.remove(
          //   "change-color"
          // );
          // event.target.classList.remove("add");
        }
      }
       this.$emit("custom-error-emit-value", this.option_value);
      }
    }
    },
    OptionKeyContainer(event) {
      console.log(this.tabIndexValue,'val');
      
      // this.focusInValue(this.tabIndexValue,event,'lls')
      // console.log(event.key,'lol',event);
      const pressedKey = event.key.toLowerCase();
      let count = 0
      if (pressedKey.length === 1 && pressedKey.match(/[a-z]/i)) {
         this.optionsD.forEach((item,index)=>{
          console.log(item[this.label].toLowerCase().startsWith(pressedKey),'tessstes');
          item[this.label].toLowerCase().startsWith(pressedKey)
          // console.log();
          if(item[this.label].toLowerCase().startsWith(pressedKey) == true && count == 0){
            document.getElementById('value'+index+this.unique).focus()
            count = 1
          }
         })
      }
      if (this.selectoptioncontainer === false) {
        if(event.code == 'Space'){
          this.keydownHandled = true
        if (event.target.className === "hlx-select-container") {
          if (
            event.target.lastElementChild.previousElementSibling.children[0]
              .className === "icon-angle-down-regular"
          ) {
            // event.target.lastElementChild.previousElementSibling.children[0].classList.add(
            //   "add"
            // );
          }
          this.selectoptioncontainer = true;
          // this.$nextTick(()=>{
            // document.getElementById('value'+0+this.unique).focus()
          // })
          // console.log(document.getElementById('value'+0),'llokd');
          if (this.selectoptioncontainer == true && this.option_value != "") {
            if (document.getElementById("title1" + this.unique)) {
              document
                .getElementById("title1" + this.unique)
                .classList.add("dropdownname-color");
            }
            if (document.getElementById("title" + this.unique)) {
              document
                .getElementById("title" + this.unique)
                .classList.add("dropdownname-color");
            }
          }
          // event.target.classList.add("change-color");
          this.containerheight = document.querySelector('#hlx-select-whole-option-container'+this.unique).getBoundingClientRect().height
          this.containerleft = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left
          this.containertop = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top
          this.containerwidth = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width,'width');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top,'top');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left,'left');
          // console.log(document.querySelector('.hlx-select-whole-option-container').offsetHeight,'height');
        } else if (
          event.target.parentElement.className === "hlx-select-container"
        ) {
          this.selectoptioncontainer = true;
          // this.$nextTick(()=>{
          //   document.getElementById('value'+0+this.unique).focus()
          // })
          // console.log(document.getElementById('value'+0),'llokd');
          this.containerheight = document.querySelector('#hlx-select-whole-option-container'+this.unique).getBoundingClientRect().height
          this.containerleft = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left
          this.containertop = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top
          this.containerwidth = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width,'width');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top,'top');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left,'left');
          if (this.selectoptioncontainer == true && this.option_value != "") {
            if (document.getElementById("title1" + this.unique)) {
              document
                .getElementById("title1" + this.unique)
                .classList.add("dropdownname-color");
            }
            if (document.getElementById("title" + this.unique)) {
              document
                .getElementById("title" + this.unique)
                .classList.add("dropdownname-color");
            }
          }
          // event.target.parentElement.classList.add("change-color");
          if (
            event.target.parentElement.lastElementChild.previousElementSibling
              .children[0].className === "icon-angle-down-regular"
          ) {
            // event.target.parentElement.lastElementChild.previousElementSibling.children[0].classList.add(
            //   "add"
            // );
          }
        } else if (
          event.target.parentElement.parentElement.className ===
          "hlx-select-container"
        ) {
          this.selectoptioncontainer = true;
          // this.$nextTick(()=>{
          //   document.getElementById('value'+0+this.unique).focus()
          // })
          // console.log(document.getElementById('value'+0),'llokd');
          this.containerheight = document.querySelector('#hlx-select-whole-option-container'+this.unique).getBoundingClientRect().height
          this.containerleft = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left
          this.containertop = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top
          this.containerwidth = document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().width,'width');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().top,'top');
          // console.log(document.querySelector('#hlx-select-container'+this.unique).getBoundingClientRect().left,'left');
          // event.target.parentElement.parentElement.classList.add(
          //   "change-color"
          // );
          // if (event.target.className === "icon-angle-down-regular") {
          //   event.target.classList.add("add");
          // }
          if (this.selectoptioncontainer == true && this.option_value != "") {
            if (document.getElementById("title1" + this.unique)) {
              document
                .getElementById("title1" + this.unique)
                .classList.add("dropdownname-color");
            }
            if (document.getElementById("title" + this.unique)) {
              document
                .getElementById("title" + this.unique)
                .classList.add("dropdownname-color");
            }
          }
        }
      }
      } 
      else if (this.selectoptioncontainer === true && event.key == 'Escape' ) {
        if(event.target.className !== 'hlx-fixed-footer'+this.unique)
        {
          // console.log(event.target.className,'ppp','hlx-fixed-footer'+this.unique);
        if (this.option_value === "") {
          this.placeholder = true;
        }
        // console.log(event.target);
        this.selectoptioncontainer = false;
        this.keydownHandled = false
        if (document.getElementById("title1" + this.unique)) {
          document
            .getElementById("title1" + this.unique)
            .classList.remove("dropdownname-color");
        }
        if (document.getElementById("title" + this.unique)) {
          document
            .getElementById("title" + this.unique)
            .classList.remove("dropdownname-color");
        }
        if (event.target.className === "hlx-select-container change-color") {
          // event.target.classList.remove("change-color");
          // console.log(event.target, 'lol')
          // event.target.lastElementChild.previousElementSibling.children[0].classList.remove(
          //   "add"
          // );
        } else if (
          event.target.parentElement.className ===
          "hlx-select-container change-color"
        ) {
          // event.target.parentElement.classList.remove("change-color");
          // event.target.parentElement.lastElementChild.previousElementSibling.children[0].classList.remove(
          //   "add"
          // );
        } else if (
          event.target.parentElement.parentElement.className ===
          "hlx-select-container change-color"
        ) {
          // event.target.parentElement.parentElement.classList.remove(
          //   "change-color"
          // );
          // event.target.classList.remove("add");
        }
      }

      // if(event.shiftKey == true && event.key === 'Tab' && this.currentIndex > 0 && this.selectoptioncontainer == true){
      //   console.log('def',document.getElementById('value'+this.currentIndex).previousElementSibling.focus())
      //   this.$nextTick(()=>{
      //     document.getElementById('value'+this.currentIndex).previousElementSibling.focus()
      //   })
      // }
      

       this.$emit("custom-error-emit-value", this.option_value);
      }
      else if(this.selectoptioncontainer === true){
      if(event.code == 'ArrowDown'){
        console.log('testt')
        this.$nextTick(()=>{
          document.getElementById('value'+this.tabIndexValue+this.unique).focus()
        })
      }
      }
      // if(this.selectoptioncontainer == true)
      // {
      //   document.getElementById('value'+0+this.unique).focus()
      // }
    },
    OptionContainer1(event) {
      if (this.iconselect === false) {
        if (event.target.className === "hlx-select-container") {
          this.nplace = true;
          this.place = false;
          this.noplace = true;
          this.iconselect = true;
          event.target.classList.add("change-color");
          event.target.nextElementSibling.nextElementSibling.children[0].classList.add(
            "add"
          );
        } else if (event.target.className === "hlx-select-input-placeholder") {
          this.nplace = true;
          this.place = false;
          this.noplace = true;
          this.iconselect = true;
          event.target.parentElement.classList.add("change-color");
          event.target.nextElementSibling.children[0].classList.add("add");
        } else if (event.target.className === "icon-angle-down-regular") {
          this.nplace = true;
          this.place = false;
          this.noplace = true;
          this.iconselect = true;
          event.target.parentElement.parentElement.classList.add(
            "change-color"
          );
          event.target.classList.add("add");
        } else if (event.target.className === "hlx-select-icon") {
          this.nplace = true;
          this.place = false;
          this.noplace = true;
          this.iconselect = true;
          event.target.parentElement.classList.add("change-color");
          event.target.children[0].classList.add("add");
        }
      } else if (this.iconselect === true) {
        if (event.target.className === "hlx-select-container change-color") {
          this.nplace = false;
          this.place = true;
          this.noplace = false;
          this.iconselect = false;
          event.target.classList.remove("change-color");
          event.target.children[2].children[0].classList.remove("add");
        } else if (event.target.className === "hlx-select-input") {
          this.nplace = false;
          this.place = true;
          this.noplace = false;
          this.iconselect = false;
          event.target.parentElement.classList.remove("change-color");
          // console.log(event.target)
          event.target.nextElementSibling.children[0].classList.remove("add");
        } else if (event.target.className === "icon-angle-down-regular add") {
          this.nplace = false;
          this.place = true;
          this.noplace = false;
          this.iconselect = false;
          event.target.parentElement.parentElement.classList.remove(
            "change-color"
          );
          event.target.classList.remove("add");
        } else if (event.target.className === "hlx-select-icon") {
          this.nplace = false;
          this.place = true;
          this.noplace = false;
          this.iconselect = false;
          event.target.parentElement.classList.remove("change-color");
          event.target.children[0].classList.remove("add");
        }
      }
    },
    color() {
      if (this.isMultiSelect === true) {
        this.isMultiSelect = false;
        document
          .getElementById("multiselect" + this.unique)
          .classList.remove("add-style");
      } else if (this.isMultiSelect === false) {
        this.isMultiSelect = true;
        document
          .getElementById("multiselect" + this.unique)
          .classList.add("add-style");
      }
    },
    MultiselectOptioncontainer() {
      this.multi_select_value = this.multiselectarray
        .filter((data) => {
          if (data.checked == true) {
            this.multiselecttitle = true;
            this.multiselectplaceholder = false;
            this.multiselectdropdownvalue = true;
            return true;
          }
        })
        .map((data) => {
          // console.log(data);
          return data[this.label];
        });
      // console.log(this.multi_select_value);
      if (this.multi_select_value.length != 0) {
        for (const i in this.multi_select_value) {
          i;
          if (this.multi_select_value.length > 3) {
            this.count = true;
            this.count_select_value = this.multi_select_value.length - 3;
          } else {
            this.count = false;
          }
        }
      } else {
        this.multiselectplaceholder = true;
        this.multiselectdropdownvalue = false;
        this.multiselecttitle = false;
      }

      // console.log(this.multiSelectOptions,'vfjdx');
    },
    removemultiselectvalue(removedItem) {
      this.multi_select_value = this.multi_select_value.filter(
        (data) => data != removedItem
      );
      this.multiselectarray.filter((value) => {
        if (value[this.label] == removedItem) {
          return (value.checked = false);
        }
      });
      this.count_select_value = this.multi_select_value.length - 3;
      // console.log(this.multi_select_value,'loloool');
      if (this.multi_select_value.length == 0) {
        this.multiselectplaceholder = true;
        this.multiselectdropdownvalue = false;
        this.multiselecttitle = false;
      }
      document.querySelectorAll(".check-container").forEach((e) => {
        if (removedItem === e.innerText) {
          e.click();
        }
      });
    },
    MultiSelectOption(event) {
      if (
        event.target.parentElement.className ===
        "hlx-multi-select-option-value-container select-value"
      ) {
        event.target.parentElement.classList.remove("select-value");
      } else if (
        event.target.className ===
        "hlx-multi-select-option-value-container select-value"
      ) {
        event.target.classList.remove("select-value");
      }
      if (event.target.className === "hlx-multi-select-option-value") {
        if (this.multi_select_value.length < "2") {
          this.option_value = event.target.innerText;
          this.multi_select_value.push(this.option_value);
          this.multi_select_count_value.push(this.option_value);
        } else if (this.multi_select_value.length >= "2") {
          this.option_value = event.target.innerText;
          this.count_select_value.push(this.option_value);
          this.multi_select_count_value.push(this.option_value);
        }
        event.target.parentElement.classList.add("select-value");
      } else if (
        event.target.className === "hlx-multi-select-option-value-container"
      ) {
        if (this.multi_select_value.length < "2") {
          this.option_value = event.target.innerText;
          this.multi_select_value.push(this.option_value);
          this.multi_select_count_value.push(this.option_value);
        } else if (this.multi_select_value.length >= "2") {
          this.option_value = event.target.innerText;
          this.count_select_value.push(this.option_value);
          this.multi_select_count_value.push(this.option_value);
        }
        event.target.classList.add("select-value");
      }
    },
    search(by) {
      this.search_by = by == "" ? null : by;
    },
    emitfiltervalue() {
      this.isMultiSelect = false;
      this.all = false;

      this.$emit("custom-change", this.checkedData);
    },
    emitclearvalue() {
      this.checkedData.forEach((e) => {
        e.checked = false;
      });
      this.$emit("custom-change", this.checkedData);
    },
    clearIcon() {
      if (this.option_value != "") {
        document.getElementById("arrowid" + this.unique).style.display = "none";
        document.getElementById("crossid" + this.unique).style.display =
          "block";
      }
    },
    arrowIcon() {
      // console.log(event);
      if (
        document.getElementById("crossid" + this.unique).style.display ==
        "block"
      ) {
        document.getElementById("arrowid" + this.unique).style.display =
          "block";
        document.getElementById("crossid" + this.unique).style.display = "none";
      }
    },
    displayCloseIcon() {
      // console.log(event,);
      if(this.clearable == true && this.selectedValue != ''){
        document.getElementById('icon-angle-down-regular'+this.unique).classList.remove('icon-angle-down-regular')
        document.getElementById('icon-angle-down-regular'+this.unique).classList.add('icon-times-circle-regular')
      }
      //  event.target.classList.remove('icon-angle-down-regular')
      //  event.target.classList.add('icon-times-circle-regular')
    },
    displayarrowIcon()
    {
      if(this.clearable == true && this.selectedValue != ''){
      document.getElementById('icon-angle-down-regular'+this.unique).classList.add('icon-angle-down-regular')
      document.getElementById('icon-angle-down-regular'+this.unique).classList.remove('icon-times-circle-regular')
      }
    }
  },
};
</script>

<style lang="scss" scoped>
i {
  font-size: 12px;
}

// button:focus-within{
// border:1px solid red;
// }
</style>
