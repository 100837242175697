<template>
  <div v-show="showColorPicker" class="colorpicker_extenstion" id="colorpicker">
    <Sketch v-model="colors" @input="updateValue" />
    <div class="color_picker_name_color">
      <label><b>Color name</b></label
      ><br />
      <div style="margin-top: 10px; width: 277px">
        <hlx-input
          :label-animation="false"
          type="text"
          :clearable="true"
          v-model:value="colorName"
          :pre-val="colorName"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Sketch } from "@ckpack/vue-color";
import HlxInput from "../components/InputComponent.vue";

export default {
  emits: ["close", "updated-value"],
  props: {
    showColorPicker: {
      type: Boolean,
      default: false,
    },
    editmode: {
      type: Boolean,
      default: false,
    },
    editPayload: {
      type: Object,
      default: () => {
        return {};
      },
    },
    colorNameprop: {
      type: String,
      default: "",
    },
  },
  components: {
    Sketch,
    HlxInput,
  },
  data() {
    return {
      colors: "#fafafa",
      colorName: "",
      selectColor: {},
    };
  },
  watch: {
    colorNameprop: {
      handler(val) {
        console.log(val);
        this.colorName = val;
      },
    },
    editPayload: {
      handler(val) {
        console.log(val);

        this.colorName = val.name;
        this.colors = val.hex;

        console.log(this.colorName, this.colors);
      },
    },
    showColorPicker: {
      handler(val) {
        console.log(val);
        if (val === true) {
          this.closefunction(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
     const  el=document.getElementsByClassName('vc-input__input')
    //  el[1].classList.add('rvalue');
    //  el[2].classList.add('gvalue');
    //  el[3].classList.add('bvalue');
    //  el[0].classList.add('hexValue');
     el[0].setAttribute('maxLength',7);
     el[1].setAttribute('maxLength',3);
     el[2].setAttribute('maxLength',3);
     el[3].setAttribute('maxLength',3);
    
   
    console.log(el,"fds");
    this.$watch(
      "colors",
      (val) => {
        console.log(val);
        this.selectColor = val;
        console.log(this.selectColor);
        console.log({
          name: this.colorName,
          hex: this.selectColor.hex,
          rgb: {
            r: this.selectColor?.rgba?.r,
            g: this.selectColor?.rgba?.g,
            b: this.selectColor?.rgba?.b,
          },
        });
      },
      { immediate: true, deep: true }
    );
    console.log(this.$refs.sketch);
  },
  methods: {
    updateValue(val) {
      console.log(val);
    },
    closefunction(val) {
      console.log();
      let count = 0;
      let closefun = val;
      document.addEventListener("click", (e) => {
        // //console.log(e,"element clode ");
        if (closefun == true) {
          const elem = document
            .getElementById("colorpicker")
            .contains(e.target);
          // console.log(elem);

          count += 1;
          if (count > 1 && elem === false) {
           
            if (this.colorName === "") {
              this.$emit("updated-value", elem, "close");
            } else if (this.editmode === true) {

              this.$emit(
                "updated-value",
                elem,
                "edit",
                {
                  name: this.colorName,
                  hex: this.selectColor.hex,
                  rgb: {
                    r: this.selectColor?.rgba?.r,
                    g: this.selectColor?.rgba?.g,
                    b: this.selectColor?.rgba?.b,
                  },
                },
                this.selectColor.hex,
                this.editPayload._id
              );
            } else {
              this.$emit(
                "updated-value",
                elem,
                "updated",
                
                {
                  name: this.colorName,
                  hex: this.selectColor.hex,
                  rgb: {
                    r: this.selectColor?.rgba?.r,
                    g: this.selectColor?.rgba?.g,
                    b: this.selectColor?.rgba?.b,
                  },
                },
                this.selectColor.hex
              );
            
            count = 0;
            closefun = false;
          }
          console.log(count);
        }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.colorpicker_extenstion {
  z-index: 100000;
  margin-top: 4%;
  max-width: 320px;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  /* padding: 20px; */
  border-radius: 20px;
  padding: 10px 10px 17px 10px;
  position: absolute;
  background: white;
  position: relative;
  position: relative;
  .color_picker_name_color {
    position: absolute;
    top: 262px;
    left: 21px;
  }
  .vc-input__label {
    color: #222;
    display: block;
    font-size: 16px;
    font-weight: 600;
    /* font-size: 488px; */
    position: absolute;
    top: -34px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-top: 3px;
    text-align: left;
    text-transform: capitalize;
  }
  .vc-input__input {
    border: solid 1px #d8d8d8;
    box-shadow: none;
    font-size: 10px;
    padding: 4px 0 3px 10%;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 36px;
    color: #565553;
    background: transparent;
    border-radius: 5px;
    
    transition: all 0.3s ease;
    font-size: 14px;
    padding: 10px 12px 10px 12px;
    line-height: 18.15px;
    letter-spacing: 0.5%;
  }
 .vc-input__input:hover {
    /* Apply border when hovered over */
    border: 1px solid #54bd95;
}
 .vc-input__input:focus {
    /* Apply border when hovered over */
    border: 1px solid #54bd95;
}
  .vc-sketch {
    .vc-sketch-sliders {
      flex: 1;
      padding: 20px 0;
    }
    background: none;
    border-radius: none;
    box-shadow: none;
    box-sizing: initial;
    padding: 10px 10px 0;
    position: relative;
    width: 280px;
    .vc-sketch-field {
      display: flex;
      padding-top: 110px;
    }
    .vc-saturation-circle {
      width: 9px;
      height: 9px;
    }

    .vc-sketch-alpha-wrap {
      display: none;
    }
    .vc-sketch-presets {
      display: none;
    }
    .vc-sketch-color-wrap {
      display: none;
    }
    .vc-hue {
      border-radius: 6px;
    }
    .vc-sketch-field--single:last-of-type {
      display: none;
    }
    .vc-hue-picker {
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
      cursor: pointer;
      height: 10px;
      margin-top: 0px;
      transform: translateX(-2px);
      width: 10px;
    }
  }
}
</style>
