<template>
   
    <div v-show="show" class="options-container">
      <div
        v-for="(i, index) in options"
        :id="'option' + index"
        :key="index"
        class="option"
        @click="select(index, i.label);$emit('chosen',i.label)"
      >
        <i
          v-if="i.icon != undefined && i.icon != ''"
          :class="i.icon"
          style="margin-right: 8px"
        ></i
        ><span>{{ i[displaydata] || i.label ||null }}</span>
      </div>
    </div>
  
  </template>
  
  <script>
  
  export default {
    name: "HlxTreeContextMenu",
   
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      displaydata: {
        type: String,
        default: "label"
      },
      options: {
        type: Array,
        default: () => {
          return [];
        },
      },
      data: {
        type: String,
        default: "",
      },
     
    },
    emits: ["chosen"],
    data() {
      return {
        custom_options: [],
      };
    },
  
    watch: {
      // options: {
      //   handler (val) {
      //     if (val) {
      //       this.custom_options = JSON.parse(JSON.stringify([...val]))
      //     }
      //   },
      //   immediate:true,
      //   deep: true
      // }
    },
    mounted() {
      var indexx = this.options.findIndex((x) => x.checked == true);
      if (indexx != -1 && indexx != undefined) {
        this.select(indexx, this.options[indexx]["label"]);
      }
    },
  
    methods: {
      select(index, name) {
        console.log("🚀 ~ file: ContextMenuComponent.vue:98 ~ select ~ index:", index)
        document.querySelectorAll(".option").forEach((d) => {
          if (d.classList.contains("selected-context-menu")) {
            d.classList.remove("selected-context-menu");
          }
        });
        // document.querySelector(`#option${index}`).classList.add('selected-context-menu')
        // if (this.data != null && this.data != "") {
        //   this.$emit("chosen", name, this.data);
        // } else {
        //   this.$emit("chosen", name);
        // }
      },
    },
  };
  </script>
  