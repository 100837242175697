<template>
  <div class="color-palette-container">
    <div class="color-palette-icon">
      <i   style="    font-size: 17px;" class="icon-calculator-simple-regular"></i>
     
      
    </div>
    <button
      :id="'color-palette-open-icon' + unique"
      aria-dis
      class="color-palette-open-icon"
      @click="colorpalette"
    >
      <i class="icon-angle-down-regular"></i>
    </button>
    <hlx-overlay
      v-if="colorcontainer"
      :show="colorcontainer"
      :top="containertop"
      :left="containerleft"
      :width="100"
      :conditionvalue="150"
      :conditionup="55"
      @close-overlay="onClose"
    >
      <template #overlay>
        <button :id="'color-container' + unique"
      style="display: flex; flex-direction: row; flex-wrap: wrap;height: auto;width: 326px;"
      class="color-container"  >
          <div
            v-for="(code, index) in characterCodes"
            :key="index"
            class="cell"
            style="width: 30px; height: 28px"
            @click="emitCharacter(code)"
            v-html="code"
          ></div>
        </button>
      </template>
    </hlx-overlay>
  </div>
</template>

<script>
import hlxOverlay from "../components/OverlayComponent.vue";

// import { defineComponent } from '@vue/composition-api'

export default {
  name: "HlxSpecialCharacters",
  components: {
    hlxOverlay,
  },
  props: {
    highlightColor: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["customChange", 'customCharacter'],
  data() {
    return {
      characterCodes:[
  "&#43;",  // Plus sign (+)
  "&#45;",  // Minus sign (-)
  "&#42;",  // Multiplication sign (×)
  "&#47;",  // Division sign (÷)
  "&#61;",  // Equal sign (=)
  "&#8800;", // Not equal (≠)
  "&#60;",  // Less than (<)
  "&#62;",  // Greater than (>)
  "&#8804;", // Less than or equal to (≤)
  "&#8805;", // Greater than or equal to (≥)
  "&#8722;", // Minus sign (−)
  "&#183;",  // Middle dot (·)
  "&#8254;", // Star symbol (∗)
  "&#8290;", // Tilde operator (⁓)
  "&#8776;", // Approximately equal (≈)
  "&#8801;", // Identical to (≡)
  "&#8734;", // Infinity (∞)
  "&#8712;", // Element of (∈)
  "&#8713;", // Not element of (∉)
  "&#8834;", // Subset (⊆)
  "&#8835;", // Superset (⊇)
  "&#8747;", // Integral (∫)
  "&#8721;", // Summation (∑)
  "&#8706;", // Partial derivative (∂)
  "&#8707;", // There exists (∃)
  "&#8658;", // Implies (⇒)
  "&#8656;", // Left arrow (←)
  "&#8771;", // Identically equal (≡)
  "&#8364;",  // Euro symbol (€)
  "&#956;",  // Greek letter mu (μ)
  "&#945;",  // Greek letter alpha (α)
  "&#946;",  // Greek letter beta (β)
  "&#8895;",  // ⋀ (Logical AND)
  "&#8896;",  // ⋁ (Logical OR)
  "&#172;",   // ¬ (Logical NOT)
  "&#8704;",  // ∀ (For All)
  "&#10003;", // ✓ (Checkmark for Success)
  "&#9888;",  // ⚠ (Warning/Error)
  "&#128308;", // 🔴 (Offline/Disconnected)
  "&#128994;", // 🟢 (Online/Connected)
  "&#128280;", // 🔘 (Status Indicator)
  "&#9472;",  // ─ (Line for Network Diagram)
  "&#9474;",  // │ (Vertical Line for Diagram)
  "&#9484;",  // ┌ (Top-Left Corner for Boxes)
  "&#9488;",  // ┐ (Top-Right Corner for Boxes)
  "&#9492;",  // └ (Bottom-Left Corner)
  "&#9496;",  // ┘ (Bottom-Right Corner)
  "&#9500;",  // ├ (T-Connection Left)
  "&#9508;",  // ┤ (T-Connection Right)
  "&#9532;",  // ┼ (Cross-Connection)
  "&#9650;",  // ▲ (Up Triangle for Routing)
  "&#9660;",  // ▼ (Down Triangle)
  "&#128274;", // 🔒 (SSL/HTTPS/Locked)
  "&#128275;", // 🔓 (HTTP/Unlocked)
  "&#8470;",   // № (Number for IPv4/IPv6 Labels)
  "&#10068;",  // ❔ (Help/Unknown State)
  "&#128246;", // 📶 (WiFi Signal)
  "&#128225;", // 📡 (Satellite Dish)
  "&#128187;", // 💻 (Laptop)
  "&#128421;", // 🔍 (Search/Lookup)
  "&#127760;", // 🌐 (Global/Internet)
  "&#128279;", // 🔗 (Hyperlink/Connection)
  "&#9881;",   // ⚙ (Settings for Configuration)
  "&#128295;", // 🔧 (Wrench for Network Tools)
  "&#10070;",  // ❖ (Diamond - Often a marker for premium content)
  "&#128210;", // 📒 (Notebook - Documentation or Terms)
  "&#128214;", // 📖 (Open Book - Legal Documentation)
  "&#9776;",   // ☰ (Hamburger Menu)
  "&#10006;",  // ✖ (Close / Cancel Icon)
  "&#8250;",   // › (Next/Forward Arrow)
  "&#8249;",   // ‹ (Back Arrow)
  "&#8594;",   // → (Right Arrow)
  "&#8592;",   // ← (Left Arrow)
  "&#8601;",   // ↙ (Down-Left Arrow)
  "&#8600;",   // ↘ (Down-Right Arrow)
  "&#8679;",   // ⇑ (Upload / Up Arrow)
  "&#8681;",   // ⇓ (Download / Down Arrow)
  "&#128712;", // 🛒 (Shopping Cart)
  "&#128100;", // 👤 (User Icon)
  "&#128077;", // 👍 (Thumbs Up)
  "&#128078;", // 👎 (Thumbs Down)
  "&#128276;", // 🔔 (Notification / Bell Icon)
  "&#128204;", // 📌 (Pin Icon)
  "&#128206;", // 📎 (Attachment Icon)
  "&#128190;", // 💾 (Save / Disk Icon)
  "&#128394;", // ✎ (Edit / Pencil Icon)
  "&#128465;", // 🗑 (Trash / Delete Icon)
  "&#9993;",   // ✉ (Email Icon)
  "&#128231;", // 📧 (Envelope for Email)
  "&#128172;", // 💬 (Chat Bubble)
  "&#128161;", // 💡 (Idea Icon)
  "&#9872;",   // ⚐ (Flag)
  "&#35;",     // # (Hash for Subnet or Tagging)
  "&#64;",     // @ (Email or Network Identity)
  "&#46;",     // . (Dot for DNS/URL Segmentation)
  "&#58;",     // : (Colon for Ports)
  "&#169;",    // © (Copyright Symbol)
  "&#174;",    // ® (Registered Trademark)
  "&#8482;",   // ™ (Trademark Symbol)
  "&#8471;",   // ℗ (Sound Recording Copyright)
  "&#9874;",   // ⚔ (Crossed Swords - Often used for protection)
  "&#8251;",   // ※ (Reference Mark)
  "&#128276;", // 🔔 (Notification / Bell Icon)
  "&#128277;", // 🔹 (Blue Diamond)
  "&#10068;",  // ❔ (Help/Unknown State)
  "&#8888;",   // ⊕ (Circled Plus)
  "&#9594;"    // ⌂ (House)
]




,
      containerleft: 0,
      containertop: 0,
      unique: Math.floor(Math.random() * 1000 + 1),
      colorcontainer: false,
      fill_color: true,
      colorvalue: "#000000",
      recent: false,
      recentvalue: "",
      recentcolor: [],
      colordata: [
        { "#ffffff": ["#f2f2f2", "#d9d9d9", "#bfbfbf", "#a6a6a6", "#808080"] },
        { "#000000": ["#808080", "#595959", "#404040", "#262626", "#0d0d0d"] },
        { "#e7e6e6": ["#d0cece", "#aeaaaa", "#757171", "#3a3838", "#161616"] },
        { "#44546a": ["#d6dce4", "#acb9ca", "#8497b0", "#333f4f", "#222b35"] },
        { "#4472c4": ["#d9e1f2", "#b4c6e7", "#8ea9db", "#305496", "#203764"] },
        { "#ed7d31": ["#fce4d6", "#f8cbad", "#f4b084", "#c65911", "#833c0c"] },
        { "#a5a5a5": ["#ededed", "#dbdbdb", "#c9c9c9", "#7b7b7b", "#525252"] },
        { "#ffc000": ["#fff2cc", "#ffe699", "#ffd966", "#bf8f00", "#806000"] },
        { "#5b9bd5": ["#ddebf7", "#bdd7ee", "#9bc2e6", "#2f75b5", "#1f4e78"] },
        { "#70ad47": ["#e2efda", "#c6e0b4", "#a9d08e", "#548235", "#375623"] },
      ],
      //  parentcolor:['#ffffff','#000000','#e7e6e6','#44546a','#4472c4','#ed7d31','#a5a5a5','#ffc000','#5b9bd5','#70ad47'],
      standardcolor: [
        "#c00000",
        "#ff0000",
        "#ffc000",
        "#ffff00",
        "#92d050",
        "#00b050",
        "#00b0f0",
        "#0070c0",
        "#002060",
        "#7030a0",
      ],
      colorpick: "",
    };
  },
  mounted() {
    if (this.highlightColor === true) {
      this.fill_color = false;
    } else {
      this.fill_color = true;
    }
    // console.log(this.colordata);
    for (let i = 0; i < this.colordata.length; i++) {
      // console.log(Object.keys(this.colordata[i])[0],'birds');
      // console.log(Object.values(this.colordata[i])[0]);
      for (let j = 0; j < Object.values(this.colordata[i])[0].length; j++) {
        // console.log((Object.values(this.colordata[i])[0])[j],'dei');
      }
    }
    document.addEventListener("click", (e) => {
      if (
        e.target.className != "color-container" &&
        e.target.className != "color-container-title" &&
        e.target.className !=
          "color-container-specific-color-checkbox-border" &&
        e.target.className !=
          "color-container-specific-color-checkbox-border-fill-color" &&
        e.target.className != "color-container-specific-color" &&
        e.target.className != "color-container-specific-color1" &&
        e.target.className != "color-container-specific-color2" &&
        e.target.className != "color-container-specific-color1-container" &&
        e.target.className != "color-container2323" &&
        e.target.className != "favcolor" &&
        e.target.className != "color-palette-container" &&
        e.target.className != "color-palette-icon" &&
        e.target.className != "icon-color-fill" &&
        e.target.className != "color-icon-border" &&
        e.target.className != "color-palette-open-icon" &&
        e.target.className != "icon-angle-down-regular"
      ) {
        this.colorcontainer = false;
      }
    });
  },
  methods: {
    emitCharacter(code){
        this.$emit("customCharacter", code);

    },
    getcolorvalue(event) {
      this.recent = true;
      this.colorvalue = event.target.value;
      this.$emit("customChange", this.colorvalue);
      // console.log(this.recentcolor);
      if (this.recentcolor.includes(this.colorvalue) != true) {
        document
          .querySelectorAll(".color-container-specific-color-checkbox-border")
          .forEach((e) => {
            e.classList.remove("add-border");
          });
        this.recentvalue = this.colorvalue;
        this.recentcolor.push(this.colorvalue);
        // let a = document.createElement('div')
        //  a.classList.add('color-container-specific-color-checkbox-border')
        //  a.classList.add('add-border')
        //  let b = document.createElement('div')
        //  b.classList.add('color-container-specific-color-checkbox-border-fill-color')
        //  a.appendChild(b)
        //  b.style.background = this.colorvalue
        //  document.querySelector('.color-container-specific-color2').appendChild(a)
      }
    },
    getcolor(event) {
      document
        .querySelectorAll(".color-container-specific-color-checkbox-border")
        .forEach((e) => {
          e.classList.remove("add-border");
        });
      if (
        event.target.className ===
        "color-container-specific-color-checkbox-border-fill-color"
      ) {
        this.colorvalue = event.target.style.background;
        this.$emit("customChange", this.colorvalue);
        event.target.parentElement.classList.add("add-border");
        this.colorcontainer = false;
      } else if (
        event.target.className ===
        "color-container-specific-color-checkbox-border"
      ) {
        this.colorvalue = event.target.children[0].style.background;
        this.$emit("customChange", this.colorvalue);
        event.target.classList.add("add-border");
        this.colorcontainer = false;
      }
      this.recent = true;
      // this.colorvalue = event.target.value
      // this.$emit('customChange', this.colorvalue )
      // console.log(this.recentcolor);
      if (this.recentcolor.includes(this.colorvalue) != true) {
        document
          .querySelectorAll(".color-container-specific-color-checkbox-border")
          .forEach((e) => {
            e.classList.remove("add-border");
          });

        this.recentvalue = this.colorvalue;
        this.recentcolor.push(this.colorvalue);
      }
      //  console.log(this.colorvalue);
    },
    colorpalette() {
      this.$nextTick(() => {
        this.containerleft =
          document
            .getElementById("color-palette-open-icon" + this.unique)
            .getBoundingClientRect().left + 5;
        this.containertop =
          document
            .getElementById("color-palette-open-icon" + this.unique)
            .getBoundingClientRect().top + 50;
      });
      console.log(this.containerleft, "left", this.containertop, "top");
      if (this.colorcontainer === false) {
        this.colorcontainer = true;
      } else if (this.colorcontainer === true) {
        this.colorcontainer = false;
      }
    },
    colorpicker() {
      this.colorpick = true;
    },
  },
};
</script>
<style>
.grid {
  display: grid;
  grid-template-columns: repeat(6, 50px); /* Adjust the number of columns */
  gap: 5px;
  margin: 20px;
}
.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  height: 50px;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
}
.cell:hover {
  background-color: #f0f0f0;
}
</style>