<!-- eslint-disable vue/no-async-in-computed-properties -->
<template>
  <!--------------------------------------------------- Mixed input ------------------------------------------------------>
  <div v-if="type.includes('mixed')" class="input-container">
    <div
      v-if="labelAnimation == true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <span
        v-if="prefix"
        :id="'prefix' + unique"
        style="
          width: max-content;
          font-size: 15px;
          color: #6b7280;
          background: #f9fafb;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          display: flex;
          padding: 8px;
          border-right: 1px solid #d8d8d8;
        "
      >
        <main>
          <slot name="prefix"></slot></main
      ></span>
      <div v-if="readOnlyProp" class="label-overlay"></div>
      <div v-if="readOnlyProp" class="readonly-overlay"></div>
      <span
        :class="symbol !== '' ? 'number-symbol-holder' : ''"
        style="margin-right: 4px"
        >{{ symbol.charAt(0) }}</span
      >
      <input
        :id="'input' + unique"
        v-model="value"
        :name="name"
        :type="type.includes('number') ? 'number' : 'text'"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :min="minLimit"
        :max="maxLimit"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground, left: getMultiInputWidth() }"
        :for="'input' + unique"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d8d8d8;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
      <span
        v-if="suffixselect"
        class="suffix-select"
        style="
          width: max-content;
          font-size: 15px;
          color: #6b7280;
          background: #f9fafb;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          display: flex;
          border-left: 1px solid #d8d8d8;
        "
      >
        <main>
          <slot name="suffix-select"></slot></main
      ></span>
      <span
        v-if="suffix"
        style="
          width: max-content;
          font-size: 15px;
          color: #6b7280;
          background: #f9fafb;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          display: flex;
          padding: 8px;
          border-left: 1px solid #d8d8d8;
        "
      >
        <main>
          <slot name="suffix"></slot></main
      ></span>
    </div>
    <div
      v-if="labelAnimation === false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <span
        v-if="prefix"
        style="
          width: max-content;
          font-size: 15px;
          color: #6b7280;
          background: #f9fafb;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          display: flex;
          padding: 8px;
          border-right: 1px solid #d8d8d8;
        "
      >
        <main>
          <slot name="prefix"></slot></main
      ></span>
      <div v-if="readOnlyProp" class="label-overlay"></div>
      <div v-if="readOnlyProp" class="readonly-overlay"></div>
      <input
        :id="'input' + unique"
        v-model="value"
        :name="name"
        :type="type.includes('number') ? 'number' : 'text'"
        :placeholder="labelValue"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :min="minLimit"
        :max="maxLimit"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d6d8d3;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
      <span
        v-if="suffix"
        style="
          width: max-content;
          font-size: 15px;
          color: #6b7280;
          background: #f9fafb;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          display: flex;
          padding: 8px;
          border-left: 1px solid #d8d8d8;
        "
      >
        <main>
          <slot name="suffix"></slot></main
      ></span>
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
  </div>
  <!--------------------------------------------------- Regular input ------------------------------------------------------>
  <div v-if="type == 'text' && singleBorder != true" class="input-container">
    <div
      v-if="labelAnimation == true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'input' + unique"
        ref="regular-text-input"
        v-model="value"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'input' + unique"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d8d8d8;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <div
      v-if="labelAnimation == false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <span v-if="customSlotPrefix" class="custom-slot-prefix">
        <main style="height: 100% !important">
          <slot name="custom-slot-prefix"> </slot>
        </main>
      </span>
      <input
        :id="'input' + unique"
        ref="regular-text-input"
        v-model="value"
        :name="name"
        type="text"
        :placeholder="labelValue"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d6d8d3;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <!-- <span
          v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
          class="input-icon"
          ><i :class="'icon-' + icon"></i
        ></span> -->
      <span v-if="customSlotSuffix" class="custom-slot-suffix">
        <main style="height: 100% !important">
          <slot name="custom-slot-suffix"> </slot>
        </main>
      </span>
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
  </div>
  <div v-if="type == 'text' && singleBorder == true" class="input-container">
    <div
      v-if="labelAnimation == true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        Cpq: small,
        disabled: disabled,
        singleBorder: singleBorder,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'input' + unique"
        ref="regular-text-input"
        v-model="value"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'input' + unique"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d8d8d8;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <div
      v-if="labelAnimation == false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        Cpq: small,
        disabled: disabled,
        singleBorder: singleBorder,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'input' + unique"
        ref="regular-text-input"
        v-model="value"
        :name="name"
        type="text"
        :placeholder="labelValue"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d6d8d3;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
  </div>
  <!--------------------------------------------------- telephone input ------------------------------------------------------>
  <div
    v-if="type == 'telephone' && singleBorder != true"
    class="input-container"
  >
    <div
      v-if="labelAnimation == true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'input' + unique"
        ref="regular-text-input"
        v-model="formattedTelephone"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'input' + unique"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d8d8d8;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <div
      v-if="labelAnimation == false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <span v-if="customSlotPrefix" class="custom-slot-prefix">
        <main style="height: 100% !important">
          <slot name="custom-slot-prefix"> </slot>
        </main>
      </span>
      <input
        :id="'input' + unique"
        ref="regular-text-input"
        v-model="formattedTelephone"
        :name="name"
        type="text"
        :placeholder="labelValue"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d6d8d3;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <!-- <span
          v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
          class="input-icon"
          ><i :class="'icon-' + icon"></i
        ></span> -->
      <span v-if="customSlotSuffix" class="custom-slot-suffix">
        <main style="height: 100% !important">
          <slot name="custom-slot-suffix"> </slot>
        </main>
      </span>
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
  </div>
  <div
    v-if="type == 'telephone' && singleBorder == true"
    class="input-container"
  >
    <div
      v-if="labelAnimation == true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        Cpq: small,
        disabled: disabled,
        singleBorder: singleBorder,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'input' + unique"
        ref="regular-text-input"
        v-model="formattedTelephone"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'input' + unique"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d8d8d8;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <div
      v-if="labelAnimation == false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        Cpq: small,
        disabled: disabled,
        singleBorder: singleBorder,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'input' + unique"
        ref="regular-text-input"
        v-model="formattedTelephone"
        :name="name"
        type="text"
        :placeholder="labelValue"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d6d8d3;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
  </div>
  <!--------------------------------------------------- Location input ------------------------------------------------------>
  <div v-if="type == 'location'" class="input-container">
    <div
      v-if="labelAnimation == true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'input' + unique"
        v-model="value"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="
          () => {
            atInput();
          }
        "
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'input' + unique"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d8d8d8;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <div
      v-if="labelAnimation == false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'input' + unique"
        v-model="value"
        :name="name"
        type="text"
        :placeholder="labelValue"
        :style="{ background: customBackground }"
        autocomplete="off"
        :readonly="readOnlyProp"
        required
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="
          () => {
            atInput();
          }
        "
        @keyup.enter="atEnter($event, value)"
      />
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d6d8d3;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
    <hlx-overlay
      v-if="showLocationOptions === true && locationData?.length > 0"
      :show="showLocationOptions === true && locationData?.length > 0"
      :top="containertop"
      :left="containerleft"
      :width="containerwidth"
      :height="containerheight"
      :conditionvalue="250"
      :conditiondown="45"
      :conditionup="10"
      @close-overlay="closeLocationOptions"
    >
      <template #overlay>
        <div class="address-options-parent-container">
          <div
            v-for="address in locationData"
            :key="address"
            class="address-options-container"
            @click="setLocation(address)"
          >
            <div
              class="address-options"
              :style="getLocationWidth"
              style="font-size: 14px"
            >
              {{ getNestedLookupValue(address, lookupProp) }}
            </div>
          </div>
          <!-- <div
              :key="address"
              class="address-options-container"
              @click="this.$emit('add-new-location', true)"
            >
              <div class="link" :style="getLocationWidth">
                <i class="icon-plus-circle-regular"></i>
                <span style="fontcolor: #2879b7; font-size: 14px"
                  >Add new address
                </span>
              </div>
            </div> -->
        </div>
        <!-- <div v-else class="address-options-parent-container">
      <div :key="address"  class="address-options-container" @click="this.$emit('add-new-location', true)">
        <div class="link" :style="getLocationWidth">
        <i class="icon-plus-circle-regular"></i> <span style="fontColor: #2879b7; font-size: 14px">Add new address </span>
        </div>
      </div>
      </div> -->
      </template>
    </hlx-overlay>
    <hlx-overlay
      v-if="showNewAddressOption"
      :show="showNewAddressOption"
      :top="containertop"
      :left="containerleft"
      :width="containerwidth"
      :height="containerheight"
      :conditionvalue="250"
      :conditiondown="45"
      :conditionup="10"
      @close-overlay="closeLocationOptions"
    >
      <template #overlay>
        <div class="address-options-parent-container">
          <!-- <div v-for="address in locationData" :key="address"  class="address-options-container" @click="setLocation(address)">
        <div class="address-options" :style="getLocationWidth" style="font-size: 14px;">
          {{ address.description }}
        </div>
      </div> -->
          <div :key="address" class="address-options-container">
            <div class="link" :style="getLocationWidth">
              <i class="icon-plus-circle-regular"></i>
              <span style="color: #2879b7; font-size: 14px"
                >Add new address
              </span>
            </div>
          </div>
        </div>
        <!-- <div v-else class="address-options-parent-container">
      <div :key="address"  class="address-options-container" @click="this.$emit('add-new-location', true)">
        <div class="link" :style="getLocationWidth">
        <i class="icon-plus-circle-regular"></i> <span style="fontColor: #2879b7; font-size: 14px">Add new address </span>
        </div>
      </div>
      </div> -->
      </template>
    </hlx-overlay>
  </div>
  <!--------------------------------------------------- text area input ------------------------------------------------------>
  <div
    v-if="type == 'textarea' && singleBorder != true"
    class="input-container"
  >
    <div
      v-if="labelAnimation == true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
      :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <textarea
        :id="'input' + unique"
        v-model="value"
        :name="name"
        :style="{
          background: customBackground,
          'caret-color': readOnlyProp ? 'transparent' : '',
        }"
        autocomplete="off"
        required
        :rows="rows"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keydown="handleTextareaKeydown"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'input' + unique"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <!-- <span
          class="input-icon"
          v-if="
            this.iconPosition == 'left' || this.iconPosition == 'right'
              ? true
              : false
          "
          ><i :class="'icon-' + this.icon"></i
        ></span> -->
    </div>
    <div
      v-if="labelAnimation == false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
      :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <textarea
        :id="'input' + unique"
        v-model="value"
        :name="name"
        :placeholder="labelValue"
        :style="{
          background: customBackground,
          'caret-color': readOnlyProp ? 'transparent' : '',
        }"
        autocomplete="off"
        required
        :rows="rows"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keydown="handleTextareaKeydown"
        @keyup.enter="atEnter($event, value)"
      />
      <!-- <span
          class="input-icon"
          v-if="
            this.iconPosition == 'left' || this.iconPosition == 'right'
              ? true
              : false
          "
          ><i :class="'icon-' + this.icon"></i
        ></span> -->
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
  </div>

  <div
    v-if="type == 'textarea' && singleBorder == true"
    class="input-container"
  >
    <div
      v-if="labelAnimation == true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        Cpq: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        singleBorder: singleBorder,
        customError: customError,
      }"
      :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <textarea
        :id="'input' + unique"
        v-model="value"
        :name="name"
        :style="{
          background: customBackground,
          'caret-color': readOnlyProp ? 'transparent' : '',
        }"
        autocomplete="off"
        required
        :rows="rows"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keydown="handleTextareaKeydown"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'input' + unique"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <!-- <span
          class="input-icon"
          v-if="
            this.iconPosition == 'left' || this.iconPosition == 'right'
              ? true
              : false
          "
          ><i :class="'icon-' + this.icon"></i
        ></span> -->
    </div>
    <div
      v-if="labelAnimation == false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        Cpq: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        singleBorder: singleBorder,
        customError: customError,
      }"
      :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <textarea
        :id="'input' + unique"
        v-model="value"
        :name="name"
        :placeholder="labelValue"
        :style="{
          background: customBackground,
          'caret-color': readOnlyProp ? 'transparent' : '',
        }"
        autocomplete="off"
        required
        :rows="rows"
        @focus="focusInput"
        @blur="blurInput"
        @mouseenter="hoverInput"
        @mouseleave="unHoverInput"
        @input="atInput"
        @keydown="handleTextareaKeydown"
        @keyup.enter="atEnter($event, value)"
      />
      <!-- <span
          class="input-icon"
          v-if="
            this.iconPosition == 'left' || this.iconPosition == 'right'
              ? true
              : false
          "
          ><i :class="'icon-' + this.icon"></i
        ></span> -->
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
  </div>
  <!----------------------------------------------------------- Email input ------------------------------------------------>
  <div v-if="type == 'email' && singleBorder != true" class="input-container">
    <div
      v-if="labelAnimation === true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
      @mouseenter="hoverEmail"
      @mouseleave="unHoverEmail"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'inputemail' + unique"
        v-model="value"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @blur="blurEmail"
        @focus="focusEmail"
        @keyup.enter="
          () => {
            validateEmail();
            atEnter($event, value);
          }
        "
        @input="atInput"
      />
      <label
        v-if="labelAnimation == true"
        :id="'labelemail' + unique"
        :style="{ background: customBackground }"
        :for="'inputemail' + unique"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d6d8d3;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
    </div>
    <div
      v-if="labelAnimation === false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
      @mouseenter="hoverEmail"
      @mouseleave="unHoverEmail"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'inputemail' + unique"
        v-model="value"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        :placeholder="labelValue"
        required
        :readonly="readOnlyProp"
        :disabled="isDisabled"
        @blur="blurEmail"
        @focus="focusEmail"
        @keyup.enter="
          () => {
            validateEmail();
            atEnter($event, value);
          }
        "
        @input="atInput"
      />
      <label
        v-if="labelAnimation == true"
        :id="'labelemail' + unique"
        :style="{ background: customBackground }"
        :for="'inputemail' + unique"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d6d8d3;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
  </div>

  <div v-if="type == 'email' && singleBorder == true" class="input-container">
    <div
      v-if="labelAnimation === true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        Cpq: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        singleBorder: singleBorder,
        customError: customError,
      }"
      @mouseenter="hoverEmail"
      @mouseleave="unHoverEmail"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'inputemail' + unique"
        v-model="value"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @blur="blurEmail"
        @focus="focusEmail"
        @keyup.enter="
          () => {
            validateEmail();
            atEnter($event, value);
          }
        "
        @input="atInput"
      />
      <label
        v-if="labelAnimation == true"
        :id="'labelemail' + unique"
        :style="{ background: customBackground }"
        :for="'inputemail' + unique"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d6d8d3;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
    </div>
    <div
      v-if="labelAnimation === false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        Cpq: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        singleBorder: singleBorder,
        customError: customError,
      }"
      @mouseenter="hoverSingleEmail"
      @mouseleave="unHoverSingleEmail"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'inputemail' + unique"
        v-model="value"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        :placeholder="labelValue"
        required
        :readonly="readOnlyProp"
        :disabled="isDisabled"
        @blur="blurSingleEmail"
        @focus="focusSingleEmail"
        @keyup.enter="
          () => {
            validateSingleEmail();
            atEnter($event, value);
          }
        "
        @input="atInput"
      />
      <label
        v-if="labelAnimation == true"
        :id="'labelemail' + unique"
        :style="{ background: customBackground }"
        :for="'inputemail' + unique"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <i
        v-if="showClear"
        :id="icon"
        class="icon-times-circle-regular"
        style="
          position: relative;
          top: 10px;
          right: 10px;
          color: #d6d8d3;
          cursor: pointer;
        "
        @click="value = ''"
      >
      </i>
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
  </div>
  <!--------------------------------------------------------- Password input ----------------------------------------------->
  <div
    v-if="type == 'password'"
    class="input-container"
    style="align-items: flex-start"
  >
    <div
      v-if="show_password_condition == true"
      id="pp"
      class="password-condition-dialog"
    >
      <div class="password-conditon-dialog-title">Password must contain</div>
      <div class="condition-box length">
        <span class="conditions">A minimum of 8 characters long</span>
        <div class="icon-area">
          <i id="length-icon" class="icon-times-circle-regular"></i>
        </div>
      </div>
      <div class="condition-box lowercase">
        <span class="conditions">A minimum of 1 lower case letter [a-z]</span>
        <div class="icon-area">
          <i id="lowercase-icon" class="icon-times-circle-regular"></i>
        </div>
      </div>
      <div class="condition-box uppercase">
        <span class="conditions">A minimum of 1 upper case letter [A-Z]</span>
        <div class="icon-area">
          <i id="uppercase-icon" class="icon-times-circle-regular"></i>
        </div>
      </div>
      <div class="condition-box number">
        <span class="conditions">A minimum of 1 numeric character [0-9]</span>
        <div class="icon-area">
          <i id="number-icon" class="icon-times-circle-regular"></i>
        </div>
      </div>
      <div class="condition-box special">
        <span class="conditions">A minimum of 1 special character [:~!]</span>
        <div class="icon-area">
          <i id="special-icon" class="icon-times-circle-regular"></i>
        </div>
      </div>
    </div>
    <div
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <form style="width: 100%" @submit.prevent="handleSubmit"> -->
      <input
        :id="'inputpassword' + unique"
        v-model="value"
        :name="name"
        type="password"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        @focus="focusPassword"
        @blur="blurPassword"
        @keyup="validatePassword"
        @mouseenter="hoverPassword"
        @mouseleave="unHoverPassword"
        @input="atInput"
        @keyup.enter="
          atEnter($event, value);
          $event.preventDefault();
        "
      />
      <!-- </form> -->
      <label
        v-if="labelAnimation == true"
        :id="'labelpassword' + unique"
        :style="{ background: customBackground }"
        :for="'inputpassword' + unique"
        @mouseenter="hoverPassword"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <span class="input-icon" style="cursor: pointer" @click="togglePassword"
        ><i
          id="password-toggle-icon"
          class="icon-eye-slash-regular"
          style="color: #a6a6a6"
        ></i
      ></span>
    </div>
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
    <!----------------------------------------------------- Password conditions dialog --------------------------------------->
  </div>
  <div v-if="type == 'confirmpassword'" class="input-container">
    <div
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <form style="width: 100%">
        <input
          :id="'inputpassword' + unique"
          v-model="value"
          :name="name"
          type="password"
          :style="{ background: customBackground }"
          autocomplete="off"
          required
          @focus="focusPassword"
          @blur="blurPassword"
          @mouseenter="hoverPassword"
          @mouseleave="unHoverPassword"
          @input="atInput"
          @keyup.enter="atEnter($event, value)"
        />
      </form>
      <label
        v-if="labelAnimation == true"
        :id="'labelpassword' + unique"
        :style="{ background: customBackground }"
        :for="'inputpassword' + unique"
        @mouseenter="hoverPassword"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <!-- <span class="input-icon" @click="togglePassword" style="cursor: pointer"
          ><i class="icon-eye-slash-regular" id="password-toggle-icon"></i
        ></span> -->
    </div>
    <!----------------------------------------------------- Password conditions dialog --------------------------------------->
    <span
      class="error-message"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      >{{ input_error_message }}</span
    >
  </div>
  <!--------------------------------------------------------- Phone number input ----------------------------------------------->
  <div
    v-if="type == 'phonenumber' && singleBorder == false"
    class="input-container"
  >
    <div v-if="labelAnimation == true" class="phone-number">
      <div
        :id="'input-field' + unique"
        class="input-field"
        :class="{
          small: small,
          disabled: disabled,
          readOnly: readOnlyProp,
          customError: customError,
        }"
      >
        <span
          :id="'prefix' + unique"
          style="
            width: 115px;
            font-size: 15px;
            color: #6b7280;
            background: white;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            display: flex;
            padding: 0 8px;
            border-right: 1px solid #d8d8d8;
            align-items: center;
            cursor: pointer;
          "
          @click="showNewCountryListFunc"
        >
          <label
            :id="'labelNumber2' + unique"
            :style="{ background: customBackground }"
            style="left: 6px !important; top: -10px; font-size: 12px"
            :for="'input' + unique"
            @mouseenter="hoverInput"
            >{{ countryLabelvalue
            }}<span v-if="showMandatory" style="color: #ff5050">{{
              " *"
            }}</span></label
          >
          <span style="display: flex; width: 30px; margin-right: 6px">
            <img
              :src="selectedCountry.flags"
              alt=""
              srcset=""
              style="max-width: 100%; height: 20px"
            />
          </span>
          <span style="width: 45px; font-size: 14px; color: #000">{{
            selectedCountry.dial_code
          }}</span>
          <i v-if="showNewCountryList" class="icon-caret-up-filled"></i>
          <i v-else class="icon-caret-down-filled"></i>
        </span>
        <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
          <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
        <input
          :id="'inputnumber' + unique"
          v-model="value"
          :disabled="isDisabled"
          :name="name"
          type="text"
          :style="{ background: customBackground }"
          :class="'phn' + unique"
          autocomplete="off"
          required
          :readonly="readOnlyProp"
          @focus="focusPhoneNumber"
          @blur="blurPhoneNumber"
          @mouseenter="hoverNumber"
          @mouseleave="unHovePhonerNumber"
          @input="emitPhoneNumber"
        />
        <label
          :id="'labelNumber' + unique"
          :style="{ background: customBackground }"
          style="left: 120px !important"
          :for="'input' + unique"
          @mouseenter="hoverInput"
          @click="focusPhoneNumber"
          >{{ labelValue
          }}<span v-if="showMandatory" style="color: #ff5050">{{
            " *"
          }}</span></label
        >
        <span
          v-if="
            iconPosition == 'left' || iconPosition == 'right' ? true : false
          "
          class="input-icon"
          ><i :class="'icon-' + icon"></i
        ></span>
      </div>
    </div>
    <div v-if="labelAnimation == false" class="phone-number">
      <div
        :id="'input-field' + unique"
        class="input-field"
        :class="{
          small: small,
          disabled: disabled,
          readOnly: readOnlyProp,
          customError: customError,
        }"
      >
        <span
          :id="'prefix' + unique"
          style="
            width: max-content;
            font-size: 15px;
            color: #6b7280;
            background: white;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            display: flex;
            padding: 0 8px;
            border-right: 1px solid #d8d8d8;
            align-items: center;
            cursor: pointer;
          "
          @click="showNewCountryListFunc"
        >
          <img :src="selectedCountry.flags" alt="" srcset="" width="30" />
          &nbsp;&nbsp; <span>{{ selectedCountry.dial_code }}</span> &nbsp;&nbsp;
          <i v-if="showNewCountryList" class="icon-caret-up-filled"></i>
          <i v-else class="icon-caret-down-filled"></i>
        </span>
        <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
          <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
        <input
          :id="'inputnumber' + unique"
          v-model="value"
          :disabled="isDisabled"
          :name="name"
          type="number"
          :style="{ background: customBackground }"
          :class="'phn' + unique"
          autocomplete="off"
          required
          :readonly="readOnlyProp"
          :placeholder="labelValue"
          @focus="focusPhoneNumber"
          @blur="blurPhoneNumber"
          @mouseenter="hoverNumber"
          @mouseleave="unHovePhonerNumber"
          @input="emitPhoneNumber"
        />
        <!-- <label
          :id="'label' + this.unique"
          :style="{ background: this.customBackground }"
          @mouseenter="hoverInput"
          :for="'input' + this.unique"
          >{{ this.labelValue }}<span style="color: #ff5050;" v-if="showMandatory">{{ ' *' }}</span></label
        > -->
      </div>
    </div>

    <hlx-overlay
      v-if="showNewCountryList"
      :show="showNewCountryList"
      :top="containertop"
      :left="containerleft"
      :width="containerwidth"
      :height="containerheight"
      :conditionvalue="250"
      :conditiondown="45"
      :conditionup="10"
      @close-overlay="showNewCountryListOverlayClose"
    >
      <template #overlay>
        <div
          class="new-country-list-container"
          style="
            width: 100%;
            box-shadow: 0 0 7px #d8d8d8;
            margin-top: 4px;
            border-radius: 5px;
            background-color: white;
          "
        >
          <span style="padding: 4px 8px; display: flex">
            <hlx-search @search-key="searchCountryCode"></hlx-search>
          </span>
          <span
            style="
              max-height: 150px;
              width: 100%;
              overflow: auto;
              display: flex;
              flex-direction: column;
              background-color: white;
            "
          >
            <span
              v-for="item in countries"
              :key="item"
              class="hover-country"
              style="
                padding: 0 8px;
                min-height: 30px;
                display: flex;
                gap: 8px;
                align-items: center;
                cursor: pointer;
              "
              :style="{
                background: selectedCountry.name === item.name ? '#54bd95' : '',
              }"
              @click="changeSelectedCountry(item)"
            >
              <span style="display: flex; width: 30px; margin-right: 6px">
                <img
                  :src="item.flags"
                  alt=""
                  srcset=""
                  style="max-width: 100%; height: 20px"
                />
              </span>
              <span style="width: 45px">{{ item.dial_code }}</span>
              <span>{{ item.name }}</span>
            </span>
          </span>
        </div>
      </template>
    </hlx-overlay>
    <span
      class="error-message phone-number-validation"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      style="height: 24px"
      >{{ input_error_message }}</span
    >
  </div>

  <div
    v-if="type == 'phonenumber' && singleBorder == true"
    class="input-container"
  >
    <div v-if="labelAnimation == true" class="phone-number">
      <div
        :id="'input-field' + unique"
        class="input-field"
        :class="{
          Cpq: small,
          disabled: disabled,
          readOnly: readOnlyProp,
          singleBorder: singleBorder,
          customError: customError,
        }"
      >
        <span
          :id="'prefix' + unique"
          style="
            width: 115px;
            font-size: 15px;
            color: #6b7280;
            background: white;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            display: flex;
            padding: 0 8px;
            border-right: 1px solid #d8d8d8;
            align-items: center;
            cursor: pointer;
          "
          @click="showNewCountryListFunc"
        >
          <label
            :id="'labelNumber2' + unique"
            :style="{ background: customBackground }"
            style="left: 6px !important; top: -10px; font-size: 12px"
            :for="'input' + unique"
            @mouseenter="hoverInput"
            >{{ countryLabelvalue
            }}<span v-if="showMandatory" style="color: #ff5050">{{
              " *"
            }}</span></label
          >
          <span style="display: flex; width: 30px; margin-right: 6px">
            <img
              :src="selectedCountry.flags"
              alt=""
              srcset=""
              style="max-width: 100%; height: 20px"
            />
          </span>
          <span style="width: 45px">{{ selectedCountry.dial_code }}</span>
          <i v-if="showNewCountryList" class="icon-caret-up-filled"></i>
          <i v-else class="icon-caret-down-filled"></i>
        </span>
        <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
          <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
        <input
          :id="'inputnumber' + unique"
          v-model="value"
          :disabled="isDisabled"
          :name="name"
          type="text"
          :style="{ background: customBackground }"
          :class="'phn' + unique"
          autocomplete="off"
          required
          :readonly="readOnlyProp"
          @focus="focusPhoneNumber"
          @blur="blurPhoneNumber"
          @mouseenter="hoverNumber"
          @mouseleave="unHovePhonerNumber"
          @input="emitPhoneNumber"
        />
        <label
          :id="'labelNumber' + unique"
          :style="{ background: customBackground }"
          style="left: 120px !important"
          :for="'input' + unique"
          @mouseenter="hoverInput"
          @click="focusPhoneNumber"
          >{{ labelValue
          }}<span v-if="showMandatory" style="color: #ff5050">{{
            " *"
          }}</span></label
        >
        <span
          v-if="
            iconPosition == 'left' || iconPosition == 'right' ? true : false
          "
          class="input-icon"
          ><i :class="'icon-' + icon"></i
        ></span>
      </div>
    </div>
    <div v-if="labelAnimation == false" class="phone-number">
      <div
        :id="'input-field' + unique"
        class="input-field"
        :class="{
          Cpq: small,
          disabled: disabled,
          readOnly: readOnlyProp,
          customError: customError,
        }"
      >
        <span
          :id="'prefix' + unique"
          style="
            width: max-content;
            font-size: 15px;
            color: #6b7280;
            background: white;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            display: flex;
            padding: 0 8px;
            border-right: 1px solid #d8d8d8;
            align-items: center;
            cursor: pointer;
          "
          @click="showNewCountryListFunc"
        >
          <img :src="selectedCountry.flags" alt="" srcset="" width="30" />
          &nbsp;&nbsp; <span>{{ selectedCountry.dial_code }}</span> &nbsp;&nbsp;
          <i v-if="showNewCountryList" class="icon-caret-up-filled"></i>
          <i v-else class="icon-caret-down-filled"></i>
        </span>
        <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
          <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
        <input
          :id="'inputnumber' + unique"
          v-model="value"
          :disabled="isDisabled"
          :name="name"
          type="number"
          :style="{ background: customBackground }"
          :class="'phn' + unique"
          autocomplete="off"
          required
          :readonly="readOnlyProp"
          :placeholder="labelValue"
          @focus="focusPhoneNumber"
          @blur="blurPhoneNumber"
          @mouseenter="hoverNumber"
          @mouseleave="unHovePhonerNumber"
          @input="emitPhoneNumber"
        />
        <!-- <label
          :id="'label' + this.unique"
          :style="{ background: this.customBackground }"
          @mouseenter="hoverInput"
          :for="'input' + this.unique"
          >{{ this.labelValue }}<span style="color: #ff5050;" v-if="showMandatory">{{ ' *' }}</span></label
        > -->
      </div>
    </div>

    <hlx-overlay
      v-if="showNewCountryList"
      :show="showNewCountryList"
      :top="containertop"
      :left="containerleft"
      :width="containerwidth"
      :height="containerheight"
      :conditionvalue="250"
      :conditiondown="45"
      :conditionup="10"
      @close-overlay="showNewCountryListOverlayClose"
    >
      <template #overlay>
        <div
          class="new-country-list-container"
          style="
            width: 100%;
            box-shadow: 0 0 7px #d8d8d8;
            margin-top: 4px;
            border-radius: 5px;
            background-color: white;
          "
        >
          <span style="padding: 4px 8px; display: flex">
            <hlx-search @search-key="searchCountryCode"></hlx-search>
          </span>
          <span
            style="
              max-height: 150px;
              width: 100%;
              overflow: auto;
              display: flex;
              flex-direction: column;
              background-color: white;
            "
          >
            <span
              v-for="item in countries"
              :key="item"
              class="hover-country"
              style="
                padding: 0 8px;
                min-height: 30px;
                display: flex;
                gap: 8px;
                align-items: center;
                cursor: pointer;
              "
              :style="{
                background: selectedCountry.name === item.name ? '#54bd95' : '',
              }"
              @click="changeSelectedCountry(item)"
            >
              <span style="display: flex; width: 30px; margin-right: 6px">
                <img
                  :src="item.flags"
                  alt=""
                  srcset=""
                  style="max-width: 100%; height: 20px"
                />
              </span>
              <span style="width: 45px">{{ item.dial_code }}</span>
              <span>{{ item.name }}</span>
            </span>
          </span>
        </div>
      </template>
    </hlx-overlay>
    <span
      class="error-message phone-number-validation"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      style="height: 24px"
      >{{ input_error_message }}</span
    >
  </div>
  <!--------------------------------------------------------- Number input ----------------------------------------------->
  <div v-if="type == 'number' && singleBorder != true" class="input-container">
    <div
      v-if="labelAnimation == true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <span :class="symbol !== '' ? 'number-symbol-holder' : ''">{{
        symbol.charAt(0)
      }}</span>
      <input
        :id="'inputnumber' + unique"
        v-model="value"
        :name="name"
        type="number"
        :step="step"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        :min="minLimit"
        :max="maxLimit"
        @focus="focusNumber"
        @blur="blurNumber"
        @mouseenter="hoverNumber"
        @mouseleave="unHoverNumber"
        @keyup="validateNumber"
        @keydown="checkArrowKey($event)"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'input' + unique"
        @mouseenter="hoverInput"
        @click="focusNumber"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <div
      v-if="labelAnimation == false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <span :class="symbol !== '' ? 'number-symbol-holder' : ''">{{
        symbol.charAt(0)
      }}</span>
      <input
        :id="'inputnumber' + unique"
        v-model="value"
        :name="name"
        type="number"
        :step="step"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        :min="minLimit"
        :max="maxLimit"
        @focus="focusNumber"
        @blur="blurNumber"
        @mouseenter="hoverNumber"
        @mouseleave="unHoverNumber"
        @keyup="validateNumber"
        @keydown="checkArrowKey($event)"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <span
      class="error-message number-validation"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      style="height: 24px"
    >
      <span v-if="minLength !== null" class="min">{{ minimum }}</span
      ><span v-if="maxLength !== null" class="max">{{ maximum }}</span>
      <span v-if="maxLength === null && minLength === null">{{
        input_error_message
      }}</span>
    </span>
  </div>

  <div v-if="type == 'number' && singleBorder == true" class="input-container">
    <div
      v-if="labelAnimation == true"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        Cpq: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        singleBorder: singleBorder,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'inputnumber' + unique"
        v-model="value"
        :name="name"
        type="number"
        :readonly="readOnlyProp"
        :step="step"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :min="minLimit"
        :max="maxLimit"
        @focus="focusNumber"
        @blur="blurNumber"
        @mouseenter="hoverNumber"
        @mouseleave="unHoverNumber"
        @keyup="validateNumber"
        @keydown="checkArrowKey($event)"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'input' + unique"
        @click="focusNumber"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <div
      v-if="labelAnimation == false"
      :id="'input-field' + unique"
      class="input-field"
      :class="{
        Cpq: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        singleBorder: singleBorder,
        customError: customError,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <span :class="symbol !== '' ? 'number-symbol-holder' : ''">{{
        symbol.charAt(0)
      }}</span>
      <input
        :id="'inputnumber' + unique"
        v-model="value"
        :name="name"
        type="number"
        :step="step"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        :min="minLimit"
        :max="maxLimit"
        @focus="focusSingleNumber"
        @blur="blurSingleNumber"
        @mouseenter="hoverSingleNumber"
        @mouseleave="unHoverSingleNumber"
        @keyup="validateSingleNumber"
        @keydown="checkArrowKey($event)"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <span
      class="error-message number-validation"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      style="height: 24px"
    >
      <span v-if="minLength !== null" class="min">{{ minimum }}</span
      ><span v-if="maxLength !== null" class="max">{{ maximum }}</span>
      <span v-if="maxLength === null && minLength === null">{{
        input_error_message
      }}</span>
    </span>
  </div>
  <!--------------------------------------------------------- Date input ----------------------------------------------->
  <div v-if="type == 'date' && singleBorder == true" class="input-container">
    <div
      v-if="labelAnimation"
      :id="'input-field' + unique"
      class="input-field"
      :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
      :class="{
        singleBorder: singleBorder,
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
      @click="openCalendar"
    >
      <div
        v-if="readOnlyProp"
        class="label-overlay"
        style="cursor: default"
        @click.stop
      ></div>
      <div
        v-if="readOnlyProp"
        class="readonly-overlay"
        style="cursor: default"
        @click.stop
      ></div>
      <div
        v-if="!readOnlyProp"
        class="readonly-overlay"
        style="cursor: pointer"
      ></div>
      <input
        :id="'inputdate' + unique"
        v-model="value"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        @focus="focusDate"
        @blur="blurDate"
        @mouseenter="hoverDate"
        @mouseleave="unHoverDate"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'inputdate' + unique"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <div
      v-if="!labelAnimation"
      :id="'input-field' + unique"
      class="input-field"
      :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
        singleBorder: singleBorder,
      }"
    >
      <!-- <div v-if="readOnlyProp" class="label-overlay"></div>
        <div v-if="readOnlyProp" class="readonly-overlay"></div> -->
      <input
        :id="'inputdate' + unique"
        v-model="value"
        :name="name"
        type="text"
        :placeholder="labelValue"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusDate"
        @blur="blurDate"
        @mouseenter="hoverDate"
        @mouseleave="unHoverDate"
        @keyup.enter="atEnter($event, value)"
      />
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon" @click="openCalendar"></i
      ></span>
    </div>
    <span
      class="error-message number-validation"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      style="height: 24px"
    >
      <span>{{ input_error_message }}</span>
    </span>
    <hlx-overlay
      v-show="show_calendar === true"
      :show="show_calendar"
      :top="containertop"
      :left="containerleft"
      :width="containerwidth"
      :conditionvalue="330"
      :conditiondown="10"
      :conditionup="330"
    >
      <template #overlay>
        <Calendar
          v-if="show_calendar"
          v-click-away="openCalendar"
          :disable-weekend="disableWeekend"
          :event="calendar_pos"
          :pre-value="calendarValue"
          :start-year="startYear"
          :end-year="endYear"
          :holiday-list="holidayList"
          @selected-date="receivedDate"
        />
      </template>
    </hlx-overlay>
  </div>
  <div v-if="type == 'date' && singleBorder == false" class="input-container">
    <div
      v-if="labelAnimation"
      :id="'input-field' + unique"
      class="input-field"
      :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
    >
      <!-- <div
          v-if="readOnlyProp"
          class="label-overlay"
          style="cursor: default"
          @click.stop
        ></div>
        <div
          v-if="readOnlyProp"
          class="readonly-overlay"
          style="cursor: default"
          @click.stop
        ></div>
        <div
          v-if="!readOnlyProp"
          class="readonly-overlay"
          style="cursor: pointer"
        ></div> -->
      <input
        :id="'inputdate' + unique"
        v-model="value"
        :name="name"
        type="text"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        :readonly="readOnlyProp"
        @focus="focusDate"
        @blur="blurDate"
        @mouseenter="hoverDate"
        @mouseleave="unHoverDate"
        @keyup.enter="atEnter($event, value)"
      />
      <label
        :id="'label' + unique"
        :style="{ background: customBackground }"
        :for="'inputdate' + unique"
        @mouseenter="hoverInput"
        >{{ labelValue
        }}<span v-if="showMandatory" style="color: #ff5050">{{
          " *"
        }}</span></label
      >
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon" @click="openCalendar"></i
      ></span>
    </div>
    <div
      v-if="!labelAnimation"
      :id="'input-field' + unique"
      class="input-field"
      :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
      :class="{
        small: small,
        disabled: disabled,
        readOnly: readOnlyProp,
        customError: customError,
      }"
      @click="openCalendar"
    >
      <div v-if="readOnlyProp" class="label-overlay"></div>
      <div v-if="readOnlyProp" class="readonly-overlay"></div>
      <input
        :id="'inputdate' + unique"
        v-model="value"
        :name="name"
        type="text"
        disabled
        :placeholder="labelValue"
        :style="{ background: customBackground }"
        autocomplete="off"
        required
        @focus="focusDate"
        @blur="blurDate"
        @mouseenter="hoverDate"
        @mouseleave="unHoverDate"
        @input="atInput"
        @keyup.enter="atEnter($event, value)"
      />
      <span
        v-if="iconPosition == 'left' || iconPosition == 'right' ? true : false"
        class="input-icon"
        ><i :class="'icon-' + icon"></i
      ></span>
    </div>
    <span
      class="error-message number-validation"
      :style="
        displayError === true ? 'display: block' : 'display:none; height:0px'
      "
      style="height: 24px"
    >
      <span>{{ input_error_message }}</span>
    </span>
    <hlx-overlay
      v-show="show_calendar === true"
      :show="show_calendar"
      :top="containertop"
      :left="containerleft"
      :width="containerwidth"
      :conditionvalue="330"
      :conditiondown="10"
      :conditionup="330"
    >
      <template #overlay>
        <Calendar
          v-if="show_calendar"
          v-click-away="openCalendar"
          :disable-weekend="disableWeekend"
          :event="calendar_pos"
          :pre-value="calendarValue"
          :start-year="startYear"
          :end-year="endYear"
          :holiday-list="holidayList"
          @selected-date="receivedDate"
        />
      </template>
    </hlx-overlay>
  </div>

  <!-- <div class="input-container" v-if="this.type == 'datetime-local'">
      <div
        class="input-field"
        :id="'input-field' + this.unique"
      >
        <input
          type="datetime-local"
          :id="'inputdate' + this.unique"
          autocomplete="off"
          required
          v-model="this.value"
          @focus="focusDate"
          @blur="blurDate"
          @mouseenter="hoverDate"
          @mouseleave="unHoverDate"
        />
        <label
          :id="'label' + this.unique"
          :style="{ background: this.customBackground }"
          @mouseenter="hoverInput"
          :for="'input' + this.unique"
          >{{ this.labelValue }}<span style="color: #ff5050;" v-if="showMandatory">{{ ' *' }}</span></label
        >
        <span
          class="input-icon"
          v-if="
            this.iconPosition == 'left' || this.iconPosition == 'right'
              ? true
              : false
          "
          ><i :class="'icon-' + this.icon"></i
        ></span>
      </div>
    </div> -->

  <!--------------------------------------------------------- checkbox ----------------------------------------------->
  <div
    v-if="type == 'single-partial-checkbox'"
    style="width: 14px"
    class="whole-checkbox"
  >
    <!-- {{ partiallyChecked }} -->
    <label
      style="width: 14px"
      class="check-container"
      @mouseover="hoverSelectAll"
      @mouseleave="unHoverSelectAll"
    >
      <i
        v-if="partiallyValue == true"
        style="left: -24px"
        class="icon-minus-regular partially-checked"
      ></i>
      <input
        style="margin-right: 15px"
        type="checkbox"
        class="selcet"
        :disabled="disabled"
        :class="'select-all' + unique"
        :checked="checked"
        @click="handleSingleCheckBox"
      />
      <span
        :id="'select-all-checkmark' + unique"
        ref="select-all-checkmark"
        :class="partiallyValue ? 'select-all-theme-hover select-all-theme' : ''"
        class="checkmark"
        @mouseover="hoverSelectAll"
        @mouseleave="unHoverSelectAll"
      ></span>
    </label>
  </div>

  <div v-if="type == 'checkbox'" class="whole-checkbox">
    <label
      v-if="showSelectAll == true && optionsD.length > 0"
      class="check-container"
      @mouseover="hoverSelectAll"
      @mouseleave="unHoverSelectAll"
      >{{ "Select all" }}
      <i
        v-if="partiallySelected == true"
        class="icon-minus-regular partially-checked"
      ></i>
      <input
        style="margin-right: 15px"
        type="checkbox"
        class="selcet"
        :disabled="disabled"
        :class="'select-all' + unique"
        :checked="false"
        @click="selectAll"
      />
      <span
        :id="'select-all-checkmark' + unique"
        ref="select-all-checkmark"
        class="checkmark"
        @mouseover="hoverSelectAll"
        @mouseleave="unHoverSelectAll"
      ></span>
    </label>
    <div
      v-for="element in optionsD"
      :key="element.id"
      class="checkbox-container"
    >
      <label
        class="check-container"
        :class="{
          'checkContainer-readOnly': element.readOnly,
          'checkContainer-disabled': element.disabled,
        }"
      >
        <span v-if="!customSlot" class="ellipsis">
          <main style="width: 100%; text-wrap: wrap; word-wrap: break-word">
            <slot name="checkbox-body">
              {{ element[propValue] }}
            </slot>
          </main>
        </span>
        <span v-else class="ellipsis" style="width: 100%">
          <main>
            <slot name="checkbox-body">
              <div style="margin-top: 20px">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <span style="display: flex; align-items: center; gap: 16px">
                    <span
                      style="width: 100%; text-wrap: wrap; margin-right: 10px"
                      :style="
                        checkboxDescriptionProp === ''
                          ? { 'margin-bottom': '5px' }
                          : {}
                      "
                    >
                      {{ element[checkboxTitleProp] }}
                    </span>
                    <!-- <span style="color: #2879b7">
                        {{ element[checkboxSubTitleProp] }}
                      </span> -->
                  </span>
                  <span>{{ element[checkboxLabelProp] }}</span>
                </div>
                <div
                  v-if="checkboxDescriptionProp !== ''"
                  style="margin-top: 10px; color: #a6a6a6; height: 18px"
                >
                  {{ element[checkboxDescriptionProp] }}
                </div>
              </div>
            </slot>
          </main>
        </span>
        <input
          :id="'check-drag' + unique"
          type="checkbox"
          :checked="element.checked"
          :disabled="element.disabled"
          class="check-correct"
          :class="'check-drag' + unique"
          @click="checkItem(element, $event)"
        />
        <span
          class="checkmark"
          :style="
            customSlot === true && checkboxDescriptionProp !== ''
              ? { bottom: '30px' }
              : {}
          "
          :class="{
            'check-readOnly': element.readOnly,
            'check-disabled': element.disabled,
          }"
        ></span>
      </label>
    </div>
  </div>
  <!------------------------------------------------ single - checkbox-------------------------------------------->

  <!-- <div v-if="type == 'single-checkbox-example'" class="whole-checkbox">
      <label
          class="check-container"
          :class="{
            'checkContainer-readOnly': readOnly,
            'checkContainer-disabled': disabled,
          }"
        >
        
          <i
          v-if="partiallyChecked == true"
          style="left: -24px;"
          class="icon-minus-regular partially-checked"
        ></i>
          <input
            :id="'check-drag' + unique"
            type="checkbox"
            :checked="checked"
            :disabled="disabled"
            class="check-correct"
            :class="'check-drag' + unique"
            @click="handleSingleCheckBox"
          />
          <span
            class="checkmark"
            :class="{
  'select-all-theme-hover select-all-theme': partiallyChecked,
  'check-readOnly': readOnly,
  'check-disabled': disabled,
}"
          ></span>
        </label>
    </div> -->

  <div v-if="type == 'single-checkbox'" class="whole-checkbox">
    <!-- <label
        class="check-container"
        :class="{
          'checkContainer-readOnly': readOnly,
          'checkContainer-disabled': isDisabled,
        }"
      >
        <span class="ellipsis">{{ labelValue }}</span
        ><span v-if="showMandatory" style="color: #ff5050">{{ " *" }}</span>
        <input
          :id="'check-drag' + unique"
          type="checkbox"
          :checked="checked"
          :disabled="isDisabled"
          class="check-correct"
          :class="'check-drag' + unique"
          @click="handleSingleCheckBox"
        />
        <span
          class="checkmark"
          :class="{ 'check-readOnly': readOnly, 'check-disabled': isDisabled }"
        ></span>
      </label> -->
    <label
      class="check-container"
      :class="{
        'checkContainer-readOnly': readOnly,
        'checkContainer-disabled': disabled,
      }"
    >
      <span v-if="!customSlot" class="ellipsis">
        <main style="width: 100%; text-wrap: wrap; word-wrap: break-word">
          <slot name="checkbox-body">
            {{ labelValue }}
          </slot>
        </main>
      </span>
      <input
        :id="'check-drag' + unique"
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
        class="check-correct"
        :class="'check-drag' + unique"
        @click="handleSingleCheckBox"
      />
      <span
        class="checkmark"
        :class="{
          'check-readOnly': readOnly,
          'check-disabled': disabled,
        }"
      ></span>
    </label>
  </div>
  <!------------------------------------------------------Single Radio ---------------------------------------------->
  <div
    v-if="type == 'single-radio'"
    :style="
      position === 'horizontal'
        ? 'display: flex; flex-direction: row'
        : 'display: flex; flex-direction: column'
    "
  >
    <label
      :id="'radio' + unique"
      class="radio-container"
      :class="{ paddingTop: bellTheme, isDisabled: isDisabled }"
      :style="position === 'horizontal' ? 'margin-right: 20px;' : ''"
    >
      <span class="ellipsis"
        ><i :class="'icon-' + icon"></i> &nbsp; {{ labelValue }}</span
      ><span v-if="showMandatory" style="color: #ff5050">{{ " *" }}</span>
      <input
        :id="unique"
        type="radio"
        :name="name"
        :for="'radio' + unique"
        :checked="checked"
        :disabled="isDisabled"
        @click="handleSingleRadio"
      />
      <span
        class="radio-checkmark"
        :class="{ bellTheme: bellTheme, disabled: isDisabled }"
      ></span>
    </label>
  </div>
  <!--------------------------------------------------------- Radio ----------------------------------------------->
  <div
    v-if="type == 'radio-buttons'"
    :style="
      position === 'horizontal'
        ? 'display: flex; flex-direction: row; flex-flow: wrap; gap: 10px;'
        : 'display: flex; flex-direction: column'
    "
  >
    <div v-for="element in optionsD" :key="element.id">
      <label
        :id="'radio' + element.id + unique"
        class="radio-container"
        :class="{ paddingTop: bellTheme, isDisabled: isDisabled }"
        :style="
          position === 'horizontal'
            ? 'margin-right: 20px; display:flex; align-items: center'
            : ''
        "
      >
        <span class="ellipsis">
          <main>
            <slot name="radio-buttons-body">
              <i :class="'icon-' + element.icon"></i> &nbsp;
              {{ element[propValue] }}
            </slot>
          </main>
        </span>
        <input
          :id="element.id + unique"
          type="radio"
          :name="'radio' + unique"
          :disabled="isDisabled"
          :for="'radio' + element.id + unique"
          :checked="element.checked"
          @click="checkRadio(element)"
        />
        <span
          class="radio-checkmark"
          :class="{ bellTheme: bellTheme, disabled: isDisabled }"
          :style="position === 'horizontal' ? 'margin-top: 6px' : ''"
        ></span>
      </label>
    </div>
  </div>

  <!-------------------------------------------------------Border Multi Radio --------------------------------------->
  <div class="Border_Multi_Radio">
    <div
      v-if="type == 'border-radio-buttons'"
      :style="
        position === 'horizontal'
          ? 'display: flex; flex-direction: row; flex-flow: row wrap;'
          : 'display: flex; flex-direction: column'
      "
      style="gap: 10px"
    >
      <div
        v-for="element in optionsD"
        :key="element.id"
        style="
          padding: 6px;
          border: 1px solid #d3d3d3;
          width: max-content;
          border-radius: 3px;
          min-height: 33px !important;
        "
      >
        <label
          :id="'radio' + element.id + unique"
          class="radio-container"
          :class="{ paddingTop: bellTheme, isDisabled: isDisabled }"
          :style="
            position === 'horizontal'
              ? 'margin-right: 20px; display:flex; align-items: center'
              : ''
          "
          style="height: max-content"
        >
          <span
            class="border_radio_button_ellipsis"
            :class="{ border_ellipsis: showEllipsis }"
            ><i :class="'icon-' + element.icon"></i> &nbsp;
            {{ element[propValue] }}</span
          >
          <input
            :id="element.id + unique"
            type="radio"
            :name="'radio' + unique"
            :disabled="isDisabled"
            :for="'radio' + element.id + unique"
            :checked="element.checked"
            @click="checkRadio(element)"
          />
          <span
            class="radio-checkmark"
            :class="{ bellTheme: bellTheme, disabled: isDisabled }"
            :style="position === 'horizontal' ? '' : ''"
          ></span>
        </label>
      </div>
    </div>
  </div>
  <!--------------------------------------------------------- Switch ----------------------------------------------->
  <div v-if="type == 'switch'" style="margin: 0 10px 0 15px">
    <label :class="'switch' + unique">
      <input
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
        @click="checkSwitch"
      />
      <span :id="readOnly ? 'switchDisabled' : ''" class="slider round"></span>
    </label>
  </div>
</template>

<script>
import Calendar from "./CalendarComponent.vue";
// import hlxselect from "../components/SelectComponent.vue";
import { directive } from "vue3-click-away";
import hlxOverlay from "../components/OverlayComponent.vue";
// import axios from "axios";
import hlxSearch from "../components/SearchComponent.vue";
import countriesJson from "../assets/data/countries.json";

import {
  isValidPhoneNumber,
  validatePhoneNumberLength,
  AsYouType,
} from "libphonenumber-js";

export default {
  name: "HlxInput",
  components: {
    Calendar,
    // hlxselect,
    hlxOverlay,
    hlxSearch,
  },
  directives: {
    ClickAway: directive,
  },
  inheritAttrs: false,
  props: {
    lookupProp: {
      type: String,
      default: "description",
    },
    countryLabelvalue: {
      type: String,
      default: "Country",
    },
    defaultMailError: {
      type: String,
      default: "Please enter a valid email in the format: abc@gmail.com",
    },
    defaultNumberError: {
      type: String,
      default: "Enter a valid phone number",
    },
    // intelliOptions: {
    //   type: Array
    // },
    customSlotSuffix: {
      type: Boolean,
      default: false,
    },
    customSlotPrefix: {
      type: Boolean,
      default: false,
    },
    customSlot: {
      type: Boolean,
      default: false,
    },
    checkboxTitleProp: {
      type: String,
      default: "",
    },
    checkboxSubTitleProp: {
      type: String,
      default: "",
    },
    checkboxDescriptionProp: {
      type: String,
      default: "",
    },
    checkboxLabelProp: {
      type: String,
      default: "",
    },
    focus: {
      type: Boolean,
      default: false,
    },
    singleBorder: {
      type: Boolean,
      default: false,
    },
    step: {
      type: String,
      default: "1",
    },
    countryCode: {
      type: Object,
      default: () => {
        return {
          name: "",
          dial_code: "",
          flags: "",
          short: "",
        };
      },
    },
    dateFormat: {
      type: String,
      default: "dd (short month), yyyy",
    },
    characterLimit: {
      type: Number,
      default: null,
    },
    customErrorMessage: {
      type: String,
      default: "",
    },
    customError: {
      type: Boolean,
      default: false,
    },
    bellTheme: {
      type: Boolean,
      default: false,
    },
    customBackground: {
      type: String,
      default: "#ffffff",
    },
    labelAnimation: {
      type: Boolean,
      default: false,
    },
    labelValue: {
      type: String,
      default: "",
    },
    propValue: {
      type: String,
      default: "name",
    },
    type: {
      type: String,
      default: "text",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconPosition: {
      type: String,
      default: "",
    },
    minLimit: {
      type: Number,
      default: null,
    },
    maxLimit: {
      type: Number,
      default: null,
    },
    passwordCondition: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "",
    },
    minLength: {
      type: Number,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    preVal: {
      type: [String, Number, Boolean, Object],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showEllipsis: {
      type: Boolean,
      default: true, // Default value to show ellipses
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    displayError: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checked: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
    startYear: {
      type: Number,
      default: 1900,
    },
    endYear: {
      type: Number,
      default: 2100,
    },
    disableWeekend: {
      type: Boolean,
      default: false,
    },
    holidayList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    position: {
      type: String,
      default: "vertical",
    },
    countryCodes: {
      type: Array,
      default: () => [
        {
          id: 1,
          flag: "in",
          name: "India",
          value: "India",
          code: "+91",
          checked: false,
        },
        {
          id: 2,
          flag: "ca",
          name: "Canada",
          value: "Canada",
          code: "+1",
          checked: false,
        },
        {
          id: 3,
          flag: "us",
          name: "USA",
          value: "USA",
          code: "+1",
          checked: false,
        },
        {
          id: 4,
          flag: "cn",
          name: "China",
          value: "China",
          code: "+86",
          checked: false,
        },
        {
          id: 5,
          flag: "jp",
          name: "Japan",
          value: "Japan",
          code: "+81",
          checked: false,
        },
        {
          id: 6,
          flag: "kr",
          name: "South Korea",
          value: "South Korea",
          code: "+82",
          checked: false,
        },
        {
          id: 7,
          flag: "br",
          name: "Brazil",
          value: "Brazil",
          code: "+55",
          checked: false,
        },
        {
          id: 8,
          flag: "mx",
          name: "Mexico",
          value: "Mexico",
          code: "+52",
          checked: false,
        },
        {
          id: 9,
          flag: "ru",
          name: "Russia",
          value: "Russia",
          code: "+7",
          checked: false,
        },
        {
          id: 10,
          flag: "au",
          name: "Australia",
          value: "Australia",
          code: "+61",
          checked: false,
        },
        {
          id: 11,
          flag: "gb",
          name: "United Kingdom",
          value: "United Kingdom",
          code: "+44",
          checked: false,
        },
        {
          id: 12,
          flag: "fr",
          name: "France",
          value: "France",
          code: "+33",
          checked: false,
        },
        {
          id: 13,
          flag: "de",
          name: "Germany",
          value: "Germany",
          code: "+49",
          checked: false,
        },
      ],
    },
    prefix: {
      type: Boolean,
      default: false,
    },
    partiallyChecked: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: Boolean,
      default: false,
    },
    suffixselect: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 4,
    },
    locationDataProp: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showNewAddressOption: {
      type: Boolean,
      default: false,
    },
    symbol: {
      type: String,
      default: "",
    },
  },
  emits: [
    "at-enter",
    "at-input",
    "selected",
    "update:value",
    "update:name",
    "focus-out",
    "change",
    "check-box-event",
    "radio-event",
    "iso-date",
    "add-new-address",
    "postal-code",
    "address-data",
    "object-emit",
    "formattedDate",
    "at-focus",
    "partially-value",
  ],
  data() {
    return {
      previousTelephoneInput: "",
      partiallyValue: false,
      showLocationOptions: false,
      containerwidth: 0,
      containertop: 0,
      containerleft: 0,
      containerheight: 200,
      locationData: [],
      new_data: [
        {
          id: 1,
          flag: "in",
          name: "India",
          value: "India",
          checked: false,
        },
        {
          id: 2,
          flag: "ca",
          name: "Canada",
          value: "Canada",
          code: "+1",
          checked: false,
        },
        {
          id: 3,
          flag: "us",
          name: "USA",
          code: "+1",
          checked: false,
          value: "USA",
        },
        {
          id: 4,
          flag: "cn",
          name: "China",
          code: "+86",
          checked: false,
          value: "China",
        },
        {
          id: 5,
          flag: "jp",
          name: "Japan",
          code: "+81",
          checked: false,
          value: "Japan",
        },
        {
          id: 6,
          flag: "kr",
          name: "South Korea",
          code: "+82",
          checked: false,
          value: "South Korea",
        },
        {
          id: 7,
          flag: "br",
          name: "Brazil",
          code: "+55",
          checked: false,
          value: "Brazil",
        },
        {
          id: 8,
          flag: "mx",
          name: "Mexico",
          code: "+52",
          checked: false,
          value: "Mexico",
        },
        {
          id: 9,
          flag: "ru",
          name: "Russia",
          code: "+7",
          checked: false,
          value: "Russia",
        },
        {
          id: 10,
          flag: "au",
          name: "Australia",
          code: "+61",
          checked: false,
          value: "Australia",
        },
        {
          id: 11,
          flag: "gb",
          name: "United Kingdom",
          code: "+44",
          checked: false,
          value: "United Kingdom",
        },
        {
          id: 12,
          flag: "fr",
          name: "France",
          code: "+33",
          checked: false,
          value: "France",
        },
        {
          id: 13,
          flag: "de",
          name: "Germany",
          code: "+49",
          checked: false,
          value: "Germany",
        },
      ],
      calendar_pos: {},
      value: "",
      input_error_message: "",
      show_password_condition: false,
      minimum: "",
      maximum: "",
      unique: Math.floor(Math.random() * 1000 + 1),
      show_calendar: false,
      country_code: "",
      root_style: {},
      country_code_data: [
        {
          id: 1,
          flag: "in",
          name: "India",
          code: "+91",
          checked: false,
        },
        {
          id: 2,
          flag: "ca",
          name: "Canada",
          code: "+1",
          checked: false,
        },
        {
          id: 3,
          flag: "us",
          name: "USA",
          code: "+1",
          checked: false,
        },
      ],
      select_all: false,
      all_checked: true,
      partially_checked: false,
      atleastOne: false,
      typing: false,
      // intelliData: [],
      small: true,
      showMandatory: false,
      optionsD: [],
      isDisabledVar: false,
      countries: [],
      new_country_codes: [
        {
          name: "Afghanistan",
          dial_code: "+93",
          code: "AF",
          maxLength: 9,
        },
        {
          name: "Aland Islands",
          dial_code: "+358",
          code: "AX",
          maxLength: 17,
        },
        {
          name: "Albania",
          dial_code: "+355",
          code: "AL",
          maxLength: 9,
        },
        {
          name: "Algeria",
          dial_code: "+213",
          code: "DZ",
          maxLength: 9,
        },
        {
          name: "AmericanSamoa",
          dial_code: "+1684",
          code: "AS",
          maxLength: 17,
        },
        {
          name: "Andorra",
          dial_code: "+376",
          code: "AD",
          maxLength: 9,
        },
        {
          name: "Angola",
          dial_code: "+244",
          code: "AO",
          maxLength: 9,
        },
        {
          name: "Anguilla",
          dial_code: "+1264",
          code: "AI",
          maxLength: 10,
        },
        {
          name: "Antarctica",
          dial_code: "+672",
          code: "AQ",
          maxLength: 17,
        },
        {
          name: "Antigua and Barbuda",
          dial_code: "+1268",
          code: "AG",
          maxLength: 10,
        },
        {
          name: "Argentina",
          dial_code: "+54",
          code: "AR",
          maxLength: 10,
        },
        {
          name: "Armenia",
          dial_code: "+374",
          code: "AM",
          maxLength: 8,
        },
        {
          name: "Aruba",
          dial_code: "+297",
          code: "AW",
          maxLength: 7,
        },
        {
          name: "Australia",
          dial_code: "+61",
          code: "AU",
          maxLength: 15,
        },
        {
          name: "Austria",
          dial_code: "+43",
          code: "AT",
          maxLength: 13,
        },
        {
          name: "Azerbaijan",
          dial_code: "+994",
          code: "AZ",
          maxLength: 9,
        },
        {
          name: "Bahamas",
          dial_code: "+1242",
          code: "BS",
          maxLength: 10,
        },
        {
          name: "Bahrain",
          dial_code: "+973",
          code: "BH",
          maxLength: 8,
        },
        {
          name: "Bangladesh",
          dial_code: "+880",
          code: "BD",
          maxLength: 10,
        },
        {
          name: "Barbados",
          dial_code: "+1246",
          code: "BB",
          maxLength: 10,
        },
        {
          name: "Belarus",
          dial_code: "+375",
          code: "BY",
          maxLength: 10,
        },
        {
          name: "Belgium",
          dial_code: "+32",
          code: "BE",
          maxLength: 9,
        },
        {
          name: "Belize",
          dial_code: "+501",
          code: "BZ",
          maxLength: 7,
        },
        {
          name: "Benin",
          dial_code: "+229",
          code: "BJ",
          maxLength: 8,
        },
        {
          name: "Bermuda",
          dial_code: "+1441",
          code: "BM",
          maxLength: 10,
        },
        {
          name: "Bhutan",
          dial_code: "+975",
          code: "BT",
          maxLength: 8,
        },
        {
          name: "Bolivia, Plurinational State of",
          dial_code: "+591",
          code: "BO",
          maxLength: 17,
        },
        {
          name: "Bosnia and Herzegovina",
          dial_code: "+387",
          code: "BA",
          maxLength: 8,
        },
        {
          name: "Botswana",
          dial_code: "+267",
          code: "BW",
          maxLength: 8,
        },
        {
          name: "Brazil",
          dial_code: "+55",
          code: "BR",
          maxLength: 10,
        },
        {
          name: "British Indian Ocean Territory",
          dial_code: "+246",
          code: "IO",
          maxLength: 17,
        },
        {
          name: "Brunei Darussalam",
          dial_code: "+673",
          code: "BN",
          maxLength: 17,
        },
        {
          name: "Bulgaria",
          dial_code: "+359",
          code: "BG",
          maxLength: 9,
        },
        {
          name: "Burkina Faso",
          dial_code: "+226",
          code: "BF",
          maxLength: 8,
        },
        {
          name: "Burundi",
          dial_code: "+257",
          code: "BI",
          maxLength: 8,
        },
        {
          name: "Cambodia",
          dial_code: "+855",
          code: "KH",
          maxLength: 8,
        },
        {
          name: "Cameroon",
          dial_code: "+237",
          code: "CM",
          maxLength: 8,
        },
        {
          name: "Canada",
          dial_code: "+1",
          code: "CA",
          maxLength: 10,
        },
        {
          name: "Cape Verde",
          dial_code: "+238",
          code: "CV",
          maxLength: 7,
        },
        {
          name: "Cayman Islands",
          dial_code: "+ 345",
          code: "KY",
          maxLength: 10,
        },
        {
          name: "Central African Republic",
          dial_code: "+236",
          code: "CF",
          maxLength: 8,
        },
        {
          name: "Chad",
          dial_code: "+235",
          code: "TD",
          maxLength: 8,
        },
        {
          name: "Chile",
          dial_code: "+56",
          code: "CL",
          maxLength: 9,
        },
        {
          name: "China",
          dial_code: "+86",
          code: "CN",
          maxLength: 12,
        },
        {
          name: "Christmas Island",
          dial_code: "+61",
          code: "CX",
          maxLength: 17,
        },
        {
          name: "Cocos (Keeling) Islands",
          dial_code: "+61",
          code: "CC",
          maxLength: 17,
        },
        {
          name: "Colombia",
          dial_code: "+57",
          code: "CO",
          maxLength: 10,
        },
        {
          name: "Comoros",
          dial_code: "+269",
          code: "KM",
          maxLength: 7,
        },
        {
          name: "Congo",
          dial_code: "+242",
          code: "CG",
          maxLength: 17,
        },
        {
          name: "Congo, The Democratic Republic of the Congo",
          dial_code: "+243",
          code: "CD",
          maxLength: 17,
        },
        {
          name: "Cook Islands",
          dial_code: "+682",
          code: "CK",
          maxLength: 5,
        },
        {
          name: "Costa Rica",
          dial_code: "+506",
          code: "CR",
          maxLength: 8,
        },
        {
          name: "Cote d'Ivoire",
          dial_code: "+225",
          code: "CI",
          maxLength: 17,
        },
        {
          name: "Croatia",
          dial_code: "+385",
          code: "HR",
          maxLength: 12,
        },
        {
          name: "Cuba",
          dial_code: "+53",
          code: "CU",
          maxLength: 8,
        },
        {
          name: "Cyprus",
          dial_code: "+357",
          code: "CY",
          maxLength: 11,
        },
        {
          name: "Czech Republic",
          dial_code: "+420",
          code: "CZ",
          maxLength: 12,
        },
        {
          name: "Denmark",
          dial_code: "+45",
          code: "DK",
          maxLength: 8,
        },
        {
          name: "Djibouti",
          dial_code: "+253",
          code: "DJ",
          maxLength: 6,
        },
        {
          name: "Dominica",
          dial_code: "+1767",
          code: "DM",
          maxLength: 10,
        },
        {
          name: "Dominican Republic",
          dial_code: "+1849",
          code: "DO",
          maxLength: 10,
        },
        {
          name: "Ecuador",
          dial_code: "+593",
          code: "EC",
          maxLength: 8,
        },
        {
          name: "Egypt",
          dial_code: "+20",
          code: "EG",
          maxLength: 9,
        },
        {
          name: "El Salvador",
          dial_code: "+503",
          code: "SV",
          maxLength: 11,
        },
        {
          name: "Equatorial Guinea",
          dial_code: "+240",
          code: "GQ",
          maxLength: 9,
        },
        {
          name: "Eritrea",
          dial_code: "+291",
          code: "ER",
          maxLength: 7,
        },
        {
          name: "Estonia",
          dial_code: "+372",
          code: "EE",
          maxLength: 10,
        },
        {
          name: "Ethiopia",
          dial_code: "+251",
          code: "ET",
          maxLength: 9,
        },
        {
          name: "Falkland Islands (Malvinas)",
          dial_code: "+500",
          code: "FK",
          maxLength: 17,
        },
        {
          name: "Faroe Islands",
          dial_code: "+298",
          code: "FO",
          maxLength: 6,
        },
        {
          name: "Fiji",
          dial_code: "+679",
          code: "FJ",
          maxLength: 7,
        },
        {
          name: "Finland",
          dial_code: "+358",
          code: "FI",
          maxLength: 12,
        },
        {
          name: "France",
          dial_code: "+33",
          code: "FR",
          maxLength: 9,
        },
        {
          name: "French Guiana",
          dial_code: "+594",
          code: "GF",
          maxLength: 9,
        },
        {
          name: "French Polynesia",
          dial_code: "+689",
          code: "PF",
          maxLength: 6,
        },
        {
          name: "Gabon",
          dial_code: "+241",
          code: "GA",
          maxLength: 7,
        },
        {
          name: "Gambia",
          dial_code: "+220",
          code: "GM",
          maxLength: 7,
        },
        {
          name: "Georgia",
          dial_code: "+995",
          code: "GE",
          maxLength: 9,
        },
        {
          name: "Germany",
          dial_code: "+49",
          code: "DE",
          maxLength: 13,
        },
        {
          name: "Ghana",
          dial_code: "+233",
          code: "GH",
          maxLength: 9,
        },
        {
          name: "Gibraltar",
          dial_code: "+350",
          code: "GI",
          maxLength: 8,
        },
        {
          name: "Greece",
          dial_code: "+30",
          code: "GR",
          maxLength: 10,
        },
        {
          name: "Greenland",
          dial_code: "+299",
          code: "GL",
          maxLength: 6,
        },
        {
          name: "Grenada",
          dial_code: "+1473",
          code: "GD",
          maxLength: 10,
        },
        {
          name: "Guadeloupe",
          dial_code: "+590",
          code: "GP",
          maxLength: 9,
        },
        {
          name: "Guam",
          dial_code: "+1671",
          code: "GU",
          maxLength: 10,
        },
        {
          name: "Guatemala",
          dial_code: "+502",
          code: "GT",
          maxLength: 8,
        },
        {
          name: "Guernsey",
          dial_code: "+44",
          code: "GG",
          maxLength: 17,
        },
        {
          name: "Guinea",
          dial_code: "+224",
          code: "GN",
          maxLength: 8,
        },
        {
          name: "Guinea-Bissau",
          dial_code: "+245",
          code: "GW",
          maxLength: 17,
        },
        {
          name: "Guyana",
          dial_code: "+592",
          code: "GY",
          maxLength: 7,
        },
        {
          name: "Haiti",
          dial_code: "+509",
          code: "HT",
          maxLength: 8,
        },
        {
          name: "Holy See (Vatican City State)",
          dial_code: "+379",
          code: "VA",
          maxLength: 17,
        },
        {
          name: "Honduras",
          dial_code: "+504",
          code: "HN",
          maxLength: 8,
        },
        {
          name: "Hong Kong",
          dial_code: "+852",
          code: "HK",
          maxLength: 9,
        },
        {
          name: "Hungary",
          dial_code: "+36",
          code: "HU",
          maxLength: 9,
        },
        {
          name: "Iceland",
          dial_code: "+354",
          code: "IS",
          maxLength: 9,
        },
        {
          name: "India",
          dial_code: "+91",
          code: "IN",
          maxLength: 10,
        },
        {
          name: "Indonesia",
          dial_code: "+62",
          code: "ID",
          maxLength: 10,
        },
        {
          name: "Iran, Islamic Republic of Persian Gulf",
          dial_code: "+98",
          code: "IR",
          maxLength: 17,
        },
        {
          name: "Iraq",
          dial_code: "+964",
          code: "IQ",
          maxLength: 10,
        },
        {
          name: "Ireland",
          dial_code: "+353",
          code: "IE",
          maxLength: 11,
        },
        {
          name: "Isle of Man",
          dial_code: "+44",
          code: "IM",
          maxLength: 17,
        },
        {
          name: "Israel",
          dial_code: "+972",
          code: "IL",
          maxLength: 9,
        },
        {
          name: "Italy",
          dial_code: "+39",
          code: "IT",
          maxLength: 11,
        },
        {
          name: "Jamaica",
          dial_code: "+1876",
          code: "JM",
          maxLength: 10,
        },
        {
          name: "Japan",
          dial_code: "+81",
          code: "JP",
          maxLength: 13,
        },
        {
          name: "Jersey",
          dial_code: "+44",
          code: "JE",
          maxLength: 17,
        },
        {
          name: "Jordan",
          dial_code: "+962",
          code: "JO",
          maxLength: 9,
        },
        {
          name: "Kazakhstan",
          dial_code: "+7",
          code: "KZ",
          maxLength: 10,
        },
        {
          name: "Kenya",
          dial_code: "+254",
          code: "KE",
          maxLength: 10,
        },
        {
          name: "Kiribati",
          dial_code: "+686",
          code: "KI",
          maxLength: 5,
        },
        {
          name: "Korea, Democratic People's Republic of Korea",
          dial_code: "+850",
          code: "KP",
          maxLength: 17,
        },
        {
          name: "Korea, Republic of South Korea",
          dial_code: "+82",
          code: "KR",
          maxLength: 17,
        },
        {
          name: "Kosovo",
          dial_code: "+383",
          code: "XK",
          maxLength: 17,
        },
        {
          name: "Kuwait",
          dial_code: "+965",
          code: "KW",
          maxLength: 8,
        },
        {
          name: "Kyrgyzstan",
          dial_code: "+996",
          code: "KG",
          maxLength: 9,
        },
        {
          name: "Laos",
          dial_code: "+856",
          code: "LA",
          maxLength: 10,
        },
        {
          name: "Latvia",
          dial_code: "+371",
          code: "LV",
          maxLength: 8,
        },
        {
          name: "Lebanon",
          dial_code: "+961",
          code: "LB",
          maxLength: 8,
        },
        {
          name: "Lesotho",
          dial_code: "+266",
          code: "LS",
          maxLength: 8,
        },
        {
          name: "Liberia",
          dial_code: "+231",
          code: "LR",
          maxLength: 8,
        },
        {
          name: "Libyan Arab Jamahiriya",
          dial_code: "+218",
          code: "LY",
          maxLength: 17,
        },
        {
          name: "Liechtenstein",
          dial_code: "+423",
          code: "LI",
          maxLength: 9,
        },
        {
          name: "Lithuania",
          dial_code: "+370",
          code: "LT",
          maxLength: 8,
        },
        {
          name: "Luxembourg",
          dial_code: "+352",
          code: "LU",
          maxLength: 11,
        },
        {
          name: "Macao",
          dial_code: "+853",
          code: "MO",
          maxLength: 17,
        },
        {
          name: "Macedonia",
          dial_code: "+389",
          code: "MK",
          maxLength: 17,
        },
        {
          name: "Madagascar",
          dial_code: "+261",
          code: "MG",
          maxLength: 10,
        },
        {
          name: "Malawi",
          dial_code: "+265",
          code: "MW",
          maxLength: 8,
        },
        {
          name: "Malaysia",
          dial_code: "+60",
          code: "MY",
          maxLength: 9,
        },
        {
          name: "Maldives",
          dial_code: "+960",
          code: "MV",
          maxLength: 7,
        },
        {
          name: "Mali",
          dial_code: "+223",
          code: "ML",
          maxLength: 8,
        },
        {
          name: "Malta",
          dial_code: "+356",
          code: "MT",
          maxLength: 8,
        },
        {
          name: "Marshall Islands",
          dial_code: "+692",
          code: "MH",
          maxLength: 7,
        },
        {
          name: "Martinique",
          dial_code: "+596",
          code: "MQ",
          maxLength: 9,
        },
        {
          name: "Mauritania",
          dial_code: "+222",
          code: "MR",
          maxLength: 7,
        },
        {
          name: "Mauritius",
          dial_code: "+230",
          code: "MU",
          maxLength: 7,
        },
        {
          name: "Mayotte",
          dial_code: "+262",
          code: "YT",
          maxLength: 17,
        },
        {
          name: "Mexico",
          dial_code: "+52",
          code: "MX",
          maxLength: 10,
        },
        {
          name: "Micronesia, Federated States of Micronesia",
          dial_code: "+691",
          code: "FM",
          maxLength: 17,
        },
        {
          name: "Moldova",
          dial_code: "+373",
          code: "MD",
          maxLength: 8,
        },
        {
          name: "Monaco",
          dial_code: "+377",
          code: "MC",
          maxLength: 9,
        },
        {
          name: "Mongolia",
          dial_code: "+976",
          code: "MN",
          maxLength: 8,
        },
        {
          name: "Montenegro",
          dial_code: "+382",
          code: "ME",
          maxLength: 12,
        },
        {
          name: "Montserrat",
          dial_code: "+1664",
          code: "MS",
          maxLength: 10,
        },
        {
          name: "Morocco",
          dial_code: "+212",
          code: "MA",
          maxLength: 9,
        },
        {
          name: "Mozambique",
          dial_code: "+258",
          code: "MZ",
          maxLength: 9,
        },
        {
          name: "Myanmar",
          dial_code: "+95",
          code: "MM",
          maxLength: 9,
        },
        {
          name: "Namibia",
          dial_code: "+264",
          code: "NA",
          maxLength: 10,
        },
        {
          name: "Nauru",
          dial_code: "+674",
          code: "NR",
          maxLength: 7,
        },
        {
          name: "Nepal",
          dial_code: "+977",
          code: "NP",
          maxLength: 9,
        },
        {
          name: "Netherlands",
          dial_code: "+31",
          code: "NL",
          maxLength: 9,
        },
        {
          name: "Netherlands Antilles",
          dial_code: "+599",
          code: "AN",
          maxLength: 17,
        },
        {
          name: "New Caledonia",
          dial_code: "+687",
          code: "NC",
          maxLength: 6,
        },
        {
          name: "New Zealand",
          dial_code: "+64",
          code: "NZ",
          maxLength: 10,
        },
        {
          name: "Nicaragua",
          dial_code: "+505",
          code: "NI",
          maxLength: 8,
        },
        {
          name: "Niger",
          dial_code: "+227",
          code: "NE",
          maxLength: 8,
        },
        {
          name: "Nigeria",
          dial_code: "+234",
          code: "NG",
          maxLength: 10,
        },
        {
          name: "Niue",
          dial_code: "+683",
          code: "NU",
          maxLength: 4,
        },
        {
          name: "Norfolk Island",
          dial_code: "+672",
          code: "NF",
          maxLength: 17,
        },
        {
          name: "Northern Mariana Islands",
          dial_code: "+1670",
          code: "MP",
          maxLength: 17,
        },
        {
          name: "Norway",
          dial_code: "+47",
          code: "NO",
          maxLength: 8,
        },
        {
          name: "Oman",
          dial_code: "+968",
          code: "OM",
          maxLength: 8,
        },
        {
          name: "Pakistan",
          dial_code: "+92",
          code: "PK",
          maxLength: 11,
        },
        {
          name: "Palau",
          dial_code: "+680",
          code: "PW",
          maxLength: 7,
        },
        {
          name: "Palestinian Territory, Occupied",
          dial_code: "+970",
          code: "PS",
          maxLength: 17,
        },
        {
          name: "Panama",
          dial_code: "+507",
          code: "PA",
          maxLength: 8,
        },
        {
          name: "Papua New Guinea",
          dial_code: "+675",
          code: "PG",
          maxLength: 11,
        },
        {
          name: "Paraguay",
          dial_code: "+595",
          code: "PY",
          maxLength: 9,
        },
        {
          name: "Peru",
          dial_code: "+51",
          code: "PE",
          maxLength: 11,
        },
        {
          name: "Philippines",
          dial_code: "+63",
          code: "PH",
          maxLength: 10,
        },
        {
          name: "Pitcairn",
          dial_code: "+64",
          code: "PN",
          maxLength: 17,
        },
        {
          name: "Poland",
          dial_code: "+48",
          code: "PL",
          maxLength: 9,
        },
        {
          name: "Portugal",
          dial_code: "+351",
          code: "PT",
          maxLength: 11,
        },
        {
          name: "Puerto Rico",
          dial_code: "+1939",
          code: "PR",
          maxLength: 10,
        },
        {
          name: "Qatar",
          dial_code: "+974",
          code: "QA",
          maxLength: 8,
        },
        {
          name: "Romania",
          dial_code: "+40",
          code: "RO",
          maxLength: 9,
        },
        {
          name: "Russia",
          dial_code: "+7",
          code: "RU",
          maxLength: 10,
        },
        {
          name: "Rwanda",
          dial_code: "+250",
          code: "RW",
          maxLength: 9,
        },
        {
          name: "Reunion",
          dial_code: "+262",
          code: "RE",
          maxLength: 17,
        },
        {
          name: "Saint Barthelemy",
          dial_code: "+590",
          code: "BL",
          maxLength: 17,
        },
        {
          name: "Saint Helena, Ascension and Tristan Da Cunha",
          dial_code: "+290",
          code: "SH",
          maxLength: 17,
        },
        {
          name: "Saint Kitts and Nevis",
          dial_code: "+1869",
          code: "KN",
          maxLength: 10,
        },
        {
          name: "Saint Lucia",
          dial_code: "+1758",
          code: "LC",
          maxLength: 10,
        },
        {
          name: "Saint Martin",
          dial_code: "+590",
          code: "MF",
          maxLength: 17,
        },
        {
          name: "Saint Pierre and Miquelon",
          dial_code: "+508",
          code: "PM",
          maxLength: 6,
        },
        {
          name: "Saint Vincent and the Grenadines",
          dial_code: "+1784",
          code: "VC",
          maxLength: 10,
        },
        {
          name: "Samoa",
          dial_code: "+685",
          code: "WS",
          maxLength: 7,
        },
        {
          name: "San Marino",
          dial_code: "+378",
          code: "SM",
          maxLength: 10,
        },
        {
          name: "Sao Tome and Principe",
          dial_code: "+239",
          code: "ST",
          maxLength: 7,
        },
        {
          name: "Saudi Arabia",
          dial_code: "+966",
          code: "SA",
          maxLength: 9,
        },
        {
          name: "Senegal",
          dial_code: "+221",
          code: "SN",
          maxLength: 9,
        },
        {
          name: "Serbia",
          dial_code: "+381",
          code: "RS",
          maxLength: 12,
        },
        {
          name: "Seychelles",
          dial_code: "+248",
          code: "SC",
          maxLength: 7,
        },
        {
          name: "Sierra Leone",
          dial_code: "+232",
          code: "SL",
          maxLength: 8,
        },
        {
          name: "Singapore",
          dial_code: "+65",
          code: "SG",
          maxLength: 12,
        },
        {
          name: "Slovakia",
          dial_code: "+421",
          code: "SK",
          maxLength: 9,
        },
        {
          name: "Slovenia",
          dial_code: "+386",
          code: "SI",
          maxLength: 8,
        },
        {
          name: "Solomon Islands",
          dial_code: "+677",
          code: "SB",
          maxLength: 5,
        },
        {
          name: "Somalia",
          dial_code: "+252",
          code: "SO",
          maxLength: 8,
        },
        {
          name: "South Africa",
          dial_code: "+27",
          code: "ZA",
          maxLength: 9,
        },
        {
          name: "South Sudan",
          dial_code: "+211",
          code: "SS",
          maxLength: 17,
        },
        {
          name: "South Georgia and the South Sandwich Islands",
          dial_code: "+500",
          code: "GS",
          maxLength: 17,
        },
        {
          name: "Spain",
          dial_code: "+34",
          code: "ES",
          maxLength: 9,
        },
        {
          name: "Sri Lanka",
          dial_code: "+94",
          code: "LK",
          maxLength: 9,
        },
        {
          name: "Sudan",
          dial_code: "+249",
          code: "SD",
          maxLength: 9,
        },
        {
          name: "Suriname",
          dial_code: "+597",
          code: "SR",
          maxLength: 7,
        },
        {
          name: "Svalbard and Jan Mayen",
          dial_code: "+47",
          code: "SJ",
          maxLength: 17,
        },
        {
          name: "Swaziland",
          dial_code: "+268",
          code: "SZ",
          maxLength: 17,
        },
        {
          name: "Sweden",
          dial_code: "+46",
          code: "SE",
          maxLength: 13,
        },
        {
          name: "Switzerland",
          dial_code: "+41",
          code: "CH",
          maxLength: 12,
        },
        {
          name: "Syrian Arab Republic",
          dial_code: "+963",
          code: "SY",
          maxLength: 17,
        },
        {
          name: "Taiwan",
          dial_code: "+886",
          code: "TW",
          maxLength: 9,
        },
        {
          name: "Tajikistan",
          dial_code: "+992",
          code: "TJ",
          maxLength: 9,
        },
        {
          name: "Tanzania, United Republic of Tanzania",
          dial_code: "+255",
          code: "TZ",
          maxLength: 17,
        },
        {
          name: "Thailand",
          dial_code: "+66",
          code: "TH",
          maxLength: 9,
        },
        {
          name: "Timor-Leste",
          dial_code: "+670",
          code: "TL",
          maxLength: 17,
        },
        {
          name: "Togo",
          dial_code: "+228",
          code: "TG",
          maxLength: 8,
        },
        {
          name: "Tokelau",
          dial_code: "+690",
          code: "TK",
          maxLength: 4,
        },
        {
          name: "Tonga",
          dial_code: "+676",
          code: "TO",
          maxLength: 7,
        },
        {
          name: "Trinidad and Tobago",
          dial_code: "+1868",
          code: "TT",
          maxLength: 10,
        },
        {
          name: "Tunisia",
          dial_code: "+216",
          code: "TN",
          maxLength: 8,
        },
        {
          name: "Turkey",
          dial_code: "+90",
          code: "TR",
          maxLength: 10,
        },
        {
          name: "Turkmenistan",
          dial_code: "+993",
          code: "TM",
          maxLength: 8,
        },
        {
          name: "Turks and Caicos Islands",
          dial_code: "+1649",
          code: "TC",
          maxLength: 10,
        },
        {
          name: "Tuvalu",
          dial_code: "+688",
          code: "TV",
          maxLength: 6,
        },
        {
          name: "Uganda",
          dial_code: "+256",
          code: "UG",
          maxLength: 9,
        },
        {
          name: "Ukraine",
          dial_code: "+380",
          code: "UA",
          maxLength: 9,
        },
        {
          name: "United Arab Emirates",
          dial_code: "+971",
          code: "AE",
          maxLength: 9,
        },
        {
          name: "United Kingdom",
          dial_code: "+44",
          code: "GB",
          maxLength: 10,
        },
        {
          name: "United States",
          dial_code: "+1",
          code: "US",
          maxLength: 10,
        },
        {
          name: "Uruguay",
          dial_code: "+598",
          code: "UY",
          maxLength: 11,
        },
        {
          name: "Uzbekistan",
          dial_code: "+998",
          code: "UZ",
          maxLength: 9,
        },
        {
          name: "Vanuatu",
          dial_code: "+678",
          code: "VU",
          maxLength: 7,
        },
        {
          name: "Venezuela, Bolivarian Republic of Venezuela",
          dial_code: "+58",
          code: "VE",
          maxLength: 17,
        },
        {
          name: "Vietnam",
          dial_code: "+84",
          code: "VN",
          maxLength: 10,
        },
        {
          name: "Virgin Islands, British",
          dial_code: "+1284",
          code: "VG",
          maxLength: 17,
        },
        {
          name: "Virgin Islands, U.S.",
          dial_code: "+1340",
          code: "VI",
          maxLength: 17,
        },
        {
          name: "Wallis and Futuna",
          dial_code: "+681",
          code: "WF",
          maxLength: 6,
        },
        {
          name: "Yemen",
          dial_code: "+967",
          code: "YE",
          maxLength: 9,
        },
        {
          name: "Zambia",
          dial_code: "+260",
          code: "ZM",
          maxLength: 9,
        },
        {
          name: "Zimbabwe",
          dial_code: "+263",
          code: "ZW",
          maxLength: 10,
        },
      ],
      selectedCountry: {
        name: "",
        dial_code: "",
        flags: "",
        short: "",
      },
      validationConstraint: "",
      showNewCountryList: false,
      newCountryList: [],
      countriesV3: countriesJson.map((country) => ({
        name: country.name.common,
        flags: country.flags.svg,
        short: country.cca2,
      })),
    };
  },
  computed: {
    formattedTelephone() {
      return this.formatAsTelephone(this.value);
    },
    getLocationWidth() {
      const parent = document?.querySelector("#input-field" + this.unique);
      if (parent) {
        let parentWidth = window.getComputedStyle(parent).width;
        this.$nextTick(() => {
          this.containerleft = document
            ?.querySelector("#input-field" + this.unique)
            ?.getBoundingClientRect()?.left;
          this.containertop = document
            ?.querySelector("#input-field" + this.unique)
            ?.getBoundingClientRect()?.top;
          // console.log(this.containertop,'top');
          this.containerwidth = document
            ?.querySelector("#input-field" + this.unique)
            ?.getBoundingClientRect()?.width;
        });
        // console.log(parentWidth);
        return {
          width: `calc(${parentWidth} - 1rem)`,
        };
      } else {
        // Handle the case where the element with the specified ID is not found
        return {};
      }
    },
    isDisabled() {
      return this.isDisabledVar;
    },
    showClear() {
      // console.log(this.clearable, this.value, this.icon)
      if (
        this.clearable === true &&
        this.value !== "" &&
        this.icon === undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    partiallySelected() {
      return this.partially_checked;
    },
  },
  watch: {
    symbol: {
      handler(val) {
        if (val !== "") {
          this.$nextTick(() => {
            document
              .getElementById("inputnumber" + this.unique)
              ?.classList?.add("symbol-padding");
          });
        }
      },
      immediate: true,
      deep: true,
    },
    locationDataProp: {
      handler(val) {
        this.locationData = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
      deep: true,
    },
    partiallyChecked: {
      handler(val) {
        if (this.checked === false) {
          this.partiallyValue = val;
        } else {
          this.partiallyValue = false;
          this.$emit("partially-value", this.partiallyValue);
        }
      },
      immediate: true,
      deep: true,
    },
    // checked: {
    //   handler(val) {

    //   },
    //   immediate: true,
    //   deep: true,
    // },
    readOnly: {
      handler(val) {
        this.readOnlyProp = val;
        if (this.type === "checkbox" || this.type === "single-checkbox") {
          if (val === true) {
            this.isDisabledVar = true;
          }
        }
      },
      immediate: true,
    },
    disabled: {
      handler(val) {
        if (
          val === true &&
          (this.type !== "radio buttons" ||
            this.type !== "checkbox" ||
            this.type !== "single-checkbox" ||
            this.type !== "single-radio")
        ) {
          this.readOnlyProp = true;
        }
        if (this.type === "checkbox" || this.type === "single-checkbox") {
          if (val === true) {
            this.isDisabledVar = true;
          }
        }
      },
      immediate: true,
    },
    customErrorMessage: {
      handler(val) {
        console.log(val, "cety");
        this.input_error_message = val;
        if (
          this.singleBorder &&
          this.displayError &&
          this.type !== "email" &&
          this.customError &&
          this.input_error_message !== ""
        ) {
          this.$nextTick(() => {
            document.getElementById(
              "input-field" + this.unique
            ).style.borderBottom = "1px solid " + "#ff5050";
          });
        }
      },
      immediate: true,
      deep: true,
    },
    customError: {
      handler(val) {
        if (val) {
          this.input_error_message = this.customErrorMessage;
          if (this.singleBorder == true && val == true) {
            this.$nextTick(() => {
              document
                .querySelector("#input-field" + this.unique)
                .classList.add("single-border-error");
              document.querySelector(
                "#input-field" + this.unique
              ).style.borderBottom = "1px solid " + "#ff5050 !important";
            });
          } else if (this.singleBorder == true && val == false) {
            this.$nextTick(() => {
              document
                .querySelector("#input-field" + this.unique)
                .classList.remove("single-border-error");
              document.querySelector(
                "#input-field" + this.unique
              ).style.borderBottom = "1px solid " + "#ff5050 !important";
            });
          }
        } else {
          this.input_error_message = "";
        }
      },
      immediate: true,
    },
    countryCodes: {
      handler(val) {
        return val.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      },
      immediate: true,
      deep: true,
    },
    countryCode: {
      handler(val) {
        // console.log(val, 'countrycode');
        if (this.type === "phonenumber" && val.name !== "") {
          this.selectedCountry = val;
        }
        if (this.countries.length > 0 && val.name !== "") {
          let item = this.countries.filter((i) => {
            // console.log(i, 'countrycode');
            return i.name === val.name;
          });
          // console.log(item, 'countrycode');
          val.dial_code = item[0]?.dial_code;
          val.flags = item[0]?.flags;
          val.short = item[0]?.short;
        }
      },
      immediate: true,
      deep: true,
    },
    options: {
      handler(val) {
        // const r = document.querySelector(':root')
        // const rs = getComputedStyle(r)
        if (
          this.type === "radio-buttons" ||
          this.type === "border-radio-buttons"
        ) {
          this.optionsD = JSON.parse(JSON.stringify([...val]));
          if (this.preVal !== "") {
            this.optionsD.forEach((e, index) => {
              index;
              e.checked = false;
              // console.log(e, 'e on loop');
            });
            // console.log(val, this.optionsD);
            this.optionsD.forEach((e) => {
              if (e.value === this.preVal) {
                console.log(e.value, this.preVal);
                e.checked = true;
                // console.log(e, 'e on change');
                this.$emit("update:value", e.value);
                this.$emit("update:name", e.name);
              }
            });
          }
        }
        if (this.type === "checkbox") {
          val.forEach((e) => {
            if (e.readOnly === true) {
              e.disabled = true;
            }
          });
          this.optionsD = JSON.parse(JSON.stringify([...val]));
          // console.log(this.optionsD, val, 'options')
          if (document.querySelector(".select-all" + this.unique)) {
            document.querySelector(".select-all" + this.unique).checked = false;
          }
          // console.log(val,'rainbow');
          val.forEach((ele) => {
            if (ele.checked === false) {
              this.all_checked = false;
            }
            if (ele.checked === true) {
              this.atleastOne = true;
            }
          });
          if (this.all_checked === true) {
            if (
              this.showSelectAll === true &&
              this.optionsD !== undefined &&
              this.optionsD.length > 0
            ) {
              this.$nextTick(() => {
                document.querySelector(
                  ".select-all" + this.unique
                ).checked = true;
              });
            }
          }
          if (this.atleastOne === true && this.all_checked === false) {
            this.partially_checked = true;
          } else {
            this.partially_checked = false;
          }
          if (this.partially_checked === true) {
            // console.log('ps ', this.optionsD, this.optionsD.length, this.showSelectAll)
            if (
              this.showSelectAll === true &&
              this.optionsD !== undefined &&
              this.optionsD.length > 0
            ) {
              // console.log('ps true')
              this.$nextTick(() => {
                document.getElementById(
                  "select-all-checkmark" + this.unique
                ).style.background = "#54bd95";
                document.getElementById(
                  "select-all-checkmark" + this.unique
                ).style.border = "1px solid #54bd95";
              });
            }
          }
          // console.log(this.options, this.all_checked, this.atleastOne, this.partially_checked, 'options')
          if (this.all_checked === false) {
            if (document.querySelector(".select-all" + this.unique)) {
              document
                .getElementById("select-all-checkmark" + this.unique)
                .classList.remove("select-all-theme");
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
    preVal: {
      handler(val) {
        if (val !== undefined && val !== "") {
          if (this.type === "date") {
            const date = new Date(val);
            const day = String(date.getUTCDate()).padStart(2, "0");
            const month = String(date.getUTCMonth() + 1).padStart(2, "0");
            const year = date.getUTCFullYear();
            this.calendarValue = `${day}-${month}-${year}`;
            this.value = this.formatDate(this.calendarValue, this.dateFormat);
          } else if (
            this.type === "radio-buttons" ||
            this.type === "border-radio-buttons"
          ) {
            // console.log('inside radio', val)
            this.optionsD.forEach((e, index) => {
              index;
              e.checked = false;
              // console.log(e, 'e on loop');
            });
            // console.log(val, this.optionsD);
            this.optionsD.forEach((e) => {
              if (e.value === val) {
                console.log(e.value, val);
                e.checked = true;
                // console.log(e, 'e on change');
                this.$emit("update:value", e.value);
                this.$emit("update:name", e.name);
              }
            });
            console.log(this.optionsD);
          } else {
            if (typeof val === "object" && isNaN(val) === false) {
              this.value = String(val?.name);
            } else {
              this.value = String(val);
            }
          }
        } else if (val === "") {
          if (typeof val === "object") {
            this.value = String(val?.name);
          } else {
            this.value = String(val);
          }
        }
        // setTimeout(() => {

        //   this.showLocationOptions = false
        // }, 100);
      },
      deep: true,
      immediate: true,
    },
    checked: {
      handler(val) {
        if (this.type === "single-partial-checkbox") {
          if (val === true) {
            this.partiallyValue = false;
            this.$emit("partially-value", this.partiallyValue);
          }
          if (val === false && this.partiallyChecked === true) {
            this.partiallyValue = true;
          }
        }
        if (this.type === "single-checkbox") {
          const ob = {};
          ob.name = this.labelValue || "";
          ob.checked = val;
          // console.log('hscb', ob)
          this.$emit("update:value", val);
          this.$emit("change", ob);
        }
      },
    },
    required: {
      handler(val) {
        if (val) {
          this.showMandatory = val;
        }
      },
      immediate: true,
      deep: true,
    },
    value: {
      handler(val) {
        if (val !== "") {
          this.showLocationOptions = true;

          this.$nextTick(() => {
            if (this.type === "phonenumber") {
              document
                .getElementById("labelNumber" + this.unique)
                ?.classList?.add("force-focus-input");
            } else if (this.type === "email") {
              document
                .getElementById("labelemail" + this.unique)
                ?.classList?.add("force-focus-input");
            } else {
              document
                .getElementById("label" + this.unique)
                ?.classList?.add("force-focus-input");
            }
          });
        } else {
          this.showLocationOptions = false;
          this.$nextTick(() => {
            if (this.type === "phonenumber") {
              document
                .getElementById("labelNumber" + this.unique)
                ?.classList?.remove("force-focus-input");
            } else if (this.type === "email") {
              document
                .getElementById("labelemail" + this.unique)
                ?.classList?.remove("force-focus-input");
            } else {
              document
                .getElementById("label" + this.unique)
                ?.classList?.remove("force-focus-input");
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },
    focus: {
      handler(val) {
        this.$nextTick(() => {
          if (val == true) {
            document
              .querySelector("#input-field" + this.unique)
              ?.children[0]?.focus();
          }
          this.$emit("at-focus", val);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    // console.log(this.countriesV3);
    this.newCountryList = countriesJson;
    const r = document.querySelector(":root");
    const rs = getComputedStyle(r);
    this.root_style = rs;
    if (this.iconPosition === "left" && this.icon !== undefined) {
      if (document.getElementById(this.icon)) {
        document.getElementById(this.icon).style.display = "none";
      }
      document.querySelector("#input-field" + this.unique).style.flexDirection =
        "row-reverse";
      document.querySelector("#label" + this.unique).style.left = "38px";
      document.querySelector("#input" + this.unique).style.padding =
        "0 15px 0 0";
    }
    if (this.iconPosition === "right" && this.icon !== undefined) {
      if (document.getElementById(this.icon)) {
        document.getElementById(this.icon).style.display = "none";
      }
      if (this.type === "text") {
        document.querySelector("#input" + this.unique).style.padding =
          "0 0 0 15px";
      }
      // if (this.type === 'date') {
      //   document.querySelector('#inputdate' + this.unique).style.padding =
      //     '0 0 0 15px'
    }
    this.optionsD = JSON.parse(JSON.stringify([...this.options]));
    if (this.preVal !== "") {
      this.optionsD.forEach((e, index) => {
        index;
        e.checked = false;
        // console.log(e, 'e on loop');
      });
      // console.log(val, this.optionsD);
      this.optionsD.forEach((e) => {
        if (e.value === this.preVal) {
          console.log(e.value, this.preVal);
          e.checked = true;
          // console.log(e, 'e on change');
          this.$emit("update:value", e.value);
          this.$emit("update:name", e.name);
        }
      });
    }
    if (this.type === "checkbox") {
      if (document.querySelector(".select-all" + this.unique)) {
        document.querySelector(".select-all" + this.unique).checked = false;
      }
      this.all_checked = true;
      this.optionsD.forEach((ele) => {
        if (ele.checked === false) {
          this.all_checked = false;
        }
        if (ele.checked === true) {
          this.atleastOne = true;
        }
      });
      if (this.all_checked === true) {
        if (
          this.showSelectAll === true &&
          this.optionsD !== undefined &&
          this.optionsD.length > 0
        ) {
          this.$nextTick(() => {
            document.querySelector(".select-all" + this.unique).checked = true;
            document
              .getElementById("select-all-checkmark" + this.unique)
              .classList.add("select-all-theme");
          });
        }
      }
      if (this.atleastOne === true && this.all_checked === false) {
        this.partially_checked = true;
      } else {
        this.partially_checked = false;
      }
      if (this.partially_checked === true) {
        if (
          this.showSelectAll === true &&
          this.optionsD !== undefined &&
          this.optionsD.length > 0
        ) {
          // document.getElementById(
          this.$nextTick(() => {
            // console.log('partially selected trueeee', document.getElementById("select-all-checkmark" + this.unique));
            if (document.getElementById("select-all-checkmark" + this.unique)) {
              document
                .getElementById("select-all-checkmark" + this.unique)
                .classList.add("select-all-theme");
            }
          });

          //   'select-all-checkmark' + this.unique
          // ).style.background = '#54bd95'
          // document.getElementById(
          //   'select-all-checkmark' + this.unique
          // ).style.border = '1px solid #54bd95'
        }
      }
    }
    if (this.type === "phonenumber") {
      // let countries = this.newCountryList;
      // console.log(this.countriesV3);
      // this.countries = countries?.data?.map((country) => ({
      //   name: country.name.common,
      //   flags: country.flags.svg,
      //   short: country.cca2,
      // }));
      // console.log(this.countriesV3);
      this.countries = this.mergeArrays(
        this.new_country_codes,
        this.countriesV3
      );
      this.oGcountries = JSON.parse(
        JSON.stringify(
          this.mergeArrays(this.new_country_codes, this.countriesV3)
        )
      );
      if (this.countryCode.name === "") {
        this.selectedCountry.name = this.countries[0].name;
        this.selectedCountry.dial_code = this.countries[0].dial_code;
        this.selectedCountry.flags = this.countries[0].flags;
        this.selectedCountry.short = this.countries[0].short;
      }
      if (this.countries.length > 0 && this.countryCode.name !== "") {
        let item = this.countries.filter((i) => {
          // console.log(i, 'countrycode');
          return i.name === this.countryCode.name;
        });
        // console.log(item, 'countrycode item');
        // eslint-disable-next-line vue/no-mutating-props
        this.countryCode.dial_code = item[0]?.dial_code;
      }
    }
  },
  methods: {
    getNestedLookupValue(obj, path) {
      return path.split(".").reduce((acc, key) => acc && acc[key], obj);
    },
    validateTelephone() {
      const validTelephonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
      const invalidNumbers = ["(000) 000-0000"];

      if (!validTelephonePattern.test(this.formattedTelephone)) {
        this.input_error_message = "Please enter a valid telephone number";
        if (this.singleBorder) {
          document.getElementById(
            "input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#ff5050";
        } else {
          document.getElementById("input-field" + this.unique).style.border =
            "1px solid " + "#ff5050";
          if (this.labelAnimation) {
            document.getElementById("label" + this.unique).style.color =
              "#ff5050";
          }
        }
      } else if (invalidNumbers.includes(this.formattedTelephone)) {
        this.input_error_message = "Invalid telephone number (e.g., all zeros)";
        if (this.singleBorder) {
          document.getElementById(
            "input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#ff5050";
        } else {
          document.getElementById("input-field" + this.unique).style.border =
            "1px solid " + "#ff5050";
          if (this.labelAnimation) {
            document.getElementById("label" + this.unique).style.color =
              "#ff5050";
          }
        }
      } else {
        this.input_error_message = "";
        if (this.singleBorder) {
          document.getElementById(
            "input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#d8d8d8";
        } else {
          document.getElementById("input-field" + this.unique).style.border =
            "1px solid " + "#d8d8d8";
          if (this.labelAnimation) {
            document.getElementById("label" + this.unique).style.color =
              "#A6A6A6";
          }
        }
        console.log("Telephone number is valid");
      }
    },
    formatAsTelephone(value) {
      const cleaned = value.replace(/\D/g, "");
      const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
      if (!match) return cleaned;
      const [, areaCode, prefix, lineNumber] = match;
      let formatted = "";
      if (areaCode) formatted += `(${areaCode}`;
      if (areaCode && areaCode.length === 3) formatted += `) `;
      if (prefix) formatted += `${prefix}`;
      if (prefix && prefix.length === 3) formatted += `-`;
      if (lineNumber) formatted += `${lineNumber}`;
      return formatted;
    },
    showNewCountryListOverlayClose(val) {
      // console.log(val.target.classList[0].includes("input"));
      if (val.target.classList[0].includes("input") === false) {
        this.showNewCountryList = false;
      }
    },
    showNewCountryListFunc() {
      if (!this.readOnlyProp) {
        this.showNewCountryList = true;
        this.containerleft = document
          .querySelector("#input-field" + this.unique)
          .getBoundingClientRect().left;
        this.containertop = document
          .querySelector("#input-field" + this.unique)
          .getBoundingClientRect().top;
        this.containerwidth = document
          .querySelector("#input-field" + this.unique)
          .getBoundingClientRect().width;
      }
    },
    searchCountryCode(searchString) {
      if (searchString !== "") {
        const searchTerm = searchString.toLowerCase();

        this.countries = this.oGcountries.filter((obj) => {
          const nameMatch = obj.name.toLowerCase().includes(searchTerm);
          const dialCodeMatch = obj.dial_code
            .toLowerCase()
            .includes(searchTerm);
          const shortMatch = obj.short.toLowerCase().includes(searchTerm);

          return nameMatch || dialCodeMatch || shortMatch;
        });
      } else {
        this.countries = this.oGcountries;
      }
    },
    changeSelectedCountry(country) {
      // console.log(country);
      this.selectedCountry = country;
      this.showNewCountryList = false;
      this.countries = this.oGcountries;
      this.validatePhoneNumber();
      document.querySelector("#input-field" + this.unique).focus();
    },
    mergeArrays(array1, array2) {
      const resultArray = array2.reduce((result, obj2) => {
        const matchingObj1 = array1.find((obj1) => obj1.code === obj2.short);
        if (matchingObj1) {
          // If a matching object is found, update the dial_code and include in result
          result.push({ ...obj2, dial_code: matchingObj1.dial_code });
        }
        // Otherwise, exclude from result
        return result;
      }, []);

      // Sort resultArray based on the "name" property
      resultArray.sort((a, b) => a.name.localeCompare(b.name));

      return resultArray;
    },
    updateValidation() {
      const selectedCountry = this.countries.find(
        (country) => country.idd.root === this.selectedCountry
      );
      this.validationConstraint = selectedCountry.idd.suffixes[0];
    },
    getMultiInputWidth() {
      this.$nextTick(() => {
        // setTimeout(() => {
        if (this.prefix) {
          const prefixElement = document.getElementById(
            "input-field" + this.unique
          );
          if (prefixElement) {
            // const styles = window.getComputedStyle(prefixElement);
            const totalWidth = prefixElement.children[0].clientWidth;
            // console.log(prefixElement.children[0].clientWidth);
            document.getElementById("label" + this.unique).style.left =
              totalWidth + 9 * 1 + "px";
            document.getElementById("label" + this.unique).style.top =
              10 + "px";
          }
        }
        // }, 500);
      });
    },
    closeLocationOptions() {
      this.showLocationOptions = false;
    },
    async setLocation(val) {
      console.log("address-data---------->:", val);
      this.$emit("address-data", val);
      this.$emit("object-emit", val);

      //   let result = {}
      //   try {
      //   // eslint-disable-next-line vue/no-async-in-computed-properties
      //   const response = await axios.get('/api/maps/api/place/details/json', {
      //     params: {
      //       place_id: val.place_id,
      //       fields: 'address_components',
      //       key: this.params.apiKey,
      //     },
      //   });
      //   result = response.data.result.address_components.filter((item) => {
      //     return item.types.includes('postal_code')
      //   });
      // } catch (error) {
      //   console.error('Error fetching autocomplete data:', error.message);
      // }
      //   console.log('postal code---------->:', result[0]?.long_name)
      //   this.$emit('postal-code', result[0]?.long_name)
      this.value = this.getNestedLookupValue(val, this.lookupProp);
      this.$emit("update:value", this.value);
      this.locationData = [];
      this.showLocationOptions = false;
    },
    handleTextareaKeydown($event) {
      if (this.readOnlyProp) {
        const allowedKeys = [
          "ArrowLeft",
          "ArrowRight",
          "ArrowUp",
          "ArrowDown", // Navigation keys
          "Home",
          "End",
          "PageUp",
          "PageDown", // More navigation keys
          "Shift",
          "Control",
          "Alt",
          "Meta", // Modifier keys
          "CapsLock",
          "Tab", // Other functional keys
          "Escape", // Escape key
          "Copy",
          "Paste",
          "Cut", // Clipboard operations
        ];
        if (
          !allowedKeys.includes($event.key) && // Allow the specified keys
          !($event.ctrlKey && $event.key === "c") && // Allow Ctrl+C
          !($event.metaKey && $event.key === "c") // Allow Command+C (for Mac)
        ) {
          $event.preventDefault();
          $event.stopPropagation();
        }
      }
    },
    handleSubmit() {
      // Handle form submission here
      // You can access the entered password with `this.value`
      // You can perform any necessary logic or API calls
      // without reloading the page
      // this.atEnter($event, this.value);
    },
    handleSingleCheckBox($event) {
      const ob = {};
      ob.name = this.labelValue || "";
      ob.checked = $event.target.checked;
      if (this.type === "single-partial-checkbox") {
        this.partiallyValue = false;
        this.$emit("partially-value", this.partiallyValue);
      }
      // console.log('hscb', ob)
      this.$emit("update:value", $event.target.checked);
      this.$emit("selected", ob);
    },
    handleSingleRadio($event) {
      this.$emit("update:value", this.value);
      this.$emit("update:name", this.labelValue);
      const ob = {};
      ob.name = this.labelValue || "";
      ob.value = this.pre_val;
      ob.checked = $event.target.checked;
      this.$emit("selected", ob);
      // console.log('hscb', ob)
      // console.log('va', $event.target.checked)
    },
    // intelliSense () {
    //   // console.log('inside intellisense', val.data, this.value)
    //   const valu = this.value
    //   if (this.value.length > 0) {
    //     this.typing = true
    //   } else {
    //     this.typing = false
    //   }
    //   this.intelliData = this.intelliOptions.filter(function (op) {
    //     return op.toLowerCase().includes(valu.toLowerCase())
    //   })
    //   // console.log(this.value, this.intelliOptions, this.intelliData)
    // },
    // intelliOptionChosen (val) {
    //   this.value = val
    //   this.typing = false
    // },
    atEnter(event, value) {
      const obj = {};
      obj.event = event;
      obj.value = value;
      // console.log(obj);
      this.$emit("at-enter", obj);
      if (this.type === "date") {
        if (new Date(this.value) == "Invalid Date") {
          console.log(new Date(this.value), "newDateFormatter");
          this.input_error_message =
            "Please enter a valid date in the format - month day year";
          if (this.singleBorder) {
            console.log("sb");
            document
              .querySelector("#input-field" + this.unique)
              .classList.add("single-border-error");
            document.querySelector(
              "#input-field" + this.unique
            ).style.borderBottom = "1px solid " + "#ff5050 !important";
          } else {
            document.querySelector("#input-field" + this.unique).style.border =
              "1px solid " + "#ff5050";
          }
          if (this.labelAnimation) {
            document.getElementById("label" + this.unique).style.color =
              "#ff5050";
          }
        } else {
          console.log(new Date(this.value), "newDateFormatter78");
          this.newDateFormatter(new Date(this.value));
        }
      }
    },
    hoverSelectAll() {
      document
        .getElementById("select-all-checkmark" + this.unique)
        .classList.add("select-all-theme-hover");
    },
    unHoverSelectAll() {
      if (this.select_all === false && this.partially_checked === false) {
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.remove("select-all-theme-hover");
      }
    },
    selectAll($event) {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      this.select_all = $event.target.checked;
      if (this.select_all === true) {
        this.partially_checked = false;
        document
          .querySelectorAll("[id^=check-drag" + this.unique + "]")
          .forEach((ele) => {
            if (ele.disabled === false) {
              ele.checked = true;
            }
          });
        this.optionsD.forEach((ele) => {
          if (ele.disabled === false) {
            ele.checked = true;
          }
        });
        // const r = document.querySelector(':root')
        // const rs = getComputedStyle(r)
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.remove("select-all-grey");
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.add("select-all-theme");
      } else {
        this.partially_checked = false;
        document
          .querySelectorAll("[id^=check-drag" + this.unique + "]")
          .forEach((ele) => {
            if (ele.disabled === false) {
              ele.checked = false;
            }
            if (ele.checked === true) {
              document
                .getElementById("select-all-checkmark" + this.unique)
                .classList.add("select-all-theme");
              this.partially_checked = true;
            }
          });
        this.optionsD.forEach((ele) => {
          if (ele.disabled === false) {
            ele.checked = false;
          }
        });
        if (this.partially_checked === false) {
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-grey");
        } else if (this.partially_checked === true) {
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-theme");
        }
      }
      this.$emit("update:value", this.optionsD);
      this.$emit("check-box-event", this.optionsD);
    },
    checkItem(val, $event) {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      this.atleastOne = false;
      this.all_checked = true;
      val.checked = $event.target.checked;
      if ($event.target.checked === false) {
        if (this.showSelectAll === true) {
          document.querySelector(".select-all" + this.unique).checked = false;
        }
      }
      // if (this.all_checked === true) {
      //   document.querySelector('.select-all').checked = true
      // }
      this.$emit("update:value", this.optionsD);
      this.optionsD.forEach((ele) => {
        if (ele.checked === false) {
          this.all_checked = false;
        }
        if (ele.checked === true) {
          this.atleastOne = true;
        }
      });
      if (this.showSelectAll === true) {
        if (this.all_checked === true) {
          // console.log('all checked')
          document.querySelector(".select-all" + this.unique).checked = true;
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-grey");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-theme");
        } else if (
          document.getElementById("select-all-checkmark" + this.unique)
        ) {
          this.partially_checked = false;
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-theme");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-grey");
        }
        if (this.atleastOne === true && this.all_checked === false) {
          this.partially_checked = true;
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-grey");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-theme");
        } else if (this.all_checked === true) {
          this.partially_checked = false;
        }
      }
      // this.optionsD = JSON.parse(JSON.stringify([...this.options]))
      // this.$emit('update:value',this.options)
      this.$emit("selected", val);
      this.$emit("check-box-event", this.optionsD);
    },
    checkRadio(val) {
      // console.log(val.name, 'radio val')
      this.optionsD.forEach((ele) => {
        ele.checked = false;
        if (ele === val) {
          ele.checked = true;
        }
      });
      this.$emit("update:value", val.value);
      this.$emit("update:name", val.name);
      this.$emit("selected", val);
      this.$emit("radio-event", this.optionsD);
    },
    checkSwitch($event) {
      this.$emit("update:value", $event.target.checked);
      this.$emit("selected", $event.target.checked);
    },
    updateValue(input, step) {
      const currentValue = this.value;

      // Split the integer and decimal parts
      const [integerPart, decimalPart] = String(currentValue)
        .split(".")
        .map(Number);

      // Update the integer part by the step (1 for up arrow, -1 for down arrow)
      const newIntegerPart = integerPart + step;
      const newDecimalPart = decimalPart || 0; // Preserve the original decimal part, default to 0 if none

      // Combine the new integer part with the decimal part
      let newValue = `${newIntegerPart}.${newDecimalPart
        .toString()
        .padEnd(2, "0")}`;

      // Parse as a float for comparison to min/max limits
      const newValueFloat = parseFloat(newValue);

      // Check if new value is within the min and max limits
      console.log(input.min, input.max, "date input fk");
      if (input.min !== "" && input.max !== "") {
        if (newValueFloat < input.min) {
          newValue = input.min.toString();
        } else if (newValueFloat > input.max) {
          newValue = input.max.toString();
        }
      }

      // Set the updated value back to the input
      this.value = newValue;

      this.$emit("update:value", this.value);
      this.$emit("at-input", this.value);
    },

    checkArrowKey(event) {
      // Only execute on ArrowUp or ArrowDown keys
      if (event.key === "ArrowUp") {
        this.updateValue(event.target, 1); // Increase by 1
        event.preventDefault(); // Prevent default increment behavior
      } else if (event.key === "ArrowDown") {
        this.updateValue(event.target, -1); // Decrease by 1
        event.preventDefault(); // Prevent default decrement behavior
      }
    },
    atInput($event) {
      // console.log("at");
      if (this.characterLimit !== null) {
        if (this.value.length >= this.characterLimit) {
          // console.log(this.value);
          $event.preventDefault();
          this.value = this.value.slice(0, this.characterLimit);
        }
      }
      if (this.maxLength >= 0) {
        if (this.value.length > this.maxLength) {
          this.input_error_message =
            "Maximum character limit is " + this.maxLength;
        } else if (this.customError === true) {
          this.input_error_message = this.customErrorMessage;
        } else {
          this.input_error_message = "";
        }
      }
      if (this.minLength >= 0) {
        if (this.value.length < this.minLength) {
          this.input_error_message =
            "Minimum character limit is " + this.minLength;
        } else if (this.customError === true) {
          this.input_error_message = this.customErrorMessage;
        } else {
          this.input_error_message = "";
        }
      }
      if (this.customError === true) {
        this.input_error_message = this.customErrorMessage;
      } else {
        this.input_error_message = "";
      }

      if (this.type == "telephone") {
        const inputValue = $event.target.value;

        // Detect if user is deleting
        const isDeleting =
          inputValue.length < this.previousTelephoneInput.length;

        // Clean the input: remove non-numeric characters
        let rawValue = inputValue.replace(/\D/g, "");

        // Handle deletion gracefully
        if (isDeleting) {
          // Only slice when trying to delete
          rawValue = rawValue.slice(0, this.value.length - 1);
        } else {
          // Limit to 10 digits
          rawValue = rawValue.slice(0, 10);
        }

        // Update the raw telephone number
        this.value = rawValue;

        // Update the input box value with the formatted version
        $event.target.value = this.formatAsTelephone(rawValue);

        // Save the current input for next time
        this.previousTelephoneInput = $event.target.value;
      }
      this.$emit("update:value", this.value);
      this.$emit("at-input", this.value);
    },
    countryCodeRecieved(val) {
      this.value = "";
      val.forEach((e) => {
        if (e.checked === true) {
          this.country_code = e.code;
        }
      });
      this.validatePhoneNumber();
      document.querySelector(".phn" + this.unique).focus();
    },
    validatePhoneNumber() {
      // console.log(this.country_code, this.value, 'ooooooooooooooo')
      // if (this.value.charAt(0) !== '+') {
      //   this.value = Number(this.country_code) + this.value
      // }
      // console.log('val ph', this.value)
      // const pattern = /^\d+\.?\d*$/;
      // const isString = pattern.test(
      //   String(this.value).charAt(String(this.value).length - 1)
      // );
      // if (isString === false) {
      //   this.value = this.value.slice(0, -1);
      // }
      // if (this.value !== "") {
      //   if (this.country_code !== "") {
      //     if (this.country_code === "+91") {
      //       // this.value = this.country_code + this.value.toString()
      //       if (
      //         /\+?\d[\d -]{8,12}\d/.test(this.value) &&
      //         this.value.toString().length <= 14
      //       ) {
      //         // console.log(this.value.toString().length, 'true')
      //         this.input_error_message = "";
      //       } else if (this.value.toString().length > 14) {
      //         this.input_error_message = "Enter a valid phone number";
      //       }
      //     } else if (this.country_code === "+1") {
      //       if (
      //         /\D*([2-9]\d{2})(\D*)([2-9]\d{2})(\D*)(\d{4})\D*/.test(
      //           this.value
      //         ) &&
      //         this.value.toString().length <= 13
      //       ) {
      //         // console.log(this.value.toString().length, 'true')
      //         this.input_error_message = "";
      //       } else if (this.value.toString().length > 13) {
      //         this.input_error_message = "Enter a valid phone number";
      //       }
      //     } else {
      //       this.input_error_message = "Enter a valid country code";
      //     }
      //   } else {
      //     this.input_error_message = "Enter a valid country code";
      //   }
      // } else if (this.customError === true) {
      //   this.input_error_message = this.customErrorMessage;
      // } else {
      //   this.input_error_message = "";
      // }
      // this.value = this.value.toString();
      // console.log(
      //   validatePhoneNumberLength(this.value, this.selectedCountry.short)
      // );
      // console.log(isValidPhoneNumber("7687767878", this.selectedCountry.short));
      if (this.value.length > 0) {
        if (
          validatePhoneNumberLength(this.value, this.selectedCountry.short) ===
            undefined &&
          isValidPhoneNumber(this.value, this.selectedCountry.short)
        ) {
          this.input_error_message = "";
          // document.getElementById("inputnumber" + this.unique).focus();
        } else {
          // this.input_error_message = "Enter a valid phone number";
          this.input_error_message = this.defaultNumberError;
          // document.getElementById("inputnumber" + this.unique).focus();
        }
        if (this.input_error_message === "") {
          document.getElementById("input-field" + this.unique).style.border =
            "1px solid " + "#54bd95";
          if (this.labelAnimation) {
            document.getElementById("labelNumber" + this.unique).style.color =
              "#54bd95";
            document.getElementById("labelNumber2" + this.unique).style.color =
              "#54bd95";
          }
        } else {
          document.getElementById("input-field" + this.unique).style.border =
            "1px solid " + "#ff5050";
          if (this.labelAnimation) {
            document.getElementById("labelNumber" + this.unique).style.color =
              "#ff5050";
            document.getElementById("labelNumber2" + this.unique).style.color =
              "#ff5050";
          }
        }
      } else {
        this.input_error_message = "";
        // if (this.input_error_message === "") {
        //     document.getElementById("input-field" + this.unique).style.border =
        //       "1px solid " + "#54bd95";
        //     if (this.labelAnimation) {
        //       document.getElementById("labelNumber" + this.unique).style.color =
        //         "#54bd95";
        //       document.getElementById("labelNumber2" + this.unique).style.color =
        //         "#54bd95";
        //     }
        //   }
        // document.getElementById("inputnumber" + this.unique).focus();
      }
      // if (this.value.charAt(0) !== '+') {
      //   // this.value = this.country_code + this.value
      // }
      const valid = this.input_error_message === "" ? true : false;
      this.$emit("update:value", {
        country: this.selectedCountry,
        value: this.value,
        valid: valid,
      });
    },
    emitPhoneNumber() {
      this.validatePhoneNumber();
      let limit = this.new_country_codes.filter((i) => {
        return i.name === this.selectedCountry.name;
      });
      console.log(this.selectedCountry, limit);
      let length = limit[0]?.maxLength;
      if (this.value.length > length + 5) {
        this.value = this.value.slice(0, length + 5);
      }
      this.value = new AsYouType(this.selectedCountry.short).input(
        this.value.toString()
      );
      const valid = this.input_error_message === "" ? true : false;
      if (valid) {
        document.getElementById("input-field" + this.unique).style.border =
          "1px solid " + "#d8d8d8";
        if (this.labelAnimation) {
          document.getElementById("labelNumber" + this.unique).style.color =
            "#a6a6a6";
          document.getElementById("labelNumber2" + this.unique).style.color =
            "#a6a6a6";
        }
      }
      this.$emit("update:value", {
        country: this.selectedCountry,
        value: this.value,
        valid: valid,
      });
      this.$emit("at-input", {
        country: this.selectedCountry,
        value: this.value,
        valid: valid,
      });
    },
    formatDate(actualDateString, format) {
      const monthsShort = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const monthsLong = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Parse the actualDateString into a Date object
      const [day, month, year] = actualDateString.split("-").map(Number);
      const actualDate = new Date(year, month - 1, day);

      const dateComponents = {
        dd: actualDate.getDate().toString().padStart(2, "0"),
        mm: (actualDate.getMonth() + 1).toString().padStart(2, "0"),
        yyyy: actualDate.getFullYear().toString(),
        "(short month)": monthsShort[actualDate.getMonth()],
        "(long month)": monthsLong[actualDate.getMonth()],
      };

      // Replace format placeholders with date components
      const formattedDate = format.replace(
        /(dd|mm|yyyy|\(short month\)|\(long month\))/g,
        (match) => dateComponents[match] || match
      );

      return formattedDate;
    },
    newDateFormatter(val) {
      const date = new Date(val);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const em = this.input_error_message;
      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        this.receivedDate(`${day}-${month}-${year}`);
        if (!this.customError) {
          this.input_error_message = "";
        }
        if (this.singleBorder) {
          document
            .querySelector("#input-field" + this.unique)
            .classList.remove("single-border-error");
          document.querySelector(
            "#input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#d8d8d8";
        } else {
          document.querySelector("#input-field" + this.unique).style.border =
            "1px solid " + "#d8d8d8";
        }
        if (this.labelAnimation) {
          document.querySelector("#label" + this.unique).style.color =
            "#a6a6a6";
        }
      } else {
        if (this.value !== "") {
          this.input_error_message =
            "Please enter a valid date in the format - month day year";
          if (this.singleBorder) {
            document
              .querySelector("#input-field" + this.unique)
              .classList.add("single-border-error");
            document.querySelector(
              "#input-field" + this.unique
            ).style.borderBottom = "1px solid " + "#ff5050 !important";
          } else {
            document.querySelector("#input-field" + this.unique).style.border =
              "1px solid " + "#ff5050";
          }
          if (this.labelAnimation) {
            document.getElementById("label" + this.unique).style.color =
              "#ff5050";
          }
        }
      }
      if (this.customError) {
        if (this.singleBorder) {
          document
            .querySelector("#input-field" + this.unique)
            .classList.add("single-border-error");
          document.querySelector(
            "#input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#ff5050 !important";
          this.input_error_message = em;
        }
      } else {
        if (this.singleBorder) {
          document
            .querySelector("#input-field" + this.unique)
            .classList.remove("single-border-error");
          document.querySelector(
            "#input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#ff5050 !important";
          this.input_error_message = "";
        }
      }
      // return `${day}-${month}-${year}`;
      this.$emit("focus-out", this.value);
    },
    receivedDate(val) {
      this.calendarValue = val;
      // Your input date string
      console.log(val, "this.formattedDate");
      const inputDateString = val;

      // Step 1: Parse the date string into a JavaScript Date object
      const parts = inputDateString.split("-");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Month is 0-based (0 = January, 1 = February, etc.)
      const year = parseInt(parts[2]);
      const inputDate = new Date(Date.UTC(year, month, day));

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const monthAbbreviation = months[parseInt(month) - 1];
      const formattedDate = `${monthAbbreviation} ${day},${year}`;
      console.log(formattedDate);
      this.$emit("formattedDate", formattedDate);
      // Step 2: Convert the JavaScript Date object to MongoDB date format
      const mongoDBDateString = inputDate.toISOString();
      this.value = this.formatDate(val, this.dateFormat);
      this.show_calendar = false;
      this.$emit("update:value", mongoDBDateString);
      this.$emit("at-input", mongoDBDateString);
      this.$emit("iso-date", mongoDBDateString);
      if (this.customError === true) {
        this.input_error_message = this.customErrorMessage;
      } else {
        this.input_error_message = "";
      }
      if (this.singleBorder) {
        document
          .querySelector("#input-field" + this.unique)
          .classList.remove("single-border-error");
        document.querySelector(
          "#input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#d8d8d8 !important";
      } else {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#d8d8d8";
      }
      if (this.labelAnimation) {
        document.querySelector("#label" + this.unique).style.color = "#a6a6a6";
      }
      // this.blurDate();
    },
    receivedSingleDate(val) {
      this.calendarValue = val;
      // Your input date string
      const inputDateString = val;

      // Step 1: Parse the date string into a JavaScript Date object
      const parts = inputDateString.split("-");
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Month is 0-based (0 = January, 1 = February, etc.)
      const year = parseInt(parts[2]);
      const inputDate = new Date(Date.UTC(year, month, day));

      // Step 2: Convert the JavaScript Date object to MongoDB date format
      const mongoDBDateString = inputDate.toISOString();
      this.value = this.formatDate(val, this.dateFormat);
      this.show_calendar = false;
      this.$emit("update:value", mongoDBDateString);
      this.$emit("at-input", mongoDBDateString);
      this.$emit("iso-date", mongoDBDateString);
      this.blurSingleDate();
    },
    openCalendar($event) {
      if (!this.readOnlyProp) {
        this.calendar_pos = $event;
        this.show_calendar = !this.show_calendar;
        if (this.show_calendar === true) {
          this.focusDate();
        } else {
          this.blurDate();
        }
      }
    },
    openSingleCalendar($event) {
      this.calendar_pos = $event;
      this.show_calendar = !this.show_calendar;
      if (this.show_calendar === true) {
        this.focusSingleDate();
      } else {
        this.blurSingleDate();
      }
    },
    // Following functions are for validating input functionalities
    hoverInput() {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      // const elem = document.querySelector('#input' + this.unique)
      // if (elem !== document.activeElement) {
      //   document.querySelector('#input-field' + this.unique).style.border =
      //     '1px solid ' + rs.getPropertyValue('--theme')
      // }
    },
    unHoverInput() {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      // const elem = document.querySelector('#input' + this.unique)
      // if (elem !== document.activeElement) {
      //   document.querySelector('#input-field' + this.unique).style.border =
      //     '1px solid ' + rs.getPropertyValue('--border')
      // }
    },
    focusInput() {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      // document.querySelector('#input-field' + this.unique).style.border =
      //   '1px solid ' + rs.getPropertyValue('--theme')
      // if (this.iconPosition === 'left' && this.icon !== undefined) {
      //   document.querySelector('#label' + this.unique).style.left = '5px'
      // }

      if (this.required === true) {
        this.showMandatory = true;
      }
    },
    blurInput() {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      // document.querySelector('#input-field' + this.unique).style.border =
      //   '1px solid ' + rs.getPropertyValue('--border')
      if (this.iconPosition === "left" && this.icon !== undefined) {
        document.querySelector("#label" + this.unique).style.left = "38px";
      }
      // this.$emit('blurEvent', this.value)
      if (this.required === true) {
        this.showMandatory = true;
      }
      if (this.type == "telephone") {
        this.validateTelephone();
      }
      this.$emit("focus-out", this.value);
    },
    // Following functions are for validating email functionalities
    blurEmail() {
      if (this.value === "") {
        document.getElementById("input-field" + this.unique).style.border =
          "1px solid " + "#d8d8d8";
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#A6A6A6";
        }
      } else {
        this.validateEmail();
      }
      if (this.input_error_message === "") {
        document.getElementById("input-field" + this.unique).style.border =
          "1px solid " + "#d8d8d8";
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#A6A6A6";
        }
      } else {
        document.getElementById("input-field" + this.unique).style.border =
          "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#ff5050";
        }
      }

      if (this.required === true) {
        this.showMandatory = true;
      }
      this.$emit("focus-out", this.value);
    },
    blurSingleEmail() {
      if (this.value === "") {
        document.getElementById(
          "input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#d8d8d8";
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#A6A6A6";
        }
      } else {
        this.validateEmail();
      }
      if (this.input_error_message === "") {
        document.getElementById(
          "input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#d8d8d8";
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#A6A6A6";
        }
      } else {
        document.getElementById(
          "input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#ff5050";
        }
      }

      if (this.required === true) {
        this.showMandatory = true;
      }
      this.$emit("focus-out", this.value);
    },
    focusEmail() {
      if (this.input_error_message === "") {
        if (this.singleBorder) {
          document.getElementById(
            "input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#54bd95";
        } else {
          document.getElementById("input-field" + this.unique).style.border =
            "1px solid " + "#54bd95";
        }
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#54bd95";
        }
      } else {
        if (this.singleBorder) {
          document.getElementById(
            "input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#ff5050";
        } else {
          document.getElementById("input-field" + this.unique).style.border =
            "1px solid " + "#ff5050";
        }
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#ff5050";
        }
      }

      if (this.required === true) {
        this.showMandatory = true;
      }
    },
    focusSingleEmail() {
      if (this.input_error_message === "") {
        document.getElementById(
          "input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#54bd95";
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#54bd95";
        }
      } else {
        document.getElementById(
          "input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#ff5050";
        }
      }

      if (this.required === true) {
        this.showMandatory = true;
      }
    },
    hoverEmail() {
      const elem = document.querySelector("#inputemail" + this.unique);
      if (elem !== document.activeElement) {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#54bd95";
      }
      if (this.input_error_message !== "") {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#ff5050";
      }
    },
    hoverSingleEmail() {
      const elem = document.querySelector("#inputemail" + this.unique);
      if (elem !== document.activeElement) {
        document.querySelector(
          "#input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#54bd95";
      }
      if (this.input_error_message !== "") {
        document.querySelector(
          "#input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#ff5050";
      }
    },
    unHoverEmail() {
      const elem = document.querySelector("#inputemail" + this.unique);
      if (elem !== document.activeElement) {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#d8d8d8";
      }
      if (this.input_error_message !== "") {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#ff5050";
      }
    },
    unHoverSingleEmail() {
      const elem = document.querySelector("#inputemail" + this.unique);
      if (elem !== document.activeElement) {
        document.querySelector(
          "#input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#d8d8d8";
      }
      if (this.input_error_message !== "") {
        document.querySelector(
          "#input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#ff5050";
      }
    },
    validateEmail() {
      // eslint-disable-next-line no-useless-escape
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.value)) {
        this.input_error_message = "";
        if (this.singleBorder) {
          document.getElementById(
            "input-field" + this.unique
          ).style.borderBottomColor = "1px solid " + "#54bd95";
        } else {
          document.getElementById("input-field" + this.unique).style.border =
            "1px solid " + "#54bd95";
        }
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#54bd95";
        }
      } else {
        // this.input_error_message =
        //   "Please enter a valid email in the format: abc@gmail.com";
        this.input_error_message = this.defaultMailError;
        if (this.singleBorder) {
          document.getElementById(
            "input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#ff5050";
        } else {
          document.getElementById("input-field" + this.unique).style.border =
            "1px solid " + "#ff5050";
        }
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#ff5050";
        }
      }
    },
    validateSingleEmail() {
      // eslint-disable-next-line no-useless-escape
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.value)) {
        this.input_error_message = "";
        document.getElementById(
          "input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#54bd95";
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#54bd95";
        }
      } else {
        // this.input_error_message =
        //   "Please enter a valid email in the format: abc@gmail.com";
        this.input_error_message = this.defaultMailError;
        document.getElementById(
          "input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("labelemail" + this.unique).style.color =
            "#ff5050";
        }
      }
    },
    // Following functions are for validating password functionalities
    focusPassword() {
      if (this.passwordCondition === true) {
        this.show_password_condition = true;
      }
      document.getElementById("input-field" + this.unique).style.border =
        "1px solid " + "#54bd95";
      // const width = document.querySelector('.input-container').offsetWidth
      // console.log(width + 'px')
      // console.log('hello', document.getElementById('pp').getC)
      // document.getElementById('pp').style.width = '400px !important'
      if (this.required === true) {
        this.showMandatory = true;
      }
    },
    blurPassword() {
      this.show_password_condition = false;
      document.getElementById("input-field" + this.unique).style.border =
        "1px solid " + "#d8d8d8";
      if (this.required === true) {
        this.showMandatory = true;
      }
      this.$emit("focus-out", this.value);
    },
    hoverPassword() {
      // const elem = document.querySelector('#inputpassword' + this.unique)
      // if (elem !== document.activeElement) {
      //   document.querySelector('#input-field' + this.unique).style.border =
      //     '1px solid ' + '#54bd95'
      // }
    },
    unHoverPassword() {
      // const elem = document.querySelector('#inputpassword' + this.unique)
      // if (elem !== document.activeElement) {
      //   document.querySelector('#input-field' + this.unique).style.border =
      //     '1px solid ' + '#d8d8d8'
      // }
    },
    conditionTrue(val) {
      if (this.passwordCondition) {
        document.querySelector("." + val).style.color = "#02b55c";
        document
          .querySelector("#" + val + "-icon")
          .classList.remove("icon-times-circle-regular");
        document
          .querySelector("#" + val + "-icon")
          .classList.add("icon-check-circle-filled");
      }
    },
    conditionFalse(val) {
      if (this.passwordCondition) {
        document.querySelector("." + val).style.color = "#565553";
        document
          .querySelector("#" + val + "-icon")
          .classList.remove("icon-check-circle-filled");
        document
          .querySelector("#" + val + "-icon")
          .classList.add("icon-times-circle-regular");
      }
    },
    validatePassword() {
      // console.log('password validation')
      if (/^(?=.*[a-z]).+$/.test(this.value)) {
        this.conditionTrue("lowercase");
      } else {
        this.conditionFalse("lowercase");
      }
      if (/^(?=.*[A-Z]).+$/.test(this.value)) {
        this.conditionTrue("uppercase");
      } else {
        this.conditionFalse("uppercase");
      }
      if (/^(?=.*[_\W]).+$/.test(this.value)) {
        this.conditionTrue("special");
      } else {
        this.conditionFalse("special");
      }
      if (/^(?=.*[0-9]).+$/.test(this.value)) {
        this.conditionTrue("number");
      } else {
        this.conditionFalse("number");
      }
      if (String(this.value).length >= 8) {
        this.conditionTrue("length");
      } else {
        this.conditionFalse("length");
      }
    },
    togglePassword() {
      const icon = document.querySelector("#password-toggle-icon").classList[0];
      if (icon === "icon-eye-slash-regular") {
        document.querySelector("#password-toggle-icon").classList.remove(icon);
        document
          .querySelector("#password-toggle-icon")
          .classList.add("icon-eye-regular");
      } else {
        document.querySelector("#password-toggle-icon").classList.remove(icon);
        document
          .querySelector("#password-toggle-icon")
          .classList.add("icon-eye-slash-regular");
      }
      const ele = document.querySelector("#inputpassword" + this.unique);
      if (ele.type === "password") {
        ele.type = "text";
      } else {
        ele.type = "password";
      }
    },
    // Following functions are for validating Number input functionalities
    focusNumber($event) {
      $event?.target?.parentElement?.children[1]?.focus();
      document.querySelector("#input-field" + this.unique).style.border =
        "1px solid " + "#54bd95";
      if (this.labelAnimation) {
        document.getElementById("label" + this.unique).style.color = "#54bd95";
      }
      this.minimum = "Minimum this.value: " + this.minLimit + " , ";
      this.maximum = "Minimum this.value: " + this.maxLimit;
      if (this.required === true) {
        this.showMandatory = true;
      }
      if (this.input_error_message !== "") {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("label" + this.unique).style.color =
            "#ff5050";
        }
      }
    },
    focusSingleNumber() {
      document.querySelector("#input-field" + this.unique).style.borderBottom =
        "1px solid " + "#54bd95";
      if (this.labelAnimation) {
        document.getElementById("label" + this.unique).style.color = "#54bd95";
      }
      this.minimum = "Minimum this.value: " + this.minLimit + " , ";
      this.maximum = "Minimum this.value: " + this.maxLimit;
      if (this.required === true) {
        this.showMandatory = true;
      }
      if (this.input_error_message !== "") {
        document.querySelector(
          "#input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("label" + this.unique).style.color =
            "#ff5050";
        }
      }
    },
    focusPhoneNumber($event) {
      $event?.target?.parentElement?.children[1]?.focus();
      document.querySelector("#input-field" + this.unique).style.border =
        "1px solid " + "#54bd95";
      if (this.labelAnimation) {
        document.getElementById("labelNumber" + this.unique).style.color =
          "#54bd95";
        document.getElementById("labelNumber2" + this.unique).style.color =
          "#54bd95";
      }
      this.minimum = "Minimum this.value: " + this.minLimit + " , ";
      this.maximum = "Minimum this.value: " + this.maxLimit;
      if (this.required === true) {
        this.showMandatory = true;
      }
      if (this.input_error_message !== "") {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("labelNumber" + this.unique).style.color =
            "#ff5050";
          document.getElementById("labelNumber2" + this.unique).style.color =
            "#ff5050";
        }
      }
    },
    blurNumber() {
      let label = document.getElementById("label" + this.unique);
      if (this.value !== "" && this.labelAnimation === true) {
        label?.classList?.add("force-focus-input");
      } else {
        label?.classList?.remove("force-focus-input");
      }
      document.querySelector("#input-field" + this.unique).style.border =
        "1px solid " + "#d8d8d8";
      if (this.labelAnimation) {
        document.getElementById("label" + this.unique).style.color = "#a6a6a6";
      }
      this.minimum = "";
      this.maximum = "";
      if (this.required === true) {
        this.showMandatory = true;
      }
      if (this.input_error_message !== "") {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("label" + this.unique).style.color =
            "#ff5050";
        }
      }
      this.$emit("focus-out", this.value);
    },
    blurSingleNumber() {
      document.querySelector("#input-field" + this.unique).style.borderBottom =
        "1px solid " + "#d8d8d8";
      if (this.labelAnimation) {
        document.getElementById("label" + this.unique).style.color = "#a6a6a6";
      }
      this.minimum = "";
      this.maximum = "";
      if (this.required === true) {
        this.showMandatory = true;
      }
      if (this.input_error_message !== "") {
        document.querySelector(
          "#input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("label" + this.unique).style.color =
            "#ff5050";
        }
      }
      this.$emit("focus-out", this.value);
    },
    blurPhoneNumber() {
      document.querySelector("#input-field" + this.unique).style.border =
        "1px solid " + "#d8d8d8";
      if (this.labelAnimation) {
        document.getElementById("labelNumber" + this.unique).style.color =
          "#a6a6a6";
        document.getElementById("labelNumber2" + this.unique).style.color =
          "#a6a6a6";
      }
      this.minimum = "";
      this.maximum = "";
      if (this.required === true) {
        this.showMandatory = true;
      }
      if (this.input_error_message !== "") {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("labelNumber" + this.unique).style.color =
            "#ff5050";
          document.getElementById("labelNumber2" + this.unique).style.color =
            "#ff5050";
        }
      }
      this.validatePhoneNumber();
      this.$emit("focus-out", this.value);
    },
    hoverNumber() {
      const elem = document.querySelector("#inputnumber" + this.unique);
      if (elem !== document.activeElement) {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#54bd95";
        //   if (this.labelAnimation) {
        //   document.getElementById("label" + this.unique).style.color =
        //     "#54bd95";
        // }
      }
    },
    hoverSingleNumber() {
      const elem = document.querySelector("#inputnumber" + this.unique);
      if (elem !== document.activeElement) {
        document.querySelector(
          "#input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#54bd95";
        //   if (this.labelAnimation) {
        //   document.getElementById("label" + this.unique).style.color =
        //     "#54bd95";
        // }
      }
    },
    unHoverNumber() {
      const elem = document.querySelector("#inputnumber" + this.unique);
      if (elem !== document.activeElement) {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#d8d8d8";
        if (this.labelAnimation) {
          document.getElementById("label" + this.unique).style.color =
            "#a6a6a6";
        }
      }
      if (this.input_error_message !== "") {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("label" + this.unique).style.color =
            "#ff5050";
        }
      }
    },
    unHoverSingleNumber() {
      const elem = document.querySelector("#inputnumber" + this.unique);
      if (elem !== document.activeElement) {
        document.querySelector(
          "#input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#d8d8d8";
        if (this.labelAnimation) {
          document.getElementById("label" + this.unique).style.color =
            "#a6a6a6";
        }
      }
      if (this.input_error_message !== "") {
        document.querySelector(
          "#input-field" + this.unique
        ).style.borderBottom = "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("label" + this.unique).style.color =
            "#ff5050";
        }
      }
    },
    unHovePhonerNumber() {
      const elem = document.querySelector("#inputnumber" + this.unique);
      if (elem !== document.activeElement) {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#d8d8d8";
        if (this.labelAnimation) {
          document.getElementById("labelNumber" + this.unique).style.color =
            "#a6a6a6";
          document.getElementById("labelNumber2" + this.unique).style.color =
            "#a6a6a6";
        }
      }
      if (this.input_error_message !== "") {
        document.querySelector("#input-field" + this.unique).style.border =
          "1px solid " + "#ff5050";
        if (this.labelAnimation) {
          document.getElementById("labelNumber" + this.unique).style.color =
            "#ff5050";
          document.getElementById("labelNumber2" + this.unique).style.color =
            "#ff5050";
        }
      }
    },
    // Following functions are for validating Date input functionalities
    focusDate() {
      document.querySelector("#input-field" + this.unique).style.border =
        "1px solid " + "#54bd95";
      this.containerleft = document
        .querySelector("#input-field" + this.unique)
        .getBoundingClientRect().left;
      this.containertop = document
        .querySelector("#input-field" + this.unique)
        .getBoundingClientRect().top;
      this.containerwidth = document
        .querySelector("#input-field" + this.unique)
        .getBoundingClientRect().width;
    },
    focusSingleDate() {
      document.querySelector("#input-field" + this.unique).style.borderBottom =
        "1px solid " + "#54bd95";
      this.containerleft = document
        .querySelector("#input-field" + this.unique)
        .getBoundingClientRect().left;
      this.containertop = document
        .querySelector("#input-field" + this.unique)
        .getBoundingClientRect().top;
      this.containerwidth = document
        .querySelector("#input-field" + this.unique)
        .getBoundingClientRect().width;
    },
    blurDate() {
      if (new Date(this.value) == "Invalid Date" && this.value !== "") {
        console.log(new Date(this.value), "newDateFormatter");
        this.input_error_message =
          "Please enter a valid date in the format - month day year";
        if (this.singleBorder) {
          document
            .querySelector("#input-field" + this.unique)
            .classList.add("single-border-error");
          document.querySelector(
            "#input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#ff5050 !important";
        } else {
          document.querySelector("#input-field" + this.unique).style.border =
            "1px solid " + "#ff5050";
        }
        if (this.labelAnimation) {
          document.getElementById("label" + this.unique).style.color =
            "#ff5050";
        }
      } else if (this.customError == false) {
        this.newDateFormatter(new Date(this.value));
        this.input_error_message = "";
        if (this.labelAnimation) {
          document.getElementById("label" + this.unique).style.color =
            "#a6a6a6 ";
        }
        if (this.singleBorder) {
          document
            .querySelector("#input-field" + this.unique)
            .classList.remove("single-border-error");
          document.querySelector(
            "#input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#d8d8d8 !important";
        } else {
          document.querySelector("#input-field" + this.unique).style.border =
            "1px solid " + "#d8d8d8";
        }
      } else if (this.customError) {
        if (this.singleBorder) {
          document
            .querySelector("#input-field" + this.unique)
            .classList.add("single-border-error");
          document.querySelector(
            "#input-field" + this.unique
          ).style.borderBottom = "1px solid " + "#ff5050 !important";
        }
      }
      console.log(
        "date blurred",
        new Date(this.value) == "Invalid Date",
        this.value
      );
      this.show_calendar = false;
      this.$emit("focus-out", this.value);
      this.newDateFormatter(this.value);
    },
    blurSingleDate() {
      this.show_calendar = false;
      document.querySelector("#input-field" + this.unique).style.borderBottom =
        "1px solid " + "#d8d8d8";
      this.$emit("focus-out", this.value);
    },
    hoverDate() {
      // const elem = document.querySelector('#inputdate' + this.unique)
      // if (elem !== document.activeElement) {
      //   document.querySelector('#input-field' + this.unique).style.border =
      //     '1px solid ' + '#54bd95'
      // }
    },
    unHoverDate() {
      // const elem = document.querySelector('#inputdate' + this.unique)
      // if (elem !== document.activeElement) {
      //   document.querySelector('#input-field' + this.unique).style.border =
      //     '1px solid ' + '#d8d8d8'
      // }
    },
    validateNumber() {
      if (
        this.minLimit &&
        this.maxLimit !== undefined &&
        document.querySelector(".max") &&
        document.querySelector(".min")
      ) {
        if (this.value > this.maxLimit) {
          document.querySelector(".max").style.color = "#ff5050";
        } else {
          document.querySelector(".max").style.color = "#02b55c";
        }
        if (this.value < this.minLimit) {
          document.querySelector(".min").style.color = "#ff5050";
        } else {
          document.querySelector(".min").style.color = "#02b55c";
        }
        if (this.value === "") {
          document.querySelector(".max").style.color = "#02b55c";
          document.querySelector(".min").style.color = "#02b55c";
        }
      }
    },
    validateSingleNumber() {
      if (
        this.minLimit &&
        this.maxLimit !== undefined &&
        document.querySelector(".max") &&
        document.querySelector(".min")
      ) {
        if (this.value > this.maxLimit) {
          document.querySelector(".max").style.color = "#ff5050";
        } else {
          document.querySelector(".max").style.color = "#02b55c";
        }
        if (this.value < this.minLimit) {
          document.querySelector(".min").style.color = "#ff5050";
        } else {
          document.querySelector(".min").style.color = "#02b55c";
        }
        if (this.value === "") {
          document.querySelector(".max").style.color = "#02b55c";
          document.querySelector(".min").style.color = "#02b55c";
        }
      }
    },
  },
};
</script>

<!-- @import "../assets/styles/variables"; -->
<style lang="scss">
:root {
  --theme: var(--hlx-color-primary);
  --border: var(--hlx-border-color);
  --error: var(--hlx-color-error);
  --success: var(--hlx-color-success);
  --darkGrey: var(--hlx-border-color-dark);
}
.border_radio_button_ellipsis {
  white-space: nowrap;
  overflow: hidden;

  &.border_ellipsis {
    text-overflow: ellipsis;
    max-width: 100px;
  }
}

.force-focus-input {
  font-size: 12px !important;
  top: -9px !important;
  left: 6px !important;
}

.symbol-padding {
  padding-left: 18px !important;
}

.number-symbol-holder {
  position: relative;
  left: 12px;
  font-size: 14px;
  top: 8px;
  height: 100%;
  color: #565553;
}
</style>
