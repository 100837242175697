<template>
  <section
    :id="'hlx-pagination-container-hlx-' + Math.floor(Math.random() * 1000)"
    class="hlx-pagination-container"
    :class="{ 'rows-per-page': enableRowsPerPage, 'go-to': enableGoTo }"
  >
    <div v-if="enableRowsPerPage == true" class="hlx-rows-per-page-container">
      <slot name="custom-rows-per-page-title">
        <p v-if="options.attributes.rowsPerPageTitle" class="rpp-title">
          {{labelValue.Rows_per_page}}
        </p>
      </slot>
      <span class="custom-select-wrapper">
        <hlx-select
          v-model:value="rowsPerPageCount"
          :pre-value="rowsPerPageCount"
          :options="computedRowsPerPage"
          :clearable="false"
          :placeholder_value="''"
          @custom-change="updateRowsPerPage"
        ></hlx-select>
      </span>
      <slot name="custom-rows-per-page-result">
        <p v-if="options.attributes['rowsPerPageResult']" class="rpp-result">
          {{labelValue.Showing}} {{ result.from }} - {{ result.to }} {{labelValue.of}} {{ total }} {{labelValue.results}}
        </p>
      </slot>
    </div>

    <section class="pagination-and-goto">
      <div
        v-if="enableGoTo == true && isPagination === true"
        class="hlx-go-to-container"
      >
        <div class="go-to-wrapper">
          <p>{{ labelValue.Go_to }}</p>
          <div class="input-area">
            <hlx-input
              v-model:value="goToPageNumber"
              type="number"
              label-value="Number"
              :clearable="false"
              :pre-val="goToPageNumber"
              @at-enter="goToPage"
            />
          </div>
        </div>
      </div>
      <div v-show="isPagination === true" class="hlx-pagination">
        <!-- Left nav -->
        <span ref="prevBtn" class="nav prev-btn" @click="previousPage()"
          ><i class="icon-angle-left-regular"></i>
        </span>

        <!-- 1st pager -->
        <div
          v-if="1 !== totalPages"
          class="page-btn"
          :class="{ higlight: currentPage == 1 }"
          :page="1"
          @click="handleCurrentChange(1)"
        >
          1
        </div>

        <!-- Seperator dot left -->
        <div class="page-btn dec" :class="{ hide: hideLeftNav }">
          <!-- @click="previousPage()" -->
          <i class="icon-more-horizontal-regular"></i>
        </div>

        <!-- Middle pagers -->
        <div
          v-for="(page, pageIndex) in pageNumbers"
          :key="pageIndex"
          class="page-btn"
          :page="page"
          :class="{
            higlight: currentPage == page,
            background: background == true
          }"
          @click="handleCurrentChange(page)"
        >
          {{ page }}
        </div>

        <!-- Seperator dot right -->
        <div class="page-btn inc" :class="{ hide: hiderightNav }">
          <!-- @click="nextPage()" -->
          <i class="icon-more-horizontal-regular"></i>

          <!-- <i class="icon-angle-right-regular dec-icon"></i> -->
        </div>

        <!-- Last pager -->
        <div
          class="page-btn"
          :page="totalPages"
          :class="{
            higlight: currentPage == totalPages,
            background: background
          }"
          @click="handleCurrentChange(totalPages)"
        >
          {{ totalPages }}
        </div>

        <!-- Next nav -->
        <span
          ref="nextBtn"
          class="nav next-btn"
          :class="{ disabled: currentPage == totalPages }"
          :style="{ 'pointer-events': 'none' }"
          @click="nextPage()"
          ><i class="icon-angle-right-regular"> </i>
        </span>
      </div>
    </section>
    <input
      class="hlx-pagination-input-value-hidden"
      :value="currentPage"
      type="number"
      @input="$emit('update:modelValue', currentPage)"
    />
    <input
      class="hlx-pagination-input-value-hidden"
      :value="rowsPerPageCount"
      type="number"
      @input="$emit('update:rowsPerPage', rowsPerPageCount)"
    />
  </section>
</template>
<script>
import { ref } from "vue";
import hlxSelect from "./SelectComponent.vue";
import HlxInput from "./InputComponent.vue";

export default {
  name: "HlxPagination",
  components: {
    hlxSelect,
    HlxInput
  },
  props: {
    labelValue: {
      type: Object,
      default: function () {
        return {
          Rows_per_page : "Rows per page",
          Showing: "Showing",
          of: "of",
          results: "results",
          Go_to: "Go to",
        };
      },
    },
    total: {
      type: Number,
      default: 0
    },
    modelValue: {
      type: Number,
      default: 1
    },
    rowsPerPage: {
      type: Number,
      default: 5
    },
    // pagerCount: {
    //   type: Number,
    //   default: 5
    // },
    rowsPerPageList: {
      type: Array,
      default: () => {
        return [5, 10, 20];
      }
    },
    background: {
      type: Boolean,
      default: false
    },
    enableRowsPerPage: {
      type: Boolean,
      default: false
    },
    enableGoTo: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {
        let obj = {};
        obj["attributes"] = {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 40,
              right: 0
            }
          }
        };
 
        return obj;
      }
    }
  },
  emits: [
    "current-page",
    "updated:rows-per-page",
    "update:rowsPerPage",
    "go-to",
    "update:modelValue"
  ],
  data() {
    return {
      result: {
        from: 0,
        to: 0
      },
      goToPageNumber: 1,
      rowsPerPageCount: 5,
      dropdown_data: [
        { name: 5, value: 5, checked: true, disabled: false, id: 32 },
        { name: 10, value: 5, checked: false, disabled: false, id: 33 },
        { name: 20, value: 5, checked: false, disabled: false, id: 34 }
      ],
      currentPage: ref(1),
      pageNumbers: ref([]),
      hideLeftNav: ref(true),
      hiderightNav: ref(true)
    };
  },
  computed: {
    isPagination() {
      return this.totalPages > 1 ? true : false;
    },
    computedRowsPerPage() {
      return this.rowsPerPageList.map((item, i) => {
        return {
          name: item,
          value: item,
          // checked: i == 0 ? true : false,
          disabled: false,
          id: 32 + i
        };
      });
    },
    totalPages() {
      return Math.ceil(this.total / this.rowsPerPageCount);
    },
    computedPager() {
      // if(this.pagerCount == this.totalPages){
      //   return 1
      // }
      // ;
      // if (this.totalPages < 5) {
      //   if (Math.round(this.totalPages / 2) - 2 <= this.pagerCount) {
      //     return this.totalPages;
      //   } else if (Math.round(this.totalPages / 2) - 2 >= this.pagerCount) {
      //     return 5;
      //   }
      //   return this.totalPages;
      // }
      // if (this.pagerCount === undefined && this.totalPages < 5) {
      //   return this.totalPages;
      // } else if (!this.pagerCount) {
      //   return 5;
      // } else {
      //   return (this.pagerCount - 2) % 2 == 0
      //     ? this.pagerCount - 2 + 1
      //     : this.pagerCount - 2;
      // }
      if (this.totalPages <= 6) {
        // return Math.abs(this.totalPages - 2);
        return this.totalPages - 2;
        // return 5
      }
      return 5;
    }
  },
  watch: {
    total: {
      handler(newTotalPage, oldTotalPage) {
        if (newTotalPage && newTotalPage != oldTotalPage) {
          this.currentPage = 1;
          this.customWatch(1);
        }
      },
      immediate: true
    },
    // totalPages: {
    //   handler(i, o) {
    //

    //   }
    // },
    modelValue: {
      handler(page) {
        this.currentPage = page;
      },
      immediate: true
    },
    currentPage: {
      handler(curr_page) {
        this.customWatch(curr_page);
        // this.handleEnableDisableSeperator(curr_page);
        this.$emit("current-page", this.currentPage);
      },
      immediate: true
    },
    rowsPerPage: {
      handler(rowsPerPage) {
        if (
          !rowsPerPage ||
          (this.rowsPerPageList[0] != rowsPerPage && rowsPerPage == 5)
        ) {
          this.rowsPerPageCount = this.rowsPerPageList[0];
        } else {
          this.rowsPerPageCount = this.rowsPerPage;
        }
        // this.rowsPerPageCount = rowsPerPage
      },
      immediate: true,
      deep: true
    },
    rowsPerPageList: {
      handler() {
        this.customWatch(this.currentPage);
        // this.$emit("current-rows-per-page", this.rowsPerPageCount);
      },
      immediate: true
    }
  },
  mounted() {
    this.pageNumbers = [];
    // Check prop and 1st value is same in rowsperpage
    if (
      !this.rowsPerPage ||
      (this.rowsPerPageList[0] != this.rowsPerPage && this.rowsPerPage == 5)
    ) {
      this.rowsPerPageCount = this.rowsPerPageList[0];
    } else {
      this.rowsPerPageCount = this.rowsPerPage;
    }
    // let curr_page = this.modelValue;
    this.currentPage = this.modelValue;
    // this.cu
    // if (this.totalPages == this.computedPager) {
    //   for (let i = 0; i < this.computedPager - 2; i++) {
    //     this.pageNumbers.push(++curr_page);
    //   }
    // } else {
    //   for (let i = 0; i < this.computedPager; i++) {
    //     this.pageNumbers.push(++curr_page);
    //   }
    // }
    this.customWatch(this.currentPage);

    // this.handleEnableDisableSeperator(this.currentPage)
    const prevBtn = this.$refs.prevBtn;
    const nextBtn = this.$refs.nextBtn;
    if (prevBtn) {
      prevBtn.style.pointerEvents = "none";
      nextBtn.style.pointerEvents = "auto";
      prevBtn.classList.add("disabled");
    }
    this.result = {
      from: 1,
      to: this.rowsPerPageCount
    };
    // if (this.totalPages > 6) {
    //   this.hiderightNav = false;
    // }
    this.showingResult();
  },

  methods: {
    handleEnableDisableSeperator(currentPage) {
      //
      let middlePager = Math.round(this.computedPager / 2);
      // if(currentPage == 1 && )
      // if(this.totalPages < this.totalPages - middlePager)
      if (this.totalPages <= 6) {
        this.hideLeftNav = true;
        this.hiderightNav = true;

        return;
      }

      if (currentPage >= this.computedPager && this.totalPages != currentPage) {
        this.hideLeftNav = false;
        if (currentPage < this.totalPages - middlePager) {
          this.hiderightNav = false;
        }
        if (currentPage >= this.totalPages - middlePager) {
          this.hiderightNav = true;
        }
      }
      if (currentPage < this.computedPager) {
        this.hideLeftNav = true;
        if (this.computedPager < this.totalPages) {
          this.hiderightNav = false;
        }
      }
      if (currentPage == this.totalPages) {
        this.hiderightNav = true;
        if (currentPage > this.computedPager) {
          this.hideLeftNav = false;
        }
      }
      // if(currentPage<)
    },
    showingResult() {
      let from =
        this.rowsPerPageCount * this.currentPage - this.rowsPerPageCount + 1;
      let to = this.rowsPerPageCount * this.currentPage;
      if (this.currentPage == this.totalPages) {
        to = this.total;
        // ;
      }
      //

      //
      this.result = {
        from,
        to
      };
      // return this.result
    },
    preparePageNumbers(start, limit) {
      let pageNumbers = [];
      for (let i = 0; i < limit; i++) {
        pageNumbers.push(start++);
      }
      return pageNumbers;
    },
    goToPage({ value }) {
      if (value >= this.totalPages) {
        this.currentPage = this.totalPages;
      } else if (value <= 1) {
        this.currentPage = 1;
      } else {
        this.currentPage = value;
      }
      this.goToPageNumber = this.currentPage;
      this.$emit("go-to", this.goToPageNumber);
    },
    customWatch(currentPage) {
      this.handleEnableAndDisableOfNav(currentPage);
      this.handleEnableDisableSeperator(currentPage);
      this.showingResult();
      let middleOfPager = Math.round(this.computedPager / 2);

      if (currentPage < this.computedPager || currentPage == 1) {
        this.pageNumbers = this.preparePageNumbers(2, this.computedPager);
        return;
      }
      if (
        currentPage >= this.computedPager &&
        currentPage < this.totalPages - middleOfPager
      ) {
        this.pageNumbers = this.preparePageNumbers(
          currentPage - (middleOfPager - 1),
          this.computedPager
        );
        return;
      }
      if (
        currentPage >= this.computedPager &&
        currentPage >= this.totalPages - middleOfPager
      ) {
        this.pageNumbers = this.preparePageNumbers(
          this.totalPages - this.computedPager,
          this.computedPager
        );
        return;
      }

      // if (currentPage == this.totalPages) {
      //   this.pageNumbers = this.preparePageNumbers(
      //     this.totalPages - this.computedPager,
      //     this.computedPager
      //   );
      //   return;
      // }
    },
    handleEnableAndDisableOfNav(curr_page) {
      /**
       * @Disable previous button if page was 1
       * and remove @Disable from next button
       */
      if (curr_page <= 1) {
        const prevBtn = this.$refs.prevBtn;

        const nextBtn = this.$refs.nextBtn;

        if (prevBtn && nextBtn) {
          prevBtn.classList.add("disabled");
          // ;
          prevBtn.style.pointerEvents = "none";
          nextBtn.style.pointerEvents = "auto";
          nextBtn.classList.remove("disabled");
        }
      }

      /**
       * remove @disable from next and previous button if page between first and last page
       */
      if (curr_page > 1 && curr_page < this.totalPages) {
        const prevBtn = this.$refs.prevBtn;

        const nextBtn = this.$refs.nextBtn;

        if (prevBtn && nextBtn) {
          // ;

          prevBtn.style.pointerEvents = "auto";
          nextBtn.style.pointerEvents = "auto";
          prevBtn.classList.remove("disabled");
          nextBtn.classList.remove("disabled");
        }
      }
      /**
       * @Disable next button and activate previous button if page is last
       */
      if (curr_page >= this.totalPages) {
        const nextBtn = this.$refs.nextBtn;
        const prevBtn = this.$refs.prevBtn;

        if (nextBtn && prevBtn) {
          nextBtn.style.pointerEvents = "none";
          nextBtn.classList.add("disabled");
          prevBtn.classList.remove("disabled");
          prevBtn.style.pointerEvents = "auto";
        }
      }
    },
    updateRowsPerPage() {
      this.currentPage = 1;
      this.customWatch(this.currentPage);

      this.$emit("updated:rows-per-page", this.rowsPerPageCount);
      this.$emit("update:rowsPerPage", this.rowsPerPageCount);
      // if (this.currentPage >= this.totalPages) {
      //   // this.currentPage = 1
      //   this.customWatch(1);
      // } else {
      //   if (this.currentPage >= this.totalPages) {
      //     this.currentPage = this.totalPages;
      //     this.customWatch(this.currentPage);
      //     this.$emit("updated:rows-per-page", this.rowsPerPageCount);
      //     return;
      //   }
      //   this.$emit("updated:rows-per-page", this.rowsPerPageCount);
      //   return this.customWatch(this.currentPage);
      // }
    },
    defaultPageNumbers(type) {
      if (type == "prev") {
        this.pageNumbers = [];
        let curr_page = 1;
        for (let i = 0; i < this.computedPager; i++) {
          this.pageNumbers.push(++curr_page);
        }
      }
    },
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    previousPage() {
      this.currentPage > 1 ? this.currentPage-- : "";
    },
    nextPage() {
      this.currentPage < this.totalPages ? this.currentPage++ : "";
    }
  }
};
</script>
