<template>
  <div class="component-example tree-grid-background">
    <div style="display: flex; justify-content: space-between">
      <div v-if="type === 'product'" class="new-tree-legent">
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-box-check-regular tree-icone3-legent"> </i></span
          ><span class="legent-name"> Product Specification</span>
        </div>
        <div class="tree-legent-full-detail">
          <span>
            <i
              class="icon-users-group-alt-regular tree-icone2-legent"
            ></i> </span 
          ><span class="legent-name"> Customer Facing Service</span>
        </div>
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-shield-bolt-regular tree-icon-legent"></i> </span
          ><span class="legent-name"> Resource Facing Service</span>
        </div>
        <div class="tree-legent-full-detail">
          <span>
            <i
              class="icon-sd-card-regular tree-icon-Physical-legent"
            ></i> </span
          ><span class="legent-name"> Physical Resource</span>
        </div>
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-cpu-regular tree-icon-Logical-legent"></i> </span
          ><span class="legent-name">Logical Resource</span>
        </div>
      </div>
      <div
        v-if="
          type === 'service' && nodes.service.name === 'Customer Facing Service'
        "
        class="new-tree-legent"
      >
        <div class="tree-legent-full-detail">
          <span>
            <i
              class="icon-users-group-alt-regular tree-icone2-legent"
            ></i> </span
          ><span class="legent-name"> Customer Facing Service</span>
        </div>
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-shield-bolt-regular tree-icon-legent"></i> </span
          ><span class="legent-name"> Resource Facing Service</span>
        </div>
        <div class="tree-legent-full-detail">
          <span>
            <i
              class="icon-sd-card-regular tree-icon-Physical-legent"
            ></i> </span
          ><span class="legent-name"> Physical Resource</span>
        </div>
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-cpu-regular tree-icon-Logical-legent"></i> </span
          ><span class="legent-name">Logical Resource</span>
        </div>
      </div>
      <div
        v-if="
          type === 'service' && nodes.service.name === 'Resource Facing Service'
        "
        class="new-tree-legent"
      >
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-shield-bolt-regular tree-icon-legent"></i> </span
          ><span class="legent-name"> Resource Facing Service</span>
        </div>
        <div class="tree-legent-full-detail">
          <span>
            <i
              class="icon-sd-card-regular tree-icon-Physical-legent"
            ></i> </span
          ><span class="legent-name"> Physical Resource</span>
        </div>
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-cpu-regular tree-icon-Logical-legent"></i> </span
          ><span class="legent-name">Logical Resource</span>
        </div>
      </div>
      <div
        v-if="type === 'resource' && nodes.service.name === 'Physical Resource'"
        class="new-tree-legent"
      >
        <div class="tree-legent-full-detail">
          <span>
            <i
              class="icon-sd-card-regular tree-icon-Physical-legent"
            ></i> </span
          ><span class="legent-name"> Physical Resource</span>
        </div>
      </div>
      <div
        v-if="type === 'resource' && nodes.service.name === 'Logical Resource'"
        class="new-tree-legent"
      >
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-cpu-regular tree-icon-Logical-legent"></i> </span
          ><span class="legent-name">Logical Resource</span>
        </div>
      </div>

      <!-- <div v-if="type === 'orderTree'" class="new-tree-legent">
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-box-check-regular tree-icone3-legent">
            </i></span
          ><span class="legent-name">Product order</span>
        </div>
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-users-group-alt-regular tree-icone2-legent"></i> </span
          ><span class="legent-name">Service order</span>
        </div>
        background: rgba(246, 218, 237, 0.7);
  color: rgba(237, 104, 187, 1);
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-shield-bolt-regular tree-icon-legent"></i> </span
          ><span class="legent-name">Resource Order</span>
        </div>
     background: rgba(101, 155, 185, 0.23);
  color: rgba(66, 144, 189, 1);
        <div class="tree-legent-full-detail">
          <span>
            <i class="icon-tag-regular tree-icon-Logical-legent"></i> </span
          ><span class="legent-name">Order Item</span>
        </div>
         background-color: rgba(175, 140, 233, 0.45);
  color: #af8ce9;
      </div> -->

      <div class="tree-zoom">
        <span class="tree-zoom-background">
          <span class="zoomOut" @click="zoomout()">
            <i class="icon-minus-filled"></i
          ></span>
          <span class="vl"></span>
          <span class="zoomIn" @click="zoomin()">
            <i class="icon-plus-filled"></i
          ></span>
        </span>
      </div>
    </div>
    <div class="example-body custom">
      <div class="tree-container">
        <ul
          id="hlx-tree-011"
          ref="hlx-tree-structure"
          class="hlx-tree-area-upc"
          :class="direction ? direction : 'vertical'"
          :isDraggable="isDraggable"
          @mousedown="mouseDown"
          @mouseup="mouseUp"
          @mousemove="mouseMove"
          @mouseleave="mouseleaveHandler"
        >
          <hlx-tree-child-v2
          :optionvalue="optiondata"
          :displayvalue="displaydata"
            :nodes="nodes"
            :type="type"
            :read-only="readOnly"
            :parent-name="parentName"
            @card-nodes="Cardemit"
            @context="fromAddContextMenu"
            @Parent-detail="ParentDetail"
          />
        </ul>
      </div>
    </div>
  </div>
  <!-- class="tree" :class="tree_direction" -->

  <hlx-drawer
    :show="showAddTreePanel"
    position="right"
    width="400"
    :footer="true"
    :title="'Edit notes'"
    @close="closeTreePannel"
  >
    <template #body>
      <div class="header-area-contact-drawer">
        <span
          style="
            display: flex;
            width: 25px;
            height: 25px;
            position: fixed;
            right: 400px;
            background: white;
            z-index: 1000;
            top: 0;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            box-shadow: -2px 0px 6px 0px #b9b6b6;
            cursor: pointer;
          "
          @click="showAddTreePanel = false"
        >
          <i class="icon-times-regular"></i>
        </span>
        <b v-if="editMode == false"> {{ labelvalue?.Add_Specification }}</b>
        <b v-else> {{ labelvalue?.Edit_Specification }}</b>
      </div>
      <div class="body-area-contact-drawer">
        <main
          class="tree-panel"
          style="
            border-top: 1px solid rgb(216, 216, 216);
            margin-top: 13px;
            padding-top: 8px;
          "
        >
          <div style="margin-top: 10px; margin-bottom: 25px">
            <hlx-select
              :key="selectcomponentkey"
              :pre-value="servicePreValue"
              :options="service_dropdown_data"
              :disabled="editServiceDisable"
              :label="'name'"
              :prop-value="'value'"
              :clearable="true"
              :placeholder-value="placeholderValue"
              :label-animation="true"
              @selected-value="optionChosenService"
              @custom-error-emit-value="checkval"
            >
            </hlx-select>
          </div>

          <hlx-select
            :key="selectcomponentkey"
            v-model:value="specificationPreValue"
            :pre-value="specificationPreValue"
            :clearable="true"
            :scroll-classname="'tree-panel'"
            :options="specification_dropdown_data"
            :label="'name'"
            :placeholder-value="'Specification'"
            :prop-value="'name'"
            :label-animation="true"
            :inline-search="true"
            @selected-value="optionChosenSpecification"
            @custom-error-emit-value="checkval1"
          >
          </hlx-select>
        </main>
      </div>
    </template>
    <template #footer>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding: 19px;
          border-top: 1px solid #d8d8d8;
        "
      >
        <hlx-button class="secondary sm" @click="closeTreePannel"
          >
          {{labelvalue?.Cancel}}
          </hlx-button
        >
        <hlx-button
          v-if="editMode == false"
          class="primary sm"
          style="margin-left: 10px"
          :disabled="addButtonDisable"
          @click="addChild(currentnodesValue)"
          >
          <!-- Add -->
           {{  labelvalue?.Add}}
          </hlx-button
        >
        <hlx-button
          v-else
          class="primary sm"
          style="margin-left: 10px"
          @click="editnodes(currentnodesValue)"
          >
          <!-- Save -->
          {{  labelvalue?.Save}}
          </hlx-button
        >
      </div>
    </template>
  </hlx-drawer>
  <div class="tree-delete-modelbox">
    <hlx-modal
      :modal-active="modal_active_delete"
      :height="'220px'"
      :width="'400px'"
      :modal="true"
      :close-icon="false"
      :outer-layer-close-option="true"
    >
      <template #header>
        <div>
          <!-- Delete data -->
          {{  labelvalue?.Delete_data}}
        </div>
      </template>
      <template #content>
        <div class="modal-content">
          <div style="margin-left: 11px">
            <!-- Are you sure you want to delete -->
            {{  labelvalue?.content}}
            <b>{{ currentnodesValue?.specification?.name }}</b> ?
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button
          class="secondary-success sm"
          @click="modal_active_delete = false"
          >
          <!-- Cancel -->
          {{labelvalue?.Cancel}}
          </hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          @click="deletenodes(currentnodesValue)"
          >
          <!-- Delete -->
          {{labelvalue?.Delete}}
          </hlx-button
        >
      </template>
    </hlx-modal>
  </div>
</template>

<script>
import axios from "axios";
import hlxTreeChildV2 from "./TreeChildVersion2Component.vue";
import hlxModal from "../components/ModalwindowComponent.vue";
import hlxSelect from "./SelectComponent.vue";
import HlxDrawer from "../components/DrawerComponent.vue";
import HlxButton from "../components/ButtonComponent.vue";
export default {
  name: "HlxTreeV2",

  components: {
    hlxTreeChildV2,
    HlxButton,
    hlxSelect,
    hlxModal,
    HlxDrawer,
  },
  props: {
    optiondata: {
      type: Object,
      default: function () {
        return {
          Link_a_child:"Link a child",
          Remove: "Remove",
          Delete: "Delete",
          Add: "Add",
          Edit: "Edit"
        };
      },
    },
    labelvalue: {
      type: Object,
      default: function () {
        return {
          Add_Specification:"Add Specification",
          Edit_Specification: "Edit Specification",
          Specification:"Specification",
          Delete_data : "Delete data",
          content: "Are you sure you want to delete",
          Cancel: "Cancel",
          Delete: "Delete",
          Save: "Save",
          Add: "Add"
        };
      },
    },
    config: {
      type: Object,
      default: function () {
        return {
          url: "",
          key: "",
        };
      },
    },
    displaydata: {
      type: String,
      default: "label",
    },
    type: {
      type: String,
      default: () => "product",
    },
    nodes: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customerFacingServiceOption: {
      type: Array,
      default: () => {
        return [];
      },
    },
    resourceFacingServiceOption: {
      type: Array,
      default: () => {
        return [];
      },
    },
    logicalResourceOption: {
      type: Array,
      default: () => {
        return [];
      },
    },
    physicalResourceOption: {
      type: Array,
      default: () => {
        return [];
      },
    },
    direction: {
      type: String,
      default: () => "vertical",
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    zoomable: {
      type: Boolean,
      default: false,
    },

    expandAll: {
      type: Boolean,
      default: false,
    },
    collapseAll: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "response",
    "nodesdata",
    "updated-data",
    "card-nodes",
    "currentdata",
    "currenteditdata",
    "delete-nodes",
    "zoomIn",
    "zoomOut",
    "updated-node", 'addTreeLog', 'context', 'editTreeLog', 'deleteTreeLog',
  ],
  data() {
    return {
      speciifcationintialVal:"",
      updatedNode: [],
      parentdetail: {},
      addButton: 0,
      addButtonDisable: true,
      editServiceDisable: false,
      modal_active_delete: false,
      servicePreValue: "",
      specificationPreValue: "",
      currentnodesValue: {},
      selectcomponentkey: 0,
      modalcomponentkey: 0,
      service_dropdown_data: [],
      specification_dropdown_data: [],
      specification: {},
      service: {},
      showAddTreePanel: false,
      editMode: false,
      ele: null,
      isMouseDown: false,
      initX: 0,
      initY: 0,
      height: 0,
      scale: 1,
      start: { x: 0, y: 0 },
      widht: 0,
      zoom: 100,
      treedata: {},
      serviceOptionLength: 0,
      specificationOptionLength: 0,
    };
  },
  watch: {
    service: {
      handler(val) {
        console.log(val);
      },
    },
    specification: {
      handler(val) {
        console.log(val);
      },
    },
    specificationOptionLength: {
      handler(val) {
        console.log(val, this.serviceOptionLength);
        if (val > 0) {
          this.addButtonDisable = false;
        } else {
          this.addButtonDisable = true;
        }
      },
      immediate: true,
      deep: true,
    },
    // addButton: {
    //   handler(val) {
    //     console.log(val);
    //     if (val > 0  ) {
    //       this.addButtonDisable = true;
    //     } else {
    //       this.addButtonDisable = false;
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    type: {
      handler(val) {
        if (val === "product") {
          this.placeholderValue = "Choose the service";
        } else if (val === "resource") {
          this.placeholderValue = "Type of resource";
        } else if (val === "service") {
          this.placeholderValue = "Choose resource";
        } else {
          this.placeholderValue = "Type of resource";
        }
      },
      immediate: true,
      deep: true,
    },
    nodes: {
      handler(val) {
        this.treedata = val;
        this.$nextTick(() => {
          if (this.readOnly === true) {
            const i = document.querySelectorAll(".tempp-container");
            i.forEach((e) => {
              e.style.display = "none";
            });
          }
        });
      },
      immediate: true,
      deep: true,
    },
    readOnly: {
      handler(val) {
        const i = document.querySelectorAll(".cardhover");

        if (i) {
          const isContextActive = document.querySelector(".active-context");

          const handleEvent = (e, displayValue) => {
            const el = e?.children[0]?.children[0]?.children[0]?.children[2];
            if (el) {
              // console.log("Element found:", el);
              if (el.style) {
                el.style.display = displayValue;
              } else {
                // console.error("Element does not have a style property:", el);
              }
            } else {
              // console.error("Element not found:", e);
            }
          };

          i.forEach((e) => {
            e.addEventListener("mouseover", () => {
              if (val === true && this.readOnly === true) {
                // handleEvent(e, "none");
              } else if (val === false) {
                handleEvent(e, "flex");
              }
            });

            e.addEventListener("mouseleave", () => {
              if (!isContextActive) {
                handleEvent(e, "none");
              }
            });
          });
        }
      },

      immediate: true,
      deep: true,
    },
    zoom: {
      handler(n) {
        if (isNaN(n)) return;
        if (n > 100) {
          this.zoomIn(n);
        } else {
          this.zoomOut(n);
        }
      },
      immediate: true,
    },
    expandAll: {
      handler(expand) {
        if (expand) {
          let ele = this.$refs["hlx-tree-structure"];
          //check if element is not null
          if (ele) {
            let allChild = ele.querySelector("li")?.querySelectorAll("ul.hide");
            allChild = [...allChild];
            allChild.filter((childEl) => {
              if (childEl && childEl.classList.contains("hide")) {
                childEl.classList.remove("hide");
              }
            });
          }
        }
      },
      immediate: true,
    },
    collapseAll: {
      handler(collapse) {
        if (collapse) {
          let ele = this.$refs["hlx-tree-structure"];
          // //console.log("🚀 ~ file: TreeComponent.vue:91 ~ handler ~ ele:", ele);
          //check if element is not null
          if (ele) {
            let allChild = ele.querySelector("li")?.querySelectorAll("ul");
            allChild = [...allChild];
            allChild.filter((childEl) => {
              if (childEl && !childEl.classList.contains("hide")) {
                childEl.classList.add("hide");
              }
            });
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.ele = this.$refs["hlx-tree-structure"];
  },
  methods: {
  Cardemit(nodes) {
      console.log(nodes, "Cardemit");
      this.$emit("card-nodes", nodes);
    },
    ParentDetail(val) {
      this.parentdetail = val;
    },
    zoomin() {
      if (this.zoom < 200) {
        this.zoom += 10;
        // console.log(this.zoom);
      }
    },
    zoomout() {
      if (this.zoom > 30) {
        this.zoom -= 10;
        // console.log(this.zoom);
      }
    },
    deletenodes(val) {
      this.deleteLabelRecursive(this.nodes, val.id);
console.log(val,"deletenodes");

      this.$emit("deleteTreeLog", val.specification , val.service);
      this.$emit("updated-data", this.nodes);
      this.modal_active_delete = false;
    },
    deleteLabelRecursive(nodes, labelToDelete) {
      if (!nodes.child) return;

      nodes.child = nodes.child.filter((childnodes) => {
        if (childnodes.id === labelToDelete) {
          return false;
        }
        this.deleteLabelRecursive(childnodes, labelToDelete);
        return true;
      });
    },
    editnodes(val) {
      let child = [];
      if (val?.child) {
        child = val.child;
        (val.service = this.service), (val.specification = this.specification);
        val["child"] = child;
      } else {
        (val.service = this.service), (val.specification = this.specification);
      }
      console.log({from:this.speciifcationintialVal,to:this.specification.name}, ( this.service), ( this.specification));
      
      this.$emit("editTreeLog",this.specification , this.service , this.speciifcationintialVal )
      this.closeTreePannel();
    },
    generateUniqueId() {
      return Math.random().toString(36).substr(2, 9);
    },
    async addChild(val) {
      // console.log(this.specification, this.specification?._id);
      this.addButtonDisable = true;
      const drop = await axios.get(
        `${this.config.url}/${this.specification?._id}`,
        {
          headers: {
            "hlx-api-key": this.config.key,
          },
        }
      );
      // console.log(this.config.url);
      // console.log(drop.data.tree.child, drop.data, "drop.data");
      if (
        drop?.data?.tree?.child != undefined &&
        this.specification?._id != undefined
      ) {
        if (val?.child) {
          val.child.push(drop?.data?.tree);
        } else {
          val.child = [];
          val.child.push(drop?.data?.tree);
        }
      } else if (
        drop?.data?.tree?.child == undefined ||
        this.specification?._id == undefined
      ) {
        if (val?.child) {
          val.child.push({
            id: this.generateUniqueId(),
            service: this.service,
            specification: this.specification,
          });

          this.$emit("updated-node", {
            parent: this.parentdetail,
            child: { service: this.service, specification: this.specification },
          });
        } else {
          val.child = [];
          val.child.push({
            id: this.generateUniqueId(),
            service: this.service,
            specification: this.specification,
          });
          this.$emit("updated-node", {
            parent: this.parentdetail,
            child: { service: this.service, specification: this.specification },
          });
        } 
      }
      console.log(this.specification , this.service);
      
      this.$emit("addTreeLog",this.specification , this.service)
      this.$emit("updated-data", this.nodes);
      this.closeTreePannel();
    },
    closeTreePannel() {
      // this.modalcomponentkey += 1;
      // this.selectcomponentkey += 1;
      this.addButtonDisable = false;
      this.editMode = false;
      this.showAddTreePanel = false;
      this.service = {};
      this.specification = {};
      this.specificationOptionLength = 0;
      this.serviceOptionLength = 0;
    },
    async optionChosenSpecification(val) {
      this.specification = val;
      this.specificationOptionLength = this.specification.name.length;

      // console.log(val, drop.data, "optionChosenSpecification");
    },
    optionChosenService(val) {
      this.service = val;
      this.serviceOptionLength = this.service.name.length;
      // console.log(val);
      if (this.service?.name === "Customer Facing Service") {
        this.specification_dropdown_data = this.customerFacingServiceOption;
      } else if (this.service?.name === "Resource Facing Service") {
        this.specification_dropdown_data = this.resourceFacingServiceOption;
      } else if (this.service?.name === "Physical Resource") {
        this.specification_dropdown_data = this.physicalResourceOption;
      } else if (this.service?.name === "Logical Resource") {
        this.specification_dropdown_data = this.logicalResourceOption;
      }
    },
    fromAddContextMenu(val, nodes, spechName) {
      // console.log(val, "delete");
      this.currentnodesValue = nodes;
      if (spechName === "service") {
        this.service_dropdown_data = [
          {
            name: "Customer Facing Service",
            value: "Customer Facing Service",
            icones: "icon-users-group-alt-regular tree-icone2",
          },
          {
            name: "Resource Facing Service",
            value: "Resource Facing Service",
            icones: "icon-shield-bolt-regular tree-icon",
          },
          {
            name: "Physical Resource",
            value: "Physical Resource",
            icones: "icon-sd-card-regular tree-icon-Physical",
          },
          {
            name: "Logical Resource",
            value: "Logical Resource",
            icones: "icon-cpu-regular tree-icon-Logical",
          },
        ];
      } else if (
        spechName === "Resource Facing Service" ||
        spechName === "Customer Facing Service"
      ) {
        this.service_dropdown_data = [
          {
            name: "Resource Facing Service",
            value: "Resource Facing Service",
            icones: "icon-shield-bolt-regular tree-icon",
          },
          {
            name: "Physical Resource",
            value: "Physical Resource",
            icones: "icon-sd-card-regular tree-icon-Physical",
          },
          {
            name: "Logical Resource",
            value: "Logical Resource",
            icones: "icon-cpu-regular tree-icon-Logical",
          },
        ];
      }
      this.$emit("context", val, nodes);
      if (val === "Add") {
        // this.selectcomponentkey += 1;
        this.specificationPreValue = "";
        this.servicePreValue = "";
        this.editMode = false;
        this.showAddTreePanel = true;
        this.addButtonDisable = true;
        this.editServiceDisable = false;
        this.selectcomponentkey += 1;
      } else if (val === "Edit") {
        // console.log(nodes.service.name, nodes?.specification?.name, "edit mode");
        this.editServiceDisable = true;
        this.servicePreValue = nodes?.service?.name;
        this.service = nodes?.service
        this.speciifcationintialVal= JSON.parse(JSON.stringify(nodes?.specification))
        this.specificationPreValue = nodes?.specification?.name;
        this.selectcomponentkey += 1;
        if (nodes.service.name === "Customer Facing Service") {
          this.service_dropdown_data = [
            {
              name: "Customer Facing Service",
              value: "Customer Facing Service",
              icones: "icon-users-group-alt-regular tree-icone2",
            },
            {
              name: "Resource Facing Service",
              value: "Resource Facing Service",
              icones: "icon-shield-bolt-regular tree-icon",
            },
            {
              name: "Physical Resource",
              value: "Physical Resource",
              icones: "icon-sd-card-regular tree-icon-Physical",
            },
            {
              name: "Logical Resource",
              value: "Logical Resource",
              icones: "icon-cpu-regular tree-icon-Logical",
            },
          ];
        }
        if (nodes?.service?.name === "Customer Facing Service") {
          this.specification_dropdown_data = this.customerFacingServiceOption;
        } else if (nodes?.service?.name === "Resource Facing Service") {
          this.specification_dropdown_data = this.resourceFacingServiceOption;
        } else if (nodes?.service?.name === "Physical Resource") {
          this.specification_dropdown_data = this.physicalResourceOption;
        } else if (nodes?.service?.name === "Logical Resource") {
          this.specification_dropdown_data = this.logicalResourceOption;
        }
        this.editMode = true;
        this.showAddTreePanel = true;
        this.addButtonDisable = true;
      } else if (val === "Delete") {
        this.modal_active_delete = true;
      }
    },
    // Cardemit(nodes) {
    //   this.$emit("card-nodes", nodes);
    // },

    mouseDown($event) {
      if (this.isDraggable) {
        this.isMouseDown = true;

        this.ele.style.cursor = "grabbing";
        this.ele.style.userSelect = "none";
        this.start = {
          x: $event.clientX - this.initX,
          y: $event.clientY - this.initY,
        };
      }
    },

    mouseUp() {
      if (this.isDraggable) {
        this.isMouseDown = false;
        this.ele.style.cursor = "grab";
        this.ele.style.userSelect = "auto";
        this.ele.classList.remove("drag-border");
      }
    },

    mouseMove($event) {
      if (this.isMouseDown && this.isDraggable) {
        this.initX = $event.clientX - this.start.x;
        this.initY = $event.clientY - this.start.y;
        this.ele.style.cursor = "grabbing";
        this.ele.style.userSelect = "none";
        this.ele.style.transform =
          "translate(" +
          this.initX +
          "px, " +
          this.initY +
          "px) scale(" +
          this.scale +
          ")";
        if (!this.ele.classList.contains("drag-over")) {
          this.ele.classList.add("drag-border");
        }
      }
    },

    mouseleaveHandler() {
      if (this.isDraggable) {
        this.isMouseDown = false;
        this.ele.style.cursor = "grab";
        this.ele.style.userSelect = "auto";
        this.ele.classList.remove("drag-border");
      }
    },
    setTransform() {
      this.zoomElement = this.ele;
      if (this.zoomElement) {
        this.zoomElement.style.cursor = "grabbing";
        this.zoomElement.style.userSelect = "none";
        this.zoomElement.style.transform =
          "translate(" +
          this.initX +
          "px, " +
          this.initY +
          "px) scale(" +
          this.scale +
          ")";
      }
    },
    zoomIn(perc) {
      if (perc == 100) {
        this.scale = 1;
        this.setTransform();
        return;
      }
      this.scale = (perc * 2) / 200;
      if (this.scale <= 2) {
        this.setTransform();
      }
    },
    zoomOut(perc) {
      if (perc == 100) {
        this.scale = 1;
        this.setTransform();
        return;
      }
      this.scale = (perc * 2) / 200;

      if (this.scale > 0.2) {
        this.setTransform();
      }
    },
    resetDefault() {
      this.initX = -60;
      this.initY = 206;
      this.scale = 0.75;
      const group_div = [...document.querySelectorAll(".content-nodes.group")];
      group_div.filter((group) => {
        const group_child = group.parentnodes.querySelector("ul");
        group_child.classList.add("hide");
        group.querySelector("i").setAttribute("class", "i-circle-add");
      });
      this.setTransform();
    },
  },
};
</script>
<style lang="scss">
.example-body.custom {
  position: relative;
  min-height: 100%; // Set minimum height to 100%
  height: auto; // Allow height to adjust automatically
  overflow: scroll;
}
.component-example {
  margin: 10px;
  position: relative;
}
.example-body {
  position: relative;
  .tree-container {
    position: relative;
    // height: auto;
    width: 100%;
    margin-left: auto;
    padding: 0 35%;
    /* border: solid; */
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  // &.custom {
  //   // height: 800px;
  //   // overflow: auto;
  // }
  .custom {
    position: sticky;
    left: 0%;
  }
}
.tree-grid-background {
  border: 1px solid #e5e7eb;
  background-color: #e5e9ee;
  background-image: radial-gradient(#d2d6db 20%, transparent 0);
  height: 100%;
  background-size: 15px 15px;
  .tree-legent-full-detail {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .new-tree-legent {
    z-index: 100;
    padding: 8px 8px 8px 8px;
    // height: auto;
    flex-direction: column;
    position: absolute;
    gap: 12px;
    display: flex;
    margin: 5px 1% 5px 1%;
    background-color: white;
    transition: width 0.5s;
    -webkit-transition: width 0.5s;
    text-align: center;
  }

  .legent-name {
    display: flex;
  }

  .new-tree-legent,
  .legent-name {
    display: flex;
    font-size: 12px;
  }

  .new-tree-legent:hover {
    width: 183px;
  }
}

.tree-legenter div.card .card-body {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  padding: 10px;
}
.tree-zoom {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}

@media screen and (max-width: 768px) {
  .tree-zoom {
    text-align: center;
    margin-right: 0;
  }
}
.tree-zoom-background {
  background: white;
  height: 26px;
  font-size: 16px;
  color: #a6a6a6;
  align-items: center;
  width: 70px;
  box-shadow: 0px 0px 5px #d8d8d8;
  display: flex;
  border-radius: 6px;
  .zoomIn,
  .zoomOut {
    padding: 10px;
  }
  cursor: pointer;
  .vl {
    border-left: 1px solid #d8d8d8;
    height: 26px !important;
  }
  .tree-legent-background {
    background: white;
  }
  .tree-legent-icon1 {
    display: flex;
  }
}
</style>
