import HlxAccordion from './AccordionComponent.vue'
import HlxAlertNotification from './AlertComponent.vue'
import HlxBadge from './BadgeComponent.vue'
import HlxBreadcrumb from './BreadcrumbComponent.vue'
import HlxButton from './ButtonComponent.vue'
import HlxCalendar from './CalendarComponent.vue'
import HlxCard from './CardsComponent.vue'
import HlxColorpalette from './ColorpaletteComponent.vue'
import HlxCodeEditor from './CodeEditorComponent.vue'
import HlxContextMenu from './ContextMenuComponent.vue'
import HlxDashboard from './DashboardComponentNew.vue'
import HlxDataView from './DataviewComponent.vue'
import HlxDatePicker from './datePickerComponent.vue'
import HlxDivider from './DividerComponent.vue'
import HlxDottedpagination from './DottedPagination.vue'
import HlxDraggable from './DraggableComponent.vue'
import HlxDrawer from './DrawerComponent.vue'
import HlxErrorPage from './ErrorpageComponent.vue'
import HlxFileUpload from './FileUploadComponent.vue'
import HlxImageGallery from './ImagegalleryComponent.vue'
import HlxImportProgress from './ImportProgressComponent.vue'
import HlxInput from './InputComponent.vue'
import HlxInputElement from './InputElementsComponent.vue'
import HlxLabel from './LabelComponent.vue'
import HlxLoader from './LoaderComponent.vue'
import HlxModal from './ModalwindowComponent.vue'
import HlxMultiSelect from './MultiSelectComponent.vue'
import HlxObjectViewer from './ObjectViewerComponent.vue'
import HlxPagination from './PaginationComponent.vue'
import HlxRating from './RatingComponent.vue'
import HlxSearch from './SearchComponent.vue'
import HlxSelect from './SelectComponent.vue'
import HlxSidebar from './SideBar.vue'
import HlxSignup from './SignupComponent.vue'
import HlxSlider from './SliderComponent.vue'
import HlxSpeedDial from './SpeeddialComponent.vue'
import HlxSwitch from './SwitchComponent.vue'
import HlxStepper from './StepperComponent.vue'
import HlxTabPane from './TabComponent.vue'
// import HlxTable from './NewTable.vue'
// import HlxColumn from './NewTableColumn.vue'
import HlxTable from './table/HlxTable.vue'
import HlxTableCell from './table/HlxTableCell.vue'
import HlxTableHead from './table/HlxTableHead.vue'
import HlxTab from './TabWrapperComponent.vue'
import HlxTag from './TagComponent.vue'
import HlxTextEditor from './TexteditorComponent.vue'
import HlxTimeline from './TimelineComponent.vue'
import HlxTree from './TreeComponent.vue'
import HlxTreeChild from './TreeChildComponent.vue'
import HlxTreeV2 from './TreeVersion2Component.vue'
import HlxTreeChildV2 from './TreeChildVersion2Component.vue'
import HlxTreeSelect from './TreeSelectComponent.vue'
import HlxTreeSelectChild from './TreeSelectChildComponent.vue'
import HlxVisualRuleBuilder from './VisualRuleBuilderComponent.vue'
import HlxOverlay from './OverlayComponent.vue'
import HlxMinMaxSlider from './MinMaxRangeSliderComponent.vue'
import HlxMultiUpload from './multiUploadComponent.vue'
import HlxCascader from './CascaderNewComponent.vue'
import HlxRectangleSelect from './RectangleSelectComponent.vue'
import ContentEditableComponent from './ContentEditableComponent.vue'
import HlxEmail from './EmailComponent.vue'
import HlxColorPicker from './colorPickerComponent.vue'
import HlxSwatch from './swatchComponent.vue'
import HlxNativeColorPicker from './nativeColorPickerComponent.vue'
import HlxDropdownColorPicker from './dropdownColorPickerComponent.vue'
import HlxStatus from './StatusComponent.vue'
import HlxTagcount from './TagcountComponent.vue'
import HlxSpecialCharacters from './specialCharacters.vue'

export default {
  HlxSpecialCharacters,
  HlxDropdownColorPicker,
  HlxNativeColorPicker,
  HlxSwatch,
  HlxColorPicker,
  HlxEmail,
  ContentEditableComponent,
  HlxCascader,
  HlxMultiUpload,
  HlxRectangleSelect,
  HlxMinMaxSlider,
  HlxOverlay,
  HlxAccordion,
  HlxAlertNotification,
  HlxBadge,
  HlxBreadcrumb,
  HlxButton,
  HlxCalendar,
  HlxCard,
  HlxColorpalette,
  HlxCodeEditor,
  HlxContextMenu,
  HlxDashboard,
  HlxDataView,
  HlxDatePicker,
  HlxDivider,
  HlxDottedpagination,
  HlxDraggable,
  HlxDrawer,
  HlxErrorPage,
  HlxFileUpload,
  HlxImageGallery,
  HlxImportProgress,
  HlxInput,
  HlxInputElement,
  HlxLabel,
  HlxLoader,
  HlxModal,
  HlxMultiSelect,
  HlxObjectViewer,
  HlxPagination,
  HlxRating,
  HlxSearch,
  HlxSelect,
  HlxSidebar,
  HlxSignup,
  HlxSlider,
  HlxSpeedDial,
  HlxStepper,
  HlxSwitch,
  HlxTabPane,
  HlxTab,
  // HlxTable,
  // HlxColumn,
  HlxTable,
  HlxTableCell,
  HlxTableHead,
  HlxTag,
  HlxTimeline,
  HlxTextEditor,
  HlxTree,
  HlxTreeChild,
  HlxTreeV2,
  HlxTreeChildV2,
  HlxTreeSelect,
  HlxTreeSelectChild,
  HlxVisualRuleBuilder,
  HlxStatus,
  HlxTagcount
}
export {
  HlxDropdownColorPicker,
  HlxNativeColorPicker,
  HlxSwatch,
  HlxColorPicker,
  HlxEmail,
  ContentEditableComponent,
  HlxCascader,
  HlxMultiUpload,
  HlxRectangleSelect,
  HlxMinMaxSlider,
  HlxOverlay,
  HlxAccordion,
  HlxAlertNotification,
  HlxBadge,
  HlxBreadcrumb,
  HlxButton,
  HlxCalendar,
  HlxCard,
  HlxColorpalette,
  HlxCodeEditor,
  HlxContextMenu,
  HlxDashboard,
  HlxDataView,
  HlxDatePicker,
  HlxDivider,
  HlxDottedpagination,
  HlxDraggable,
  HlxDrawer,
  HlxErrorPage,
  HlxFileUpload,
  HlxImageGallery,
  HlxImportProgress,
  HlxInput,
  HlxInputElement,
  HlxLoader,
  HlxLabel,
  // HlxLoginPage,
  // HlxLoginForm,
  // HlxLoginModal,
  HlxModal,
  HlxMultiSelect,
  HlxObjectViewer,
  // HlxOTPVerificationModal,
  HlxPagination,
  HlxRating,
  HlxSearch,
  HlxSelect,
  HlxSidebar,
  HlxSignup,
  // HlxSignupForm,
  HlxSlider,
  HlxSpeedDial,
  HlxStepper,
  HlxSwitch,
  HlxTabPane,
  HlxTab,
  // HlxTable,
  // HlxColumn,
  HlxTable,
  HlxTableCell,
  HlxTableHead,
  HlxTag,
  HlxTimeline,
  HlxTextEditor,
  HlxTree,
  HlxTreeChild,
  HlxTreeV2,
  HlxTreeChildV2,
  HlxTreeSelect,
  HlxTreeSelectChild,
  HlxVisualRuleBuilder,
  HlxStatus,
  HlxTagcount,
  HlxSpecialCharacters
}
