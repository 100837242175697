<template>
<div v-if="disabled == false" :id="'inline-multi-select'+unique" class="inline-multi-select" :style="error == true ? 'border-color:#ff5050 !important' : ''"     @click="optioncontainer1" 
>
    <div class="inline-input-section">
    <div v-show="dropdownclose == true" :id="'inline-multi-select-tag-value'+unique" class="inline-multi-select-tag-value">
            <div
      v-for="(i, index) in showselectedvalue"
      :id="'custom-multi-select-tag-value' + unique"
      :key="index"
      class="custom-multi-select-tag-value"
      > 
             <hlx-tag
             :limit="false"
        class="multiselect-inline-tag-option"
        @tag-close.stop="removed(i[propValue])"
        >{{ i[label] }}</hlx-tag
      >
            </div>
    </div>
    <input v-show="dropdownopen == true" :id="'inline-select-input'+unique" ref="multiselect" v-model="inlinemultisearchvalue" type="text" @keypress="multiselectsearch" @input="multiselectType" @keyup.enter="addvalue">
    <div v-show="inline_count_value != 0 " class="inline-multi-select-count-section" >
    <div class="inline-multi-select-count" :data-tooltip="tooltipdata" :data-tooltip-location="tooltipposition">
      {{ "+" + inline_count_value }}
    </div>
    </div>
    <i :id="'arrow' + unique" class="icon-angle-down-regular"></i>
      <p v-show="dropdownclose == true && showselectedvalue.length == 0 &&required == true" class="custom-multi-select-title1">
      {{ label_Value}}<span class="asterik">{{ "*" }}</span>
    </p>
          <p v-show="dropdownclose == true && showselectedvalue.length == 0 &&required == false" class="custom-multi-select-title1">
      {{ label_Value }}
    </p>
    <p v-show="labelAnimation == true && required == false && (dropdownopen== true || showselectedvalue.length != 0)" class="custom-multi-select-title" :style="error == true ? 'Color:#ff5050 !important' : ''" >
      {{ label_Value }}
    </p>
        <p v-show="labelAnimation == true && required == true && (dropdownopen== true || showselectedvalue.length != 0)" class="custom-multi-select-title" :style="error == true ? 'Color:#ff5050 !important' : ''" >
      {{ label_Value }}
    </p>
      <p v-if="error == true" class="customerror">{{ customError }} </p>
    <!-- <div class="inline-multi-select-placeholder">
      {{label_Value}}
    </div> -->
    </div>
    <hlx-overlay v-show="dropdownopen == true" :key="componentkey" :search="inlinemultisearchvalue" :show ="dropdownopen" :top ="containertop" :left="containerleft" :width="containerwidth" :conditionvalue="250" :conditiondown="45" :conditionup="10">
  <template #overlay>
        <div
      class="inline-multi-select-option-container"
    >
    <span class="input-container232" style="overflow-x: none !important;">
  <div class="whole-checkbox">
    <label
      v-if="showSelectAll == true && multiselectsearch1.length > 0"
      class="check-container"
      @mouseover="hoverSelectAll"
      @mouseleave="unHoverSelectAll"
      >{{ "Select all" }}
      <i
        v-if="partiallySelected == true"
        class="icon-minus-regular partially-checked"
      ></i>
      <input
        style="margin-right: 15px"
        type="checkbox"
        class="selcet"
        :class="'select-all' + unique"
        :checked="false"
        @click="selectAll"
      />
      <span
        :id="'select-all-checkmark' + unique"
        class="checkmark"
        @mouseover="hoverSelectAll"
        @mouseleave="unHoverSelectAll"
      ></span>
    </label>
    <div
      v-for="element in multiselectsearch1"
      :key="element.id"
      class="checkbox-container"
    >
      <label class="check-container">
        <span class="ellipsis">{{ element[label] }}</span>
        <input
          :id="'check-drag' + unique"
          type="checkbox"
          :checked="element.checked"
          :disabled="element.disabled"
          class="check-correct"
          :class="'check-drag' + unique"
          @click="checkItem(element, $event)"
        />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
      </span>
          <div v-if="filterSelect == true" class="custom-multi-select-footer">
        <hlx-button class="secondary-success sm">Cancel</hlx-button>
        <hlx-button class="primary sm" @click="filtersave">Save</hlx-button>
      </div>
    </div>
  </template>
    </hlx-overlay>
</div>

<div v-if="disabled == true" :id="'inline-multi-select'+unique" class="inline-multi-select" 
 :style="[hovered == true? 'border-color:#d8d8d8 !important' : '' ]"            
      @mouseover="hovered = true"
      @mouseout="hovered = false"
>
    <div class="inline-input-section">
    <div v-show="dropdownclose == true" :id="'inline-multi-select-tag-value'+unique" class="inline-multi-select-tag-value">
            <div
      v-for="(i, index) in showselectedvalue"
      :id="'custom-multi-select-tag-value' + unique"
      :key="index"
      class="custom-multi-select-tag-value"
      > 
             <hlx-tag
        class="multiselect-inline-tag-option"
        >{{ i[label] }}</hlx-tag
      >
            </div>
    </div>
    <input v-show="dropdownopen == true" :id="'inline-select-input'+unique" ref="multiselect" v-model="inlinemultisearchvalue" type="text">
    <div v-show="inline_count_value != 0 " class="inline-multi-select-count-section" >
    <div class="inline-multi-select-count" :data-tooltip="tooltipdata" :data-tooltip-location="tooltipposition">
      {{ "+" + inline_count_value }}
    </div>
    </div>
    <i :id="'arrow' + unique" class="icon-angle-down-regular"></i>
      <p v-show="dropdownclose == true && showselectedvalue.length == 0 &&required == true" class="custom-multi-select-title1">
      {{ label_Value}}<span class="asterik">{{ "*" }}</span>
    </p>
          <p v-show="dropdownclose == true && showselectedvalue.length == 0 &&required == false" class="custom-multi-select-title1">
      {{ label_Value }}
    </p>
    <p v-show="labelAnimation == true && required == false && (dropdownopen== true || showselectedvalue.length != 0)"
     class="custom-multi-select-title"  :style="[hovered == true? 'color:#a6a6a6 !important' : '' ]"            
      @mouseover="hovered = true"
      @mouseout="hovered = false" >
      {{ label_Value }}
    </p>
        <p v-show="labelAnimation == true && required == true && (dropdownopen== true || showselectedvalue.length != 0)" class="custom-multi-select-title"    
      :style="[hovered == true? 'color:#a6a6a6 !important' : '' ]"            
      @mouseover="hovered = true"
      @mouseout="hovered = false" >
      {{ label_Value }}
    </p>
      <p class="customerror">{{ customError }} </p>
    <!-- <div class="inline-multi-select-placeholder">
      {{label_Value}}
    </div> -->
    </div>
    <hlx-overlay v-show="dropdownopen == true" :show ="dropdownopen" :top ="containertop" :left="containerleft" :width="containerwidth" :conditionvalue="250" :conditiondown="45" :conditionup="10" >
  <template #overlay>
        <div
      class="inline-multi-select-option-container"
    >
    <span class="input-container232" style="overflow-x: none !important;">
  <div class="whole-checkbox">
    <label
      v-if="showSelectAll == true && multiselectsearch1.length > 0"
      class="check-container"
      @mouseover="hoverSelectAll"
      @mouseleave="unHoverSelectAll"
      >{{ "Select all" }}
      <i
        v-if="partiallySelected == true"
        class="icon-minus-regular partially-checked"
      ></i>
      <input
        style="margin-right: 15px"
        type="checkbox"
        class="selcet"
        :class="'select-all' + unique"
        :checked="false"
        @click="selectAll"
      />
      <span
        :id="'select-all-checkmark' + unique"
        class="checkmark"
        @mouseover="hoverSelectAll"
        @mouseleave="unHoverSelectAll"
      ></span>
    </label>
    <div
      v-for="element in multiselectsearch1"
      :key="element.id"
      class="checkbox-container"
    >
      <label class="check-container">
        <span class="ellipsis">{{ element[label] }}</span>
        <input
          :id="'check-drag' + unique"
          type="checkbox"
          :checked="element.checked"
          :disabled="element.disabled"
          class="check-correct"
          :class="'check-drag' + unique"
          @click="checkItem(element, $event)"
        />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
      </span>
          <div v-if="filterSelect == true" class="custom-multi-select-footer">
        <hlx-button class="secondary-success sm">Cancel</hlx-button>
        <hlx-button class="primary sm" @click="filtersave">Save</hlx-button>
      </div>
    </div>
  </template>
    </hlx-overlay>
</div>




  <!-- <div v-if="disabled == false" :id="'custom-multi-select' + unique" class="custom-multi-select">
    <span v-show="all != true" class="not-all-tag" 
>
    <div
      v-for="(i, index) in showselectedvalue"
      :id="'custom-multi-select-tag-value' + unique"
      :key="index"
      class="custom-multi-select-tag-value"
      :style="[
        index < multiselecttagvalue - 1 ? 'display:block' : 'display:none',
      ]"
      @click="optioncontainer"
    >
      <hlx-tag
        v-if="index < multiselecttagvalue - 1"
        class="multiselect-inline-tag-option"
        @tag-close="removed(i[propValue])"
        >{{ i[label] }}</hlx-tag
      >
    </div>
    </span>
    <div
      v-show="multi_select_inline_count_show == true && all != true"
      :id="'multi-select-inline-count-value' + unique"
      class="multi-select-inline-count-value"
      @click="optioncontainer"
    >
      {{ "+" + multi_select_inline_count_value }}
    </div>
    <input
      v-show="all != true"
      :id="'custom-multi-select-input' + unique"
      v-model="inputvalue"
      type="text"
      class="custom-multi-select-input"
      :placeholder="placeholder_value"
      @keyup.enter="addvalue"
      @click="optioncontainer"
    />
    <i
      :id="'arrow' + unique"
      class="icon-angle-down-regular"
      @click="optioncontainer"
    ></i>
    <p v-if="title == true" class="cutom-multi-select-title">
      {{ label_Value }}
    </p>
     <div v-if="all == true" class="all-option" @click="optioncontainer">All</div>
    <div
      v-if="dropdowncontainer === true"
      class="custom-multi-select-option-container"
      :style="['top:'+ top + 'px', 'left:'+ left + 'px']"
    >
    <span class="input-container232" style="overflow-x: none !important;">
      <hlxInput
        :options="multiselectsearch"
        :type="'checkbox'"
        :show-select-all="true"
        @check-box-event="returnvalue"
        @selected="valueee"
      /></span>
          <div v-if="filterSelect == true" class="custom-multi-select-footer">
        <hlx-button class="secondary-success sm">Cancel</hlx-button>
        <hlx-button class="primary sm" @click="filtersave">Save</hlx-button>
      </div>
    </div>
  </div>


    <div v-if="disabled == true" :id="'custom-multi-select' + unique" class="custom-multi-select" >
    <span v-if="all != true" class="not-all-tag" 
>
    <div
      v-for="(i, index) in showselectedvalue"
      :id="'custom-multi-select-tag-value' + unique"
      :key="index"
      class="custom-multi-select-tag-value"
      :style="[
        index < multiselecttagvalue - 1 ? 'display:block' : 'display:none',
      ]"
    
    >
      <hlx-tag
        v-if="index < multiselecttagvalue - 1"
        class="multiselect-inline-tag-option"
        @tag-close="removed(i[propValue])"
        >{{ i[label] }}</hlx-tag
      >
    </div>
    </span>
    <div
      v-show="multi_select_inline_count_show == true && all != true"
      :id="'multi-select-inline-count-value' + unique"
      class="multi-select-inline-count-value"
    
    >
      {{ "+" + multi_select_inline_count_value }}
    </div>
    <input
      v-if="all != true "
      :id="'custom-multi-select-input' + unique"
      v-model="inputvalue"
      type="text"
      class="custom-multi-select-input"
      :placeholder="placeholder_value"
      :style="disabled == true ? 'display:none' : '' "
      @keyup.enter="addvalue"
    />
    <i
      :id="'arrow' + unique"
      class="icon-angle-down-regular"
    
    ></i>
    <p v-if="title == true" class="cutom-multi-select-title">
      {{ label_Value }}
    </p>
     <div v-if="all == true" class="all-option">All</div>
    <div
      v-if="dropdowncontainer === true"
      class="custom-multi-select-option-container"
    >
    <span class="input-container232" style="overflow-x: none !important;">
      <hlxInput
        :options="multiselectsearch"
        :type="'checkbox'"
        :show-select-all="true"
        @check-box-event="returnvalue"
        @selected="valueee"
      /></span>
          <div v-if="filterSelect == true" class="custom-multi-select-footer">
        <hlx-button class="secondary-success sm">Cancel</hlx-button>
        <hlx-button class="primary sm" @click="filtersave">Save</hlx-button>
      </div>
    </div>
  </div> -->
</template>

<script>
// import hlxInput from "./InputComponent.vue";
import hlxTag from "./TagComponent.vue";
import hlxButton from './ButtonComponent.vue';
import hlxOverlay from '../components/OverlayComponent.vue'


export default {
  name: "HlxMultiSelect",
  components: {
    // hlxInput,
    hlxTag,
    hlxButton,
    hlxOverlay
  },
  props: {
      tooltipposition:{
            type:String,
            default:'right'
      },
    error:{
      type:Boolean,
      default:false
    },
    customError:{
      type:String,
      default:''
    },

        scrollClassname:{
       type:String,
       default:''
    },
        disabled:{
        type:Boolean,
        default:false
    },
    filterSelect:{
        type:Boolean,
        default:false
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    label: {
      type: String,
      default: "name",
    },
    propValue: {
      type: String,
      default: "value",
    },
    preValue: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    labelAnimation: {
      type: Boolean,
      default: true,
    },
    required:{
      type:Boolean,
      default:false
    }
  },
  emits: ["add-data", "custom-change", "selected-value","tagRemoveData","selected-array","custom-error-emit-value","arrow-click", 'typeValue','inputValue','removed-value'],
  data() {
    return {
      eachtagWidth:0,
      tooltipdata:'',
      containerwidth:0,
      containertop:0,
      containerleft:0,
      componentkey:0,
      filtereddata:[],
      hovered:false,
      preeval:[],
      preval:[],
      count22:0,
      removereferencedata:[],
      multiselectdata:[],
      searchoptions:[],
      tagwidth:0,
      disabledvalue:[],
      inlinemultisearchvalue:'',
      dropdownclose:true,
      dropdownopen:false,
      all:false,
      unique: Math.floor(Math.random() * 1000 + 1),
      multi_select_inline_tag_array: [],
      multi_select_inline_tag_value: 0,
      inputvalue: "",
      multi_select_inline_count_show: false,
      multi_select_inline_count_value: 0,
      multiselecttagvalue: 0,
      showselectedvalue: [],
      returndata: [],
      multiselectinlineoptions: [],
      tagarray: [],
      dropdowncontainer: false,
      placeholder_value: "",
      title: false,
      label_Value: "",
      top:45,
      left:1,
      count:0,
      inline_count_value:0,
      

      showSelectAll:true,
      select_all: false,
      all_checked: true,
      partially_checked: false,
      atleastOne: false,
      typing: false,
      // intelliData: [],
      small: true,
      showMandatory: false,
      optionsD: [],
      duplicatedata:[]
    };
  },
  computed: {
        partiallySelected() {
      return this.partially_checked;
    },
    multiselectsearch1() {
      const data = [...this.optionsD];
      let data1 = []
      this.$emit("inputValue", this.inlinemultisearchvalue);
      if (this.inlinemultisearchvalue != "" && this.inlinemultisearchvalue) {
        data1 = data.filter((e)=>{
          if(e[this.label]
            .toString()
            .toLowerCase()
            .includes(this.inlinemultisearchvalue.toLowerCase()))
            {
                      this.checkBox()
              // this.showSelectAll = false
              return e
            }
        })
        // this.switchdropdown(data1)
        return data1
      } else {
        this.checkBox()
        // this.showselectall()
        // this.switchdropdown(data)
        // console.log('hi',data);
        return data;
      }
    }

  },
  watch: {
    placeholder:{
       handler(val){
        this.placeholder_value = val;
       },
       immediate:true,
       deep:true
    },
    placeholder_value:{
      handler(val){
        this.label_Value = val
       },
       immediate:true,
       deep:true
    },
    optionsD:{
     handler(val)
     {
      val
      this.resizedata()
     },
     immediate:true,
     deep:true
    },
    inlinemultisearchvalue:{
     handler(val)
     {
       this.$emit("typeValue", val);
      // console.log(val,"multiselectType");
     },
     immediate:true,
     deep:true
    },
    label:{
       handler(val)
       {
        val
       },
       immediate:true,
       deep:true
    },
    propValue:{
             handler(val)
       {
        val
       },
       immediate:true,
       deep:true
    },
    inline_count_value:{
      handler(val)
      {
          val
      },
      immediate:true,
      deep:true
    },
    tagwidth:{
      handler(val)
      {
         if(val != 0)
         {
          this.$nextTick(()=>{
            if(document.querySelector("#inline-multi-select-tag-value" + this.unique))
            {
              this.tagwidth = document.querySelector("#inline-multi-select-tag-value" + this.unique).getBoundingClientRect().width
              this.resizedata()
            }

          })

         }
      },
        immediate:true,
        deep:true
    },
    options: {
      handler(val) {
        val.forEach((e)=>{
          if (e.checked === undefined) {
          e.checked = false;
          e.disabled = false;
        }
        })
          let count = 0
          this.$nextTick(()=>{
            if (document.querySelector(".select-all" + this.unique)) {
    document.querySelector(".select-all" + this.unique).checked = false;
  }
          })
        this.preeval.forEach((e)=>{
                this.options.forEach((ele)=>{
                  if(ele[this.propValue] == e)
                    {
                      ele.checked = true
                      count = count + 1
                    }
                })
              })
              if(count == this.optionsD.length)
              {
                this.all_checked = true
                this.partially_checked = false
                              this.$nextTick(() => {
                                if(document.querySelector(
                  ".select-all" + this.unique
                ))
                {
                  // console.log('kslksk');
                document.querySelector(
                  ".select-all" + this.unique
                ).checked = true;
                }
              });
              }
              else if(count != this.optionsD.length && count>0){
                this.partially_checked = true
                this.all_checked = false
                              this.$nextTick(() => {
                //     document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).classList.add('partially-checked-color')             
                document.getElementById(
                  "select-all-checkmark" + this.unique
                ).style.background = "#54bd95";
                document.getElementById(
                  "select-all-checkmark" + this.unique
                ).style.border = "1px solid #54bd95";
              });
              }
              else{
                this.all_checked = false
                 this.partially_checked = false;
              }
          this.optionsD = JSON.parse(JSON.stringify([...val]));

      },
      immediate: true,
      deep: true,
    },
    preeval:{
       handler(val)
       {
          if(val.length != 0)
          {
              val.forEach((e)=>{
                this.options.forEach((ele)=>{
                  if(ele[this.propValue] == e)
                    {
                      ele.checked = true
                    }
                    else{
                      ele.checked = false
                    }
                })
              })
              
          }
       },
       immediate:true,
       deep:true

    },
    preValue:{
      handler(val)
      {
         if(val.length != 0)
         {
            this.preeval = JSON.parse(JSON.stringify([...val]))
         }
      },
      immediate:true,
      deep:true
    },
    showselectedvalue:{
      handler(val)
      {
        if(val.length != 0)
        {
          this.title = true
        }
      },
      immediate:true,
      deep:true,
    },
    // inlinemultisearchvalue:{
    //   handler()
    //   {
    //       // if(val != '')
    //       // {
    //       //   this.multiselectsearch()
    //       // }
    //   },
    //   immediate:true,
    //   deep:true
    // },
    // returnvalue:{
    //   handler(val)
    //   {
    //        val
    //   },
    //   immediate:true,
    //   deep:true
    // }

  },
  mounted() {
    // this.multiselectsearch()
    this.$nextTick(()=>{
      if(document.querySelector("#inline-multi-select-tag-value" + this.unique))
        {
          this.tagwidth =  document.querySelector("#inline-multi-select-tag-value" + this.unique).getBoundingClientRect().width
        }
    })
    window.addEventListener('resize',(()=>{
      // console.log(document.querySelector("#inline-multi-select-tag-value" + this.unique));
     if(document.querySelector("#inline-multi-select-tag-value" + this.unique))
      {
          this.tagwidth = document.querySelector("#inline-multi-select-tag-value" + this.unique).getBoundingClientRect().width
          // this.resizedata()
          // console.log(this.tagwidth,'this.tagwidth');
      }
    }))
    // window.addEventListener("error", (event) => {
    //   console.log(event);
    // })
  //  console.log( document.querySelector("#inline-multi-select-tag-value" + this.unique).getBoundingClientRect().width); 
  // if(document.querySelector("#inline-multi-select-tag-value" + this.unique))
    //  this.$nextTick(()=>{
    //     if(document.querySelector('#inline-multi-select' + this.unique) != null)
    //     {
    //       var element = document.querySelector('#inline-multi-select' + this.unique);
    //       var position = element.getBoundingClientRect();
    //       var y = position.top;
    //       var innerheight = window.innerHeight - y
    //       if(innerheight < 300)
    //       {
    //         let findlength = this.filtereddata.length+1
    //                 if(findlength == 2)
    //                 {
    //                   this.top = -94
    //                   this.left = -2
    //                 }
    //                 else if(findlength == 3)
    //                 {
    //                   this.top = -124
    //                   this.left = -2
    //                 }
    //                 else if(findlength == 4)
    //                 {
    //                   this.top = -154
    //                   this.left = -2
    //                 }
    //                 else if(findlength == 5)
    //                 {
    //                   this.top = -184
    //                   this.left = -2
    //                 }
    //                 else if(findlength == 6)
    //                 {
    //                   this.top = -213
    //                   this.left = -2
    //                 }
    //                 else if(findlength == 7)
    //                 {
    //                   this.top = -245
    //                   this.left = -2

    //                 }
    //                 else{
    //                   this.top = -267
    //                   this.left = -2
    //                 }
    //       }
    //       else{
    //         this.top = 45
    //         this.left = -1
    //       }
    //     }
    //   })
      
      // this.$nextTick(()=>{
      //   if(this.scrollClassname != '')
      //   {
      //      if(document.querySelector('.'+this.scrollClassname))
      //     {
      //                 document.querySelector('.'+this.scrollClassname).addEventListener("scroll", (event) => {
      //                   event
      //         this.$nextTick(()=>{
      //            if(document.querySelector('#inline-multi-select' + this.unique)) 
      //         {
      //           var element = document.querySelector('#inline-multi-select' + this.unique);
      //           var position = element.getBoundingClientRect();
      //           var y = position.top;
      //           var innerheight = window.innerHeight - y
      //     if(innerheight < 250)
      //     {
      //       let findlength = this.filtereddata.length+1
      //               if(findlength == 2)
      //               {
      //                 this.top = -94
      //                 this.left = -2
      //               }
      //               else if(findlength == 3)
      //               {
      //                 this.top = -124
      //                 this.left = -2
      //               }
      //               else if(findlength == 4)
      //               {
      //                 this.top = -154
      //                 this.left = -2
      //               }
      //               else if(findlength == 5)
      //               {
      //                 this.top = -184
      //                 this.left = -2
      //               }
      //               else if(findlength == 6)
      //               {
      //                 this.top = -213
      //                 this.left = -2
      //               }
      //               else if(findlength == 7)
      //               {
      //                 this.top = -245
      //                 this.left = -2

      //               }
      //               else{
      //                 this.top = -267
      //                 this.left = -2
      //               }
      //     }
      //           else{
      //             this.top = 45
      //             this.left = -1
      //           }
      //         }
      //       })
      //                 })
      //     }
      //   }
      // })
    
    this.placeholder_value = this.placeholder;
    this.label_Value = this.placeholder_value;
    this.tagwidth =  document.querySelector("#inline-multi-select-tag-value" + this.unique).getBoundingClientRect().width

    document.addEventListener("click", (e) => {
      if (this.dropdownopen == true) {
        const elem = document
          .getElementById("inline-multi-select" + this.unique)
          .contains(e.target);
        if (elem === false) {
         this.dropdownopen = false
        this.dropdownclose = true
        if(document.querySelector('#inline-multi-select-tag-value'+this.unique))
        document.querySelector('#inline-multi-select-tag-value'+this.unique).style.display = 'flex'
        document.querySelector('#inline-multi-select'+this.unique).classList.remove('add-style')
        document.querySelector('#arrow'+this.unique).classList.add('icon-angle-down-regular')
        document.querySelector('#arrow'+this.unique).classList.remove('icon-angle-up-regular')

                  let arr1= []
        this.multiselectinlineoptions.forEach((e)=>{
          if(e.checked == true)
          {
             arr1.push(e)
          }
        })
        this.$emit("custom-error-emit-value", arr1);

        }
      }
    });
    if (this.options.length != 0) {
      this.options.forEach((e) => {
        if (e.checked === undefined) {
          e.checked = false;
          e.disabled = false;
        }
      });
    }
    this.multiselectinlineoptions = JSON.parse(
      JSON.stringify([...this.options])
    );
    this.multiselectdata = JSON.parse(
      JSON.stringify([...this.options])
    );

    // this.prevalue = this.preValue
    // console.log(this.preValue,'preValue');
    if(this.preValue != []) 
    {
      this.preValue.forEach((e)=>{
        this.multiselectinlineoptions.forEach((ele)=>{
          if(ele[this.propValue] === e)
          {
            ele.checked = true
            ele.disabled = false
          }
        })
      })
    }
    // this.resizedata();    
  },
  methods: {
    showselectall()
    {
      this.showSelectAll = true
      // this.componentkey += 1
      // let count = 0
      //    this.optionsD.forEach((e)=>{
      //     if(e.checked == true)
      //     {
      //       count = count+1
      //     }
      //    })
      //    if(count == this.optionsD.length)
      //    {
      //     this.all_checked = true
      //    }
      //    else if(count>0)
      //    {
      //     this.partially_checked =
      //    }
      //    this.showSelectAll = true
    },
    multiselectType(val){
console.log(val,"multiselectType");
    },
    hoverSelectAll() {
      document
        .getElementById("select-all-checkmark" + this.unique)
        .classList.add("select-all-theme-hover");
    },
    unHoverSelectAll() {
      if (this.select_all === false && this.partially_checked === false) {
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.remove("select-all-theme-hover");
      }
    },
    selectAll($event) {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      this.select_all = $event.target.checked;
      let count = 0
      this.optionsD.forEach((ele)=>{
        if($event.target.checked == true)
        {
          ele.checked = true
          count = count+1
        }else{
          ele.checked = false
        }
      })
              if(count == this.optionsD.length)
              {
                this.all_checked = true
                this.partially_checked = false
                              this.$nextTick(() => {
                                if(document.querySelector(
                  ".select-all" + this.unique
                ))
                {
                  // console.log('kslksk');
                document.querySelector(".select-all" + this.unique).checked = true;
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-grey");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-theme");
                }
              });
              }
              else if(count != this.optionsD.length && count>0){
                this.partially_checked = true
                this.all_checked = false
                              this.$nextTick(() => {
                //     document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).classList.add('partially-checked-color')             
                // document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).style.background = "#54bd95";
                // document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).style.border = "1px solid #54bd95";
                document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-grey");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-theme");
              });
              }
              else{
                this.all_checked = false
                 this.partially_checked = false;
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-theme");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-grey");
              }

      // if (this.select_all === true) {
      //   this.partially_checked = false;
      //   document
      //     .querySelectorAll("[id^=check-drag" + this.unique + "]")
      //     .forEach((ele) => {
      //       if (ele.disabled === false) {
      //         ele.checked = true;
      //       }
      //     });
      //   this.optionsD.forEach((ele) => {
      //     if (ele.disabled === false) {
      //       ele.checked = true;
      //     }
      //   });
      //   // const r = document.querySelector(':root')
      //   // const rs = getComputedStyle(r)
      //   document
      //     .getElementById("select-all-checkmark" + this.unique)
      //     .classList.remove("select-all-grey");
      //   document
      //     .getElementById("select-all-checkmark" + this.unique)
      //     .classList.add("select-all-theme");
      // } else {
      //   this.partially_checked = false;
      //   document
      //     .querySelectorAll("[id^=check-drag" + this.unique + "]")
      //     .forEach((ele) => {
      //       if (ele.disabled === false) {
      //         ele.checked = false;
      //       }
      //       if (ele.checked === true) {
      //         document
      //           .getElementById("select-all-checkmark" + this.unique)
      //           .classList.add("select-all-theme");
      //         this.partially_checked = true;
      //       }
      //     });
      //   this.optionsD.forEach((ele) => {
      //     if (ele.disabled === false) {
      //       ele.checked = false;
      //     }
      //   });
      //   // console.log(this.partially_checked)
      //   if (this.partially_checked === false) {
      //     document
      //       .getElementById("select-all-checkmark" + this.unique)
      //       .classList.add("select-all-grey");
      //   } else if (this.partially_checked === true) {
      //     document
      //       .getElementById("select-all-checkmark" + this.unique)
      //       .classList.add("select-all-theme");
      //   }
      // }
      
       this.$emit("custom-change", this.optionsD)
              let selectedvalue = []
       this.optionsD.forEach((e)=>{
        if(e.checked == true)
        {
          selectedvalue.push(e)
        }
       })
      this.$emit("selected-array", selectedvalue);
    },
    checkBox()
    {
      // console.log('aaa');
        //   this.optionsD.forEach((e)=>{
        //   if (e.checked === undefined) {
        //   e.checked = false;
        //   e.disabled = false;
        // }
        // })
        // this.multiselectinlineoptions = JSON.parse(JSON.stringify([...val]));
        //     this.multiselectsearch()


          let count = 0
          this.$nextTick(()=>{
            if (document.querySelector(".select-all" + this.unique)) {
    document.querySelector(".select-all" + this.unique).checked = false;
  }
          })
        // this.preeval.forEach((e)=>{
                this.optionsD.forEach((ele)=>{
                  if(ele.checked == true)
                    {
                      // ele.checked = true
                      count = count + 1
                    }
                })
              // })
              if(count == this.optionsD.length)
              {
                this.all_checked = true
                this.partially_checked = false
                              this.$nextTick(() => {
                                if(document.querySelector(
                  ".select-all" + this.unique
                ))
                {
                  // console.log('kslksk');
                document.querySelector(
                  ".select-all" + this.unique
                ).checked = true;
                }
              });
              }
              else if(count != this.optionsD.length && count>0){
                this.partially_checked = true
                this.all_checked = false
                              this.$nextTick(() => {
                //     document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).classList.add('partially-checked-color')             
                document.getElementById(
                  "select-all-checkmark" + this.unique
                ).style.background = "#54bd95";
                document.getElementById(
                  "select-all-checkmark" + this.unique
                ).style.border = "1px solid #54bd95";
              });
              }
              else{
                this.all_checked = false
                 this.partially_checked = false;
          // document
          //   .getElementById("select-all-checkmark" + this.unique)
          //   .classList.remove("select-all-theme");
          // document
          //   .getElementById("select-all-checkmark" + this.unique)
          //   .classList.add("select-all-grey");
              }
    },
    checkItem(val, $event) {
      console.log(val.checked,$event,'testttt')
      if(val.checked == true){
        this.$emit('removed-value',val)
      }
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      this.currentChecked = $event.target.checked
      this.atleastOne = false;
      this.all_checked = true;
      val.checked = $event.target.checked;
      let count = 0
      this.optionsD.forEach((ele)=>{
         if(ele.checked == true)
         {
             count = count + 1
         }
      })
              if(count == this.optionsD.length)
              {
                this.all_checked = true
                this.partially_checked = false
                              this.$nextTick(() => {
                                if(document.querySelector(
                  ".select-all" + this.unique
                ))
                {
                  // console.log('kslksk');
                document.querySelector(".select-all" + this.unique).checked = true;
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-grey");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-theme");
                }
              });
              }
              else if(count != this.optionsD.length && count>0){
                this.partially_checked = true
                this.all_checked = false
                              this.$nextTick(() => {
                //     document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).classList.add('partially-checked-color')             
                // document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).style.background = "#54bd95";
                // document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).style.border = "1px solid #54bd95";
                document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-grey");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-theme");
              });
              }
              else{
                this.all_checked = false
                 this.partially_checked = false;
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-theme");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-grey");
              }
      if ($event.target.checked === false) {
        if (this.showSelectAll === true) {
          document.querySelector(".select-all" + this.unique).checked = false;
        }
      }
      // if (this.all_checked === true) {
      //   document.querySelector('.select-all').checked = true
      // }
      // this.optionsD.forEach((ele) => {
      //   if (ele.checked === false) {
      //     this.all_checked = false;
      //   }
      //   if (ele.checked === true) {
      //     this.atleastOne = true;
      //   }
      // });
      // if (this.showSelectAll === true) {
      //   if (this.all_checked === true) {
      //     // console.log('all checked')
      //     document.querySelector(".select-all" + this.unique).checked = true;
      //     document
      //       .getElementById("select-all-checkmark" + this.unique)
      //       .classList.remove("select-all-grey");
      //     document
      //       .getElementById("select-all-checkmark" + this.unique)
      //       .classList.add("select-all-theme");
      //   } else if (
      //     document.getElementById("select-all-checkmark" + this.unique)
      //   ) {
      //     this.partially_checked = false;
      //     document
      //       .getElementById("select-all-checkmark" + this.unique)
      //       .classList.remove("select-all-theme");
      //     document
      //       .getElementById("select-all-checkmark" + this.unique)
      //       .classList.add("select-all-grey");
      //   }
      //   if (this.atleastOne === true && this.all_checked === false) {
      //     this.partially_checked = true;
      //     document
      //       .getElementById("select-all-checkmark" + this.unique)
      //       .classList.remove("select-all-grey");
      //     document
      //       .getElementById("select-all-checkmark" + this.unique)
      //       .classList.add("select-all-theme");
      //   } else if (this.all_checked === true) {
      //     this.partially_checked = false;
      //   }
      // }

      this.$emit("custom-change", this.optionsD)
       let selectedvalue = []
       this.optionsD.forEach((e)=>{
        if(e.checked == true)
        {
          selectedvalue.push(e)
        }
       })
      this.$emit("selected-array", selectedvalue);
    },
    
    dataremove() 
    {
       this.multiselectinlineoptions.forEach((e)=>{
        e.checked =false
       })
       this.filtereddata = this.multiselectinlineoptions
    },
        multiselectsearch() {
          
      const data = [...this.multiselectinlineoptions];
      let data1 = []
      if (this.inlinemultisearchvalue != "" && this.inlinemultisearchvalue) {
        data1 = data.filter((e)=>{
          if(e[this.label]
            .toString()
            .toLowerCase()
            .includes(this.inlinemultisearchvalue.toLowerCase()))
            {
              return e
            }
        })
        this.filtereddata = data1
        console.log(this.inlinemultisearchvalue,'val',this.inlinemultisearchvalue);

        return data1
      } else {
        this.filtereddata = data

        return data;
      }
    },
    // selectedcheckbox(val)
    // {
    //     console.log(val,'valll');
    // },
    optioncontainer1()
    {
      // console.log(event.target);
       if(this.dropdownclose == true)
       {  
        this.$emit('arrow-click')
        if(event.target.className != 'hlx-tag multiselect-inline-tag-option' && event.target.className != 'content' && event.target.className != 'icon-times-regular')
        {
        this.dropdownopen = true
        this.dropdownclose = false
        let selectedcount = 0
        this.optionsD.forEach((e)=>{
          if(e.checked == true)
          {
            selectedcount = selectedcount + 1
          }
        })
        this.inline_count_value = selectedcount
        if(document.getElementById('inline-select-input'+this.unique))
            {
                let inputhtml = document.getElementById('inline-select-input'+this.unique)
                window.requestAnimationFrame(() => inputhtml.focus())

            }
        // document.querySelector('#inline-multi-select'+this.unique).classList.add('add-style')
        // document.querySelector('#inline-select-input'+this.unique).style.display = 'block'
        // document.querySelector('#inline-select-input'+this.unique).focus()
        document.querySelector('#arrow'+this.unique).classList.remove('icon-angle-down-regular')
        document.querySelector('#arrow'+this.unique).classList.add('icon-angle-up-regular') 
        this.containerleft = document.querySelector('#inline-multi-select'+this.unique).getBoundingClientRect().left
        this.containertop = document.querySelector('#inline-multi-select'+this.unique).getBoundingClientRect().top
        this.containerwidth = document.querySelector('#inline-multi-select'+this.unique).getBoundingClientRect().width

        }
       }
       else if(this.dropdownclose == false)
       { 
        this.dropdownopen = false
        this.dropdownclose = true
        // document.querySelector('#inline-multi-select-tag-value'+this.unique).style.display = 'flex'
        document.querySelector('#inline-multi-select'+this.unique).classList.remove('add-style')
        document.querySelector('#arrow'+this.unique).classList.add('icon-angle-down-regular')
        document.querySelector('#arrow'+this.unique).classList.remove('icon-angle-up-regular')
        if(document.querySelector("#inline-multi-select-tag-value" + this.unique)){
          this.resizedata()
        }
                let arr1= []
        this.optionsD.forEach((e)=>{
          if(e.checked == true)
          {
             arr1.push(e)
          }
        })
        this.$emit("custom-error-emit-value", arr1);
       }
    },
        switchdropdown(data)
    {
      // console.log(data);
                  this.$nextTick(()=>{
        if(document.querySelector('#inline-multi-select' + this.unique))
        {
          var element = document.querySelector('#inline-multi-select' + this.unique);
          var position = element.getBoundingClientRect();
          // var x = position.left;
          var y = position.top;
          // console.log(x,y,'pop',window.innerHeight - y);
          var innerheight = window.innerHeight - y
          if(innerheight < 250)
          {
            // console.log();
            let findlength = data.length+1
                    if(findlength == 2)
                    {
                      this.top = -94
                      this.left = -2
                    }
                    else if(findlength == 3)
                    {
                      this.top = -124
                      this.left = -2
                    }
                    else if(findlength == 4)
                    {
                      this.top = -154
                      this.left = -2
                    }
                    else if(findlength == 5)
                    {
                      this.top = -184
                      this.left = -2
                    }
                    else if(findlength == 6)
                    {
                      this.top = -213
                      this.left = -2
                    }
                    else if(findlength == 7)
                    {
                      this.top = -245
                      this.left = -2

                    }
                    else{
                      this.top = -267
                      this.left = -2
                    }
          }
          else{
            this.top = 45
            this.left = -1
          }
        }
      })
    },
    returnvalue(val) {
      // console.log(val,'op');

      // this.$emit("custom-change", val);

      let selectedvalue = []
      let count = 0 
      if(this.inlinemultisearchvalue != '')
      {
          val.forEach((e)=>{
            this.multiselectdata.forEach((ele)=>{
            if(e.checked == true)
               {
                 if(ele[this.propValue] == e[this.propValue])
                 {
                  // console.log(ele[this.propValue] == e[this.propValue]);
                  // console.log(ele);
                    ele.checked = true
                 }
               }
             else if(e.checked == false){
                if(ele[this.propValue] == e[this.propValue])
                 {
                    ele.checked = false
                 }
               }
           })
          })
          // console.log(this.multiselectdata,'data');
                        // this.$emit("custom-change", this.multiselectdata);

                this.multiselectinlineoptions = this.multiselectdata
                        this.multiselectinlineoptions.forEach((e)=>{
                    if(e.checked == true)
                    {
                      count = count+1
                      selectedvalue.push(e)
                    }
                  })
                  this.$emit("selected-array",selectedvalue)
                // this.inline_count_value = count
                if(document.querySelector("#inline-multi-select-tag-value" + this.unique)){
                  this.resizedata()
                }
      }
      else{
         this.multiselectdata = val
          this.multiselectinlineoptions = val;
        // console.log('oo');
              this.$emit("custom-change", val);

        this.resizedata();
        val.forEach((e)=>{
        if(e.checked == true)
        {
          count = count+1
          selectedvalue.push(e)
        }
       })
      this.$emit("selected-array",selectedvalue)
      }
    },
        datachange() {
        // console.log('aaa');
        let count2 = 0
        let datacount = 0 
        // console.log(e[0].contentRect.width);
        // console.log(this.tagwidth);
        this.tagwidth = 250
        if(count2 == 0)
         {
          this.showselectedvalue = []
          this.disabledvalue = []
          let countvalue = this.tagwidth /100
         let conditionvalue1 = Math.floor(countvalue)*100
         let conditionvalue2 = (Math.floor(countvalue)*100)+20
         if(conditionvalue1>this.tagwidth || this.tagwidth<conditionvalue2)
         {
           this.multiselecttagvalue = Math.floor(countvalue)
           this.multiselecttagvalue = this.multiselecttagvalue - 1
         }else{
           this.multiselecttagvalue = Math.floor(countvalue)
         }        
         let count1 = 0
        //  console.log(this.multiselectinlineoptions,'op');
         this.multiselectinlineoptions.forEach((e)=>{
          if(e.checked == true)
          {
            // console.log(e[this.propValue]);
            datacount = datacount +1
            // console.log(this.multiselecttagvalue,datacount);
            if(datacount <= this.multiselecttagvalue)
            {
               this.showselectedvalue.push(e)
            }
            else{
               count1 = count1 + 1
               this.disabledvalue.push(e[this.propValue])
            }
            this.inline_count_value = count1
          }
         })
         count2 = count2+1
         }
  console.log(this.showselectedvalue);
    },
    resizedata() {
      if(document.querySelector("#inline-multi-select-tag-value" + this.unique))
      {
        let count2 = 0
        let datacount = 0 
        if(count2 == 0)
        this.showselectedvalue = []
        this.disabledvalue = []
        this.eachtagWidth = 0
         {
        //   let countvalue = this.tagwidth /100
        //  let conditionvalue1 = Math.floor(countvalue)*100
        //  let conditionvalue2 = (Math.floor(countvalue)*100)+20
        //  console.log(conditionvalue1,conditionvalue2,'conditionvalue',this.tagwidth,conditionvalue1>this.tagwidth , this.tagwidth<conditionvalue2)
        //  if(conditionvalue1>this.tagwidth || this.tagwidth<conditionvalue2)
        //  {
        //    this.multiselecttagvalue = Math.floor(countvalue)
        //    this.multiselecttagvalue = this.multiselecttagvalue - 1
        //  }else{
        //    this.multiselecttagvalue = Math.floor(countvalue)
        //  } 
        let count1 = 0     
        this.optionsD.forEach((e)=>{
          const values = this.showselectedvalue.map(obj => obj[this.label]);
          // console.log(values.includes(e[this.label]),'values');
          if(e.checked == true && values.includes(e[this.label]) != true )
          {
            // this.showselectedvalue.push(e)
            let createElement = document.createElement('div')
            let iconElement = document.createElement('i')
            createElement.classList.add('find-height')
            iconElement.classList.add('icon-times-regular')
            iconElement.classList.add('find-icon')
            // console.log(iconElement,'iconElement');
            document.body.appendChild(createElement)
            createElement.innerText= e[this.label]
            createElement.appendChild(iconElement)
            this.eachtagWidth = this.eachtagWidth + createElement.offsetWidth;
            // console.log(this.eachtagWidth ,'lol')
            datacount = datacount +1
            if((this.eachtagWidth + 40 )<this.tagwidth)
            {
              this.showselectedvalue.push(e)
            }else{
              count1 = count1 + 1
              if(this.disabledvalue.includes(e[this.label]) != true)
              {
               this.disabledvalue.push(e[this.label])
               this.tooltipdata =  this.disabledvalue
                            .map((i) => {
                              return i;
                            })
                            .join(", ")
              }
            }
            this.inline_count_value = count1
          }
          // else if(e.checked == false && values.includes(e[this.label]) == true)
          // {
          // }
        })  
        //  let count1 = 0
        //  this.optionsD.forEach((e)=>{
        //   if(e.checked == true)
        //   {
        //     datacount = datacount +1
        //     if(datacount <= this.multiselecttagvalue)
        //     {
        //        this.showselectedvalue.push(e)
        //     }
        //     else{
        //        count1 = count1 + 1
        //        this.disabledvalue.push(e[this.label])
        //       this.tooltipdata =  this.disabledvalue
        //                     .map((i) => {
        //                       return i;
        //                     })
        //                     .join(", ")
        //     }
        //   }
        //     this.inline_count_value = count1
        //  })
         count2 = count2+1
         }
      }
  
    },
    addvalue() {
     
      console.log(this.inputvalue,'ooopo');
      var data= this.inlinemultisearchvalue
      let obj = {};
      obj[this.label] = data;
      obj[this.propValue] = data;
      obj.checked = true;
      obj.disabled = false;
      // this.multiselectinlineoptions.push(obj)
      this.inlinemultisearchvalue = ""
      // let selectedvalue = []
      //  this.multiselectinlineoptions.forEach((e)=>{
      //   if(e.checked == true)
      //   {
      //     selectedvalue.push(e)
      //   }
      //  })
      //  selectedvalue.push(obj)
      // this.$emit("visualRuleData",selectedvalue)
      this.$emit("add-data", obj,this.optionsD);
      // this.$emit("custom-change", this.multiselectinlineoptions);
      // this.resizedata();
    },
    removed(data) {
      let count = 0
      this.optionsD.map((e) => {
        if (e[this.propValue] === data) {
          e.checked = false;
          e.disabled = false;
          this.$emit('removed-value',e)
        }
        if(e.checked == true)
        {
          count = count+1
        }
        // return e;
      });
                    if(count == this.optionsD.length)
              {
                this.all_checked = true
                this.partially_checked = false
                              this.$nextTick(() => {
                                if(document.querySelector(
                  ".select-all" + this.unique
                ))
                {
                  // console.log('kslksk');
                document.querySelector(".select-all" + this.unique).checked = true;
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-grey");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-theme");
                }
              });
              }
              else if(count != this.optionsD.length && count>0){
                this.partially_checked = true
                this.all_checked = false
                              this.$nextTick(() => {
                //     document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).classList.add('partially-checked-color')             
                // document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).style.background = "#54bd95";
                // document.getElementById(
                //   "select-all-checkmark" + this.unique
                // ).style.border = "1px solid #54bd95";
                document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-grey");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-theme");
              });
              }
              else{
                this.all_checked = false
                 this.partially_checked = false;
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("select-all-theme");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-grey");
              }
              // if()
                      if (this.showSelectAll === true) {
          document.querySelector(".select-all" + this.unique).checked = false;
        }
              // document.querySelector(".select-all" + this.unique).checked = false;
    //   console.log(data);
    //  var a = []
    //   a = this.optionsD.map((e) => {
    //     if (e[this.propValue] === data) {
    //       e.checked = false;
    //       e.disabled = false;
    //     }
    //     return e;
    //   });

    //   this.optionsD = a

      // let data1 = this.optionsD.filter((e)=>{
      //    return e.checked == true
      // })
      // if(data1.length == 0)
      // {
      //   this.componentkey += 1
      //   this.partially_checked = false
      //   this.all_checked = false
      // }
      // console.log(data1.length);
      
      // this.multiselectinlineoptions = a
      // // console.log(this.multiselectinlineoptions);
      //           let selectedvalue = []
      //     let prevaalue = []
      //     this.multiselectinlineoptions.forEach((e)=>{
      //       if(e.checked == true)
      //       {
      //         selectedvalue.push(e)
      //         prevaalue.push(e[this.propValue])
      //       }
      //     })
      //     this.preval = prevaalue
      //     this.$emit("selected-array",selectedvalue)
      //     if(document.querySelector("#inline-multi-select-tag-value" + this.unique))
      //     {
      //       this.resizedata()
      //     }
      //   let arr1= []
      //   this.multiselectinlineoptions.forEach((e)=>{
      //     if(e.checked == true)
      //     {
      //        arr1.push(e)
      //     }
      //   })
      //   this.$emit("custom-error-emit-value", arr1);
      //   console.log(this.multiselectinlineoptions,'lllll',this.filtereddata);
      //   let count = this.multiselectinlineoptions.filter((e)=>{
      //        return e.checked === true
      //   })
      //   if(count.length == 0)
      //   {
      //     this.componentkey += 1
      //   }
      //   this.filtereddata = this.multiselectinlineoptions
      this.componentkey += 1
      let selectedvalue = []
       this.optionsD.forEach((e)=>{
        if(e.checked == true)
        {
          selectedvalue.push(e)
        }
       })
      this.$emit("selected-array", selectedvalue);
      this.$emit("custom-change", this.optionsD)
    },
    optioncontainer(event) {
      if (this.dropdowncontainer == false) {
        // console.log('akaak');
        this.dropdowncontainer = true;
        document
          .getElementById("arrow" + this.unique)
          .classList.add("icon-angle-up-regular");
        document
          .getElementById("arrow" + this.unique)
          .classList.remove("icon-angle-down-regular");
        document
          .getElementById("custom-multi-select" + this.unique)
          .classList.add("add-style");
      } else if (this.dropdowncontainer == true) {
        if (event.target.className !== "icon-times-regular") {
          this.dropdowncontainer = false;
          document
            .getElementById("arrow" + this.unique)
            .classList.remove("icon-angle-up-regular");
          document
            .getElementById("arrow" + this.unique)
            .classList.add("icon-angle-down-regular");
          document
            .getElementById("custom-multi-select" + this.unique)
            .classList.remove("add-style");
        }
      }
    },
    filtersave()
    {
       let selectedvalue = []
       this.multiselectinlineoptions.forEach((e)=>{
        if(e.checked == true)
        {
          selectedvalue.push(e[this.propValue])
        }
       })
      //  console.log(selectedvalue);
      //  this.$emit("custom-change", this.multiselectinlineoptions);
      //  this.$emit("selected-value",this.multiselectinlineoptions,selectedvalue)
    },
  },
};
</script>

<style>
.find-height{
  /* opacity:0; */
  padding:6px;
  font-size: 12px;
  font-weight: 500;
  font-family:'Opensans';
  position :absolute;
  visibility: hidden;
  top:-9999px;
  letter-spacing: 0.005em;
}
.find-icon{
  font-size: 15px;
  margin-left: 2px;
}
</style>
