<template>
  <nav  class="breadcrumb" aria-label="Breadcrumb">
    <ul v-if="type==='Pathway-breadcrumb'">
      <li v-for="(item, index) in items" :key="index" @click="current(item)">
        <i
          v-if="item.icon != undefined"
          aria-hidden="true"
          :class="item.icon"
        ></i>
        <a
          v-if="index === items.length - 1"
          :id="'breadcrumb' + index + unique"
          style="
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 45px;
            width: auto;
            max-width: 80px;
            display: inline-block;
          "
          @mouseover="
            tooltipover(
              'breadcrumb' + index + unique,
              'tooltip-text' + 'breadcrumb' + index + unique
            )
          "
          @mouseleave="
            tooltipleave(
              'breadcrumb' + index + unique,
              'tooltip-text' + 'breadcrumb' + index + unique
            )
          "
          >{{ item.label }}
          <p
            id="tooltip-text1"
            :class="'tooltip-text' + 'breadcrumb' + index + unique"
          >
            {{ item.label }}
          </p></a
        >
        <a
          v-else
          :id="'breadcrumb' + unique + index"
          class="show-breadcrumb"
          style="
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 45px;
            width: auto;
            max-width: 80px;
            display: inline-block;
          "
          @mouseover="
            tooltipover(
              'breadcrumb' + unique + index,
              'tooltip-text' + 'breadcrumb' + unique + index
            )
          "
          @mouseleave="
            tooltipleave(
              'breadcrumb' + unique + index,
              'tooltip-text' + 'breadcrumb' + unique + index
            )
          "
          >{{ item.label }}
          <p
            id="tooltip-text1"
            :class="'tooltip-text' + 'breadcrumb' + unique + index"
          >
            {{ item.label }}
          </p></a
        >
        <!-- <router-link :to="item.link" :class="{ disabled: index === items.length - 1 } " >{{ item.label }}</router-link> -->
        <span v-if="index < items.length - 1" class="breadcrumb-separator">
          <i
            v-if="isIconSeparator"
            aria-hidden="true"
            :class="separator.icon"
          ></i>
          <span v-else>{{ separator }}</span>
        </span>
      </li>
    </ul>
  <div v-if="type==='back-breadcrumb'" class="back-breadcrumb" style="
    display: flex;
    cursor:pointer;
    align-items: center;
    gap: 8px;
    max-width: 100px;
" @click="current('back')"><i style="font-weight: 800;" class="icon-arrow-left-regular" ></i><p>{{labelValue}}</p></div>
  </nav>

</template>

<script>
export default {
  name: "HlxBreadcrumb",
  props: {
    labelValue: {
        type: String,
        default: "Back",
      },
     autoRoute:{
      type:Boolean,
       default:false
    },
    type: {
      type: String,
      default: "back-breadcrumb",
    },
    items: {
      type: Array,
      required: true,
    },
    separator: {
      type: [String, Object],
      default: "/",
    },
  },
  emits: ["path"],
  data() {
    return {
      unique: Math.floor(Math.random() * 1000 + 1),
    };
  },
  computed: {
    isIconSeparator() {
      return typeof this.separator === "object";
    },
  },
  methods: {
    current(item) {
      if(this.autoRoute === true){
        this.$router.back();
      }
      this.$emit("path", item);
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      console.log(data, index);
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let height = tooltip.offsetHeight;
              console.log(element1.getBoundingClientRect().top);
              let top = element1.getBoundingClientRect().top;
              let left = element1.getBoundingClientRect().left + 77;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
