<template>
  <Teleport to="body" class="alert-not">
    <section v-if="notifications.length > 0" class="hlx-alert-wrapper">
      <div
        v-for="notification in notifications"
        :id="'alert' + notification.id"
        :ref="'hlx-alert-' + notification.id"
        :key="notification"
        class="hlx alert"
        :class="getClass(notification)"
      >
        <span class="icon">
          <i :class="icon(notification.type)"></i>
        </span>
        <div class="text">
          <p class="alert-description">{{ notification.message }}</p>
        </div>
        <button
          class="close"
          aria-label="close"
          @click="closeAlert(notification.id)"
        >
          <i class="icon-times-filled"></i>
        </button>
      </div>
    </section>
  </Teleport>
</template>

<script>
export default {
  name: "HlxAlertNotification",
  // props: ["type", "message", "showAlert","autoClose",'delay'],
  props: {
    notify: {
      type: Object,
      default: () => ({})
    },
    autoClose: {
      type: Boolean,
      default: () => true
    },
    delay: {
      type: Number,
      default (d) {
        return d > 3000 ? d : 2000
      },
      validator(v) {
        return v > 3000 ? v : 2000;
      }
    }
  },
  data() {
    return {
      notifications: [],
      alertStatus: false,
      typeData: ""
    };
  },
  computed: {
    computedAlert() {
      return this.alertStatus;
    }
  },
  watch: {
    notify: {
      handler (data) {
        // console.log("🚀 ~ file: AlertComponent.vue:57 ~ handler ~ data:", data)
        const id = Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
        if (Object.keys(data).length > 0) {
          this.push({ ...data, id })
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getClass(notification){
      return notification?.type
            ? 'alert-' + notification?.type.toLowerCase()
            : 'alert-success'
    },
    closeAlert (id) {
      // console.log("🚀 ~ file: AlertComponent.vue:77 ~ closeAlert ~ id:", id)
      this.$refs['hlx-alert-' + id][0]?.classList.add('close')
      setTimeout(() => {
        this.notifications = this.notifications.filter(
          (notify) => notify.id && notify.id != id
        );
      }, 1000);
    },
    push (notify) {
      // console.log("🚀 ~ file: AlertComponent.vue:85 ~ push ~ notify:", notify)
      this.notifications.push({ ...notify })
      if (this.autoClose) {
        setTimeout(() => {
          this.$refs['hlx-alert-' + notify.id][0]?.classList.add('close')
          setTimeout(() => {
            this.notifications.shift()
          }, 1000)
        }, this.delay + this.notifications.length * 1000)
      }
      // pushs = pushs+1500
    },
    icon (type) {
      if (type) {
        switch (type.toLowerCase()) {
          case 'danger':
            return 'icon-exclamation-circle-regular'
          case 'warning':
            return 'icon-exclamation-circle-regular'
          case 'primary':
            return 'icon-info-circle-regular'
          default:
            return 'icon-check-circle-regular'
        }
      }
      return "tick-bg";
    }
  }
};
</script>
