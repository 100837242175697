<template>
  <hlx-overlay v-if="show" :show ="show" :top ="top" :left="left" :width="width" :conditionvalue="conditionvalue" :conditionup="conditionup" :conditiondown = "conditiondown" @close-overlay="onClose">
  <template #overlay>
  <div v-show="show" class="options-container">
    <div
      v-for="(i, index) in options"
      :id="'option' + index"
      :key="index"
      class="option"
      @click="select(index, i.label);$emit('chosen',i.label)"
    >
      <i
        v-if="i.icon != undefined && i.icon != ''"
        :class="i.icon"
        style="margin-right: 8px"
      ></i
      ><span>{{ i[display] || i.label || null }}</span>
    </div>
  </div>
  </template>
  </hlx-overlay>
</template>

<script>
import hlxOverlay from '../components/OverlayComponent.vue'
export default {
  name: "HlxContextMenu",
  components:{
    hlxOverlay
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    display: {
      type: String,
      default: "label",
    },
    data: {
      type: String,
      default: "",
    },
    top:{
        type:Number,
        default:0
      },
      width:{
        type:Number,
        default:0
      },
      left:{
        type:Number,
        default:0
      },
      conditionvalue:{
        type:Number,
        default:0
      },
      conditionup:{
        type:Number,
        default:0
      },
      conditiondown:{
        type:Number,
        default:0
      }
  },
  emits: ["chosen", 'close-context-menu'],
  data() {
    return {
      custom_options: [],
    };
  },

  watch: {
    // options: {
    //   handler (val) {
    //     if (val) {
    //       this.custom_options = JSON.parse(JSON.stringify([...val]))
    //     }
    //   },
    //   immediate:true,
    //   deep: true
    // }
  },
  mounted() {
    var indexx = this.options.findIndex((x) => x.checked == true);
    if (indexx != -1 && indexx != undefined) {
      this.select(indexx, this.options[indexx]["label"]);
    }
  },

  methods: {
    select(index, name) {
      name
      // console.log("🚀 ~ file: ContextMenuComponent.vue:98 ~ select ~ index:", index)
      document.querySelectorAll(".option").forEach((d) => {
        if (d.classList.contains("selected-context-menu")) {
          d.classList.remove("selected-context-menu");
        }
      });
      // document.querySelector(`#option${index}`).classList.add('selected-context-menu')
      // if (this.data != null && this.data != "") {
      //   this.$emit("chosen", name, this.data);
      // } else {
      //   this.$emit("chosen", name);
      // }
    },
    onClose(){
      this.$emit("close-context-menu")
    }
  },
};
</script>
